import React from "react";
import PropTypes from "prop-types";
import {
	Button,
	Card,
	CardBody,
	Container,
	DataTable,
	Spinner,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Input,
	toast,
	CardHeader,
	MDBIcon,
	MDBCol,
	MDBRow,
	MDBSelect,
	MDBCard,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import ProductService from "../Security/ProductService/productTypeService";
import ProductSubTypeService from "../Security/ProductService/productSubTypeService";
import InsuranceService from "../Security/InsuranceService/insuranceService";
import ReactTooltip from "react-tooltip";
import TrainingLinkService from "./trainingLinkService";
import EquipmentTrainingLinkService from "./EquipmentTrainingLinkService";

export default class productTypes extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
					width: 100,
				},
				{
					label: "Name",
					field: "name",
					sort: "asc",
					width: 200,
				},
			],
			rows: [],
		};

		this.state = {
			data: data,
			isLoaded: false,
			modal: false,
			addNewModal: false,
			currIndex: 0,
			currProduct: {},
			newProduct: {
				insuranceMatrixEntry: [],
				requiresSerialNumber: false,
			},
			errorMessage: "",
			insuranceList: [],
			insuranceLoaded: false,
			videosLoaded: false,
			allVideos: [],
			vidsList: [],
			fullVideosLoaded: false,
			chosenVideo: {},
			insurance: [],
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		insuranceTypes: PropTypes.array,
	};

	componentDidMount() {
		this.retrieveInsurance();
		this.getAllVideos();
	}

	getAllVideos() {
		TrainingLinkService.getAllTrainingLinks().then((r) => {
			let obj = [];

			r.forEach((v) => {
				obj.push({ text: v.name + ":   " + v.description, value: v });
			});

			this.setState({
				allVideos: r,
				vidsList: obj,
				fullVideosLoaded: true,
			});
		});
	}

	getCategoryVideos(id) {
		EquipmentTrainingLinkService.getVideosByCategory(id).then((r) => {
			this.setState({
				currentEquipmentVideos: r,
				videosLoaded: true,
			});
		});
	}

	handleVideoChange(video) {
		this.setState({ chosenVideo: video[0] });
	}

	addVideo() {
		let video = this.state.chosenVideo,
			prod = this.state.currProduct,
			v = this.state.currentEquipmentVideos;

		if (!video.id) {
			return;
		}

		if (
			v.findIndex((vals) => {
				return vals.trainingLinkId === video.id;
			}) > -1
		) {
			toast.warning("Video already exists foRr this product.");
			return;
		}

		this.setState({
			videosLoaded: false,
		});

		let vo = {
			name: video.name,
			productCategoryId: prod.id,
			trainingLinkId: video.id,
			productSubTypeId: -1,
		};

		EquipmentTrainingLinkService.createTrainingLink(vo)
			.then((r) => {
				v.push(r);

				this.setState({
					currentEquipmentVideos: v,
					chosenVideo: {},
					//videosLoaded: false,
				});
			})
			.catch((err) => {
				console.log(err);
			})
			.finally((a) => {
				this.setState({
					videosLoaded: true,
				});
			});
	}

	retrieveInsurance() {
		const { insuranceTypes } = this.context;
		let t = this;
		let d = this.state.data,
			l = [];
		if (!insuranceTypes || insuranceTypes.length === 0) {
			return InsuranceService.findAllInsurance()
				.then((res) => {
					res.forEach((insurance) => {
						d.columns.push({
							label: insurance.name,
							field: insurance.name,
							sort: "asc",
							width: 100,
						});
						l.push(insurance.name);
					});
					// // I push this here so the buttons are at the end of the data table
					d.columns.push(
						{
							label: "View Products",
							field: "editButton",
							sort: "asc",
							width: 100,
						},
						{
							label: "Edit Category",
							field: "editCategory",
							sort: "asc",
							width: 100,
						},
					);
					t.setState({
						data: d,
						insuranceList: l,
						insuranceTypes: res,
						insuranceLoaded: true,
					});
					this.retrieveProducts();
				})
				.catch((err) => {
					console.log(err);
				});
		}
		else {
			insuranceTypes.forEach((insurance) => {
				d.columns.push({
					label: insurance.name,
					field: insurance.name,
					sort: "asc",
					width: 100,
				});
				l.push(insurance.name);
			});
			// // I push this here so the buttons are at the end of the data table
			d.columns.push(
				{
					label: "View Products",
					field: "editButton",
					sort: "asc",
					width: 100,
				},
				{
					label: "Edit Category",
					field: "editCategory",
					sort: "asc",
					width: 100,
				},
			);
			t.setState({
				data: d,
				insuranceList: l,
				insuranceTypes: insuranceTypes,
				insuranceLoaded: true,
			});
			this.retrieveProducts();
        }
	}

	retrieveProducts() {
		let t = this;
		return ProductService.getAllProducts()
			.then((res) => {
				console.log(res)
				let arr = [],
					dt = this.state.data;
					
				// Sorts the insurance arr in each product so its easier to add to each row of the table
				res.forEach((element) => {
					element.insuranceMatrixEntry.sort(function (a, b) {
						return a.insuranceType.sortOrder - b.insuranceType.sortOrder;
					});
				});

				res.forEach((product, index) => {
					arr.push(this.makeRows(product, index));
				});

				dt.rows = arr;
				t.setState({
					data: dt,
					isLoaded: true,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	makeRows(product, index) {
		let l = this.state.insuranceList,
			obj = {};
			
		obj.id = product.id;
		obj.name = product.name || "no name";
		l.forEach((name, index) => {
			obj[name] = product.insuranceMatrixEntry && product.insuranceMatrixEntry.length > 0 &&
				product.insuranceMatrixEntry[index] &&
				product.insuranceMatrixEntry[index].points &&
				typeof product.insuranceMatrixEntry[index].points === "number"
				? product.insuranceMatrixEntry[index].points
					: "no points";
		});
		obj.editButton = this.renderRouteButton(product, index);
		obj.editCategory = this.renderCategoryButton(product, index);
		return obj;
	}

	renderCategoryButton(product, index) {
		return (
				<MDBIcon icon="edit"
						 style={{color: "#7ac5ff"}}
						 onClick={() => {
							 let p = JSON.parse(JSON.stringify(product));
							 this.getCategoryVideos(p.id);
							this.setState({
								currIndex: index,
								currProduct: p,
								modal: !this.state.modal,
							});
						}}/>
		);
	}

	renderRemoveButton(product) {
		return (
			<MDBIcon icon="trash"
				style={{ color: "#db0404" }}
				onClick={() => this.handleRemoveClick(product)}
			/>
		);
	}

	// handleRemoveClick(product) {
	// 	const{currentUser}=this.context;
	//
	//  ProductService.deleteProduct(product)
	// 		.then((res) => {
	// 			if(res.delete === true){
	// 				let d = this.state.data;
	// 				d.rows = [];
	// 				this.setState({
	// 					data: d,
	// 					modal: false,
	// 					isLoaded: false,
	// 				});
	// 				toast.success("Product Removed");
	// 				this.retrieveProducts();
	// 			}
	// 			else{
	// 				toast.warn("An error occurred trying to delete this product.");
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			toast.warn("Error occurred while removing Product");
	// 		});
	// }

	toggleEdit = () => {
		this.setState({
			modal: !this.state.modal,
			errorMessage: "",
		});
	};

	removeVideoFromEquipment(v, idx) {
		this.setState({
			videosLoaded: false,
		});

		let myVideos = this.state.currentEquipmentVideos.slice();

		EquipmentTrainingLinkService.deleteTrainingLink(v.id)
			.then((r) => {
				myVideos.splice(idx, 1);
				this.setState({
					currentEquipmentVideos: myVideos,
				});
			})
			.catch((e) => {
				toast.warning("There was an error.  Try again later.");
			})
			.finally((a) => {
				this.setState({
					videosLoaded: true,
				});
			});
	}

	addNewToggle = () =>{
		this.setState({
			addNewModal: false,
			newProduct:	{insuranceMatrixEntry: [], requiresSerialNumber: false, }
			}
		)
	}

	renderAddNewModal() {
		return (
			<Modal isOpen={this.state.addNewModal} toggle={this.addNewToggle}>
				<ModalHeader
					style={{ backgroundColor: "#5881C1", color: "white" }}
					toggle={this.addNewToggle}
				>
					Add New Product Category
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					{this.renderForm()}
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Add New Payor Source"}
						onClick={this.handleAddNewSave}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Cancel"}
						onClick={this.addNewToggle}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

	handleAddNewSave = () => {
		if (this.validateAddNew()) {
			let cp = this.state.newProduct;
			cp.insuranceMatrixEntry.forEach((insurance) => {
				parseInt(insurance.points, 10);
			});

			return ProductService.createProduct(cp)
				.then(() => {
					toast.success(cp.name + " Product Type Created Has Been Created");
					this.getInsuranceTypes();
					this.retrieveProducts();
					this.setState({
						newProduct: {
							name: "",
							insuranceMatrixEntry: [],
							requiresSerialNumber: false,
						},
						errorMessage: "",
					});
					this.addNewToggle();
				})
				.catch((err) => {
					toast.warn("An error occurred while saving");
				});
		}
	};

	renderForm() {
		return (
			<div>
				<p style={{ color: "red" }}>{this.state.errorMessage}</p>
				<Input
					outline
					label={"Name"}
					value={this.state.newProduct.name}
					onChange={this.handleNewNameChange.bind(this)}
				/>
				<Input
					filled
					type="checkbox"
					id="requiresSerialNumber"
					value="Requires Serial Number"
					label="Requires Serial Number"
					checked={this.state.currProduct.requiresSerialNumber}
					onChange={this.handleNewCheckChange.bind(this)}
				/>
				<MDBRow>{this.renderInputs()}</MDBRow>
			</div>
		);
	}

	renderInputs() {
		let l = this.state.insuranceList;
		let cp = this.state.newProduct.insuranceMatrixEntry;
		console.log(l)
		return l.map((name, index) => {
			return (
				<MDBCol size="4">
					<Input
						type="number"
						outline
						key={index}
						value={cp[index] ? cp[index].points : ""}
						onChange={this.handleNewInsuranceChange.bind(this, index)}
						label={name}
					/>
				</MDBCol>
			);
		});
	}

	handleNewInsuranceChange(property, e) {
		let p = this.state.newProduct;
		p.insuranceMatrixEntry[property].points = parseFloat(e.target.value);
		this.setState({
			currProduct: p,
		});
	}

	handleNewCheckChange = (event) => {
		var p = this.state.newProduct;
		p.requiresSerialNumber = !p.requiresSerialNumber;
		this.setState({ newProduct: p });
	};

	renderModal() {
		return (
			<Container>
				<Modal size={"lg"} isOpen={this.state.modal} toggle={this.toggleEdit}>
					<ModalHeader
						toggle={this.toggleEdit}
						style={{ backgroundColor: "#90A4AE", color: "white" }}
					>
						Edit Product Type
					</ModalHeader>
					<ModalBody style={{ backgroundColor: "white" }}>
						<Container>
							<p style={{ color: "red" }}>{this.state.errorMessage}</p>
							<Input
								value={this.state.currProduct.name}
								onChange={this.handleNameChange.bind(this, "name")}
								label="Name"
								size="sm"
							/>
							<Input
								filled
								type="checkbox"
								id="requiresSerialNumber"
								value="Requires Serial Number"
								label="Requires Serial Number"
								checked={this.state.currProduct.requiresSerialNumber}
								onChange={this.handleCheckChange.bind(this)}
							/>
							<MDBRow>{this.renderModalBody()}</MDBRow>
						</Container>

						<MDBRow>
							<MDBCol size="10">
								<MDBSelect
									outline
									color="default"
									style={{ maxHeight: "100px", marginTop: 1 }}
									options={this.state.vidsList}
									label={"Choose a Video to Add"}
									getValue={this.handleVideoChange.bind(this)}
								/>
							</MDBCol>
							<MDBCol size="2">
								<MDBIcon
									data-tip={"Add Video"}
									size={"2x"}
									icon={"plus"}
									style={{ color: "green" }}
									onClick={() => this.addVideo()}
								/>
							</MDBCol>
						</MDBRow>

						<MDBRow>{this.renderProductVideos()}</MDBRow>
					</ModalBody>
					<ModalFooter style={{ backgroundColor: "white" }}>
						<Button
							floating
							size="sm"
							color={"success"}
							data-tip={"Update Product Type"}
							onClick={this.saveButtonClick}
						>
							<MDBIcon icon="check" style={{ fontSize: "2em" }} />
						</Button>

						<Button
							floating
							size="sm"
							color={"red"}
							data-tip={"Cancel"}
							onClick={this.closeButtonClick}
						>
							<MDBIcon icon="times" style={{ fontSize: "2em" }} />
						</Button>

						<ReactTooltip />
					</ModalFooter>
				</Modal>
			</Container>
		);
	}

	renderProductVideos() {
		let t = this;

		function deleteButton(v, idx) {
			return (
				<MDBIcon
					style={{ color: "red" }}
					icon={"trash"}
					onClick={() => t.removeVideoFromEquipment(v, idx)}
				/>
			);
		}

		if (!this.state.videosLoaded) {
			return <Spinner small />;
		}
		return this.state.currentEquipmentVideos.map((v, idx) => {
			return (
				<MDBCol size="6">
					<MDBCard key={idx} style={{ margin: 6, padding: 4 }}>
						<MDBRow>
							<MDBCol size="9">{v.name}</MDBCol>

							<MDBCol size="3">{deleteButton(v, idx)}</MDBCol>
						</MDBRow>
					</MDBCard>
				</MDBCol>
			);
		});
	}

	handleCheckChange = (event) => {
		let p = this.state.currProduct;
		p.requiresSerialNumber = !this.state.currProduct.requiresSerialNumber;
		this.setState({
			currProduct: p,
		});
	};

	renderModalBody() {
		if (this.state.modal) {
			let l = this.state.insuranceList,
				insurance = this.state.currProduct.insuranceMatrixEntry;

			return l.map((name, index) => {
				return (
					<MDBCol size="4">
						<Input
							type="number"
							key={index}
							label={name}
							value={insurance[index] && insurance.length > 0 ? insurance[index].points : ""}
							onChange={this.handleInsuranceChange.bind(this, index)}
							size="sm"
						/>
					</MDBCol>
				);
			});
		}
	}

	handleInsuranceChange(property, e) {
		let np = this.state.currProduct;

		if (!np.insuranceMatrixEntry || np.insuranceMatrixEntry.length === 0) {
			const { insuranceTypes } = this.context;
			let it = insuranceTypes.sort((a, b) => {
				return a.sortOrder - b.sortOrder;
			});
			console.log(it);
			np.insuranceMatrixEntry = [];
			it.forEach(x => {
				np.insuranceMatrixEntry.push({
					deleted: false,
					deletedAt: null,
					deletedById: null,
					insuranceType: x,
					insuranceTypeId: x.id,
					points: 0,
					//productTypeId: 
				});
			});
		}
		np.insuranceMatrixEntry[property].points = parseFloat(e.target.value);
		this.setState({
			currProduct: np,
		});
	}

	handleNewNameChange(e) {
		let np = this.state.newProduct;
		np.name = e.target.value;
		this.setState({
			newProduct: np,
		});
	}

	handleNameChange(property, e) {
		let p = this.state.currProduct;
		p[property] = e.target.value;
		this.setState({
			currProduct: p,
		});
	}

	closeButtonClick = () => {
		this.setState({
			currProduct: {},
			modal: !this.state.modal,
			errorMessage: "",
		});
	};

	saveButtonClick = () => {
		let d = this.state.data,
			i = this.state.currIndex,
			cp = this.state.currProduct,
			l = this.state.insuranceList;

		if (this.validate()) {
			return ProductService.updateProduct(cp)
				.then((product) => {
					console.log(product)
					//product.insuranceMatrixEntry.sort((a, b) => a.insuranceType.sortOrder - b.insuranceType.sortOrder);
					d.rows[i].name = product.name;
					l.forEach((name, index) => {
						d.rows[i][name] = product.insuranceMatrixEntry[index].points;
					});
					d.rows[i].editCategory = this.renderCategoryButton(product, i);

					this.setState({
						data: d,
						modal: !this.state.modal,
					});
					toast.success("Product Edited!");
				})
				.catch((err) => {
					toast.warn("An error occurred while saving.");
				});
		}
	};

	validateAddNew(){
		let cp = this.state.newProduct,
			cc = this.state.newProduct.insuranceMatrixEntry,
			i = 0;
		cc.forEach((insurance) => {
			if (insurance.points === undefined || insurance.points === "") {
				this.setError(
					"Please enter points for " + insurance.insuranceType.name
				);
				i += 1;
			}
		});
		if (cp.name === undefined || cp.name === "") {
			this.setError("Please enter a Name");
			i += 1;
		}
		return i === 0;
	}

	validate() {
		let cp = this.state.currProduct,
			cc = this.state.currProduct.insuranceMatrixEntry,
			l = this.state.insuranceList,
			i = 0;

		l.forEach((name, index) => {
			if (!cc || cc.length === 0 || cc[index].points === "") {
				this.setError("Please enter " + name + " Points");
				i += 1;
			}
		});
		if (cp.name === "") {
			this.setError("Please enter a Name");
			i += 1;
		}
		return i === 0;
	}

	setError(e) {
		this.setState({
			errorMessage: e,
		});
	}

	renderRouteButton(product, index) {
		let arr = [];
		return (

			<MDBIcon far icon="eye"
				key={index}
				size="sm"
				floating
				onClick={() => {
					ProductSubTypeService.getOneProductSubType(product.id)
						.then((res) => {
							res.forEach((element) => {
								arr.push({
									id: element.id,
									name: element.name,
									deleted: element.deleted,
									deletedAt: element.deletedAt,
									hcps: element.hcps,
									productType: element.productType.name,
								});
							});
						})
						.catch((err) => {
							console.log(err);
						});
					this.props.history.push({
						pathname: "products/" + product.id,
						state: {
							product: product,
						},
					});
				}} />

		);
	}

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<DataTable striped info={false} small data={{rows: this.state.data.rows, columns: this.state.data.columns}}>
					{" "}
				</DataTable>
			);
		} else {
			return <div></div>;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	addNewClick = () => {
		const { insuranceTypes } = this.context;
		let np = this.state.newProduct,
			it = insuranceTypes.sort((a,b) => {
				return a.sortOrder - b.sortOrder;
			});
		console.log(it);
		np.insuranceMatrixEntry = [];
		it.forEach(x => {
			np.insuranceMatrixEntry.push({
				deleted: false,
				deletedAt: null,
				deletedById: null,
				insuranceType: x,
				insuranceTypeId: x.id,
				points: 0,
				//productTypeId: 
			});
		});
		this.setState({
			addNewModal: true,
			newProduct: np,
		})
		// return this.props.history.push({
		// 	pathname: "products/new",
		// });
	};

	render() {
		if (this.state.isLoaded === true && this.state.insuranceLoaded === true) {
			return (
				<div>
					<ToastContainer
						hideProgressBar={false}
						newestOnTop={true}
						autoClose={3000}
					/>
					<Container className="mt-5" style={{ maxWidth: "80%" }}>
						<Card>
							<CardHeader
								style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize: 30 }}>
								Product Categories
								<Button
									style={{ float: "right" }}
									floating
									size="sm"
									color={"secondary"}
									data-tip={"Add New Product Category"}
									onClick={this.addNewClick.bind()}
								>
									<MDBIcon icon="cart-plus" style={{ fontSize: "2em" }} />
								</Button>
							</CardHeader>
							<CardBody>{this.renderTable()}</CardBody>

							<ReactTooltip />
						</Card>
						{this.renderModal()}
						{this.renderAddNewModal()}
					</Container>
				</div>
			);
		} else {
			return this.renderLoadingSpinner();
		}
	}
}
