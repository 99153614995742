import React from "react";
import {
    MDBBtn,
    MDBCol,
    MDBRow,
    MDBCard,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
    MDBIcon,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBInput,
    toast, MDBModal, MDBContainer, Input,
} from "mdbreact";

import Select from "react-select";
import partsService from "../../Security/ProductService/partsService";
import "./createQuoteModal.css";
import moment from "moment";
import orderService from "../../Security/OrderService/orderService";

const columns = [
    {
        label: "ID",
        field: "id",
        sort: "asc",
    },
    {
        label: "Vendor",
        field: "vendor",
        sort: "asc",
    },
    {
        label: "Product",
        field: "product",
        sort: "asc",
    },
    {
        label: "Equipment",
        field: "equipment",
        sort: "asc",
    },
    {
        label: "Qty",
        field: "qty",
        sort: "asc",
    },
    {
        label: " ",
        field: "remove",
        sort: "asc",
    },
];

const searchColumns = [
    {
        label: "Item ID",
        field: "itemId",
        sort: "asc",
    },
    {
        label: "Manufacturer",
        field: "manufacturer",
        sort: "asc",
    },
    {
        label: "Description",
        field: "description",
        sort: "asc"
    }
]

export default class finalQuoteModal extends React.Component {
    constructor(props) {
        super(props);

        this.togglefinalQuoteModalPopup = props.togglefinalQuoteModalPopup;

        this.closeModal = props.closeModal;

        this.state = {
            orderId: props.order.id,
            patientName: props.order.patientName,
            eO: props.order,

            isFinalized: false,
            isValid: false,
            allServiceParts: [],
            productList: [],
            equipment: [],
            vendorList: [],
            servicePartsByVendor: [],
            allQuoteItems: [],

            productId: null,
            productVendor: null,
            selectedProduct: null,
            selectedVendor: null,
            selectedEquipment: null,
            qty: 1,
            selectedQty: 1,

            prevOrderId: null,
            currentOrder: props.order,
            currentProducts: props.products || [],
            prevOrder: null,

            patient: null,
            currentCondition: null,
            makeModel: props.order.currentModel || "",
            baseCode: null,
            neglectOrNormalUse: null,
            serialNumber: props.order.serialNumber || "",
            underWarranty: true,
            deliveryDate: null,
            originalPurchaser: null,
            technicianRequired: true,
            serviceDescription: null,
            previousRepairs: null,



            make: null,
            model: null,
            prevOrderProductType: null,
            curOrderProductType: null,

            techRequired: "",
            warranty: "",

            small: this.props.small,
            onSmall: this.props.onSmall,
            isDisabled: true,

            nProduct: {
                manufacturer: "",
                model: "",
                description: "",
                itemId: "",
                msrp: 0.00,
                hcpc: "",
                cost: 0.00,
                deleted: false,
            },
            nProductQty: 1,
            showAddProduct: false,
            shipToPatient: false,

            searchQuery: '',
            searchResults: [],
            selectedRow: {},
        };
    }

    componentDidMount() {
        this.getAllParts();
        this.getServiceReason();
    }

    createProductAddToQuote() {
        var prod = this.state.nProduct;
        partsService.createPart(prod)
            .then(res => {
                console.log(res)
                if (res) {
                    let st = this.state,
                        e = this.state.allQuoteItems,
                        row = {
                            itemId: res.id,
                            vendor: res.manufacturer,
                            product: res.model,
                            equipment: res.itemId,
                            qty: parseInt(st.nProductQty),
                        };
                    e.push(row);

                    this.setState({
                        selectedEquipment: null,
                        nProductQty: 1,
                        allQuoteItems: e,
                        nProduct: {
                            manufacturer: "",
                            model: "",
                            description: "",
                            itemId: "",
                            msrp: 0.00,
                            hcpc: "",
                            cost: 0.00,
                            deleted: false,
                        },
                        showAddProduct: false,
                    });
                }
                else
                    toast.warn("There was an issue creating the part.");
            })
            .catch(err => {
                console.log(err);
                toast.error("There was an issue creating the part.");
            })
    }

    getServiceReason() {
        let id = this.state.orderId;

        orderService
            .getServiceOrderReason(id)
            .then((data) => {
                this.setState({
                    prevOrderId: data.previousOrderId,
                });
            })
            .catch((err) => {
                // console.log(err);
            });
    }

    filterOrders() {
        //let currentProducts = this.state.currentOrder.orderActivityProducts.map(
        //    (x) => x.products
        //);
        //let prevProducts = this.state.prevOrder.orderActivityProducts.map(
        //    (d) => d.products
        //);

        let currentProducts = this.state.orderProducts;
        let prevProducts = this.state.prevProducts;

        let sn = this.state.prevOrder.serialNumber;
        let pt = prevProducts.map((e) => e.productType.name);
        let cpt = currentProducts.map((e) => e.productType.name);
        let pst = prevProducts.map((e) => e.productSubType.name);
        let cst = currentProducts.map((e) => e.productSubType.name);
        let chcpcs = currentProducts.map((e) => e.hcpcs);
        let phcpcs = prevProducts.map((e) => e.hcpcs);

        if (
            pt.toString() === cpt.toString() &&
            pst.toString() === cst.toString() &&
            chcpcs.toString() === phcpcs.toString()
        ) {
            this.setState({
                serialNumber: sn,
                makeModel: pt.toString() + "/" + pst.toString(),
                baseCode: chcpcs.toString(),
            });
        }
    }

    getOrderHistory() {
        let id = this.state.prevOrderId;

        orderService
            .getOrderActivity(id)
            .then((data) => {
                this.setState({
                    prevOrder: data,
                    deliveryDate: moment(data.setupDate).format("MM/DD/YYYY"),
                });
                //this.filterOrders();
                orderService.getOrderProducts(id)
                    .then(res => {
                        this.setState({
                            prevProducts: res,
                        })
                        //this.filterOrders();
                    })
                    .catch(e => {
                        console.log(e)
                    })
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getAllParts() {
        partsService
            .getAllParts()
            .then((r) => {
                this.setState({
                    vendorList: r && r.length > 0 ? [...new Set(r.map((x) => x.manufacturer))] : [],
                    allServiceParts: r && r.length > 0 ? r.filter(x => x.deleted !== true) : [],
                });
            })
            .catch((e) => {
                console.log(e);
                this.setState({});
            });
    }

    handleCreateQuoteBtn() {
        this.setState({ isFinalized: true });
        this.getOrderHistory();
    }

    finalQuote() {
        let arr = [];
        this.state.allQuoteItems.map((r, i) => {
            return arr.push({
                itemId: r.itemId,
                qty: r.qty,
            });
        });

        partsService
            .createQuote(this.state.orderId, arr, this.state.shipToPatient)
            .then((r) => {
                if (r === true) {
                    this.setState({
                        //allQuoteItems: [],
                        isFinalized: true,
                    });
                    this.getOrderHistory();
                    toast.success("Quote created.");
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    createRxForm() {
        let items = this.state.allQuoteItems || [],
            orderId = this.state.orderId,
            drName = this.state.currentOrder.orderActivityClinicalInfo ? this.state.currentOrder.orderActivityClinicalInfo.name : (this.state.currentOrder.patient.ciName || ""),
            drNpi = this.state.currentOrder.orderActivityClinicalInfo ? this.state.currentOrder.orderActivityClinicalInfo.npi : (this.state.currentOrder.patient.ciNpi || ""),
            serviceReason = this.state.serviceDescription ? this.state.serviceDescription.trim() : "",
            arr = [];
        items.map((r) => {
            return arr.push({
                itemId: r.itemId,
                qty: r.qty,
            });
        });
        var obj = {
            items: arr,
            doctorName: drName,
            npi: drNpi,
            serviceReason: serviceReason,
        };

        partsService.createRx(orderId, obj)
            .then(res => {
                if (res === true) {
                    toast.success("Prescription created. Refresh the page to view the new attachment.");
                    this.setState({
                        allQuoteItems: [],
                        //serviceDescription: null,
                    })
                }
                else {
                    toast.warn("There was an error creating the prescription.");
                }
            })
            .catch(ex => {
                console.log(ex)
            })
    }

    getVendorList() {
        let vendors = this.state.vendorList;
        let a = [];
        vendors.map((v) => {
            return a.push({
                label: v,
                value: v,
            });
        });
        return a;
    }

    getProductItemId(equipment) {
        let cat = this.state.selectedProduct;
        let all = this.state.allServiceParts;

        all.filter(
            (x) => x.description === equipment.value && x.model === cat.value
        ).map((d) => {
            this.setState({
                productId: d.id,
            });
            return true;
        });
    }

    getProductsByVendor(vendor) {
        let a = [];
        let all = this.state.allServiceParts;
        all.filter((x) => x.manufacturer === vendor.value).map((p) => {
            if (a.filter((r) => r.label === p.model).length === 0) {
                return a.push({
                    label: p.model,
                    value: p.model,
                });
            }
            return null;
        });
        this.setState({
            productList: [...new Set(a)],
        });
    }

    getEquipmentByProduct(product) {
        let a = [];
        let all = this.state.allServiceParts;

        //Filter selected products
        let currentIds = [];
        let products = this.state.allQuoteItems;
        if (products.length > 0) {
            products.map((p) => currentIds.push(p.itemId));
        }
        //End filter selected products

        all.filter(
            (x) =>
                x.model === product.value &&
                x.manufacturer === this.state.productVendor.value
        ).map((p) => {
            if (a.filter((r) => r.label === p.description).length === 0) {
                if (currentIds.length > 0 && currentIds.includes(p.id)) {
                    return null;
                }
                return a.push({
                    label: p.description,
                    value: p.description,
                });
            }
            return null;
        });

        a.sort(function (a, b) {
            if (a.label.toLowerCase() < b.label.toLowerCase()) {
                return -1;
            }

            return 0;
        });

        this.setState({
            equipment: [...new Set(a)],
        });
    }

    handleVendorChange = (e) => {
        if (e.value == null) {
            e = null;
        }

        this.setState({
            productVendor: e,
            selectedProduct: null,
            selectedEquipment: null,
            selectedQty: 1,
        });
        this.getProductsByVendor(e);
    };

    handleProductChange = (e) => {
        if (e.value == null) {
            e = null;
        }

        this.setState({
            selectedProduct: e,
            selectedEquipment: null,
            selectedQty: 1,
        });
        this.getEquipmentByProduct(e);
    };

    handleEquipmentChange = (e) => {
        if (e.value == null) {
            e = null;
        }

        this.setState({
            selectedEquipment: e,
        });
        this.getProductItemId(e);
    };

    handleQtyChange = (e) => {
        if (e.target.value == null) {
            e.target.value = null;
        }
        this.setState({
            selectedQty: e.target.value,
        });
    };

    renderVendorDropdown() {
        return (
            <div>
                <Select
                    placeholder={ "Vendor..." }
                    options={this.getVendorList()}
                    onChange={this.handleVendorChange.bind(this)}
                    value={this.state.productVendor}
                />
            </div>
        );
    }

    renderProductDropdown() {
        return (
            <div>
                <Select
                    isDisabled={
                        this.state.productVendor !== null
                            ? !this.state.isDisabled
                            : this.state.isDisabled
                    }
                    placeholder={ "Product..." }
                    value={this.state.selectedProduct}
                    options={this.state.productList}
                    onChange={this.handleProductChange.bind(this)}
                />
            </div>
        );
    }

    renderEquipmentDropdown() {
        return (
            <div>
                <Select
                    isDisabled={
                        this.state.selectedProduct !== null
                            ? !this.state.isDisabled
                            : this.state.isDisabled
                    }
                    placeholder={ "Equipment..." }
                    options={this.state.equipment}
                    value={this.state.selectedEquipment}
                    onChange={this.handleEquipmentChange.bind(this)}
                />
            </div>
        );
    }

    renderProductInput() {
        return (
            <>
                <input
                    type="number"
                    id="quantity"
                    className={
                        this.state.selectedEquipment !== null
                            ? "activeInput"
                            : "disabledInput disabled"
                    }
                    style={{
                        borderRadius: "5%",
                        backgroundColor: "white",
                        width: "100%",
                        minHeight: "94%",
                        textAlign: "right",
                    }}
                    placeholder="Qty"
                    min="1"
                    onChange={this.handleQtyChange.bind()}
                    value={this.state.selectedQty}
                ></input>
            </>
        );
    }

    validateInput = (e) => {
        if (!e.target.value || !e.target.value.trim()) {
            e.target.className += " invalid";
        } else {
            e.target.classList.replace("invalid", "normal");
        }
    };

    validateTextarea = (e) => {
        if (!e.target.value || !e.target.value.trim()) {
            e.target.className += " invalidTextarea";
        } else {
            e.target.classList.replace("invalidTextarea", "normalTextarea");
        }
    };

    handleSkipPASummary() {
        window.location.reload();
        // toast.success("PDF Created.");
    }

    handleSkipCreateQuote() {
        this.setState({
            //allQuoteItems: [],
            isFinalized: true,
        });
    }

    handleCreatePdfBtn() {
        let st = this.state,
            paSummary = {
                orderId: st.orderId,
                patient: st.patientName.trim(),
                currentCondition: st.currentCondition.trim(),
                makeModel: st.makeModel.trim(),
                baseCode: st.baseCode.trim(),
                neglectOrNormalUse: st.neglectOrNormalUse.trim(),
                serialNumber: st.serialNumber.trim(),
                underWarranty: st.warranty,
                deliveryDate: moment(st.deliveryDate)
                    .format("MM/DD/YYYY")
                    .trim(),
                originalPurchaser: st.originalPurchaser.trim(),
                technicianRequired: st.techRequired,
                serviceDescription: st.serviceDescription.trim(),
                previousRepairs: st.previousRepairs.trim(),
            };

        let paSummaryValues = Object.values(paSummary);

        if (paSummaryValues.includes(null || "")) {
            toast.error("All fields are required.");
        } else {
            orderService
                .buildPaSummary(paSummary)
                .then((r) => {
                    toast.success("PDF Created.");
                    setTimeout(() => {
                        //this.finalQuote();
                        this.createRxForm();
                    }, 100);
                })
                .then((r) => {
                    //toast.success("PDF Created.");
                    //setTimeout(() => {
                    //    this.finalQuote();
                    //    this.createRxForm();
                    //}, 100);
                    setTimeout(() => {
                        this.props.onToggle();
                        this.props.saveOrder(this.state.eO)
                        window.location.reload();
                    }, 1000);
                })
                .catch((e) => {
                    toast.error(
                        "An error occurred while creating a PA summary."
                    );
                    console.log(e);
                });
        }
    }

    onClick = (nr) => () => {
        this.setState({
            techRequired: nr,
        });
    };

    onWarrantyClick = (nr) => () => {
        this.setState({
            warranty: nr,
        });
    };

    handleTextChange(property, e) {
        e.target.classList.replace("invalid", "normal");

        this.setState({
            [property]: e.target.value,
        });
    }

    renderPASummary() {
        return (
            <>
                <form
                    className="needs-validation"
                    onSubmit={this.validateInput}
                    noValidate
                >
                    <MDBRow>
                        <MDBCol md={"6"}>
                            <MDBInput
                                label="Patient Name"
                                outline
                                required
                                value={
                                    this.state.patientName !== null
                                        ? this.state.patientName
                                        : this.handleTextChange.bind(
                                            this,
                                            "patientName"
                                        )
                                }
                                onChange={this.handleTextChange.bind(
                                    this,
                                    "patientName"
                                )}
                                onBlur={this.validateInput}
                            ></MDBInput>
                        </MDBCol>
                        <MDBCol md={"6"}>
                            <MDBInput
                                required
                                label="Current Condition"
                                outline
                                onChange={this.handleTextChange.bind(
                                    this,
                                    "currentCondition"
                                )}
                                onBlur={this.validateInput}
                            ></MDBInput>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={"9"}>
                            <MDBInput
                                label="Equipment Make/Model"
                                outline
                                value={
                                    this.state.makeModel !== null
                                        ? this.state.makeModel
                                        : this.handleTextChange.bind(
                                            this,
                                            "makeModel"
                                        )
                                }
                                onChange={this.handleTextChange.bind(
                                    this,
                                    "makeModel"
                                )}
                                onBlur={this.validateInput}
                            ></MDBInput>
                        </MDBCol>
                        <MDBCol md={"3"}>
                            <MDBInput
                                label="Base Code"
                                value={
                                    this.state.baseCode !== null
                                        ? this.state.baseCode
                                        : this.handleTextChange.bind(
                                            this,
                                            "baseCode"
                                        )
                                }
                                onChange={this.handleTextChange.bind(
                                    this,
                                    "baseCode"
                                )}
                                onBlur={this.validateInput}
                                outline
                            ></MDBInput>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={"6"}>
                            <MDBInput
                                label="Neglect or Normal Use"
                                outline
                                onChange={this.handleTextChange.bind(
                                    this,
                                    "neglectOrNormalUse"
                                )}
                                onBlur={this.validateInput}
                            ></MDBInput>
                        </MDBCol>
                        <MDBCol md={"6"}>
                            <MDBInput
                                label="Serial Number"
                                outline
                                value={
                                    this.state.serialNumber !== null
                                        ? this.state.serialNumber
                                        : this.handleTextChange.bind(
                                            this,
                                            "serialNumber"
                                        )
                                }
                                onChange={this.handleTextChange.bind(
                                    this,
                                    "serialNumber"
                                )}
                                onBlur={this.validateInput}
                            ></MDBInput>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow style={{ padding: "20px 0px 30px" }}>
                        <MDBCol md={"3"}>Under Warranty? </MDBCol>
                        <MDBCol md={"2"}>
                            <MDBInput
                                gap
                                onClick={this.onWarrantyClick(true)}
                                checked={
                                    this.state.warranty === true ? true : false
                                }
                                label="Yes"
                                type="radio"
                                id="warranty1"
                                containerClass="mr-5"
                            />{" "}
                        </MDBCol>
                        <MDBCol md={"1"}>
                            <MDBInput
                                gap
                                onClick={this.onWarrantyClick(false)}
                                checked={
                                    this.state.warranty === false ? true : false
                                }
                                label="No"
                                type="radio"
                                id="warranty2"
                                containerClass="mr-5"
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={"3"}>
                            <MDBInput
                                label="Delivery Date"
                                outline
                                type="date"
                                value={this.state.deliveryDate}
                                onChange={this.handleTextChange.bind(
                                    this,
                                    "deliveryDate"
                                )}
                                onBlur={this.validateInput}
                            ></MDBInput>
                        </MDBCol>
                        <MDBCol md={"9"}>
                            <MDBInput
                                label="Original Purchaser"
                                id="ogPurchaser"
                                onChange={this.handleTextChange.bind(
                                    this,
                                    "originalPurchaser"
                                )}
                                outline
                                onBlur={this.validateInput}
                            ></MDBInput>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow style={{ padding: "20px 0px 30px" }}>
                        <MDBCol md={"3"}>Technician Required? </MDBCol>

                        <MDBCol md={"2"}>
                            <MDBInput
                                gap
                                onClick={this.onClick(true)}
                                checked={
                                    this.state.techRequired === true
                                        ? true
                                        : false
                                }
                                label="Yes"
                                type="radio"
                                id="techRequired1"
                                containerClass="mr-5"
                            />{" "}
                        </MDBCol>
                        <MDBCol md={"2"}>
                            <MDBInput
                                gap
                                onClick={this.onClick(false)}
                                checked={
                                    this.state.techRequired === false
                                        ? true
                                        : false
                                }
                                label="No"
                                type="radio"
                                id="techRequired2"
                                containerClass="mr-5"
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={"12"}>
                            <div className="form-group">
                                <label
                                    htmlFor="serviceDescription"
                                    style={{
                                        textDecoration: "underline",
                                        fontWeight: "500",
                                    }}
                                >
                                    Description of Service:
                                </label>
                                <MDBInput
                                    required
                                    type="textarea"
                                    outline
                                    id="serviceDescription"
                                    style={{ width: "100%" }}
                                    className="form-control"
                                    onChange={this.handleTextChange.bind(
                                        this,
                                        "serviceDescription"
                                    )}
                                    rows="3"
                                    onBlur={this.validateTextarea}
                                />
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={"12"}>
                            <div className="form-group">
                                <label
                                    htmlFor="prevRepairs"
                                    style={{
                                        textDecoration: "underline",
                                        fontWeight: "500",
                                    }}
                                >
                                    Previous Repairs:
                                </label>
                                <MDBInput
                                    required
                                    type="textarea"
                                    outline
                                    id="prevRepairs"
                                    style={{ width: "100%" }}
                                    className="form-control"
                                    onChange={this.handleTextChange.bind(
                                        this,
                                        "previousRepairs"
                                    )}
                                    rows="3"
                                    onBlur={this.validateTextarea}
                                />
                            </div>
                        </MDBCol>
                    </MDBRow>
                </form>
            </>
        );
    }

    handleRemoveItem(i) {
        let all = this.state.allQuoteItems;
        all.splice(i, 1);
        this.setState({
            allQuoteItems: [...new Set(all)],
        });
        this.getEquipmentByProduct(this.state.selectedProduct);
    }

    renderRemoveItem(i) {
        return (
            <MDBIcon
                icon="times"
                style={{ cursor: "pointer" }}
                onClick={() => this.handleRemoveItem(i)}
            />
        );
    }

    addProduct() {
        let st = this.state,
            e = st.allQuoteItems,
            row = {
                itemId: st.productId,
                vendor: st.productVendor.value,
                product: st.selectedProduct.value,
                equipment: st.selectedEquipment.value,
                qty: parseInt(st.selectedQty),
            };
        e.push(row);

        this.setState({
            selectedEquipment: null,
            selectedQty: 1,
        });
        this.getEquipmentByProduct(this.state.selectedProduct);
    }

    renderRows() {
        let arr = [];

        this.state.allQuoteItems.map((r, i) => {
            return arr.push({
                id: r.itemId,
                vendor: r.vendor,
                product: r.product,
                equipment: r.equipment,
                qty: r.qty,
                remove: this.renderRemoveItem(i),
            });
        });
        return arr;
    }

    renderSearchRows() {
        let arr = [];

        this.state.searchResults.map((r, i) => {
            return arr.push({
                itemId: r.itemId,
                manufacturer: r.manufacturer,
                description: r.description,
                clickEvent: () => {
                    this.setState({ selectedRow: r });
                    this.rowClickHandler(r);
                }
            });
        });

        return arr;
    }

    small() {
        this.state.onSmall();
    }

    rowClickHandler = (r) => {
        this.setState({
            searchResults: [],
            searchQuery: '',
            selectedRow: r,
            productId: r.id,
            productVendor: {
                label: r.manufacturer, value: r.manufacturer
            },
            selectedProduct: { label: r.model, value: r.model },
            selectedEquipment: { label: r.description, value: r.description },
            selectedQty: 1,
        })
    }

    productIsValid() {
        let prod = this.state.nProduct;
        return !(prod.manufacturer && prod.manufacturer.trim().length > 0 &&
            prod.model && prod.model.trim().length > 0 &&
            prod.itemId && prod.itemId.trim().length > 0 &&
            prod.hcpc && prod.hcpc.trim().length > 0 &&
            prod.msrp !== null && prod.cost !== null &&
            prod.description && prod.description.trim().length > 0);
    }

    render() {
        if (this.props.small) {
            return null
        }
        else
            return (
                <MDBModal centered size={"lg"} isOpen={true} disableBackdrop={false}>
                    <MDBCard>
                        {!this.state.isFinalized ? (
                            <div>
                                <MDBModalHeader>
                                    <h3>Create Quote</h3>
                                    <MDBIcon
                                        icon="minus"
                                        onClick={this.small.bind(this)}
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                            top: "22px",
                                            right: "55px",
                                        }}
                                    />
                                    <MDBIcon
                                        icon="times"
                                        onClick={this.props.onToggle}
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                            top: "22px",
                                            right: "25px",
                                        }}
                                    />
                                </MDBModalHeader>
                                <MDBModalBody>
                                    <MDBContainer>
                                        <MDBRow center className="form-group">
                                            <MDBCol md={"12"} >
                                                <MDBInput
                                                    outline
                                                    icon="search"
                                                    group
                                                    label="Item ID"
                                                    style={{ backgroundColor: "white" }}
                                                    containerClass={"smallMargin"}
                                                    value={this.state.searchQuery}
                                                    onChange={(e) => {
                                                        let parts = this.state.allServiceParts,
                                                            filteredParts = e.target && e.target.value ? parts.filter((p) => p.itemId ? p.itemId.toLowerCase().includes(e.target.value.toLowerCase()) : null) : null;

                                                        this.setState({
                                                            searchQuery: e.target.value,
                                                            searchResults: filteredParts,
                                                        })
                                                    }}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                    {this.state.searchResults && this.state.searchResults.length > 0 ? (
                                        <div style={{ maxHeight: "400px", overflowY: "auto", position: "absolute", width: "96%", backgroundColor: "white", zIndex: "9999", border: "1px solid darkgray" }}>
                                            <MDBTable hover striped small>
                                                <MDBTableHead columns={ searchColumns } />
                                                <MDBTableBody id="tableBody" style={{ cursor: "pointer" }} rows={ this.renderSearchRows() } />
                                            </MDBTable>
                                        </div>)
                                        : ""}
                                    <MDBRow>
                                        <MDBCol md={"3"}>
                                            {" "}
                                            {this.renderVendorDropdown()}
                                        </MDBCol>
                                        <MDBCol md={"3"}>
                                            {" "}
                                            {this.renderProductDropdown()}
                                        </MDBCol>
                                        <MDBCol md={"4"}>
                                            {" "}
                                            {this.renderEquipmentDropdown()}
                                        </MDBCol>
                                        <MDBCol md={"2"}>
                                            {" "}
                                            {this.renderProductInput()}
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow end>
                                         <MDBBtn
                                            style={{ marginRight: "14px" }}
                                            size="sm"
                                            color="primary"
                                            data-tip={"Add Product"}
                                            onClick={() => this.setState({ showAddProduct: true })}
                                        >
                                            Add New Product
                                        </MDBBtn> 
                                        <MDBBtn
                                            className={
                                                this.state.selectedEquipment !== null
                                                    ? ""
                                                    : "disabled"
                                            }
                                            style={{ marginRight: "14px" }}
                                            size="sm"
                                            color="primary"
                                            data-tip={"Add to Quote"}
                                            onClick={() => this.addProduct()}
                                        >
                                            Add to Quote
                                        </MDBBtn>
                                    </MDBRow>
                                    <MDBRow end style={{ marginRight: "0px" }}>
                                        <Input
                                            onClick={() => this.setState({ shipToPatient: true })}
                                            checked={this.state.shipToPatient}
                                            label="Ship To Patient"
                                            type="radio"
                                            id="shipToPatient"
                                        />
                                        <Input
                                            onClick={() => this.setState({ shipToPatient: false })}
                                            checked={!this.state.shipToPatient}
                                            label="Ship To Service Location"
                                            type="radio"
                                            id="shipToService"
                                        />
                                    </MDBRow>
                                    {this.state.showAddProduct ?
                                        <MDBRow style={{ display: (this.state.showAddProduct === false ? "none" : "flex") }}>
                                        <MDBCol md="4">
                                            <MDBInput
                                                placeholder="Manufacturer"
                                                outline
                                                type="text"
                                                onChange={(e) => {
                                                    var p = this.state.nProduct;
                                                    p.manufacturer = e.target.value;
                                                    this.setState({
                                                        nProduct: p,
                                                    })
                                                }}
                                            />
                                        </MDBCol>
                                        <MDBCol md="4">
                                            <MDBInput
                                                placeholder="Model"
                                                outline
                                                type="text"
                                                onChange={(e) => {
                                                    var p = this.state.nProduct;
                                                    p.model = e.target.value;
                                                    this.setState({
                                                        nProduct: p,
                                                    })
                                                }}
                                            />
                                        </MDBCol>
                                        <MDBCol md="4">
                                            <MDBInput
                                                placeholder="Item ID"
                                                outline
                                                type="text"
                                                onChange={(e) => {
                                                    var p = this.state.nProduct;
                                                    p.itemId = e.target.value;
                                                    this.setState({
                                                        nProduct: p,
                                                    })
                                                }}
                                            />
                                        </MDBCol>
                                        <MDBCol md="3">
                                            <MDBInput
                                                placeholder="HCPC"
                                                outline
                                                type="text"
                                                onChange={(e) => {
                                                    var p = this.state.nProduct;
                                                    p.hcpc = e.target.value;
                                                    this.setState({
                                                        nProduct: p,
                                                    })
                                                }}
                                            />
                                        </MDBCol>
                                        <MDBCol md="3">
                                            <MDBInput
                                                placeholder="MSRP"
                                                outline
                                                type="number"
                                                onChange={(e) => {
                                                    var p = this.state.nProduct;
                                                    var val = e.target.value;
                                                    if (val)
                                                        p.msrp = parseFloat(parseFloat(e.target.value).toFixed(2));
                                                    else
                                                        p.msrp = null;
                                                    this.setState({
                                                        nProduct: p,
                                                    })
                                                }}
                                            />
                                        </MDBCol>
                                        <MDBCol md="3">
                                            <MDBInput
                                                placeholder="Cost"
                                                outline
                                                type="number"
                                                onChange={(e) => {
                                                    var p = this.state.nProduct;
                                                    var val = e.target.value;
                                                    if (val)
                                                        p.cost = parseFloat(parseFloat(e.target.value).toFixed(2));
                                                    else
                                                        p.cost = null;
                                                    this.setState({
                                                        nProduct: p,
                                                    })
                                                }}
                                            />
                                        </MDBCol>
                                        <MDBCol md="3">
                                            <MDBInput
                                                placeholder="Cost"
                                                valueDefault={this.state.nProductQty || 1}
                                                outline
                                                type="number"
                                                onChange={(e) => {
                                                    var val = parseInt(e.target.value);
                                                    this.setState({
                                                        nProductQty: val,
                                                    })
                                                }}
                                            />
                                        </MDBCol>

                                        <MDBCol md="12">
                                            <MDBInput
                                                placeholder="Description"
                                                style={{ marginTop: "0!important" }}
                                                outline
                                                type="text"
                                                onChange={(e) => {
                                                    var p = this.state.nProduct;
                                                    p.description = e.target.value;
                                                    this.setState({
                                                        nProduct: p,
                                                    })
                                                }}
                                            />
                                        </MDBCol>

                                        <MDBCol md="7"></MDBCol>
                                        <MDBCol md="2">
                                            <MDBBtn
                                                color="red"
                                                size="md"
                                                onClick={() => {
                                                    var p = {
                                                        manufacturer: "",
                                                        model: "",
                                                        description: "",
                                                        itemId: "",
                                                        msrp: 0.00,
                                                        hcpc: "",
                                                        cost: 0.00,
                                                        deleted: false,
                                                    };
                                                    this.setState({
                                                        nProduct: p,
                                                        showAddProduct: false,
                                                        nProductQty: 1,
                                                    })
                                                }}
                                            >
                                                Cancel
                                            </MDBBtn>
                                        </MDBCol>
                                        <MDBCol md="3">
                                            <MDBBtn
                                                disabled={this.productIsValid()}
                                                color="success"
                                                size="md"
                                                onClick={() => this.createProductAddToQuote()}
                                            >
                                                Create and Add Product
                                            </MDBBtn>
                                        </MDBCol>

                                        <MDBCol><hr /></MDBCol>
                                    </MDBRow> : <div></div> }

                                    <MDBRow>
                                        <MDBCol>Parts in Quote</MDBCol>
                                    </MDBRow>
                                    <MDBTable striped small>
                                        <MDBTableHead columns={columns} />
                                        <MDBTableBody rows={this.renderRows()} />
                                    </MDBTable>
                                </MDBModalBody>
                                <MDBModalFooter>
                                    <MDBBtn color="secondary" size="md" onClick={() => this.handleSkipCreateQuote()}>Skip</MDBBtn>
                                    <MDBBtn
                                        size="md"
                                        color="success"
                                        className={
                                            this.state.allQuoteItems < 1
                                                ? "disabled"
                                                : ""
                                        }
                                        onClick={() => this.finalQuote()}
                                    >
                                        Create Quote(s)
                                    </MDBBtn>
                                </MDBModalFooter>
                            </div>
                        ) : (
                            <>
                                <MDBModalHeader>
                                    <h5
                                        style={{
                                            fontWeight: "bold",
                                            textDecoration: "underline",
                                        }}
                                    >
                                        Prior Authorization Repair Summary
                                    </h5>
                                    <MDBIcon
                                        icon="minus"
                                        onClick={this.small.bind(this)}
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                            top: "22px",
                                            right: "55px",
                                        }}
                                    />
                                </MDBModalHeader>

                                <MDBModalBody>{this.renderPASummary()}</MDBModalBody>

                                <MDBModalFooter>
                                    <span
                                        style={{
                                            textDecoration: "none",
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                            // marginTop: "20px",
                                            position: "absolute",
                                            left: "18px",
                                            color: "red",
                                        }}
                                    >
                                        All fields are required.
                                    </span>
                                    <MDBBtn color="secondary" size="md" onClick={() => this.handleSkipPASummary()}>Skip</MDBBtn>
                                    {/*<MDBBtn color="primary" size="md" onClick={() => this.createRxForm()}>Create RX</MDBBtn>*/}
                                    <MDBBtn
                                        className={
                                            !this.state.currentCondition ||
                                                !this.state.makeModel ||
                                                !this.state.baseCode ||
                                                !this.state.neglectOrNormalUse ||
                                                !this.state.serialNumber ||
                                                this.state.underWarranty === "" ||
                                                !this.state.deliveryDate ||
                                                !this.state.originalPurchaser ||
                                                this.state.techRequired === "" ||
                                                !this.state.serviceDescription ||
                                                !this.state.previousRepairs
                                                ? "disabled"
                                                : ""
                                        }
                                        size="md"
                                        color="success"
                                        type="submit"
                                        onClick={() => this.handleCreatePdfBtn()}
                                    >
                                        <MDBIcon
                                            icon="download"
                                            style={{ marginRight: "6px" }}
                                        />
                                        Submit
                                    </MDBBtn>
                                </MDBModalFooter>
                            </>
                        )}
                    </MDBCard>
                </MDBModal>
            );
    }
}
