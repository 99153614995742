import React from "react";
import {
    Input,
    Modal,
    MDBIcon,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from "mdbreact";
import ReactTooltip from "react-tooltip";
import Select from "react-select";

export default class CreateModal extends React.Component {
    constructor(props) {
        super(props)

        this.saveMethod = props.saveMethod;
        this.toggleMethod = props.toggleMethod;

        let obj = {};
        if (props.dataModel) {
            props.dataModel.forEach(m => {
                obj[m.fieldName] = m.defaultValue === undefined ? null : m.defaultValue;
            })
        }

        this.state = {
            dataModel: props.dataModel, // { fieldName: string, columnName: string (display name), hidden: bool, type: string (i.e.: same types for entry field, defaults to text), required: bool, options: array of objects for select list (text / value) }
            header: props.header || "Create",
            isOpen: props.isOpen,
            newObject: obj || {},
            errorMessage: null,
            filteredOptions: [],
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.isOpen !== prevState.isOpen) {
            return {
                isOpen: nextProps.isOpen,
            };
        }
        if (nextProps.dataModel !== prevState.dataModel) {
            return {
                dataModel: nextProps.dataModel,
            }
        }
    }

    validateAndSave() {
        let model = this.state.dataModel,
            reqs = model.filter(x => x.required === true) || [],
            obj = this.state.newObject,
            msg = "";

        if (reqs && reqs.length > 0) {
            reqs.forEach(r => {
                if ([null, undefined].includes(obj[r.fieldName])) {
                    msg += r.columnName + " cannot be empty.\n"
                }
            })
        }

        if (msg.length === 0) {
            this.setState({
                errorMessage: null,
            })
            delete obj.id;
            this.saveMethod(obj);
            this.toggleMethod();
        }
        else {
            this.setState({
                errorMessage: msg,
            })
        }
    }

    setValue(field, value) {
        let obj = this.state.newObject;
        obj[field] = value;
        this.setState({
            newObject: obj,
        })
    }

    render() {
        var model = this.props.dataModel || this.state.dataModel;
        return (
            <Modal isOpen={this.state.isOpen}
                toggle={() => this.toggleMethod()}>

                <ModalHeader style={{ backgroundColor: "rgb(88, 129, 193)", color: "white"}}>
                    <h3>{this.state.header}</h3>
                </ModalHeader>

                <ModalBody>
                    <div>
                        <p style={{ color: "red" }}>{this.state.errorMessage}</p>
                        {model && model.length > 0 ? model.map(r => {
                            if (!r.hidden) {
                                //if (m.canEdit !== true) {
                                //    return (<td><label style={{ paddingTop: "8px", minWidth: "20px" }}>{r[m.fieldName]}</label></td>);
                                //}
                                switch (r.type) {
                                    case "bool":
                                        return (
                                            <div style={{padding: "6px"}}>
                                                <div style={{ width: "40%", display: "inline-block" }}>
                                                    <label>{r.columnName} </label>{r.required === true ? <label style={{ color: "red" }}>*</label> : ''}
                                                </div>
                                                <div style={{ width: "60%", display: "inline-block" }}>
                                                    <Input
                                                        type="checkbox" label=" "
                                                        id={"chk_" + r.fieldName + "_"}
                                                        checked={(this.state.newObject[r.fieldName] === true)}
                                                        onChange={(e) => this.setValue(r.fieldName, e.target.checked)}
                                                    />
                                                </div>
                                            </div>)
                                    case "int":
                                    case "float":
                                    case "number":
                                        return (
                                            <div style={{ padding: "6px" }}>
                                                <div style={{ width: "40%", display: "inline-block" }}>
                                                    <label>{r.columnName} </label>{r.required === true ? <label style={{ color: "red" }}>*</label> : ''}
                                                </div>
                                                <div style={{ width: "60%", display: "inline-block" }}>
                                                    <input
                                                        placeholder={r.columnName}
                                                        type={"number"}
                                                        value={this.state.newObject[r.fieldName] || ""}
                                                        title={this.state.newObject[r.fieldName]}
                                                        onChange={(e) => {
                                                            if (r.type === "float")
                                                                this.setValue(r.fieldName, parseFloat(e.target.value))
                                                            else if (r.type === "int")
                                                                this.setValue(r.fieldName, parseInt(e.target.value))
                                                            else
                                                                this.setValue(r.fieldName, e.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>)
                                    case "dropdown":
                                        let opts = r.options;
                                        if (r.filter && this.state.newObject[r.filter]) {
                                            opts = opts.filter(x => x.realValue[r.filter] === this.state.newObject[r.filter])
                                        }
                                        return (
                                            <div style={{ padding: "6px" }}>
                                                <div style={{ width: "40%", display: "inline-block" }}>
                                                    <label>{r.columnName} </label>{r.required === true ? <label style={{ color: "red" }}>*</label> : ''}
                                                </div>
                                                <div style={{ width: "60%", display: "inline-block" }}>
                                                    <Select
                                                        options={opts}
                                                        onChange={e => {
                                                            this.setValue(r.fieldName, e.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>)
                                    case "text":
                                    default:
                                        return (<div style={{ padding: "6px" }}>
                                            <div style={{ width: "40%", display: "inline-block" }}>
                                                <label>{r.columnName} </label>{r.required === true ? <label style={{ color: "red" }}>*</label> : ''}
                                            </div>
                                            <div style={{ width: "60%", display: "inline-block" }}>
                                                <input
                                                    placeholder={r.columnName}
                                                    type={"text"}
                                                    value={this.state.newObject[r.fieldName] || ""}
                                                    style={{ width: "80%" }}
                                                    title={this.state.newObject[r.fieldName]}
                                                    onChange={(e) => {
                                                        this.setValue(r.fieldName, e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>)
                                }
                                //if (r.type === "select" && r.options && r.options.length > 0) {
                                //}
                                //else {
                                //    //return (
                                //    //    <div>
                                //    //        <div style={{ width: "40%", display: "inline-block" }}>
                                //    //            <label>{r.columnName} </label>{r.required === true ? <label style={{ color: "red" }}>*</label> : ''}
                                //    //        </div>
                                //    //        <div style={{ width: "60%", display: "inline-block" }}>
                                //    //            <input type={r.type} placeholder={r.columnName} onChange={(e) => this.setValue(r.fieldName, e.target.value)} />
                                //    //        </div>
                                //    //    </div>
                                //    //);
                                //    return (
                                //        <div>
                                //            <div style={{ width: "40%", display: "inline-block" }}>
                                //                <label>{r.columnName} </label>{r.required === true ? <label style={{ color: "red" }}>*</label> : ''}
                                //            </div>
                                //            <div style={{ width: "60%", display: "inline-block" }}>
                                //                {
                                //                    r.type === "bool" ?
                                //                        <Input
                                //                            type="checkbox" label=" "
                                //                            id={"chk_" + r.fieldName + "_"}
                                //                            checked={(this.state.newObject[r.fieldName] === true)}
                                //                            onChange={(e) => this.setValue(r.fieldName, e.target.checked)}
                                //                        /> :
                                //                        <input
                                //                            type={(["float", "int", "number"].includes(r.type) ? "number" : "text")}
                                //                            value={this.state.newObject[r.fieldName] || ""}
                                //                            style={{ width: "80%" }}
                                //                            title={this.state.newObject[r.fieldName]}
                                //                            onChange={(e) => {
                                //                                if (r.type === "float")
                                //                                    this.setValue(r.fieldName, parseFloat(e.target.value))
                                //                                else if (r.type === "int")
                                //                                    this.setValue(r.fieldName, parseInt(e.target.value))
                                //                                else
                                //                                    this.setValue(r.fieldName, e.target.value)
                                //                            }}
                                //                        />
                                //                }
                                //                {/*<input type={r.type} placeholder={r.columnName} onChange={(e) => this.setValue(r.fieldName, e.target.value)} />*/}
                                //            </div>
                                //        </div>
                                //    );
                                //}
                            }
                            return '';
                        }) : ''}
                        {this.props.dataModel && this.props.dataModel.filter(x => x.required === true).length > 0 ?
                            <p style={{ color: "red" }}>* Indicates Required Fields</p>
                            : ''}
                    </div>

                </ModalBody>

                <ModalFooter style={{ backgroundColor: "rgb(88, 129, 193)", color: "white" }}>
                    <div style={{ float: "left", display: "inline-block" }}>

                        <Button
                            style={{ float: "left" }}
                            color={"red"}
                            floating
                            size="sm"
                            data-tip={"Cancel"}
                            onClick={() => {
                                this.setState({
                                    errorMessage: null,
                                })
                                this.toggleMethod()
                            }}
                        >
                            <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                        </Button>

                        <Button
                            style={{ float: "left" }}
                            color={"green"}
                            floating
                            size="sm"
                            data-tip={"Save"}
                            onClick={() => {
                                this.validateAndSave();
                            }}
                        >
                            <MDBIcon icon="check" style={{ fontSize: "2em" }} />
                        </Button>
                    </div>
                </ModalFooter>

                <ReactTooltip />
            </Modal>)
    }
}