import React from "react";
import PropTypes from "prop-types";
import {
	Container,
	MDBRow,
	MDBInput,
	MDBBtn,
	MDBCol,
	MDBListGroup,
	MDBListGroupItem,
	toast,
} from "mdbreact";
import Select from "react-select";
import OrderService from "../Security/OrderService/orderService";

export default class PqQuoteSelector extends React.Component {
	constructor(props) {
		super(props);

		this.quoteChosen = props.quoteChosen;

		this.state = {
			quoteAvail: 1,
			activePage: 1,
			selectedQuote: {},
			selectedQuotes: [],
			selectedFiles: [],
			currQueue: props.currQueue,
			vendor: null,
		};
	}

	static contextTypes = {
		allVendors: PropTypes.array,
	};

	//componentDidUpdate(prevProps, prevState, snapshot) {
	//	if (prevProps.currQueue.length !== this.props.currQueue.length) {
	//		this.setState({
	//			currQueue: this.props.currQueue,
	//		});
	//	}
	//}

	handlePageClick(page) {
		this.setState({
			activePage: page,
		});
	}

	selectQuote(fileId) {
		let quotes = this.state.selectedQuotes,
			files = this.state.selectedFiles,
			qid = quotes.findIndex((v) => {
				return v.fileId === fileId;
			});

		if (qid > -1) {
			quotes.splice(qid, 1);
			files.splice(qid, 1);
		} else {
			quotes.push({ fileId: fileId });
			files.push(fileId);
		}

		this.setState({
			selectedQuotes: quotes,
			selectedFiles: files,
		});
	}

	handleQuoteAvail(val, prop) {
		this.setState({
			[prop]: val,
		});
	}

	fileDownloadHandler(id) {
		return OrderService.downloadDoc(id).then((res) => {
			let file = new Blob([res], { type: "application/pdf" });
			let url = window.URL.createObjectURL(file);
			let w = window.open();

			if (w) {
				w.location.href = url;
				window.URL.revokeObjectURL(url);
			}
		});
	}

	renderQuotes() {
		let files = this.props.files;

		if (files && files.length > 0) {
			return files.map((f, index) => {
				if (f.file.filename.toLowerCase().includes("quote")) {
					return (
						<MDBRow style={{ margin: 0, padding: 0 }} key={"listdiv" + index}>
							<MDBCol size="1" style={{ paddingRight: 30 }}>
								<MDBInput
									key={"radioQuote" + index}
									onClick={() => {
										this.selectQuote(f.file.id);
										this.setState({ selectedQuote: index });
									}}
									label={" "}
									checked={this.state.selectedFiles.indexOf(f.file.id) > -1}
									type="checkbox"
									id={"radioQuote" + index}
								/>
							</MDBCol>
							<MDBCol size="6">
								<MDBListGroupItem
									hover
									style={{ padding: 4 }}
									active={this.state.selectedQuote.index === index}
									onClick={() => this.fileDownloadHandler(f.file.id)}
									key={index}
								>
									<p style={{ fontSize: 12 }}> {f.file.filename}</p>
								</MDBListGroupItem>
							</MDBCol>

							<MDBCol size="4">
								{this.state.selectedFiles.indexOf(f.file.id) > -1 ? (
									<div>
										<Select
											placeholder={"Select Vendor..."}
											// value={st.vendor}
											options={this.getVendors()}
											onChange={(evt) => {
												this.selectVendor(f.file.id, evt);
											}}
										/>
									</div>
								) : (
									<div />
								)}
							</MDBCol>
						</MDBRow>
					);
				}
				return null;
			});
		} else {
			return null;
		}
	}

	renderQueues() {
		return this.state.currQueue.map((p, index) => {
			return (
				<MDBListGroupItem key={index}>
					<div className="d-flex w-100 justify-content-between">
						<h5 className="mb-1">
							Status:{" "}
							<span style={{ color: this.renderStatusColor(p.status) }}>
								{p.status}
							</span>
						</h5>
						<small>
							{"Requested On " + new Date(p.requestedOn).toLocaleDateString()}
						</small>
					</div>
					{p.quoteId ? (
						<p className="mb-1">Ordered from quote</p>
					) : (
						<div>
							<p className="mb-1">{p.productSubType.name}</p>
							<small className="text-muted">{"Quantity: " + p.quantity}</small>
							<small className="text-muted, float-right">
								{this.renderStatusMessage(p)}
							</small>
						</div>
					)}
				</MDBListGroupItem>
			);
		});
	}

	renderStatusMessage(p) {
		switch (p.status) {
			case "APPROVED":
				return (
					"Approved on " + new Date(p.statusChangedOn).toLocaleDateString()
				);
			case "DENIED":
				return "Denied on " + new Date(p.statusChangedOn).toLocaleDateString();
			default:
				return "";
		}
	}

	renderStatusColor(status) {
		switch (status) {
			case "PENDING":
				return "#FF8800";
			case "APPROVED":
				return "#007E33";
			default:
				return "#CC0000";
		}
	}

	getVendors() {
		const { allVendors } = this.context;
		let arr = [];
		if (allVendors.length > 0) {
			allVendors.map((v) => {
				return arr.push({
					label: v.name,
					value: v.id,
				});
			});
		}
		return arr;
	}

	submitChosenQuotes() {
		let quotes = this.state.selectedQuotes,
			shouldBreak = false;

		quotes.forEach((v) => {
			if (v.vendor == null) {
				toast.warn("At least one selected quote does not have a vendor.");
				shouldBreak = true;
				return;
			}
		});

		if (shouldBreak) {
			return;
		}

		this.quoteChosen(quotes);
	}

	selectVendor(fileId, vendor) {
		let quotes = this.state.selectedQuotes,
			idx = quotes.findIndex((v) => {
				return v.fileId === fileId;
			});

		if (idx > -1) {
			quotes[idx].vendor = vendor;
		}

		this.setState({
			selectedQuotes: quotes,
		});
	}

	render() {
		let st = this.state;

		return (
			<Container style={{ backgroundColor: "white" }}>
				<MDBRow>
					{st.activePage === 1 && (
						<MDBCol md="12">
							<MDBListGroup>{this.renderQuotes()}</MDBListGroup>
							<hr />
							<MDBBtn
								color="mdb-color"
								rounded
								className="float-left"
								onClick={() => this.props.closeModal()}
							>
								Close
							</MDBBtn>
							<MDBBtn
								color="secondary"
								rounded
								className="float-left"
								onClick={() => this.setState({ activePage: 2 })}
							>
								View Status
							</MDBBtn>
							<MDBBtn
								color="mdb-color"
								rounded
								className="float-right"
								disabled={st.selectedQuotes.length === 0}
								onClick={() => this.submitChosenQuotes()}
							>
								Order From Quote
							</MDBBtn>
						</MDBCol>
					)}
					{st.activePage === 2 && (
						<MDBCol md="12">
							<h3 className="font-weight-bold pl-0 my-4">
								<strong>Purchase Queue Status</strong>
							</h3>
							<div
								style={{
									maxHeight: "300px",
									overflowX: "hidden",
									overflowY: "auto",
								}}
							>
								<MDBListGroup>{this.renderQueues()}</MDBListGroup>
							</div>
							<hr />
							<br />
							<MDBBtn
								color="mdb-color"
								rounded
								className="float-left"
								onClick={() => this.handlePageClick(1)}
							>
								previous
							</MDBBtn>
							<MDBBtn
								color="mdb-color"
								rounded
								className="float-right"
								onClick={() => this.props.closeModal()}
							>
								close
							</MDBBtn>
						</MDBCol>
					)}
				</MDBRow>
			</Container>
		);
	}
}
