import api from "../api";

class ProductService {
    async getAllProducts() {
        return new Promise((res, reject) => {
            api
                .get("/products")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getProduct(id) {
        return new Promise((res, reject) => {
            return api
                .get("/products/" + id)
                .then((reason) => {
                    res(reason);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    async updateProduct(product) {
        return new Promise((res, reject) => {
            api
                .put("/products", product)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async createProduct(product) {
        return new Promise((res, reject) => {
            api
                .post("/products", product)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async deleteProduct(product) {
        return new Promise((res, reject) => {
            return api
                .delete("/products/" + product.id)
                .then((reason) => {
                    res(reason);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

const productTypeService = new ProductService();

export default productTypeService;
