import React from "react";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";
import {
    Card,
    CardBody,
    Fa,
    Row,
    Col,
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol, MDBSpinner,
} from "mdbreact";
import "./login.css";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import * as msal from "@azure/msal-browser";
import authService from "../Security/authService";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;

const VERSION = "4.7.8c", // "c" for "Cork"
    companies = [
        {
            name: 'Rehab Medical', value: {
                name: 'rehabmedical',
                authConfig: {
                    auth: {
                        clientId: "a06bbb83-c8df-45ea-b686-452403137476",
                        authority: "https://login.microsoftonline.com/f2c81385-bf14-4df0-ac96-e01bda2adf46/",
                        replyUrl: window.location.origin + "/dashboard",
                        request: {
                            scopes: ["openid", "profile", "User.Read"],
                            forceRefresh: false,
                        },
                    },
                },
            }
        },
        {
            name: 'Cork Medical', value: {
                name: 'corkmedical',
                authConfig: {
                    auth: {
                        clientId: "12640c30-8132-4ae0-a7f0-f3532247643c",
                        authority: "https://login.microsoftonline.com/ea8b3ecd-6c46-48e8-aad6-583c904c3d0a/",
                        replyUrl: window.location.origin + "/dashboard",
                        request: {
                            scopes: ["openid", "profile", "User.Read"],
                            forceRefresh: false,
                        },
                    },
                },
            }
        },
        {
            name: 'UAT', value: {
                name: 'uat',
                authConfig: {
                    auth: {
                        clientId: "a06bbb83-c8df-45ea-b686-452403137476",
                        authority: "https://login.microsoftonline.com/f2c81385-bf14-4df0-ac96-e01bda2adf46/",
                        replyUrl: window.location.origin + "/dashboard",
                        request: {
                            scopes: ["openid", "profile", "User.Read"],
                            forceRefresh: false,
                        },
                    },
                },
            }
        },
    ];

export const VER = VERSION;
export default class login extends React.Component {
    constructor() {
        super();


        let pca = null;

        let prevPage = window.sessionStorage.getItem("prevPage");
        this.state = {
            username: "",
            password: "",
            company: "",
            companyLabel: "",
            loggedIn: false,
            error: "",
            currentUser: {},
            needUpdate: false,
            currVersion: -1,
            prevPage: prevPage,

            isAuthenticated: false,
            user: {},
            token: null,
            publicClient: pca,
            loading: false,
            popupOpen: false,
            loginError: false,
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        userDataFunction: () => {
        },
        companyFunction: () => {
        },
    };

    componentDidMount() {
        const { userDataFunction, currentUser } = this.context;
        //console.log(currentUser);
        let pca = this.state.publicClient;
        if (!pca) {
            var company = {
                name: null, value: {
                    name: null,
                    authConfig: {
                        auth: {
                            clientId: "a06bbb83-c8df-45ea-b686-452403137476",
                            authority: "https://login.microsoftonline.com/f2c81385-bf14-4df0-ac96-e01bda2adf46/",
                            replyUrl: "http://localhost:3000/dashboard",
                            request: {
                                scopes: ["openid", "profile", "User.Read"],
                                forceRefresh: false,
                            },
                        },
                    },
                }
            }
            this.setPublicClient(company);
            if(currentUser)
                userDataFunction(null, null);
        }
    }

    setPublicClient(company) {
        let pca = this.state.publicClient;
        if (company && company.value.authConfig) {
            pca = new msal.PublicClientApplication(company.value.authConfig);
            this.setState({
                company: company.value.name,
                companyLabel: company.name,
            })
        }

        this.setState({
            publicClient: pca,
        })
    }

    processResult(response) {
        if (response && response.account) {
            //console.log(response)
            const {userDataFunction, companyFunction} = this.context;
            // TODO: add the user data fetch call
            let token = response.idToken ? response.idToken : null,
                auth = true;
            var obj = {
                id: null,
                username: response.account.username,
                token: response.idToken,
                issuedAt: new Date(),
                company: this.state.company || "local"
            };
            authService.setToken(obj);

            authService.getMyUserDetails()
                .then(resp => {
                    if (resp && resp.id && resp.id > 0) {
                        userDataFunction(resp, response.account);
                        companyFunction(this.state.companyLabel, this.state.company);
                        this.setState({loggedIn: true, loading: false});
                    } else {
                        this.setState({error: "You are not authorized to use this site"});
                    }
                })
                .catch(err => {
                    console.log(err);
                });

            this.setState({
                isAuthenticated: auth,
                token: token,
                //loading: false,
            })
        }
    }

    checkVersion(evt) {
        evt.preventDefault();
        this.validateAndLogin();
        return;
    }

    //Attempt to log the user into the service
    validateAndLogin(evt) {
        // const { userFunction, companyFunction } = this.context;
        let loginRequest = {
            scopes: ["openid", "profile", "User.Read"],
            forceRefresh: false,
        };
        this.login(loginRequest, "loginPopup");
    }

    logout = () => {
        let publicClient = this.state.publicClient;
        publicClient.logout();
    }

    login = async (loginRequest, method) => {
        let publicClient = this.state.publicClient;
        const signInType = (isIE || isEdge) ? "loginRedirect" : method;
        if (signInType === "loginPopup") {
            this.setState({
                popupOpen: true,
                loading: true,
            });

            try {
                await publicClient.loginPopup(loginRequest)
                    .then(res => {
                        this.processResult(res);
                    })

            } catch (error) {
                console.log(error);
                this.setState({
                    loading: false,
                });
            } finally {
                this.setState({
                    popupOpen: false,
                    //loading: false,
                });
            }
        } else if (signInType === "loginRedirect") {
            // setLoading(true);
            this.setState({
                popupOpen: true,
                loading: true
            });

            publicClient.loginRedirect(loginRequest)
        }
    }

    getTokenPopup = async (loginRequest) => {
        let publicClient = this.state.publicClient;
        try {
            const response = await publicClient.acquireTokenSilent(loginRequest);
            // setToken(response.accessToken);
            this.setState({token: response.accessToken});
        } catch (error) {
            try {
                // setPopupOpen(true);
                this.setState({popupOpen: true});

                const response = await publicClient.acquireTokenPopup(loginRequest);

                // setToken(response.accessToken);
                this.setState({token: response.accessToken});
            } catch (error) {
                console.log(error);
                // setLoginError(error);
                this.setState({
                    loginError: error,
                    loading: false,
                });
            } finally {
                // setPopupOpen(false);
                this.setState({
                    popupOpen: false,
                    //loading: false,
                });
            }
        }
    }

    getTokenRedirect = async (loginRequest) => {
        let publicClient = this.state.publicClient;
        try {
            // setToken(await publicClient.acquireTokenSilent(loginRequest));
            let tkn = await publicClient.acquireTokenSilent(loginRequest);
            this.setState({token: tkn});
        } catch (error) {

            try {
                // setLoading(true);
                this.setState({loading: true});

                publicClient.acquireTokenRedirect(loginRequest);
            } catch (error) {
                console.log(error);
                // setLoginError(error);
                this.setState({
                    loginError: error,
                    loading: false,
                });
            }
        }
    }

    getToken = async (loginRequest, method) => {
        const signInType = (isIE || isEdge) ? "loginRedirect" : method;
        if (signInType === "loginRedirect") {
            return await this.getTokenRedirect(loginRequest);
        } else {
            return await this.getTokenPopup(loginRequest);
        }
    }

    renderIntro() {
        return (
            <video
                width="100%"
                height="100%"
                autoPlay
                muted
                style={{height: 235, maxHeight: 235, border: ".1em solid #AAA"}}
            >
                <source
                    src={require("../../images/Sales_pilot_logo_animation_1.0.mp4")}
                    type={"video/mp4"}
                />
                <img
                    src={require("../../images/salesPilotLogo3.png")}
                    style={{marginBottom: 6}}
                    alt={"Sales Pilot Logo"}
                />
                <h2 style={{fontWeight: 500}}>Sales Pilot</h2>
            </video>
        );
    }

    render() {
        let st = this.state;
        if (st.loggedIn === true) {
            if (st.prevPage === "null" || st.prevPage === null) {
                return <Redirect to="/dashboard"/>;
            }
            window.sessionStorage.setItem("prevPage", null);
            return <Redirect to={st.prevPage}/>;
        }

        let idx = "";
        if (this.state.company) {
            idx = companies.find((c) => {
                return c.value.name === this.state.company;
            });
        }

        function renderLoginButton() {
            if (st.loading) {
                return (
                    <MDBBtn
                        type="submit"
                        disabled={true}
                        color={"indigo"}
                        className="btn-block btn-rounded"
                        data-test-id="login"
                    >
                        <MDBSpinner small/>
                    </MDBBtn>
                )
            }
            return (
                <MDBBtn
                    type="submit"
                    disabled={st.needUpdate}
                    color={"indigo"}
                    className="btn-block btn-rounded"
                    data-test-id="login"
                >
                    Login
                </MDBBtn>
            )
        }


        return (
            <MDBContainer>
                <MDBRow center>
                    <MDBCol size="5">
                        <form onSubmit={this.checkVersion.bind(this)}>
                            <Card>
                                <CardBody
                                    style={{
                                        fontSmoothingEnabled: true,
                                        WebkitFontSmoothing: "antialiased",
                                    }}
                                >
                                    <div className="text-center">{this.renderIntro()}</div>

                                    <div>
                                        {/* <MDBInput
											style={{ marginBottom: 4 }}
											onChange={(evt) =>
												this.setState({ username: evt.target.value })
											}
											outline
											label="Username"
											icon="user"
											group
											validate
											data-test-id="username"
										/>

										<MDBInput
											style={{ marginBottom: 4 }}
											onChange={(evt) =>
												this.setState({ password: evt.target.value })
											}
											outline
											label="Password"
											icon="lock"
											group
											type="password"
											validate
											data-test-id="password"
										/> */}
                                        <Row>
                                            <Col size="1">
                                                <Fa size="lg" icon={"building"} style={{marginTop: "25px"}}/>
                                            </Col>

                                            <Col size="11" className={"uiOutlines"}>
                                                <TextField
                                                    id="outlined-select-status"
                                                    select
                                                    size="small"
                                                    fullWidth
                                                    label="Company"
                                                    value={idx}
                                                    onChange={(e) => {
                                                        this.setPublicClient(e.target.value);
                                                        // this.setState({
                                                        // 	company: e.target.value.value.name,
                                                        // 	companyLabel: e.target.value.name,
                                                        // });
                                                    }}
                                                    variant="outlined"
                                                >
                                                    {companies.map((option, idx) => {
                                                        return (
                                                            <MenuItem key={idx} value={companies[idx]}>
                                                                {option.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </TextField>
                                            </Col>
                                        </Row>

                                        <br/>
                                    </div>
                                    <div className="text-right">
                                        {st.error ? (
                                            <h1 style={errorStyle}>{st.error}</h1>
                                        ) : (
                                            <div/>
                                        )}
                                        {/*<ClearCache auto={true}>*/}
                                        {/*    {({isLatestVersion, emptyCacheStorage}) => (*/}
                                        {/*        <div>*/}
                                        {/*            {!isLatestVersion && (*/}
                                        {/*                <p>*/}
                                        {/*                    <a*/}
                                        {/*                        href="/#"*/}
                                        {/*                        onClick={(e) => {*/}
                                        {/*                            e.preventDefault();*/}
                                        {/*                            emptyCacheStorage();*/}
                                        {/*                        }}*/}
                                        {/*                    >*/}
                                        {/*                        Update Version*/}
                                        {/*                    </a>*/}
                                        {/*                </p>*/}
                                        {/*            )}*/}
                                        {/*        </div>*/}
                                        {/*    )}*/}
                                        {/*</ClearCache>*/}
                                        {renderLoginButton()}
                                        <div style={{paddingTop: "4%"}}>
                                            {/*<span*/}
                                            {/*    style={{cursor:'pointer'}}*/}
                                            {/*    className={'float-left'}*/}
                                            {/*    onClick={() => { this.props.history.push(*/}
                                            {/*        {*/}
                                            {/*            pathname: '/forgotPassword',*/}
                                            {/*            state: st.company*/}
                                            {/*        })}}*/}
                                            {/*>Forgot Password*/}
                                            {/*</span>*/}
                                            <span style={{marginTop: "10px"}}>
												Version {VERSION}
											</span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </form>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}

const errorStyle = {
    color: "red",
    fontSize: 16,
};
