import api from "../api";

class ProdcutMatrixEntryService {
	async getAllProducts() {
		return new Promise((res, reject) => {
			api
				.get("/productMatrixEntry")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getAllProductsWithMappings() {
		return new Promise((res, reject) => {
			api
				.get("/productMatrixEntryDetails")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createProduct(product) {
		return new Promise((res, reject) => {
			api
				.post("/productMatrixEntry", product)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async editProduct(product) {
		return new Promise((res, reject) => {
			api
				.put("/productMatrixEntry", product)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async deleteProduct(id) {
		return new Promise((res, reject) => {
			api
				.delete("/productMatrixEntry/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getProductMatrixEntryBySubType(id) {
		return new Promise((res, reject) => {
			api
				.get("/productMatrixEntryBySubType/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				})
		})
	}
}

const prodcutMatrixEntryService = new ProdcutMatrixEntryService();

export default prodcutMatrixEntryService;
