import React, {Component} from "react";
import PropTypes from "prop-types";
import InventoryRequest from "./Seating/PurchaseQueue/inventoryRequest";
import PODeliveryUpdate from "./Seating/PurchaseQueue/PODeliveryUpdate";
import ReactTooltip from "react-tooltip";
import {BrowserRouter as Router, Redirect} from "react-router-dom";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Footer,
    FormInline,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol, MDBCollapse, MDBDropdownItem, MDBIcon,
    MDBInput,
    MDBModal,
    MDBModalHeader,
    MDBRow,
    ModalBody,
    Navbar,
    NavbarBrand,
    NavbarNav,
    NavItem,
    NavLink,
    toast,
    Button, MDBCardFooter, MDBModalBody,
} from "mdbreact";
import "./index.css";
import "../node_modules/open-iconic/font/css/open-iconic-bootstrap.css";
import Routes from "./Routes";
import {AiOutlineBarChart, AiOutlineSearch, AiOutlinePrinter} from "react-icons/ai";
import InsuranceService from "./Seating/Security/InsuranceService/insuranceService";
import LocationService from "./Seating/Security/Locations/locationsService";
import UserService from "./Seating/Security/UserService/userService";
import AccountService from "./Seating/Security/AccountService/accountService";
import ContactService from "./Seating/Security/ContactService/contactService";
import ProductTypeService from "./Seating/Security/ProductService/productTypeService";
import ProductSubTypeService from "./Seating/Security/ProductService/productSubTypeService";
import ReasonService from "./Seating/Settings/reasonService";
import ActivityService from "./Seating/Security/ActivityService/activityService";
import DocumentTypesService from "./Seating/Settings/documentTypesService";
import MarketingService from "./Seating/Security/MarketingService/marketingService";
import ComplaintService from "./Seating/Security/ComplaintService/ComplaintService";
import VendorService from "./Seating/Security/VendorService/vendorService";
import ProductMatrixService from "./Seating/Security/ProdcutMatrixEntryService/prodcutMatrixEntryService";
import ReverseQualityService from "./Seating/Settings/reverseQualityService";
import PatientCommunicationService from "./Seating/Settings/PatientCommunicationService";
import userRoleService from "./Seating/Security/UserRoleService/userRoleService";
import StateSalesTaxService from "./Seating/Settings/stateSalesTaxService";
import orderService from "./Seating/Security/OrderService/orderService";
//endregion

//region icon imports
import {GiGears, GiShakingHands} from "react-icons/gi";
import {FaDollarSign, FaBoxes} from "react-icons/fa";
import {MdPermMedia, MdSecurity, MdErrorOutline} from "react-icons/md";
import {FiLogOut} from "react-icons/fi";
//endregion
import API from "./Seating/Security/api";
//momentTimezone needs to be imported!!!...BC
import moment from "moment";
import UserMetricCollapse from "./Components/UserMetrics/UserMetricCollapse";
import Select from "react-select";
import UpdateNotes from "./Components/UpdateNotes";
import RegionService from "./Seating/Settings/regionServices";
import background from "./images/blue_background.png";
import QualityAssuranceScorecard from "./Components/QualityAssuranceScorecard";
import ClearCache from "react-clear-cache";
import {VER} from "./Seating/Login/login";
import * as msal from "@azure/msal-browser";
import { MainProvider } from './MainContext';

require("moment-timezone");

class App extends Component {
    constructor(props) {
        super(props);

        let cu = window.sessionStorage.getItem("currentUser");
        //ensure clearing of the session storage...BC
        //window.sessionStorage.clear();

        if (cu != null) {
            window.sessionStorage.setItem("currentUser", cu);
            cu = JSON.parse(cu);
        }


        this.state = {
            pca: null,
            collapseID: "",
            allThemes: [
                "Gorgeous Contrast",
                "Alternate Contrast",
                "Corporate",
                "Bold",
                "Plum",
                "Indigo",
                "Umber",
            ],
            currentUser: cu || {},
            company: "",
            companyName: "",
            poDeliveryUpdate: false,
            inventoryRequestOpen: false,
            insuranceTypes: [],
            insuranceSubTypes: [],
            productTypes: [],
            productSubTypes: [],
            searchText: "",
            reasons: [],
            therapists: [],
            salesReps: [],
            accounts: [],
            myAccounts: [],
            allLocations: [],
            internalLocations: [],
            allRegions: [],
            allUsers: [],
            userRefs: [],
            allActiveUsers: [],
            allLiaisons: [],
            allPCR: [],
            allAtp: [],
            thirdPartyUsers: [],
            thirdPartyUsersLoaded: false,
            cardTypes: [],
            expenseTypes: [],
            documentTypes: [],
            contactTypes: [],
            accountTypes: [],
            patientCommunicationTriggerDefs: [],
            techs: [],
            marketingCampaigns: [],
            complaintCategories: [],
            complaintReasons: [],
            allVendors: [],
            productOverrides: [],
            fullLiaisonKeys: [],
            orderModalOpen: false,
            orderErrorMessage: "",
            orderFound: false,
            orderId: "",
            stateSalesTax: [],
            reverseQualityCategories: [],
            reverseQualityCategoriesRefs: [],
            reverseQualityReasons: [],
            assuranceModalOpen: false,
            rqModalOpen: false,
            rqObject: {},
            rqOrderId: "",
            userMetricsVisible: false,
            userRoleQuotas: [],
            notLoggedIn: cu == null,
            updateNotesModalOpen: false,
            updateAcknowledgeModel: false,
            mslaAccount: null,
        };
    }

    static childContextTypes = {
        userFunction: () => {
        },
        userDataFunction: () => {
        },
        companyFunction: () => {
        },
        //getPcaFunction: () => { },
        //logoutFunction: () => { },
        //configurePcaFunction: (config) => {},
        setContextItem: (key, value) => {},
        company: PropTypes.string,
        companyName: PropTypes.string,
        currentUser: PropTypes.object,
        insuranceTypes: PropTypes.array,
        insuranceSubTypes: PropTypes.array,
        productTypes: PropTypes.array,
        productSubTypes: PropTypes.array,
        reasons: PropTypes.array,
        therapists: PropTypes.array,
        salesReps: PropTypes.array,
        accounts: PropTypes.array,
        myAccounts: PropTypes.array,
        allLocations: PropTypes.array,
        internalLocations: PropTypes.array,
        allRegions: PropTypes.array,
        allUsers: PropTypes.array,
        userRefs: PropTypes.array,
        allActiveUsers: PropTypes.array,
        allLiaisons: PropTypes.array,
        allPCR: PropTypes.array,
        allAtp: PropTypes.array,
        thirdPartyUsers: PropTypes.array,
        thirdPartyUsersLoaded: PropTypes.bool,
        patientCommunicationTriggerDefs: PropTypes.array,
        cardTypes: PropTypes.array,
        expenseTypes: PropTypes.array,
        documentTypes: PropTypes.array,
        contactTypes: PropTypes.array,
        accountTypes: PropTypes.array,
        techs: PropTypes.array,
        marketingCampaigns: PropTypes.array,
        complaintCategories: PropTypes.array,
        complaintReasons: PropTypes.array,
        allVendors: PropTypes.array,
        productOverrides: PropTypes.array,
        fullLiaisonKeys: PropTypes.array,
        timezoneVariation: PropTypes.number,
        stateSalesTax: PropTypes.array,
        reverseQualityCategories: PropTypes.array,
        reverseQualityReasons: PropTypes.array,
        userRoleQuotas: PropTypes.array
    };

    setUserDataFunction(user, msalAcct) {
        this.setState({currentUser: user});
        if (user) {
            this.setState({notLoggedIn: false, msalAccount: msalAcct});
            window.sessionStorage.setItem("currentUser", JSON.stringify(user));
            this.getAll();
        } else {
            this.setState({ notLoggedIn: true, msalAccount: null });
            window.sessionStorage.removeItem("currentUser");
            this.clearState();
        }
    }

    setUserFunction(user) {
        this.setState({ currentUser: user });
        if (user) {
            this.setState({ notLoggedIn: false });
            window.sessionStorage.setItem("currentUser", JSON.stringify(user));
            this.getAll();
        } else {
            this.setState({ notLoggedIn: true });
            window.sessionStorage.removeItem("currentUser");
            this.clearState();
        }
    }

    setCompanyFunction(friendlyCompany, company) {
        window.sessionStorage.setItem("companyName", company);
        this.setState({
            companyName: company,
            company: friendlyCompany,
        });
    }

    //getPcaFunction() {
    //    return this.state.pca;
    //}

    //configurePcaFunction(company) {
    //    var pca = this.state.pca;

    //    if (company && company.value.authConfig) {
    //        pca = new msal.PublicClientApplication(company.value.authConfig);
    //        this.setState({
    //            company: company.value.name,
    //            companyLabel: company.name,
    //        })
    //    }

    //    this.setState({
    //        pca: pca,
    //    })
    //}

    //logoutFunction() {
    //    var pca = this.state.pca,
    //        user = this.state.currentUser;

    //    if (user) {

    //    }
    //}

    setContextItem(key, value) {
        this.setState({
            [key]: value,
        })
        if (key === "allUsers") {
            this.sortUsers(value);
        }
    }

    getChildContext() {
        return {
            userFunction: this.setUserFunction.bind(this),
            userDataFunction: this.setUserDataFunction.bind(this),
            companyFunction: this.setCompanyFunction.bind(this),
            setContextItem: this.setContextItem.bind(this),
            company: this.state.company,
            companyName: this.state.companyName,
            currentUser: this.state.currentUser,
            insuranceTypes: this.state.insuranceTypes,
            insuranceSubTypes: this.state.insuranceSubTypes,
            productTypes: this.state.productTypes,
            productSubTypes: this.state.productSubTypes,
            reasons: this.state.reasons,
            therapists: this.state.therapists,
            salesReps: this.state.salesReps,
            accounts: this.state.accounts,
            myAccounts: this.state.myAccounts,
            allLocations: this.state.allLocations,
            internalLocations: this.state.internalLocations,
            allRegions: this.state.allRegions,
            allUsers: this.state.allUsers,
            allActiveUsers: this.state.allActiveUsers,
            userRefs: this.state.userRefs,
            allLiaisons: this.state.allLiaisons,
            allPCR: this.state.allPCR,
            allAtp: this.state.allAtp,
            thirdPartyUsers: this.state.thirdPartyUsers,
            thirdPartyUsersLoaded: this.state.thirdPartyUsersLoaded,
            patientCommunicationTriggerDefs: this.state.patientCommunicationTriggerDefs,
            cardTypes: this.state.cardTypes,
            expenseTypes: this.state.expenseTypes,
            documentTypes: this.state.documentTypes,
            contactTypes: this.state.contactTypes,
            accountTypes: this.state.accountTypes,
            techs: this.state.techs,
            marketingCampaigns: this.state.marketingCampaigns,
            complaintCategories: this.state.complaintCategories,
            complaintReasons: this.state.complaintReasons,
            allVendors: this.state.allVendors,
            productOverrides: this.state.productOverrides,
            fullLiaisonKeys: this.state.fullLiaisonKeys,
            timezoneVariation: this.state.timezoneVariation,
            stateSalesTax: this.state.stateSalesTax,
            reverseQualityCategories: this.state.reverseQualityCategories,
            reverseQualityReasons: this.state.reverseQualityReasons,
            userRoleQuotas: this.state.userRoleQuotas
        };
    }

    //region context entities
    getInsuranceTypes() {
        let t = sessionStorage.getItem("insuranceTypes");
        if (t && t !== "undefined") {
            this.setState({insuranceTypes: JSON.parse(t)});
            return;
        }

        InsuranceService.findAllInsurance()
            .then((types) => {
                sessionStorage.setItem("insuranceTypes", JSON.stringify(types));
                this.setState({insuranceTypes: types});
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getInsuranceSubTypes() {
        let t = sessionStorage.getItem("insuranceSubTypes");
        if (t && t !== "undefined") {
            this.setState({insuranceSubTypes: JSON.parse(t)});
            return;
        }

        InsuranceService.findAllSubInsurance()
            .then((types) => {
                let res = types.sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

                sessionStorage.setItem("insuranceSubTypes", JSON.stringify(types));
                this.setState({insuranceSubTypes: res});
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getProductTypes() {
        let t = window.sessionStorage.getItem("productTypes");
        if (t && t !== "undefined") {
            this.setState({productTypes: JSON.parse(t)});
            return;
        }

        ProductTypeService.getAllProducts()
            .then((types) => {
                sessionStorage.setItem("productTypes", JSON.stringify(types));
                this.setState({productTypes: types});
            })
            .catch((err) => {
                // do something? ... RW
                console.log(err);
            });
    }

    getProductSubTypes() {
        let t = window.sessionStorage.getItem("productSubTypes");
        if (t && t !== "undefined") {
            this.setState({productSubTypes: JSON.parse(t)});
            return;
        }

        ProductSubTypeService.getAllProductSubTypes()
            .then((types) => {
                sessionStorage.setItem("productSubTypes", JSON.stringify(types));
                this.setState({productSubTypes: types});
            })
            .catch((err) => {
                // do something? ... RW
                console.log(err);
            });
    }

    getLocations() {
        let t = sessionStorage.getItem("allLocations");
        if (t && t !== "undefined") {
            var tt = JSON.parse(t),
                vt = tt.filter(x => x.type !== null && x.type === 0);
            this.setState({
                allLocations: tt.sort((a, b) => a.name > b.name ? 1 : -1),
                internalLocations: vt.sort((a, b) => a.name > b.name ? 1 : -1),
            });
        } else {
            LocationService.findAllLocations()
                .then((locations) => {
                    var locs = locations.sort((a, b) => {
                        return a.name < b.name ? -1 : 1;
                    })
                    sessionStorage.setItem("allLocations", JSON.stringify(locs));
                    sessionStorage.setItem("internalLocations", JSON.stringify(locs.filter(x => x.type !== null && x.type === 0)));
                    this.setState({
                        allLocations: locs.sort((a, b) => a.name > b.name ? 1 : -1),
                        internalLocations: locs.filter(x => x.type !== null && x.type === 0).sort((a, b) => a.name > b.name ? 1 : -1)
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    getRegions() {
        RegionService.getAllRegions()
            .then((res) => {
                this.setState({allRegions: res});
            })
            .catch(err => {
                console.log(err);
            })
    }

    sortUsers(users) {
        let cu = this.state.currentUser;
        //let locations = cu.location || [];
        let atp = [],
            thera = [],
            active = [],
            liaisons = [],
            techs = [],
            third = [],
            pcr = [],
            reps = cu.role === "SALES" ? [cu] : [],
            ary = [];

        users.map(x => {
            if (x.isAtp === true) {
                atp.push(x);
            }
            if (x.active === true) {
                active.push(x);
            }
            if (x.deleted !== true) {
                switch (x.role) {
                    case "THERAPIST":
                        thera.push(x);
                        break;
                    case "SALES_LIAISON":
                        //case "SALES_LIAISON_MANAGER":
                        liaisons.push(x);
                        break;
                    case "OFFICE_MANAGER":
                        techs.push(x);
                        pcr.push(x);
                        break;
                    case "AREA_MANAGER":
                    case "TECHNICIAN":
                        techs.push(x);
                        break;
                    case "PATIENT_CARE_REP":
                    case "OFFICE_SUPPORT":
                        pcr.push(x);
                        break;
                    case "SALES_MANAGER":
                        pcr.push(x);
                        if (cu.role !== "SALES" && x.deleted !== true) {
                            reps.push(x);
                        }
                        break;
                    case "SALES":
                    case "ADMIN":
                        if (cu.role !== "SALES" && x.deleted !== true) {
                            reps.push(x);
                        }
                        break;
                    default:
                        break;
                }
            }
            ary.push({
                label: x.firstname + " " + x.lastname,
                value: {id: x.id, name: x.username},
            });

            return x;
        });

        this.setState({
            allUsers: users,
            allAtp: atp,
            userRefs: ary,
            allActiveUsers: active,
            allLiaisons: liaisons,
            techs: techs,
            salesReps: reps,
            therapists: thera,
            thirdPartyUsers: third,
            thirdPartyUsersLoaded: true,
            allPCR: pcr,
        });
        this.populateLiaisonKeys(liaisons);
    }

    getAllUsers() {
        UserService.getAllUsers()
            .then((res) => {
                var resp = res.sort((a, b) => {
                    if (a.firstname && a.lastname && b.firstname && b.lastname)
                        return a.firstname.trim() + " " + a.lastname.trim() > b.firstname.trim() + " " + b.lastname.trim() ? 1 : -1
                    else
                        return 1;
                });

                this.sortUsers(resp);
            })
            .catch((err) => {
                console.log(err);
            });
        //}
    }

    populateLiaisonKeys(allLiaisons) {
        let aryDates = [],
            liaisonsKeys = [],
            startDate = new Date();

        let currentTzo = new Date().getTimezoneOffset(),
            id = moment.tz(new Date(), "America/Indiana/Indianapolis"),
            idTzo = id._offset,
            variation = 0;

        //subtract indiana's timezone offset...BC (it is negative 300, so add negative)
        currentTzo += idTzo;

        //get the offset in hours...BC
        if (currentTzo !== 0) {
            variation = currentTzo / 60;
        }

        let start = 9,
            end = 16;

        //they will have a lot less choices to schedule a meeting based on their timezone...BC
        if (variation !== 0) {
            start = 9 - variation;
            end = 16 - variation;
        }

        for (let i = 0; i <= 5; i++) {
            let currentDate = new Date();
            currentDate.setDate(startDate.getDate() + i);

            if (currentDate.getDay() === 6 || currentDate.getDay() === 0) {
                continue;
            }

            let mString =
                (currentDate.getMonth() + 1).toString() +
                "/" +
                currentDate.getDate().toString() +
                "/" +
                currentDate.getFullYear().toString() +
                " | ";

            //this should generate a keystring of DDMMYYYYTTTT
            for (let i = start; i <= end; i++) {
                aryDates.push(mString + i + ":00:00--");
            }
        }

        aryDates.forEach((d) => {
            allLiaisons.forEach((l) => {
                liaisonsKeys.push(d + l.firstname + " " + l.lastname);
            });
        });

        this.setState({
            fullLiaisonKeys: liaisonsKeys,
            timezoneVariation: variation,
        });
    }

    getContactTypes() {
        ContactService.findAllContactTypes()
            .then((types) => {
                this.setState({contactTypes: types});
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getMyAccounts(userId) {
        AccountService.getAccountsByUser(userId)
            .then((accounts) => {
                this.setState({myAccounts: accounts});
            })
            .catch((err) => {
                console.log(err);
            });
    }

    retrieveCreditCardTypes() {
        ActivityService.getCreditCardTypes()
            .then((types) => {
                this.setState({cardTypes: types});
            })
            .catch((err) => {
                //handle error...BC
            });
    }

    retrieveExpenseTypes() {
        ActivityService.getExpenseTypes()
            .then((types) => {
                this.setState({expenseTypes: types});
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getAccountTypes() {
        AccountService.getAllAccountTypes()
            .then((types) => {
                this.setState({accountTypes: types});
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getReasons() {
        let t = sessionStorage.getItem("statusReasons");
        if (t && t !== "undefined") {
            this.setState({reasons: JSON.parse(t)});
            return;
        }

        ReasonService.getAllReasons()
            .then((reasons) => {
                sessionStorage.setItem("statusReasons", JSON.stringify(reasons));
                this.setState({reasons: reasons});
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getDocumentTypes() {
        let t = sessionStorage.getItem("documentTypes");
        if (t && t !== "undefined") {
            this.setState({documentTypes: JSON.parse(t)});
            return;
        }

        DocumentTypesService.getAllDocumentTypes()
            .then((docs) => {
                sessionStorage.setItem("documentTypes", JSON.stringify(docs));
                this.setState({documentTypes: docs});
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getMarketingCampaigns() {
        let t = sessionStorage.getItem("marketingCampaigns");
        if (t && t !== "undefined") {
            this.setState({marketingCampaigns: JSON.parse(t)});
            return;
        }

        //MarketingService.getActiveCampaigns()
        MarketingService.findAllCampaigns()
            .then((camps) => {
                sessionStorage.setItem("marketingCampaigns", JSON.stringify(camps));
                this.setState({marketingCampaigns: camps});
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getComplaintCategories() {
        ComplaintService.getAllIssueCategories()
            .then((cat) => {
                this.setState({complaintCategories: cat});
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getComplaintReasons() {
        ComplaintService.getAllIssueReasons()
            .then((res) => {
                this.setState({complaintReasons: res});
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getVendors() {
        VendorService.getAllVendors()
            .then((res) => {
                this.setState({
                    allVendors: res,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getProductOverrides() {
        ProductMatrixService.getAllProducts()
            .then((res) => {
                this.setState({
                    productOverrides: res,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getStateSalesTax() {
        StateSalesTaxService.getAllStates().then((res) => {
            let arr = [];

            res.forEach((state, index) => {
                arr.push({
                    id: state.id,
                    name: state.name,
                    abbr: state.abbr,
                    salesTax: parseFloat(state.salesTax),
                });
            });
            this.setState({stateSalesTax: arr});
        })
            .catch(err => {
                console.log(err);
            });
    }

    getReverseQualityCategories() {
        ReverseQualityService.getAllCategories().then((res) => {
            let arr = [],
                refAry = [];

            res.forEach((category) => {
                refAry.push({
                    label: category.name,
                    value: {
                        id: category.id,
                        name: category.name,
                    },
                });

                arr.push({
                    id: category.id,
                    name: category.name,
                });
            });
            this.setState({
                reverseQualityCategories: arr,
                reverseQualityCategoriesRefs: refAry,
            });
        })
            .catch(err => {
                console.log(err);
            });
    }

    getPatientCommunicationTriggers() {
        PatientCommunicationService.getAllPatientCommunicationTriggerDefs()
            .then((defs) => {
                this.setState({
                    patientCommunicationTriggerDefs: defs,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }
    //endregion

    getCurrentUserRoleQuotaValues() {
        userRoleService.getAllUserRoleQuotas().then((quotas) => {
            this.setState({
                userRoleQuotas: quotas
            })
        }).catch(err => {
            console.log(err);
        })
    }

    //endregion
    getReverseQualityReasons() {
        ReasonService.getAllReverseQualityReasons().then((res) => {
            let refAry = [];

            res.forEach((category) => {
                refAry.push({
                    label: category.name,
                    value: {
                        id: category.id,
                        name: category.name,
                    },
                });
            });
            this.setState({
                reverseQualityReasons: res,
                currentReverseQualityReasonRefs: refAry,
            });
        })
            .catch(err => {
                console.log(err);
            })
    }
    getUpdateAcknowledge() {
        let t = window.sessionStorage.getItem("updateAcknowledgeModel");
        if (t && t !== "undefined") {
            let x = JSON.parse(t);
            this.setState({updateAcknowledgeModel: x});
        }

    }

    componentDidMount() {
        this.getUpdateAcknowledge();
        let cu = window.sessionStorage.getItem("currentUser");

        if (cu != null && cu.length > 0) {
            this.getAll();

            //get the company name
            API.getToken()
                .then((res) => {
                    if (res) {
                        this.setState({companyName: res.company, company: res.company});
                    } else {
                        return <Redirect to={"/login"} />;
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            this.setState({notLoggedIn: true});

        }
    }

    getAll() {
        // this.getWhoAmI();
        this.getContactTypes();
        this.getInsuranceTypes();
        this.getInsuranceSubTypes();
        this.getProductTypes();
        this.getProductSubTypes();
        this.getLocations();
        this.getRegions();
        // this.getTherapists();
        // this.getSalesReps();
        //this.getAccounts();
        this.getReasons();
        this.getAllUsers();
        // this.getActiveUsers();
        this.retrieveCreditCardTypes();
        this.retrieveExpenseTypes();
        this.getDocumentTypes();
        this.getMyAccounts(this.state.currentUser.id);
        this.getAccountTypes();
        // this.getTechs();
        this.getMarketingCampaigns();
        this.getComplaintCategories();
        this.getComplaintReasons();
        // this.getThirdPartyUsers();
        this.getVendors();
        this.getProductOverrides();
        this.getStateSalesTax();
        this.getReverseQualityCategories();
        this.getReverseQualityReasons();
        this.getPatientCommunicationTriggers();
        this.getCurrentUserRoleQuotaValues();
    }

    clearState() {
        this.setState({
            collapseID: "",
            currentUser: {},
            company: "",
            inventoryRequestOpen: false,
            insuranceTypes: [],
            insuranceSubTypes: [],
            productTypes: [],
            productSubTypes: [],
            searchText: "",
            reasons: [],
            therapists: [],
            salesReps: [],
            accounts: [],
            myAccounts: [],
            allLocations: [],
            internalLocations: [],
            allUsers: [],
            userRefs: [],
            allActiveUsers: [],
            allLiaisons: [],
            allPCR: [],
            thirdPartyUsers: [],
            thirdPartyUsersLoaded: false,
            cardTypes: [],
            expenseTypes: [],
            documentTypes: [],
            contactTypes: [],
            accountTypes: [],
            techs: [],
            marketingCampaigns: [],
            complaintCategories: [],
            complaintReasons: [],
            allVendors: [],
            productOverrides: [],
            fullLiaisonKeys: [],
            reverseQualityCategories: [],
            userRoleQuotas: []
        });

        window.sessionStorage.clear();
    }

    closeInventoryRequest() {
        this.setState({
            inventoryRequestOpen: false,
        });
    }


    renderInventoryRequestModal() {
        return (
            <MDBModal
                size="lg"
                isOpen={this.state.inventoryRequestOpen}
                toggle={() => {
                    this.setState({
                        inventoryRequestOpen: false,
                    });
                }}
            >
                <InventoryRequest onClose={this.closeInventoryRequest.bind(this)} />
            </MDBModal>
        );
    }

    renderPODeliveryUpdateModal() {
        return (
            <MDBModal
                size="md"
                isOpen={this.state.poDeliveryUpdateOpen}
                toggle={() => {
                    this.setState({
                        poDeliveryUpdateOpen: false,
                    });
                }}
            >
                <PODeliveryUpdate onClose={this.closePODeliveryUpdate.bind(this)} />
            </MDBModal>
        );
    }

    closePODeliveryUpdate() {
        this.setState({
            poDeliveryUpdateOpen: false,
        });
    }

    renderOrderSearchModal() {
        return (
            <MDBModal
                isOpen={this.state.orderModalOpen}
                centered
                size="sm"
                toggle={() => {
                    this.setState({
                        //orderFound: false,
                        orderErrorMessage: "",
                        orderModalOpen: false,
                    });
                }}
            >
                <ModalBody style={{backgroundColor: "#FFFFFF"}}>
                    <MDBRow style={{justifyContent: "space-around"}}>
                        <MDBCol>
                            <FormInline
                                onSubmit={(e) => {
                                    this.searchForId(e);
                                }}
                            >
                                <MDBInput
                                    outline
                                    size="lg"
                                    icon={"search"}
                                    label="Search By Order Id"
                                    value={this.state.orderId}
                                    onChange={(e) =>
                                        this.setState({
                                            orderId: e.target.value,
                                        })
                                    }
                                />
                                <span>{this.state.orderErrorMessage}</span>
                            </FormInline>
                        </MDBCol>
                    </MDBRow>
                </ModalBody>
            </MDBModal>
        );
    }

    handleRQUserSelected = (e) => {
        let obj = this.state.rqObject;

        obj.rqUser = e.value;
        obj.rqUserId = e.value.id;
        this.setState({
            rqObject: obj,
        });
    };

    handleRqCategorySelected = (e) => {
        let obj = this.state.rqObject;

        obj.rqQualityCategory = e.value;
        obj.rqQualityCategoryId = e.value.id;

        let r = JSON.parse(JSON.stringify(this.state.reverseQualityReasons)),
            ref = [];

        r = r && r.length > 0 ? r.filter(v => {
            return v.reverseQualityCategoryId === e.value.id;
        }) : r;

        if (r && r.length > 0) {
            r.forEach(nv => {
                ref.push({
                    label: nv.name,
                    value: {
                        id: nv.id,
                        name: nv.name,
                    },
                });
            })
        }

        this.setState({
            rqObject: obj,
            currentReverseQualityReasonRefs: ref
        });
    };

    handleRqReasonSelected = (e) => {
        let obj = this.state.rqObject;

        obj.rqQualityReason = e.value;
        obj.rqQualityReasonId = e.value.id;
        this.setState({
            rqObject: obj,
        });
    }

    handleRqNote(e) {
        let obj = this.state.rqObject;

        obj.notes = e.target.value;

        this.setState({
            rqObject: obj,
        });
    }

    submitRqCategory() {
        let currentUser = this.state.currentUser,
            obj = this.state.rqObject;

        if (!obj.notes) {
            return toast.warn("You must enter some notes");
        } else if (!obj.orderId) {
            return toast.warn("You must enter an order id");
        } else if (!obj.rqUser) {
            return toast.warn("You must select a user");
        } else if (!obj.rqQualityCategory) {
            return toast.warn("You must select a category");
        } else if (!obj.rqQualityReason) {
            return toast.warn("You must select a reason");
        }

        obj.rqReportedBy = {name: currentUser.username, id: currentUser.id};
        obj.rqReportedById = currentUser.id;
        obj.rqReportedOn = new Date();

        ReverseQualityService.createReverseQualityLog(obj)
            .then((r) => {
                this.setState({
                    rqModalOpen: false,
                    rqObject: {},
                });
                toast.success("RQ Submitted!");
            })
            .catch((err) => {
                console.log(err);
            });
    }

    submitQualityAssurance(obj) {
        ReverseQualityService.createQualityAssuranceLog(obj)
            .then((r) => {
                toast.success("Quality Assurance Log Submitted!");
            })
            .catch((err) => {
                console.log(err);
                toast.success("There was an issue submitting the quality assurance log");
            })
            .finally(() => {
                this.setState({
                    assuranceModalOpen: false,
                });
            })
    }

    renderQualityAssuranceScorecard() {
        return (
            <MDBModal
                isOpen={this.state.assuranceModalOpen}
                centered
                size="lg"
                toggle={() => {
                    this.setState({
                        assuranceModalOpen: false,
                    });
                }}>
                <QualityAssuranceScorecard
                    onSubmit={this.submitQualityAssurance.bind(this)}
                    onCancel={() => this.setState({assuranceModalOpen: false})} />
            </MDBModal>)
    }

    renderReverseQualityModal() {
        return (
            <MDBModal
                isOpen={this.state.rqModalOpen}
                centered
                size="lg"
                toggle={() => {
                    this.setState({
                        rqModalOpen: false,
                        rqObject: {},
                    });
                }}
            >
                <MDBRow style={{justifyContent: "center"}}>
                    <span
                        style={{fontSize: 20, padding: 10, textDecoration: "underline"}}
                    >
                        Report Reverse Quality
                    </span>
                </MDBRow>

                <ModalBody style={{backgroundColor: "#FFFFFF", padding: 20}}>
                    <MDBRow style={{justifyContent: "center"}}>
                        <MDBCol size="5">
                            <label> Order Id </label>
                            <MDBInput
                                containerClass={"noMargin"}
                                outline
                                type="number"
                                valueDefault={this.state.rqOrderId}
                                onChange={(e) => {
                                    let obj = this.state.rqObject;
                                    obj.orderId = parseInt(e.target.value);

                                    this.setState({
                                        rqObject: obj,
                                        rqOrderId: e.target.value,
                                    });
                                }}
                            />
                        </MDBCol>
                        <MDBCol size="5">
                            <label> User Being Reported </label>
                            <Select
                                placeholder={
                                    this.state.rqObject.reportedOn
                                        ? this.state.rqObject.reportedOn.name
                                        : "Select User..."
                                }
                                options={this.state.userRefs}
                                onChange={this.handleRQUserSelected.bind(this)}
                            />
                        </MDBCol>
                    </MDBRow>

                    <MDBRow style={{justifyContent: "center"}}>
                        <MDBCol size="5">
                            <label> RQ Category </label>
                            <Select
                                placeholder={
                                    this.state.rqObject.rqQualityCategory
                                        ? this.state.rqObject.rqQualityCategory.name
                                        : "Select Category..."
                                }
                                options={this.state.reverseQualityCategoriesRefs}
                                onChange={this.handleRqCategorySelected.bind(this)}
                            />
                        </MDBCol>

                        <MDBCol size="5">
                            <label> RQ Reason </label>
                            <Select
                                placeholder={
                                    this.state.rqObject.rqReason
                                        ? this.state.rqObject.rqReason.name
                                        : "Select Reason..."
                                }
                                options={this.state.currentReverseQualityReasonRefs}
                                onChange={this.handleRqReasonSelected.bind(this)}
                            />
                        </MDBCol>

                        <MDBCol size="10">
                            <MDBInput
                                style={{border: "1px solid black", textAlign: "left"}}
                                type="textarea"
                                label="Enter notes.."
                                rows="4"
                                cols="50"
                                valueDefault={this.state.rqObject.notes}
                                onChange={this.handleRqNote.bind(this)}
                            />

                            <MDBBtn
                                color={"indigo"}
                                className="float-right"
                                onClick={() => this.submitRqCategory()}
                            >
                                Submit
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </ModalBody>
            </MDBModal>
        );
    }

    searchForId(e) {
        e.preventDefault();
        let v = this.state.orderId;
        orderService
            .getOrderActivity(v)
            .then((res) => {
                if (res && res.id && res.activity && res.activity.type === "Order") {
                    this.setState({
                        orderFound: true,
                        tempOrder: res,
                    });
                } else {
                    this.setState({
                        orderErrorMessage: "Order not found!",
                    });
                }
            })
            .catch((err) => {
                console.log(err)
                this.setState({
                    orderErrorMessage: "Order not found!",
                });
            });
    }

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) return false;
        }
        return true;
    }

    hasAccess(type) {
        let cu = this.state.currentUser;

        if (cu.role === "ADMIN" || cu.role === "SYSTEM_ADMIN") {
            return true;
        }

        switch (type) {
            case "reverseQuality":
                if (
                    ["EXECUTIVE_MANAGEMENT", "SALES_MANAGER", "AREA_MANAGER", "SALES_LIAISON_MANAGER"].includes(cu.role) ||
                    cu.role.indexOf("MANAGER") > -1
                ) {
                    return true;
                }
                break;
            case "patientApp":
            case "presidentsClub":
                //if (
                //    ["EXECUTIVE_MANAGEMENT", "SALES_MANAGER", "SALES"].includes(cu.role)
                //) {
                //    return true;
                //}
                if (
                    ["EXECUTIVE_MANAGEMENT", "ADMIN"].includes(cu.role)
                ) {
                    return true;
                }
                break;
            case "salesBonus":
                if (["FINANCE"].includes(cu.role)) {
                    return true;
                }
                break;
            case "techProfile":
                if (
                    ["EXECUTIVE_MANAGEMENT", "OFFICE_MANAGER", "AREA_MANAGER"].includes(cu.role)
                ) {
                    return true;
                }
                break;
            case "pcrLink":
                if (
                    ["EXECUTIVE_MANAGEMENT", "SALES_MANAGER"].includes(cu.role)
                ) {
                    return true;
                }
                break;
            case "liaisonMeetings":
                if (["SALES_LIAISON", "SALES_LIAISON_MANAGER", "EXECUTIVE_MANAGEMENT"].includes(cu.role)) {
                    return true;
                }
                break;
            case "purchasing":
                if (
                    [
                        "EXECUTIVE_MANAGEMENT",
                        "PURCHASING",
                        "AREA_MANAGER",
                        "WAREHOUSE_TECHNICIAN",
                        "OFFICE_MANAGER",
                    ].includes(cu.role)
                ) {
                    return true;
                }
                break;
            case "marketing":
                if (["EXECUTIVE_MANAGEMENT", "SALES_MANAGER", "MARKETING"].includes(cu.role)) {
                    return true;
                }
                break;
            case "followup":
                if (["EXECUTIVE_MANAGEMENT", "FOLLOWUP"].includes(cu.role) || cu.canFollowUp) {
                    return true;
                }
                break;
            case "reports":
                return true;
            // if(["EXECUTIVE_MANAGEMENT","AREA_MANAGER","SALES_MANAGER","OFFICE_MANAGER"].includes(cu.role)){
            //     return true;
            // }
            case "faxes":
                return true;
            case "productCategories":
                if (["EXECUTIVE_MANAGEMENT", "PURCHASING"].includes(cu.role)) {
                    return true;
                }
                break;
            default:
                break;
        }
    }

    renderRedirect() {
        if (this.state.searchedPatient === true) {
            this.setState({searchedPatient: false});
            return <Redirect to={"/patientList/" + this.state.searchText} />;
        } else if (this.state.orderFound === true) {
            this.setState({
                orderFound: false,
                orderModalOpen: false,
            });
            return <Redirect to={{pathname: "/order/" + this.state.orderId, state: {order: this.state.tempOrder}}} />;
        } else {
            return <div />;
        }
    }

    renderOrderIdSearch() {
        return (
            <NavItem>
                <NavLink
                    onClick={() => this.setState({orderModalOpen: true})}
                    to="#!"
                >
                    <AiOutlineSearch
                        size="2em"
                        data-place={"bottom"}
                        data-tip={"Search By Order Id"}
                        style={{color: "#5881C1"}}
                    />
                </NavLink>
            </NavItem>
        );
    }

    renderReverseQualityModalIcon() {
        let t = this;

        function showLogs() {
            if (t.hasAccess("reverseQuality") === true) {
                return (
                    <DropdownItem>
                        <NavLink style={{padding: 0}} to="/reverseQualityLogs">
                            Reverse Quality Logs
                        </NavLink>
                    </DropdownItem>
                );
            }
        }

        function showReasons() {
            if (t.hasAccess("admin") === true) {
                return (
                    <DropdownItem>
                        <NavLink style={{padding: 0}} to="/reverseQualityReasons">
                            Reverse Quality Reasons
                        </NavLink>
                    </DropdownItem>
                );
            }
        }

        return (
            <NavItem>
                <Dropdown size="sm">
                    <DropdownToggle nav caret style={{color: "#5881C1"}}>
                        <MdErrorOutline
                            size="2em"
                            data-place={"bottom"}
                            data-tip={"Reverse Quality"}
                            style={{color: "#5881C1"}}
                        />
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem>
                            <NavItem>
                                <NavLink
                                    style={{padding: 0}}
                                    onClick={() => this.setState({rqModalOpen: true})}
                                    to="#!"
                                >
                                    Report Reverse Quality
                                </NavLink>
                            </NavItem>
                        </DropdownItem>
                        {/*<DropdownItem>*/}
                        {/*    <NavItem>*/}
                        {/*        <NavLink*/}
                        {/*            style={{ padding: 0 }}*/}
                        {/*            onClick={() => this.setState({ assuranceModalOpen: true })}*/}
                        {/*            to="#!"*/}
                        {/*        >*/}
                        {/*            Quality Assurance*/}
                        {/*        </NavLink>*/}
                        {/*    </NavItem>*/}
                        {/*</DropdownItem>*/}
                        {showLogs()}
                        {showReasons()}
                    </DropdownMenu>
                </Dropdown>
            </NavItem>
        );
    }

    renderReportsNav() {
        if (this.hasAccess("reports")) {
            return (
                <NavItem>
                    <NavLink to="/reports">
                        <AiOutlineBarChart
                            size="2em"
                            data-place={"bottom"}
                            data-tip={"Reports"}
                            style={{color: "#5881C1"}}
                        />
                    </NavLink>
                </NavItem>
            );
        }
    }

    renderFaxesNav() {
        if (this.hasAccess("faxes")) {
            return (
                <NavItem>
                    <NavLink to="/faxes">
                        <AiOutlinePrinter
                            size="2em"
                            data-place={"bottom"}
                            data-tip={"Faxes"}
                            style={{color: "#5881C1"}}
                        />
                    </NavLink>
                </NavItem>
            );
        }
    }

    renderFollowUp() {
        if (this.hasAccess("followup")) {
            return (
                <DropdownItem>
                    <NavLink style={{padding: 0}} to="/followup">
                        Follow-Up
                    </NavLink>
                </DropdownItem>
            );
        }
    }

    renderAtp() {
        return (
            <DropdownItem>
                <NavLink style={{padding: 0}} to="/atp">
                    ATP
                </NavLink>
            </DropdownItem>
        );
    }

    renderTechProfiles() {
        if (this.hasAccess("techProfile") === true) {
            return (
                <DropdownItem>
                    <NavLink style={{padding: 0}} to="/techProfiles">
                        Technician Profiles
                    </NavLink>
                </DropdownItem>
            );
        }
    }

    renderInventoryTracking() {
        // if (this.hasAccess("inventoryTracking") === true)
        //{
        return (
            <DropdownItem>
                <NavLink style={{padding: 0}} to="/inventoryTracking">
                    Inventory Tracking
                </NavLink>
            </DropdownItem>
        );
        //}
    }

    renderPCRLink() {
        if (this.hasAccess("pcrLink") === true) {
            return (
                <DropdownItem>
                    <NavLink style={{padding: 0}} to="/pcrLinks">
                        PCR Links
                    </NavLink>
                </DropdownItem>
            );
        }
    }

    renderProductCategories() {
        if (this.hasAccess("productCategories") === true) {
            return (
                <div>
                    <MDBDropdownItem divider />
                    <MDBDropdownItem header>Products</MDBDropdownItem>
                    <MDBDropdownItem divider />

                    <DropdownItem>
                        <NavLink style={{padding: 0}} to="/products">
                            Product Categories
                        </NavLink>
                    </DropdownItem>

                    <DropdownItem>
                        <NavLink style={{padding: 0}} to="/productSizes">
                            Product Sizes
                        </NavLink>
                    </DropdownItem>
                </div>
            )
        }
    }

    renderFieldOperationsNav() {
        return (
            <NavItem>
                <Dropdown size="sm">
                    <DropdownToggle nav caret style={{color: "#5881C1"}}>
                        <MDBIcon icon="truck-moving"
                            size={"2x"}
                            data-place={"bottom"}
                            data-tip={"Field Operations"}
                        />

                    </DropdownToggle>
                    <DropdownMenu>

                        <DropdownItem>
                            <NavLink style={{padding: 0}} to="/deliveries">
                                Deliveries
                            </NavLink>
                        </DropdownItem>

                        {this.renderAtp()}

                        {/*{this.renderTechProfiles()}*/}
                        {this.renderInventoryTracking()}


                    </DropdownMenu>
                </Dropdown>
            </NavItem>
        );
    }

    renderOperationsNav() {
        let rsns = this.state.reasons.filter(x =>
            x.orderStatus === 1 &&
            x.deleted !== true &&
            !["Follow Up Task", "Verification"].includes(x.description) &&
            x.description.indexOf("Service -"));
        let srsns = this.state.reasons.filter(x =>
            x.orderStatus === 1 &&
            x.deleted !== true &&
            [61, 72].includes(x.id));

        return (
            <NavItem>
                <Dropdown size="sm" className={"customNavDropdown"}>
                    <DropdownToggle nav caret style={{color: "#5881C1"}}>
                        <GiGears
                            size="2em"
                            data-place={"bottom"}
                            data-tip={"Operations"}
                            style={{color: "#5881C1"}}
                        />
                    </DropdownToggle>

                    <DropdownMenu>
                        <DropdownItem>
                            <NavLink style={{padding: 0}} to="/order">
                                Orders
                            </NavLink>
                        </DropdownItem>

                        <DropdownItem>
                            <NavLink style={{padding: 0}} to="/patientList">
                                Patients
                            </NavLink>
                        </DropdownItem>

                        <DropdownItem>
                            <NavLink style={{padding: 0}} to="/internalRequest">
                                Internal Request
                            </NavLink>
                        </DropdownItem>

                        {/*<DropdownItem>*/}
                        {/*    <NavLink style={{padding: 0}} to="/verification">*/}
                        {/*        Verification*/}
                        {/*    </NavLink>*/}
                        {/*</DropdownItem>*/}

                        {/*<DropdownItem>*/}
                        {/*    <NavLink style={{ padding: 0 }} to="/insuranceView">*/}
                        {/*        Insurance View*/}
                        {/*    </NavLink>*/}
                        {/*</DropdownItem>*/}

                        {/*{this.renderFollowUp()}*/}

                        <DropdownItem>
                            <NavLink style={{padding: 0}} to="/billing">
                                Billing
                            </NavLink>
                        </DropdownItem>

                        <DropdownItem>
                            <NavLink style={{padding: 0}} to="/collections">
                                Patient Collections
                            </NavLink>
                        </DropdownItem>

                        <DropdownItem>
                            <NavLink style={{padding: 0}} to="/recurringOrders">
                                Recurring Orders
                            </NavLink>
                        </DropdownItem>

                        <DropdownItem>
                            <NavLink style={{padding: 0}} to="/feedback">
                                Customer Feedback
                            </NavLink>
                        </DropdownItem>
                        <MDBDropdownItem divider />
                        <MDBDropdownItem header>In Process</MDBDropdownItem>
                        <MDBDropdownItem divider />

                        {this.hasAccess("patientApp") ?
                            <DropdownItem>
                                <NavLink style={{ padding: 0 }} to="/patientApprovals">
                                    Patient App Approvals
                                </NavLink>
                            </DropdownItem>
                            : ""}

                        {this.renderFollowUp()}

                        <DropdownItem>
                            <NavLink style={{ padding: 0 }} to={{ pathname: "/verification", state: {header: "Verification"}}}>
                                Verification
                            </NavLink>
                        </DropdownItem>

                        {rsns && rsns.length > 0 ?
                            rsns.map(r => {
                                return <DropdownItem>
                                    <NavLink style={{ padding: 0 }} to={{ pathname: "/inProcess/" + r.id, state: { header: r.description } }}>
                                        {r.description}
                                    </NavLink>
                                </DropdownItem>
                            })
                        : ""}

                        <MDBDropdownItem divider />
                        <MDBDropdownItem header>Service</MDBDropdownItem>
                        <MDBDropdownItem divider />
                        <DropdownItem>
                            <NavLink style={{padding: 0}} to="/serviceDoc">
                                Documentation Follow Up
                            </NavLink>
                        </DropdownItem>
                        <DropdownItem>
                            <NavLink style={{padding: 0}} to="/pendingTicket">
                                CSR – Need Service Ticket
                            </NavLink>
                        </DropdownItem>

                        {srsns && srsns.length > 0 ?
                            srsns.map(r => {
                                return <DropdownItem>
                                    <NavLink style={{ padding: 0 }} to={{ pathname: "/inProcess/" + r.id, state: { header: r.description } }}>
                                        {r.description}
                                    </NavLink>
                                </DropdownItem>
                            })
                            : ""}


                        {/*{this.renderProductCategories()}*/}

                    </DropdownMenu>
                </Dropdown>
            </NavItem>
        );
    }

    renderSalesNav() {
        return (
            <NavItem>
                <Dropdown size="sm" className={"customNavDropdown"}>
                    <DropdownToggle nav caret style={{color: "#5881C1"}}>
                        <GiShakingHands
                            size="2em"
                            data-place={"bottom"}
                            data-tip={"Sales"}
                            style={{color: "#5881C1"}}
                        />
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem>
                            <NavLink style={{padding: 0}} to="/accounts">
                                Accounts
                            </NavLink>
                        </DropdownItem>

                        <DropdownItem>
                            <NavLink style={{padding: 0}} to="/activities">
                                Activities
                            </NavLink>
                        </DropdownItem>

                        <DropdownItem>
                            <NavLink style={{padding: 0}} to="/contacts">
                                Contacts
                            </NavLink>
                        </DropdownItem>

                        {this.renderPCRLink()}
                        {this.renderPresidentsClubNav()}
                        {this.renderBonusNav()}
                        {this.renderLiaisonMeetingsNav()}
                    </DropdownMenu>
                </Dropdown>
            </NavItem>
        );
    }

    renderPresidentsClubNav() {
        if (this.hasAccess("presidentsClub")) {
            return (
                <DropdownItem>
                    <NavLink style={{padding: 0}} to="/presidentsClub">
                        Presidents Club
                    </NavLink>
                </DropdownItem>
            );
        }
    }

    renderBonusNav() {
        if (this.hasAccess("salesBonus")) {
            return (
                <DropdownItem>
                    <NavLink style={{padding: 0}} to="/bonus">
                        Sales Bonus
                    </NavLink>
                </DropdownItem>
            );
        }
    }

    renderLiaisonMeetingsNav() {
        if (this.hasAccess("liaisonMeetings")) {
            return (
                <DropdownItem>
                    <NavLink style={{padding: 0}} to="/liaisonMeetings">
                        Sales Liaison Meetings
                    </NavLink>
                </DropdownItem>
            );
        }
    }

    renderExpensesNav() {
        return (
            <NavItem>
                <NavLink to="/expenses">
                    <FaDollarSign
                        size="2em"
                        data-place={"bottom"}
                        data-tip={"Expenses"}
                        style={{color: "#5881C1"}}
                    />
                </NavLink>
            </NavItem>
        );
    }

    renderPurchasingNav() {
        if (this.hasAccess("purchasing")) {
            return (
                <NavItem>
                    <Dropdown size="sm" className={"customNavDropdown"}>
                        <DropdownToggle nav caret style={{color: "#5881C1"}}>
                            <FaBoxes
                                size="2em"
                                data-place={"bottom"}
                                data-tip={"Purchasing"}
                                style={{color: "#5881C1"}}
                            />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/purchaseQueues">
                                    Purchase Queues
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink
                                    style={{padding: 0}}
                                    to="#!"
                                    onClick={() => {
                                        this.setState({inventoryRequestOpen: true});
                                    }}
                                >
                                    Inventory Request
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink
                                    style={{padding: 0}}
                                    to="#!"
                                    onClick={() => {
                                        this.setState({poDeliveryUpdateOpen: true});
                                    }}
                                >
                                    PO Delivery Update
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/inventoryProducts">
                                    Inventory Products
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/vendors">
                                    Vendors
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/shippingDetails">
                                    Shipping Details Report
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/serviceParts">
                                    Manage Service Parts
                                </NavLink>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </NavItem>
            );
        }
        return <div />;
    }

    renderMarketingNav() {
        if (this.hasAccess("marketing")) {
            return (
                <NavItem>
                    <Dropdown size="sm">
                        <DropdownToggle nav caret style={{color: "#5881C1"}}>
                            <MdPermMedia
                                size="2em"
                                data-place={"bottom"}
                                data-tip={"Marketing"}
                                style={{color: "#5881C1"}}
                            />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/campaignList">
                                    Campaigns
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/campaignCategories">
                                    Categories
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/campaignCharts">
                                    Charts
                                </NavLink>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </NavItem>
            );
        }
        return <div />;
    }

    renderAdminNav() {
        if (this.hasAccess("admin")) {
            return (
                <NavItem>
                    <Dropdown size="sm" className={"customNavDropdown"}>
                        <DropdownToggle nav caret style={{color: "#5881C1"}}>
                            <MdSecurity
                                size="2em"
                                data-place={"bottom"}
                                data-tip={"Admin"}
                                style={{color: "#5881C1"}}
                            />
                        </DropdownToggle>
                        <DropdownMenu>

                            <MDBDropdownItem divider style={ { marginTop: "0px" } } />
                            <MDBDropdownItem header>User Management</MDBDropdownItem>
                            <MDBDropdownItem divider />

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/userList">
                                    Users
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/userRoleQuotas">
                                    Roles and Quotas
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/thirdPartyUserList">
                                    Third Party Users
                                </NavLink>
                            </DropdownItem>
                            {/* 
                            <MDBDropdownItem divider />
                            <MDBDropdownItem header>Products</MDBDropdownItem>
                            <MDBDropdownItem divider />

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/products">
                                    Product Categories
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/productSizes">
                                    Product Sizes
                                </NavLink>
                            </DropdownItem> */}

                            <MDBDropdownItem divider />
                            <MDBDropdownItem header>Insurance</MDBDropdownItem>
                            <MDBDropdownItem divider />

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/insurance">
                                    Payor Sources
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/insuranceTypes">
                                    Insurance
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/insuranceSubTypeDetails">
                                    Insurance Details
                                </NavLink>
                            </DropdownItem>

                            <MDBDropdownItem divider />
                            <MDBDropdownItem header>Sales</MDBDropdownItem>
                            <MDBDropdownItem divider />

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/accountTypes">
                                    Account Types
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/notificationTypes">
                                    Notification Types
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/contactTypes">
                                    Contact Types
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/creditCardTypes">
                                    Credit Card Types
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/expenseTypes">
                                    Expense Types
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/documentTypes">
                                    Document Types
                                </NavLink>
                            </DropdownItem>

                            <MDBDropdownItem divider />
                            <MDBDropdownItem header>System Config</MDBDropdownItem>
                            <MDBDropdownItem divider />

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/reasons">
                                    Status Reasons
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/locations">
                                    Locations
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/regions">
                                    Regions
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/stateSalesTax">
                                    State Sales Tax
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/manualDocumentBuilder">
                                    Manual Document Builder
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/homeAssessmentCompare">
                                    Home Assessment Compare
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/deployments">
                                    Deployment
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/deliveryAcknowledgements">
                                    Delivery Form Acknowledgments
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/version">
                                    Web Version
                                </NavLink>
                            </DropdownItem>

                            {this.renderProductCategories()}

                            <MDBDropdownItem divider />
                            <MDBDropdownItem header>Patient Config</MDBDropdownItem>
                            <MDBDropdownItem divider />

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/customerFeedback">
                                    Customer Feedback Settings
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/trainingLink">
                                    Patient Training Videos
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/productMatrixEntry">
                                    Product Matrix Entries
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem>
                                <NavLink style={{padding: 0}} to="/patientApprovals">
                                    Patient App Approvals
                                </NavLink>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </NavItem>
            );
        }
        return <div />;
    }

    renderLogoutNav() {
        return (
            <NavItem>
                <NavLink
                    onClick={() => {
                        let msalAccount = this.state.msalAccount,
                            companies = [
                                {
                                    name: 'Rehab Medical', value: {
                                        name: 'rehabmedical',
                                        authConfig: {
                                            auth: {
                                                clientId: "a06bbb83-c8df-45ea-b686-452403137476",
                                                authority: "https://login.microsoftonline.com/f2c81385-bf14-4df0-ac96-e01bda2adf46/",
                                                replyUrl: window.location.origin + "/dashboard",
                                                request: {
                                                    scopes: ["openid", "profile", "User.Read"],
                                                    forceRefresh: false,
                                                },
                                            },
                                        },
                                    }
                                },
                                {
                                    name: 'Cork Medical', value: {
                                        name: 'corkmedical',
                                        authConfig: {
                                            auth: {
                                                clientId: "12640c30-8132-4ae0-a7f0-f3532247643c",
                                                authority: "https://login.microsoftonline.com/ea8b3ecd-6c46-48e8-aad6-583c904c3d0a/",
                                                replyUrl: window.location.origin + "/dashboard",
                                                request: {
                                                    scopes: ["openid", "profile", "User.Read"],
                                                    forceRefresh: false,
                                                },
                                            },
                                        },
                                    }
                                },
                                {
                                    name: 'UAT', value: {
                                        name: 'uat',
                                        authConfig: {
                                            auth: {
                                                clientId: "a06bbb83-c8df-45ea-b686-452403137476",
                                                authority: "https://login.microsoftonline.com/f2c81385-bf14-4df0-ac96-e01bda2adf46/",
                                                replyUrl: window.location.origin + "/dashboard",
                                                request: {
                                                    scopes: ["openid", "profile", "User.Read"],
                                                    forceRefresh: false,
                                                },
                                            },
                                        },
                                    }
                                },
                            ],
                            company = companies.filter(x => {
                                if (x.name === this.state.company)
                                    return x;
                                else
                                    return companies[2];
                            })[0];

                        if (company && msalAccount) {
                            let pca = new msal.PublicClientApplication({ auth: company.value.authConfig, system: { allowRedirectInIframe: true } }),
                                logoutRequest = {
                                    account: pca.getAccountByUsername(msalAccount.username),
                                    onRedirectNavigate: () => {
                                        return false;
                                    }
                                };
                            //const hint = msalAccount.idTokenClaims.login_hint;
                            pca.logoutRedirect(logoutRequest);
                        }

                        window.sessionStorage.clear();
                        this.setUserFunction(null);
                        this.setCompanyFunction(null, null);
                        this.setState({userMetricsVisible: false});
                    }}
                    to={"/login"}
                >
                    <FiLogOut
                        size="2em"
                        data-place={"bottom"}
                        data-tip={"Logout"}
                        style={{color: "#5881C1"}}
                    />
                </NavLink>
            </NavItem>
        );
    }

    retrieveCurrentMetrics() {
        orderService.getUserQuotaMetrics(this.state.currentUser.id).then(res => {

            this.setState({
                userMetricsVisible: true,
                metricsData: res
            })
        }).catch(e => {
            console.log(e);
        })
    }

    toggleMetrics() {
        let v = !this.state.userMetricsVisible;

        if (v === true) {
            this.retrieveCurrentMetrics()
        } else {
            this.setState({userMetricsVisible: v})
        }
    }

    renderNavBarItems() {
        //TODO: remove this check...BC
        if (this.state.notLoggedIn === true) {
            //don't need a navbar here.  show nothing...BC
            return <div />;
        } else {
            return (
                <Navbar
                    color={"white"}
                    style={{paddingTop: 0, paddingBottom: 0}}
                    expand="xs"
                    fixed="top">
                    <ReactTooltip />
                    {/* <MDBIcon size={"1x"} icon="angle-double-down"
                             style={{paddingRight: 8, color: "#5881C1"}}
                             onClick={() => this.toggleMetrics()}
                    /> */}
                    <NavbarBrand
                        style={{fontWeight: 500, color: "#5881C1"}}
                        href="/dashboard">

                        <img
                            src={require("./images/salesPilotWebLogo.png")}
                            alt={"Sales Pilot Logo"}
                        />
                        &nbsp; Sales Pilot
                    </NavbarBrand>

                    <NavbarNav>
                        {this.renderOrderIdSearch()}
                        {this.renderReverseQualityModalIcon()}
                        {this.renderReportsNav()}
                        {this.renderFaxesNav()}
                        {this.renderOperationsNav()}
                        {this.renderFieldOperationsNav()}
                        {this.renderSalesNav()}
                        {this.renderExpensesNav()}
                        {this.renderPurchasingNav()}
                        {this.renderMarketingNav()}
                        {this.renderAdminNav()}
                        {this.renderLogoutNav()}
                    </NavbarNav>
                </Navbar>
            );
        }
    }

    renderNavbarCollapse() {
        return (
            <MDBCollapse id="collapse1"
                isOpen={this.state.userMetricsVisible}
                style={{
                    position: "fixed",
                    top: 50,
                    backgroundColor: '#5881C1',
                    boxShadow: "0px 5px 5px #375177",
                    zIndex: 5000,
                    right: 0,
                    left: 0
                }}>
                <UserMetricCollapse history={this.props.history} metricsData={this.state.metricsData}
                    roleQuotas={this.state.userRoleQuotas} />
            </MDBCollapse>
        )
    }

    renderUpdateNotesModal() {
        return (
            <MDBModal
                size="lg"
                isOpen={this.state.updateNotesModalOpen}
                toggle={() => {
                    this.setState({
                        updateNotesModalOpen: false,
                    });
                }}>
                <MDBModalHeader style={{color: 'white', backgroundColor: '#5881c1'}}>
                    Sales Pilot Update History
                </MDBModalHeader>

                <MDBCard style={{maxHeight: 450, overflow: 'scroll'}}>
                    <MDBCardBody>
                        <UpdateNotes />
                    </MDBCardBody>
                </MDBCard>
            </MDBModal>
        );
    }

    setUpdateAcknowledge(emptyCacheStorage) {
        if (this.state.updateAcknowledgeModel) {
            window.sessionStorage.setItem("updateAcknowledgeModel", JSON.stringify(false));
            this.setState({
                updateAcknowledgeModel: false
            })
        } else {
            window.sessionStorage.setItem("updateAcknowledgeModel", JSON.stringify(true));
            setTimeout(() => {
                emptyCacheStorage();
            }, 500);
        }
    }
    renderUpdateNotesModalRequired() {

        let open = this.state.updateAcknowledgeModel;

        return (
            <MDBModal
                className={"salesPilotModal"}
                size="lg"
                isOpen={open}
                toggle={() => {

                }}
            >
                <ReactTooltip />
                <MDBModalHeader style={{color: 'white', backgroundColor: '#5881c1'}}>
                    New Sales Pilot Update
                </MDBModalHeader>

                <MDBCard>
                    <MDBModalBody style={{maxHeight: 450, overflowY: 'scroll'}}>
                        <UpdateNotes />
                    </MDBModalBody>
                    <MDBCardFooter>
                        <MDBRow>
                            <MDBCol md={"8"}>
                                <p style={{fontSize: "small"}}>
                                    To view this information at any time <br />
                                    click on "What's New" in the lower left hand corner of the website
                                </p>
                            </MDBCol>
                            <MDBCol md={"4"}>
                                <Button
                                    style={{float: "right"}}
                                    color={"secondary"}
                                    floating
                                    size="sm"
                                    data-tip={"Acknowledge"}
                                    onClick={() => {
                                        this.setUpdateAcknowledge();
                                    }}
                                >
                                    <MDBIcon icon="check" style={{fontSize: "2em"}} />
                                </Button>
                            </MDBCol>
                        </MDBRow>
                    </MDBCardFooter>
                </MDBCard>
            </MDBModal>
        );
    }
    render() {
        let st = this.state;

        function loggedIn() {
            if (st.notLoggedIn === true) {
                return <Redirect to={"/"} />
            }
        }
        return (
            <Router>
                {loggedIn()}
                <div className="flyout App-background mainBackground" style={{backgroundImage: `url(${background})`}}>
                    <div>
                        {this.renderOrderSearchModal()}
                        {this.renderReverseQualityModal()}
                        {this.renderQualityAssuranceScorecard()}
                        {this.renderInventoryRequestModal()}
                        {this.renderPODeliveryUpdateModal()}
                        {this.renderRedirect()}
                        {this.renderNavBarItems()}
                        {this.renderNavbarCollapse()}
                        {this.renderUpdateNotesModal()}
                        {this.renderUpdateNotesModalRequired()}
                        <MainProvider value={this.state}>
                            <main className={"App-main"}>
                                <Routes />
                            </main>
                        </MainProvider>
                    </div>

                    <div>
                        <Footer className={"App-footer"}>
                            <p className="text-center" style={{margin: "2px"}}>
                                <span style={{float: "left", paddingLeft: 8, cursor: "pointer"}}
                                    onClick={() => this.setState({updateNotesModalOpen: true})}>
                                    <MDBIcon icon="info-circle"
                                        style={{paddingRight: 8}}
                                    />
                                    View What's New In Version : {VER}
                                </span>
                                <ClearCache duration={30000} auto={false}>
                                    {({isLatestVersion, emptyCacheStorage, loading}) => (
                                        <span>
                                            {!isLatestVersion ? (
                                                this.setUpdateAcknowledge(emptyCacheStorage)
                                            ) :
                                                ""
                                            }
                                        </span>
                                    )}
                                </ClearCache>
                                <span style={{paddingRight: 240}}>&copy; {new Date().getFullYear()} Rehab Medical &nbsp;
                                    {this.state.company}</span>
                            </p>
                        </Footer>
                    </div>
                </div>
            </Router>
        );
    }
}

export default App;
