import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

import {isGroupTableRow} from "@devexpress/dx-grid-core";
import {
    Grid,
    Table,
    VirtualTable,
    TableHeaderRow,
    PagingPanel,
    TableGroupRow,
    GroupingPanel,
    DragDropProvider,
    Toolbar,
    TableColumnResizing, TableColumnVisibility,
} from "@devexpress/dx-react-grid-material-ui";
import Select from "react-select";
import {
    GroupingState,
    SortingState,
    IntegratedSorting,
    IntegratedGrouping,
    PagingState,
    IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
    Plugin,
    Template,
    TemplateConnector,
    TemplatePlaceholder,
} from "@devexpress/dx-react-core";

import {
    Button,
    Container,
    Col,
    Row,
    Spinner,
    toast,
    ToastContainer,
    MDBIcon, Fa, Modal, MDBModalHeader, ModalBody, ModalFooter,
} from "mdbreact";
import OrderService from "../Security/OrderService/orderService";
import RegionService from "../Settings/regionServices";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import "./orderList.css";
import ReactTooltip from "react-tooltip";
import {Card} from "@material-ui/core";
import moment from "moment";


const ItemCounter = () => (
    <Plugin name="ItemCounter">
        <Template
            name="tableCell"
            predicate={({tableRow}) => isGroupTableRow(tableRow)}
        >
            {(params) => (
                <TemplateConnector>
                    {({getCollapsedRows}) => {
                        const updatedParams = {
                            ...params,
                            tableRow: {
                                ...params.tableRow,
                                row: {
                                    ...params.tableRow.row,
                                    collapsedRows: getCollapsedRows(params.tableRow.row) || [],
                                },
                            },
                        };
                        return <TemplatePlaceholder params={updatedParams} />;
                    }}
                </TemplateConnector>
            )}
        </Template>
    </Plugin>
);

const Root = (props) => <Grid.Root {...props} style={{height: "100%"}} />;

const compareDate = (a, b) => {
    const priorityA = new Date(a);
    const priorityB = new Date(b);
    if (priorityA === priorityB) {
        return 0;
    }
    return priorityA < priorityB ? -1 : 1;
};
const sortDate = (a, b) => {
    if (a === b) {
        return 0;
    }
    return b < a ? -1 : 1;
};

function getVal(row) {
    if (row.collapsedRows.length > 0) {
        return "Count: " + row.collapsedRows.length;
    } else {
        return "";
    }
}

const Content = ({row, column}) => (
    <span>
        <span>
            {column.title} : {row.value}
        </span>
        <span style={{fontWeight: "bold"}}>&nbsp; {getVal(row)}</span>
    </span>
);

export default class InProcessQueue extends React.Component {
    constructor(props) {
        super(props);


        const defaultGroups={
            0: [{columnName: "timestamp"}],
            //Add other reason ids to set default groups on an individual basis
            61: [{columnName: "timestamp"}],
            72: [{columnName: "timestamp"}],
            59: [{columnName: "orderType"}, { columnName: "timestamp" }]
        };
        const defaultHiddenGroups={ 0: [""]
            // ,
            // 61: ["salesLocation"],
            // 72: ["salesLocation"]
        };
        const defaultRenameGroups={ 0: [{title: "",
                name: ""}],
            61: [{
                title: "Days In Queue",
                name: "timestamp"
            }],
            72: [{
                title: "Days In Queue",
                name: "timestamp"
            }],
        };
        const defaultIntegratedSortingGroups ={ 0:  [{columnName: "timestamp", compare: compareDate}],
            61:  [{columnName: "timestamp", compare: sortDate}],
            72: [{columnName: "timestamp", compare: sortDate}],
        };
        const defaultSortingGroups={ 0: [{columnName: 'timestamp', direction: 'asc'}],
            61: [{columnName: 'timestamp', direction: 'dec'}],
            72: [{columnName: 'timestamp', direction: 'dec'}],
            59: [{columnName: "orderType", direction: "dec"}]
        };

        const defaultSorting = (defaultSortingGroups[props.match.params.id] ? defaultSortingGroups[props.match.params.id] : defaultSortingGroups[0]);
        const defaultIntegratedSorting = (defaultIntegratedSortingGroups[props.match.params.id] ? defaultIntegratedSortingGroups[props.match.params.id] : defaultIntegratedSortingGroups[0]);
        const groupColumns = (defaultGroups[props.match.params.id] ? defaultGroups[props.match.params.id] : defaultGroups[0]);
        const renameColumns = (defaultRenameGroups[props.match.params.id] ? defaultRenameGroups[props.match.params.id] : defaultRenameGroups[0]);
        const defaultHiddenColumnNames = (defaultHiddenGroups[props.match.params.id] ? defaultHiddenGroups[props.match.params.id] : defaultHiddenGroups[0]);
 
        let f = props.userFilters,
            reasons = [],
            users = [];

        const data = {
            columns: [
                {
                    title: "Id",
                    name: "orderId",
                },
                {
                    title: "Order Type",
                    name: "orderType",
                },
                {
                    title: "Priority",
                    name: "priority",
                },
                {
                    title: "Pediatric",
                    name: "pediatric",
                },
                {
                    title: "Task Zero Date",
                    name: "timestamp",
                },
                {
                    title: "Started",
                    name: "startedAt",
                },
                {
                    title: "Being Worked On By",
                    name: "currentlyBeingWorkedBy",
                },
                {
                    title: "Patient Name",
                    name: "patientName",
                },
                {
                    title: "Primary Insurance",
                    name: "primaryInsurance",
                },
                {
                    title: "Primary Payor",
                    name: "primaryPayorSource",
                },
                {
                    title: "Secondary Insurance",
                    name: "secondaryInsurance",
                },
                {
                    title: "Secondary Payor",
                    name: "secondaryPayorSource",
                },
                {
                    title: "Sales Location",
                    name: "salesLocation"
                },
                {
                    title: "Points",
                    name: "points",
                },
                {
                    title: "Category",
                    name: "category",
                },
                {
                    title: "Unassign",
                    name: "unassign",
                },


            ],
            rows: [],
            integratedSortingColumnExtensions: defaultIntegratedSorting,
        };
        renameColumns.map((rename) => {
            return data.columns.map((col) => {
                    if (rename.name === col.name) {
                        return col.title = rename.title;
                }
                return null;
            })
        })

        // data.columns[3] = {
        //     title: "Task test Date",
        //         name: "timestamp",
        // };

        let defaultColumnWidths = [
            {columnName: "orderId", width: 80},
            {columnName: "orderType", width: 100},
            {columnName: "priority", width: 80},
            {columnName: "pediatric", width: 80},
            {columnName: "timestamp", width: 160},
            {columnName: "startedAt", width: 90},
            {columnName: 'currentlyBeingWorkedBy', width: 160},
            {columnName: "patientName", width: 150},
            {columnName: "primaryInsurance", width: 140},
            {columnName: "primaryPayorSource", width: 110},
            {columnName: "secondaryInsurance", width: 150},
            {columnName: "secondaryPayorSource", width: 150},
            {columnName: "salesLocation", width: 120},
            {columnName: "points", width: 70},
            {columnName: "category", width: 120},
            {columnName: "unassign", width: 80},
        ];
        this.state = {
            cName: null,
            userFilters: f,
            users: users,
            data: data,
            isLoaded: false,
            grouping: [],
            groupingColumns: groupColumns,
            defaultSorting: defaultSorting,
            defaultHiddenColumnNames: defaultHiddenColumnNames,
            defaultColumnWidths: defaultColumnWidths,
            groupSummaryItems: [],
            regions: [],
            selectedRegion: null,
            allResults: [],
            ctrl: false,
            workedOnModal: false,
            currentOrder: [],
            workedByMe: [],
            allReasons: [],
            reasonIds: reasons || [],
            selectedReasonIds: reasons || [],
            reasonId: props.match.params.id || 15,
            header: props && props.location && props.location.state && props.location.state.header ? props.location.state.header : "In Process",
            defaultGroups: defaultGroups,
        };

        this.changeGrouping = (grouping) => {
            let ary = [];

            grouping.forEach((group) => {
                ary.push({columnName: group.columnName});
            });
            this.setState({
                groupingColumns: ary,
            });
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        companyName: PropTypes.string,
        allRegions: PropTypes.array,
        allUsers: PropTypes.array,
        reasons: PropTypes.array,
    };

    getGrouping(){
        let groups = this.state.defaultGroups;
        let group = groups[this.props.match.params.id] ? groups[this.props.match.params.id] : groups[0];
        this.setState({
            groupingColumns: group
        })
    }
    downloadFunction() {
        this.clientCSV(this.state.data, "Orders.csv");
    }


    componentDidMount() {
        //const { companyName, reasons } = this.context;
        const { reasons } = this.context;

        //let gc = this.state.groupingColumns;

        //if (companyName !== "cork") {
        //    gc.splice(1, 0, { columnName: "pediatric" })
        //    this.setState({groupingColumns: gc})
        //}
        this.retrieveRegions();

        if (reasons.length !== this.state.allReasons.length) {
            this.getReasonOptions();
            this.getHeader();
        }
    }

    componentDidUpdate(prevProps, prevState, snapShot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({
                reasonId: this.props.match.params.id,
                isLoaded: false,
                header: this.props.location.state.header
            })
            this.getGrouping();
            this.getResults();
        }
    }

    setHeader() {
        const { reasons } = this.context;
        console.log(reasons)
        console.log(this.props.match.params.id)
        console.log(this.props)
        var res = reasons.filter(x => x.id === this.props.match.params.id);
        console.log(res)
        if (res)
            this.setState({ header: res.name });
        else
            this.setState({ header: "In Process" });
    }

    //This will convert all the data in the grid to a csv file
    clientCSV(stateData, filename) {
        let result,
            ctr,
            keys = [],
            headers = [],
            columnDelimiter = ",",
            lineDelimiter = "\n",
            data;

        data = stateData.rows || null;
        if (data == null || !data.length) {
            return null;
        }

        stateData.columns.forEach((col) => {
            keys.push(col.name);
            headers.push(col.title);
        });

        let replace = ["category", "patientName", "phone", "timestamp"];

        result = "";
        result += headers.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;
                if (key === "priority") {
                    if (typeof item[key] !== "string") {
                        item[key] = "Is Priority"
                    }
                }
                if (replace.indexOf(key) > -1 && item[key] != null && typeof item[key] === "string") {
                    result += item[key].replace(/,/g, " ");
                } else {
                    result += item[key];
                }


                ctr++;
            });
            result += lineDelimiter;
        });

        this.downloadBlob(result, filename);
    }

    //this actually prompts the download
    downloadBlob(csvData, filename) {
        let blob = new Blob([csvData], {
            type: "application/csv;charset=utf-8;",
        });

        if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, filename);
        } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a"),
                csvUrl = URL.createObjectURL(blob);

            link.href = csvUrl;
            link.style = "visibility:hidden";
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    formatDate(date) {
        if (date == null || date === "") {
            return "";
        }
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
            day: "2-digit",
        }).format(date);
    }

    renderPriorityIcon(order) {
        if (order.priority) {
            return <Fa icon={"arrow-circle-up"} />;
        }
        return "";
    }

    formatDateLong(date) {
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "numeric",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric"
        }).format(date);
    }

    getResults() {
        return OrderService.getInProcessView(this.state.selectedRegion, this.props.match.params.id)
            .then((res) => {
                this.buildNewResults(res);
            })
            .catch((err) => {
                this.setState({isLoaded: true});
            });
    }

    getHeader() {
        const {reasons} = this.context;
        if (this.props.match.params.id) {
            reasons.filter((x) => {
                if (x.id === this.props.match.params.id) {
                    this.setState({
                        header: x.description
                    })
                }
                return false
            })

        }
    }

    getReasonOptions() {
        const {reasons} = this.context;
        let a = [],
            statuses = this.state.selectedStatuses || [],
            rsns = reasons || this.state.allReasons,
            ids = this.state.reasonIds,
            oldIds = this.state.selectedReasonIds;

        let sortOrder = [
            "New",
            "In Process",
            "Ready to Deliver",
            "Setup",
            "Cancelled",
        ];

        if (this.state.reasonId) {
            rsns.filter((x) => {
                if (x.id === this.props.match.params.id) {
                    this.setState({
                        header: x.name
                    })
                }
                return false
            })

        }

        rsns.sort(function (a, b) {
            // Pass a function to the sort that takes 2 elements to compare
            if (a.orderStatus === b.orderStatus) {
                // If the elements both have the same `type`,
                return a.description.localeCompare(b.description); // Compare the elements by `name`.
            } else {
                // Otherwise,
                return (a.orderStatus - b.orderStatus);
                //	sortOrder.indexOf(a.orderStatus) - sortOrder.indexOf(b.orderStatus)
                //); // Subtract indexes, If element `a` comes first in the array, the returned value will be negative, resulting in it being sorted before `b`, and vice versa.
            }
        });

        statuses.forEach(s => {
            var id = s; //sortOrder.indexOf(s);
            if (!id || id < 0) {
                var t = this.state.statusOptions.find(x => x.label === s);
                if (t)
                    id = t.value;
            }
            a.push({
                checked: false,
                disabled: true,
                text: sortOrder[s],
                value: s,
            })

            rsns.forEach(r => {
                if (statuses && statuses.length > 0 && id === r.orderStatus) {
                    a.push({
                        checked: ids.includes(r.id) || oldIds.includes(r.id) || false,
                        disabled: false,
                        text: r.description,
                        value: r.id,
                    })
                }
            })
        });

        this.setState({
            availableReasons: a,
            allReasons: reasons,
            reasonIds: reasons.map((r) => r.id)
        });

    }



    compareDateOfBirth(dob) {
        let a = moment(new Date());
        let b = moment(dob);

        let y = a.diff(b, 'years');

        if (y < 18) {
            return "Yes";
        }
        return "No"
    }

    buildNewResults(res) {
        let ary = [],
            dt = this.state.data,
            daysInQueue = this.props.match.params.id === "61" || this.props.match.params.id === "72",
            today = new Date();

        function calculateDays(day) {
            let dt = 1000 * 60 * 60 * 24;

            const date1 = new Date(day);

            if (today.toLocaleDateString() === date1.toLocaleDateString()) {
                return 0;
            }

            const diffTime = Math.abs(today - date1);

            return Math.ceil(diffTime / (dt));

        }

        res.forEach((val) => {
            ary.push({
                orderId: val.orderId,
                orderType: val.orderType,
                priority: this.renderPriorityIcon(val),
                pediatric: this.compareDateOfBirth(val.dateOfBirth),
                timestamp: daysInQueue ? calculateDays(val.timestamp) : val.timestamp,
                startedAt: calculateDays(val.startedAt) + " days ago",
                currentlyBeingWorkedBy: val.currentlyWorkedBy,
                patientName: val.patientName,
                primaryInsurance: val.primaryInsurance,
                primaryPayorSource: val.primaryPayorSource,
                secondaryInsurance: val.secondaryInsurance,
                secondaryPayorSource: val.secondaryPayorSource,
                salesLocation: val.salesLocation,
                points: val.points,
                category: val.category,
                unassign: this.createBtn(val.currentlyWorkedBy, val.orderId),
            });
        });

        dt.rows = ary;
        toast.success("Found " + res.length + " Results");
        this.setState({
            data: dt,
            isLoaded: true,
        });
    }


    renderBeingWorkedOnModal() {
        const {allUsers} = this.context;
        let workingOnId = this.state.currentOrderWorkedBy,
            workingName = workingOnId ? allUsers.filter(x => x.id === workingOnId).map(x => (x.firstname + " " + x.lastname) || x.username) : "";


        return (
            <Modal isOpen={this.state.workedOnModal}>
                <MDBModalHeader
                    style={{backgroundColor: "#F93154", color: "white"}}
                >
                    Already Being Worked On
                </MDBModalHeader>
                <ModalBody style={{backgroundColor: "white"}}>
                    {/*{this.renderBeingWorkedOnBody()}*/}
                    <div>
                        Order is being worked by {workingName}
                    </div>
                </ModalBody>
                <ModalFooter style={{backgroundColor: "white"}}>

                    <Button
                        style={{float: "right"}}
                        floating
                        size="sm"
                        color={"red"}
                        data-tip={"Go Back"}
                        onClick={() => {
                            this.getResults()
                            this.setState({
                                workedOnModal: false
                            })
                        }}
                    >
                        <MDBIcon icon="times" style={{fontSize: "2em"}} />
                    </Button>

                    <Button
                        style={{float: "right"}}
                        floating
                        size="sm"
                        color={"success"}
                        data-tip={"Acknowledge"}
                        onClick={() => {
                            this.setState({
                                workedOnModal: false
                            })
                            this.openOrder(this.state.currentOrder)
                        }}
                    >
                        <MDBIcon icon="check" style={{fontSize: "2em"}} />
                    </Button>


                </ModalFooter>
                <ReactTooltip />
            </Modal>
        );
    }

    renderBeingWorkedOnBody() {
        let workedOnBy = ''
        if (this.state.currentOrder.currentlyBeingWorkedBy) {
            workedOnBy = this.state.currentOrder.currentlyBeingWorkedBy.firstname + " " + this.state.currentOrder.currentlyBeingWorkedBy.lastname
        }
        return (
            <div>
                This order is already being worked on by {workedOnBy}
            </div>
        );
    }


    openOrder(id) {
        this.updateOrder();
        if (this.state.ctrl === true) {
            this.setState({
                targetLink: id,
            });

            setTimeout(() => {
                document.getElementById("orderListId").click();
            }, 15);
        } else {
            this.props.history.push({
                pathname: "/order/" + id,
                state: {
                    //order: this.state.currentOrder,
                    goto: window.location.pathname,
                },
            });
        }
    }

    createBtn(workedBy, id) {
        const {currentUser} = this.context;
        if (!workedBy) {
            return
        }

        if (workedBy === currentUser.firstname + " " + currentUser.lastname) {
            let ary = this.state.workedByMe;
            ary.push(id);
            this.setState({
                workedByMe: ary,
            })
            return (
                <
                    MDBIcon icon="backspace"
                    size={"2x"}
                    id="btn"
                    style={{color: "#6b93c4"}}
                    onClick={() => this.clearIsWorkingOn(id)}
                />
            );
        }
    }

    checkIsWorkingOn(id) {
        const {currentUser} = this.context;
        //return OrderService.getOrderActivity(id)
        return OrderService.getCurrentlyWorkingByOrder(id)
            .then((res) => {
                this.setState({
                    currentOrder: id,
                })
                if (res > 0 && res !== currentUser.id) {
                    this.setState({
                        workedOnModal: true,
                        currentOrderWorkedBy: res,
                    })
                    return true
                } else {
                    this.setState({
                        currentOrderWorkedBy: null,
                    })
                    this.openOrder(id)
                }

            })
            .catch((err) => {
                return false;
            });
    }

    updateOrder() {
        OrderService.setBeingWorkedBy(this.state.currentOrder);
        return true;
    }

    renderTable() {
        const Cell = ({row, column, ...props}) => {
            return (
                <Table.Cell
                    {...props}
                    onClick={(e) => {
                        if (e.target.id === "btn") {
                            return
                        }
                        if (e.ctrlKey === true) {
                            this.setState({
                                ctrl: true,
                            });
                        } else {
                            this.setState({
                                ctrl: false,
                            });
                        }
                        this.checkIsWorkingOn(row.orderId);
                    }}
                    style={{
                        cursor: "pointer",
                    }}
                />
            );
        };

        if (this.state.isLoaded === true) {
            return (
                <Grid
                    style={{maxWidth: "1800px !important"}}
                    rows={this.state.data.rows}
                    rootComponent={Root}
                    columns={this.state.data.columns}

                >
                    <DragDropProvider />
                    <SortingState
            
                        defaultSorting={this.state.defaultSorting}
                    />
                    <IntegratedSorting
                        columnExtensions={this.state.data.integratedSortingColumnExtensions}
                    />
                    <GroupingState
                        grouping={this.state.groupingColumns}
                        onGroupingChange={this.changeGrouping}
                    />
                    <IntegratedGrouping />
                    <PagingState defaultCurrentPage={0} pageSize={25} />
                    <IntegratedPaging />
                    <VirtualTable cellComponent={Cell} height={"auto"} />
                    <TableColumnResizing
                        defaultColumnWidths={this.state.defaultColumnWidths}
                    />
                    <TableHeaderRow showSortingControls />
                    <TableColumnVisibility
                        defaultHiddenColumnNames={
                            this.state.defaultHiddenColumnNames
                        }
                    />
                    <PagingPanel />
                    <TableGroupRow contentComponent={Content} showColumnsWhenGrouped />
                    <ItemCounter />
                    <Toolbar />
                    <GroupingPanel />
                </Grid>
            );
        } else {
            return <div />;
        }
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor />
                </div>
            </Container>
        );
    }

    renderTableOrSpinner() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <div className={"purchasingGrid ordersListGrid"}
                style={{height: 0.8 * window.innerHeight, width: "98%", backgroundColor: "white", marginLeft: "1%"}}>
                {this.renderTable()}
            </div>
        );
    }

    retrieveRegions() {
        const {allRegions} = this.context;
        let arr = [];

        if (allRegions && allRegions.length > 0) {
            allRegions.forEach((region, index) => {
                arr.push({
                    label: region.name,
                    value: region.id,
                });
            });

            arr.push({
                label: "All Regions",
                value: -1,
            })

            this.setState({
                regions: arr,
            });
            this.getResults()
        }
        else {
            return RegionService.getAllRegions()
                .then((res) => {
                    res.forEach((region, index) => {
                        arr.push({
                            label: region.name,
                            value: region.id,
                        });
                    });

                    arr.push({
                        label: "All Regions",
                        value: -1,
                    })

                    this.setState({
                        regions: arr,

                    });
                    this.getResults()
                })
                .catch((err) => {
                    console.log(err);
                });

        }
    }


    handleSelectChange = (e) => {
        this.setState({
            workedByMe: [],
            selectedRegion: e && e.value > 0 ? e.value : null,
        },
            () => {
                this.getResults();
            });
    };

    clearIsWorkingOnAll() {
        let ary = this.state.workedByMe
        ary.forEach((id, index) => {
            this.clearIsWorkingOn(id)
        });
        this.setState({
            workedByMe: [],
        })
        this.getResults();
    }

    clearIsWorkingOn(id) {
        OrderService.clearBeingWorkedBy(id);
    }

    renderRegionSelect() {

        return (
            <Card style={{
                overflow: "visible",
                height: "50px",
                width: "98%",
                marginLeft: "1%",
                backgroundColor: "#5881C1",
                marginTop: "5px"
            }}>
                <Row>
                    <Col md={2}
                        style={{paddingTop: "5px", marginLeft: "10px"}}
                    >
                        <Select
                            styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                            menuPortalTarget={document.body}
                            placeholder="Region"
                            options={this.state.regions || [{label: "Loading Regions"}]}
                            onChange={(e) => this.handleSelectChange(e)}
                        />
                    </Col>
                    <Col md={8}>
                        <h3 style={{color: '#fff', textAlign: 'center', margin: '6px'}}>{this.state.header}</h3>
                    </Col>
                    <Col md={1}>
                        <button
                            disabled={this.state.workedByMe.length === 0}
                            type="button"
                            class={"btn btn-primary clear-btn"}
                            onClick={() => {
                                this.clearIsWorkingOnAll()
                            }}
                        >Clear My Orders
                        </button>
                    </Col>
                </Row>
            </Card>
        )
    }

    render() {
        return (
            <div>
                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={true}
                    autoClose={3000}
                    position={"top-right"}
                    style={{marginTop: "75px"}}
                />

                <div style={ { maxWidth: "98%", marginLeft: "1%", marginTop: '1rem' } }>
                    <Row>
                        {this.renderRegionSelect()}
                    </Row>
                    <Row>
                        {this.renderTableOrSpinner()}
                    </Row>

                    <Link
                        id="orderListId"
                        to={{
                            pathname: `/order/${this.state.targetLink}`,
                            state: {goto: "verification"},
                        }}
                        target="_blank"
                        rel="opener"
                        style={{display: "none"}}
                        activeclassname="active"
                    />

                    <div className={"fixed-bottom downloadCsvDiv"}>
                        <Button
                            className={"downloadCsvButton"}
                            floating
                            size="sm"
                            color={"primary"}
                            data-tip={"Download CSV"}
                            onClick={this.clientCSV.bind(
                                this,
                                this.state.data,
                                "Verification.csv"
                            )}
                        >
                            <MDBIcon icon="download" style={{fontSize: "2em"}} />
                        </Button>

                        {this.renderBeingWorkedOnModal()}
                        <ReactTooltip />
                    </div>
                </div>
            </div>
        );
    }
}
