import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { isGroupTableRow } from "@devexpress/dx-grid-core";
import {
	Grid,
	Table,
	VirtualTable,
	TableHeaderRow,
	PagingPanel,
	TableGroupRow,
	GroupingPanel,
	DragDropProvider,
	Toolbar,
	TableSummaryRow,
	TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";

import {
	SummaryState,
	IntegratedSummary,
	GroupingState,
	SortingState,
	IntegratedSorting,
	IntegratedGrouping,
	PagingState,
	IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
	Plugin,
	Template,
	TemplateConnector,
	TemplatePlaceholder,
} from "@devexpress/dx-react-core";

import {
	Button,
	Container,
	Col,
	Row,
	Spinner,
	toast,
	ToastContainer,
	MDBIcon,
	Card,
	CardBody,
	MDBDatePicker,
} from "mdbreact";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import "../Orders/orderList.css";
import ReactTooltip from "react-tooltip";
import "./customerComplaints.css";
import ComplaintService from "../Security/ComplaintService/ComplaintService";
import GlobalFunctions from "../../Seating/Filters/GlobalFunctions";
import QuickFilter from "../Filters/quickFilter";
import Select from "react-select";

const ItemCounter = () => (
	<Plugin name="ItemCounter">
		<Template
			name="tableCell"
			predicate={({ tableRow }) => isGroupTableRow(tableRow)}
		>
			{(params) => (
				<TemplateConnector>
					{({ getCollapsedRows }) => {
						const updatedParams = {
							...params,
							tableRow: {
								...params.tableRow,
								row: {
									...params.tableRow.row,
									collapsedRows: getCollapsedRows(params.tableRow.row) || [],
								},
							},
						};
						return <TemplatePlaceholder params={updatedParams} />;
					}}
				</TemplateConnector>
			)}
		</Template>
	</Plugin>
);

const Root = (props) => <Grid.Root {...props} style={{ height: "100%" }} />;

function getVal(row) {
	if (row.collapsedRows.length > 0) {
		return "Count: " + row.collapsedRows.length;
	} else {
		return "";
	}
}

const Content = ({ row, column }) => (
	<span>
		<span>
			{column.title} : {row.value}
		</span>
		<span style={{ fontWeight: "bold" }}>&nbsp; {getVal(row)}</span>
	</span>
);

export default class complaintsList extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					title: "Alert",
					name: "alert",
				},
				{
					title: "Feedback ID",
					name: "id",
				},
				{
					title: "Days Open",
					name: "daysOpen"
				},
				{
					title: "Order ID",
					name: "orderId",
				},
				{
					title: "Patient Name",
					name: "patientName",
				},
				{
					title: "Status",
					name: "status",
				},
				{
					title: "Category",
					name: "category",
				},
				{
					title: "Reason",
					name: "reason",
				},
				{
					title: "Opened On",
					name: "openedOn",
				},
				{
					title: "Opened By",
					name: "openedBy",
				},
				{
					title: "Closed On",
					name: "closedOn",
				},
				{
					title: "Closed By",
					name: "closedBy",
				},
				{
					title: "Service Location",
					name: "serviceLocation",
				},
				{
					title: "Sales Location",
					name: "salesLocation",
				},	{
					title: "Next Followup",
					name: "nextFollowupDate",
				},
				{
					title: "Last Note Date",
					name: "lastNoteDate",
				},
				{
					title: "Assigned To",
					name: "assignedTo"
				},
			],
			rows: [],
		};

		let defaultColumnWidths = [
			{ columnName: "alert", width: 70 },
			{ columnName: "id", width: 120 },
			{ columnName: "daysOpen", width: 120 },
			{ columnName: "orderId", width: 140 },
			{ columnName: "patientName", width: 150 },
			{ columnName: "status", width: 150 },
			{ columnName: "category", width: 150 },
			{ columnName: "reason", width: 150 },
			{ columnName: "openedOn", width: 150 },
			{ columnName: "openedBy", width: 150 },
			{ columnName: "closedOn", width: 150 },
			{ columnName: "closedBy", width: 150 },
			{ columnName: "serviceLocation", width: 150 },
			{ columnName: "salesLocation", width: 150 },
			{ columnName: "nextFollowupDate", width: 150 },
			{ columnName: "lastNoteDate", width: 150 },
			{ columnName: "assignedTo", width: 150 },
		];

		this.state = {
			data: data,
			allFeedback: [],
			isLoaded: false,
			sortingColumn: JSON.parse(window.localStorage.getItem("complaintListSorting")) || [{ columnName: "openedOn", direction: "desc" }],
			groupingColumns: JSON.parse(window.localStorage.getItem("complaintListGrouping")) || [],
			defaultColumnWidths: defaultColumnWidths,
			groupSummaryItems: [],
			notes: [],
			startDate: new Date(),
			endDate: new Date(),
			quickFilter: [],
			quickFilterSelected: { label: "Last 12 Months", value: 8 },
		};

		this.changeGrouping = (grouping) => {
			window.localStorage.setItem("complaintListGrouping", JSON.stringify(grouping));
			this.setState({
				groupingColumns: grouping,
			});
		};

		this.changeSorting = (sorting) => {
			window.localStorage.setItem("complaintListSorting", JSON.stringify(sorting));
			this.setState({
				sortingColumns: sorting,
            })
		};

		// this.getResults();
	}

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	componentDidMount() {
		this.getLocalStorage();
	}

	downloadFunction() {
		this.clientCSV(this.state.data, "CustomerFeedback.csv");
	}

	//This will convert all the data in the grid to a csv file
	clientCSV(stateData, filename) {
		let result,
			ctr,
			keys = [],
			headers = [],
			columnDelimiter = ",",
			lineDelimiter = "\n",
			data;

		data = stateData.rows || null;
		if (data == null || !data.length) {
			return null;
		}

		stateData.columns.forEach((col) => {
			keys.push(col.name);
			headers.push(col.title);
		});

		let replace = ["patientName", "category", "reason"];

		result = "";
		result += headers.join(columnDelimiter);
		result += lineDelimiter;

		data.forEach(function (item) {
			ctr = 0;
			keys.forEach(function (key) {
				if (ctr > 0) result += columnDelimiter;

				if (replace.indexOf(key) > -1 && item[key] != null) {
					result += item[key].replace(/,/g, " ");
				} else if (key === "nextFollowupDate") {
					result += GlobalFunctions.formatFlatDate(item[key]);
				} else {
					result += item[key];
				}

				ctr++;
			});
			result += lineDelimiter;
		});

		this.downloadBlob(result, filename);
	}

	//this actually prompts the download
	downloadBlob(csvData, filename) {
		let blob = new Blob([csvData], {
			type: "application/csv;charset=utf-8;",
		});

		if (window.navigator.msSaveBlob) {
			// FOR IE BROWSER
			navigator.msSaveBlob(blob, filename);
		} else {
			// FOR OTHER BROWSERS
			let link = document.createElement("a"),
				csvUrl = URL.createObjectURL(blob);

			link.href = csvUrl;
			link.style = "visibility:hidden";
			link.download = filename;

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	// getResults() {
	// 	let st = this.state,
	// 		d = st.startDate,
	// 		e = st.endDate;

	// 	d.setFullYear(d.getFullYear() - 1);
	// 	d = d.toLocaleDateString();

	// 	e.setDate(e.getDate() + 1);
	// 	e = e.toLocaleDateString();

	// 	let params = {
	// 		start: d,
	// 		end: d,
	// 	};
	// 	// if start date === end date, add a day
	// 	return ComplaintService.getComplaintsViewNative(params)
	// 		.then((res) => {
	// 			this.buildNewResults(res);
	// 		})
	// 		.catch((err) => {
	// 			this.setState({ isLoaded: true });
	// 		});
	// }

	filterFeedback() {
		let st = this.state,
			filters = {},
			sd = new Date(st.startDate),
			ed = new Date(st.endDate);

		if (st.startDate) {
			//Need to apply time as 0 for the start date...BC
			filters.start = sd.toLocaleDateString();
		} else {
			toast.warn("Please Choose a start date");
			return;
		}

		if (st.endDate) {
			//Need to apply time as 23:59 for the end date...BC
			filters.end = ed.toLocaleDateString();
		} else {
			toast.warn("Please Choose an end date");
			return;
		}

		this.setState({ isLoaded: false });

		return ComplaintService.getComplaintsViewNative(filters)
			.then((res) => {
				// console.log(res)
				this.buildNewResults(res);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	handleQuickFilter = (e) => {
		// console.log(e)
		let a = QuickFilter.getDates(e.value),
			startDate = "",
			endDate = "";

		if (a.length > 0) {
			startDate = a[0];
			endDate = a[1];
		}

		this.setState({
			quickFilter: a,
			quickFilterSelected: e,
			startDate: startDate,
			endDate: endDate,
		});

	};

	handleDatePickerChange = (property, value) => {
		this.setState({
			[property]: value,
		});
	};

	handleLocationChange = (e) => {
		this.setState({
			locationsSelected: e,
		});

	};

	updateLocalStorage() {
		let filters = {},
			st = this.state;


		filters.startDate = st.startDate;
		filters.endDate = st.endDate;
		filters.locations = st.locationsSelected;

		window.localStorage.setItem("feedbackFilters", JSON.stringify(filters));
	}

	getLocalStorage() {
		let f = window.localStorage.getItem("feedbackFilters");

		if (f != null) {
			f = JSON.parse(f);

			this.setState({
				startDate: f.startDate ? new Date(f.startDate) : new Date(),
				endDate: f.endDate ? new Date(f.endDate) : new Date(),
				locationsSelected: f.locations,
				isLoaded: false,
			});
		}

		setTimeout(() => {
			this.filterFeedback();
		}, 500);
	}

	filterClicked = () => {
		this.setState({
			isLoaded: false,
		});
		this.updateLocalStorage();
		this.filterFeedback();
	};


	buildNewResults(res) {
		let curDate = GlobalFunctions.getUTCMoment();
		let cur = new Date(curDate).getTime();

		let ary = [],
			notes = [],
			dt = this.state.data;

		let hrs = null;
		let min = null;

		function calculateTimeElapsed(dt2, dt1) {
			let diff = (dt2 - dt1) / 1000;

			let hourDifference = Math.floor(diff / 3600);
			diff -= hourDifference * 3600;
			let minuteDifference = Math.floor(diff / 60);
			diff -= minuteDifference * 60;

			hrs = hourDifference
			min = minuteDifference
		}

		res.forEach((val) => {
			let oo = new Date(val.openedOn).getTime();
			let diff = cur - oo;
			let daysDifference = null;
			let timeElapsed = null;

			if (val.issueStatus === "Open" && val.issueCategory === "Complaint") {
				daysDifference = Math.ceil(diff / (1000 * 3600 * 24))
			} else {
				daysDifference = ""
			}

			if (val.lastNoteDate === null && val.issueStatus === "Open" && val.issueCategory === "Complaint") {
				calculateTimeElapsed(cur, oo)
				if (hrs >= 0) {
					timeElapsed = hrs + " hrs " + min + " min";
				} else {
					timeElapsed = ""
				}
			}

			ary.push({
				alert: timeElapsed,
				id: val.id,
				daysOpen: daysDifference,
				orderId: val.orderId,
				patientId: val.patientId,
				patientName: val.patientName,
				status: val.issueStatus,
				category: val.issueCategory,
				reason: val.issueReason,
				openedOn: val.openedOn,
				openedBy: val.openedBy,
				closedOn: val.closedOn,
				closedBy: val.closedBy,
				serviceLocation: val.serviceLocation,
				salesLocation: val.salesLocation,
				nextFollowupDate: GlobalFunctions.formatDate(val.nextFollowupDate),
				lastNoteDate: GlobalFunctions.formatJsDate(val.lastNoteDate),
				assignedTo: val.assignedTo
			});
			notes.push({
				id: val.id,
				note: val.noteText,
			});
		});

		dt.rows = ary;

		toast.success("Found " + res.length + " Results");

		this.setState({
			data: dt,
			notes: notes,
			isLoaded: true,
		});
	}

	renderTable() {
		const Cell = ({ row, column, ...props }) => {
			if (row.daysOpen > 0 && row.daysOpen <= 3) {
				var rowColor = "#20e9204b"
			} else if (row.daysOpen > 3 && row.daysOpen < 10) {
				rowColor = "#e9e5204b"
			} else if (row.daysOpen >= 10) {
				rowColor = "#e920204b"
			} 

			if (column.name === "alert" && row.alert && row.alert !== "") {
				return (
					<Table.Cell
						data-tip={
							row.alert
						}
						data-for={ "alert" }
						style={ {
							cursor: "pointer",
							backgroundColor: rowColor,
						} }
					>
						<MDBIcon icon="exclamation-triangle" style={ { fontSize: "1.3em" } } />
						<ReactTooltip
							className={ "last-note-tooltip" }
							id={ "alert" }
							place={ "right" }
							effect={ "solid" }
						/>
					</Table.Cell>
				)
			}
			if (column.name === "lastNoteDate" && row.lastNoteDate) {
				return (
					<Table.Cell
						data-tip={
							this.state.notes[
								this.state.notes.findIndex(
									(note) => note.id === props.tableRow.row.id
								)
							].note
						}
						data-for={"notes"}
						style={{
							cursor: "pointer",
							backgroundColor: rowColor,
						}}
					>
						{row.lastNoteDate}
						<ReactTooltip
							className={"last-note-tooltip"}
							id={"notes"}
							place={"left"}
							effect={"solid"}
						/>
					</Table.Cell>
				);
			}
			return (
				<Table.Cell
					{...props}
					onClick={(e) => {
						if (e.ctrlKey === true) {
							this.setState({
								targetLink: row.patientId,
							});

							setTimeout(() => {
								document.getElementById("orderListId").click();
							}, 5);
						} else {
							this.props.history.push({
								pathname: "/patientInformation/" + row.patientId,
							});
						}
					}}
					style={{
						cursor: "pointer",
						backgroundColor: rowColor,
					}}
				/>
			);
		};

		if (this.state.isLoaded === true) {
			return (
				<Grid
					style={{ maxWidth: "1800px !important" }}
					rows={this.state.data.rows}
					rootComponent={Root}
					columns={this.state.data.columns}
				>
					<DragDropProvider />
					<SortingState
						defaultSorting={this.state.sortingColumn || [{ columnName: "openedOn", direction: "desc" }]}
						onSortingChange={this.changeSorting}
					/>
					<IntegratedSorting
						columnExtensions={this.state.data.integratedSortingColumnExtensions}
					/>
					<GroupingState
						grouping={this.state.groupingColumns}
						onGroupingChange={this.changeGrouping}
					/>
					<IntegratedGrouping />
					<PagingState defaultCurrentPage={0} pageSize={25} />
					<IntegratedPaging />
					<SummaryState
						totalItems={[]}
						groupItems={this.state.groupSummaryItems}
					/>
					<IntegratedSummary />
					<VirtualTable cellComponent={Cell} height={"auto"} />
					<TableColumnResizing
						defaultColumnWidths={this.state.defaultColumnWidths}
					/>
					<TableHeaderRow showSortingControls />
					<PagingPanel />
					<TableGroupRow contentComponent={Content} showColumnsWhenGrouped />
					<ItemCounter />
					<TableSummaryRow />
					<Toolbar />
					<GroupingPanel />
				</Grid>
			);
		} else {
			return <div />;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	renderTableOrSpinner() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<div className={"purchasingGrid ordersListGrid"} style={{height: 0.9 * window.innerHeight, backgroundColor: "white"}}>
				{this.renderTable()}
			</div>
		);
	}

	renderFilter() {
		return (
			<Card className={ "filtersCard" } style={ { marginBottom: "1%", backgroundColor: "#5881C1", maxHeight: "15vh" } }>
				<CardBody style={ { padding: ".25rem 1.25rem" } }>
					<Row>
						<Col md={ "2" } className={ "filterSearchBoxCol" } style={ { zIndex: "999" } }>
							<Select
								placeholder="Quick Filter"
								options={ QuickFilter.getOptions() }
								onChange={ this.handleQuickFilter.bind(this) }
								value={ this.state.quickFilterSelected }
							/>
						</Col>

						<Col md={ "3" } className={ "filterSearchBoxCol date-col" }>
							<div className={ "expenseDateOptions" }>
								<MDBDatePicker
									className={ "date-picker" }
									label={ "Start Date" }
									showTodayButton
									autoOk
									allowKeyboardControl
									keyboard={ true }
									okLabel={ false }
									value={ this.state.startDate }
									getValue={ this.handleDatePickerChange.bind(this, "startDate") }
								/>
							</div>
						</Col>
						<Col md={ "3" } className={ "filterSearchBoxCol date-col" }>
							<div className={ "expenseDateOptions" }>
								<MDBDatePicker
									className={ "date-picker" }
									label={ "End Date" }
									showTodayButton
									autoOk
									allowKeyboardControl
									keyboard={ true }
									okLabel={ false }
									value={ this.state.endDate }
									getValue={ this.handleDatePickerChange.bind(this, "endDate") }
								/>
							</div>
						</Col>
						<Col className={ "filterSearchBoxCol" }>
							<Button
								floating
								size="sm"
								color={ "indigo" }
								data-tip={ "Search Feedback" }
								onClick={ () => this.filterClicked() }
							>
								<MDBIcon icon="search" style={ { fontSize: "2em" } } />
							</Button>
						</Col>
					</Row>
				</CardBody>
			</Card>
		)
	};

	render() {
		return (
			<div>
				{ this.renderFilter() }
				<ToastContainer
					hideProgressBar={true}
					newestOnTop={true}
					autoClose={3000}
					position={"top-right"}
					style={{ marginTop: "75px" }}
				/>
				<div style={{ maxWidth: "98%", marginLeft: "1rem" }}>
					<Row>
						<Col size="12">{this.renderTableOrSpinner()}</Col>
					</Row>

					<Link
						id="orderListId"
						to={{
							pathname: `/patientInformation/${this.state.targetLink}`,
						}}
						target="_blank"
						rel="opener"
						style={{ display: "none" }}
						activeclassname="active"
					/>

					<div className={"fixed-bottom downloadCsvDiv"}>
						<Button
							className={"downloadCsvButton"}
							floating
							size="sm"
							color={"primary"}
							data-tip={"Download CSV"}
							onClick={this.clientCSV.bind(
								this,
								this.state.data,
								"CustomerComplaints.csv"
							)}
						>
							<MDBIcon icon="download" style={{ fontSize: "2em" }} />
						</Button>

						<ReactTooltip />
					</div>
				</div>
			</div>
		);
	}
}
