import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import {
    MDBRow,
    MDBCol,
    ToastContainer,
    MDBRotatingCard,
    MDBIcon,
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBNav,
    MDBNavItem,
    MDBNavLink,
    MDBTabContent,
    MDBTabPane,
    MDBCardFooter,
    MDBContainer,
} from "mdbreact";
import "./home.css";
import FindOrders from "../Security/OrderService/orderService";
import NoteService from "../Security/NoteService/noteService";
import ReactTooltip from "react-tooltip";
import TaskTable from "../Home/taskTable";
import RQTable from "./rqTable";
import PCRNotificationsTable from "../Home/pcrNotificationsTable";
import SalesPipelineView from "../Orders/salesPipelineView";
import ColumnPipeline from "../../Components/Pipeline/ColumnPipeline";
import MonthGlanceView from "../../Components/Pipeline/MonthGlance";
import DeliveryAverageDays from "../../Components/Deliveries/DeliveryAverageDays";
import OrderService from "../Security/OrderService/orderService";
import GlobalFunctions from "../Filters/GlobalFunctions";

class Kiosk extends React.Component {
    constructor(props) {
        super(props);

        this.retrieveOrders();

        this.state = {
            orders: [],
            tasks: [],
            priority: [],
            isLoaded: false,
            tasksAssignedOut: [],
            productionMonthlyToggle: false,
            currentView: "standardView",
            pipelineResults: [],
            stateTotals: {},
            statusesValues: {
                New: {
                    name: "New",
                    orders: 0,
                    ordersService: 0,
                    points: 0,
                    pointsService: 0,
                },
                InProcess: {
                    name: "In Process",
                    orders: 0,
                    ordersService: 0,
                    points: 0,
                    pointsService: 0,
                },
                ReadyToDeliver: {
                    name: "Ready To Deliver",
                    orders: 0,
                    ordersService: 0,
                    points: 0,
                    pointsService: 0,
                },
                Setup: {
                    name: "Setup",
                    orders: 0,
                    points: 0,
                    pointsService: 0,
                    ordersService: 0,
                },
            },
            statuses: [
                { name: "New", displayName: "New", flipped: false },
                { name: "InProcess", displayName: "In Process", flipped: false },
                {
                    name: "ReadyToDeliver",
                    displayName: "Ready to Deliver",
                    flipped: false,
                },
                { name: "Setup", displayName: "Setup", flipped: false },
            ],

            flippedNew: false,
            flippedInProcess: false,
            flippedSetup: false,
            flippedReadyToDeliver: false,
            activeItemJustified: "1",
            activeBasicView: "1",
            reasonId: null,
            description: null,
            reasonIdsArr: [6, 14, 15, 21, 30, 31, 59],
        };
    }

    componentDidMount() {
        this.getTasksForUser();
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        allRegions: PropTypes.array,
        reasons: PropTypes.array,
    };

    toggleBasic = (tab) => () => {
        if (this.state.activeBasicView !== tab) {
            this.setState({
                activeBasicView: tab,
            });
        }
    };

    toggleJustified = (tab) => () => {
        if (this.state.activeItemJustified !== tab) {
            this.setState({
                activeItemJustified: tab,
            });
        }
    };

    retrieveOrders() {
        let end = new Date(),
            start = new Date(),
            filters = {};

        start.setDate(end.getDate() - 14);

        filters.statuses = ["New", "InProcess", "ReadyToDeliver", "Setup"];
        filters.startDate = start;
        filters.endDate = end;

        FindOrders.getDashboardCounts()
            .then((orders) => {
                this.setState({
                    orders: orders,
                    isLoaded: true,
                });
                this.getOrderCount();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getTasksForUser() {
        const { currentUser } = this.context;

        NoteService.getOpenTasks(currentUser.id)
            .then((tasks) => {
                this.setState({
                    tasks: tasks,
                    tasksLoaded: true,
                });
                return Promise.resolve(tasks);
            })
            .catch((err) => {
                console.log(err);
                return Promise.resolve([]);
            });
    }

    getOrderCount() {
        let statusVals = this.state.statusesValues;

        this.state.orders.forEach((order) => {
            if (order.status === "New") {
                if (order.description.toLowerCase().includes("service")) {
                    statusVals.New.ordersService += order.ordercount;
                    statusVals.New.pointsService += order.totalPoints;
                } else {
                    statusVals.New.orders += order.ordercount;
                    statusVals.New.points += order.totalPoints;
                }
            } else if (order.status === "InProcess") {
                if (order.description.toLowerCase().includes("service")) {
                    statusVals.InProcess.ordersService += order.ordercount;
                    statusVals.InProcess.pointsService += order.totalPoints;
                } else {
                    statusVals.InProcess.orders += order.ordercount;
                    statusVals.InProcess.points += order.totalPoints;
                }
            } else if (order.status === "Ready to Deliver") {
                if (order.description.toLowerCase().includes("service")) {
                    statusVals.ReadyToDeliver.ordersService += order.ordercount;
                    statusVals.ReadyToDeliver.pointsService += order.totalPoints;
                } else {
                    statusVals.ReadyToDeliver.orders += order.ordercount;
                    statusVals.ReadyToDeliver.points += order.totalPoints;
                }
            } else if (order.status === "Setup") {
                if (order.description.toLowerCase().includes("service")) {
                    statusVals.Setup.ordersService += order.ordercount;
                    statusVals.Setup.pointsService += order.totalPoints;
                } else {
                    statusVals.Setup.orders += order.ordercount;
                    statusVals.Setup.points += order.totalPoints;
                }
            }
        });
        statusVals.New.points = statusVals.New.points.toFixed(2);
        statusVals.New.pointsService = statusVals.New.pointsService.toFixed(2);
        statusVals.InProcess.pointsService =
            statusVals.InProcess.pointsService.toFixed(2);
        statusVals.InProcess.points = statusVals.InProcess.points.toFixed(2);
        statusVals.ReadyToDeliver.pointsService =
            statusVals.ReadyToDeliver.pointsService.toFixed(2);
        statusVals.ReadyToDeliver.points =
            statusVals.ReadyToDeliver.points.toFixed(2);
        statusVals.Setup.pointsService = statusVals.Setup.pointsService.toFixed(2);
        statusVals.Setup.points = statusVals.Setup.points.toFixed(2);

        this.setState({
            statusValues: statusVals,
        });
    }

    generateTask(note, assignedOut) {
        let bgColor = "#FFE4F1",
            headerColor = "#FFCCE5",
            t = this;

        if (assignedOut) {
            bgColor = "#E4F9E0";
            headerColor = "#CBF1C4";
        }

        if (note.isAccountTask) {
            bgColor = "#E2F1FF";
            headerColor = "#CDE9FF";
        }

        if (note.dueDate) {
            if (new Date(note.dueDate).getDate() === new Date().getDate()) {
                bgColor = "#FFD175";
                headerColor = "#FFBE3D";
            } else if (new Date(note.dueDate).getDate() < new Date().getDate()) {
                bgColor = "#FFA6A3";
                headerColor = "#FF8C7C";
            }
        }

        function routeToOrder(note) {
            if (note.activity && note.activityId) {
                t.props.history.push({
                    pathname: "/order/" + note.activityId,
                });
            }
        }

        function routeToAccount(note) {
            if (note.account && note.account.id) {
                t.props.history.push({
                    pathname: "/account/" + note.account.id + "/detail",
                });
            }
        }

        function renderAssignedTo() {
            if (note.assignedTo) {
                return (
                    <span style={{ fontSize: 14 }}>
                        {" "}
                        <MDBIcon
                            icon="long-arrow-alt-right"
                            data-tip={"Assigned To"}
                        />{" "}
                        {note.assignedTo.firstname + " " + note.assignedTo.lastname}
                    </span>
                );
            }
        }

        function renderAccountPatient(note) {
            return (
                <MDBRow style={{ fontSize: 13 }}>
                    <MDBCol
                        size="4"
                        onClick={() => {
                            routeToAccount(note);
                        }}
                    >
                        {note.account ? note.account.name : "No Account"}
                    </MDBCol>

                    <MDBCol
                        size="4"
                        onClick={() => {
                            routeToOrder(note);
                        }}
                    >
                        {note.patientName || ""}
                    </MDBCol>

                    <MDBCol size="4">
                        {note.dueDate ? new Date(note.dueDate).toLocaleString() : ""}
                    </MDBCol>
                </MDBRow>
            );
        }

        return (
            <MDBCard style={{ backgroundColor: bgColor, margin: 10 }} key={note.id}>
                <MDBCardHeader
                    style={{ backgroundColor: headerColor, cursor: "pointer" }}
                >
                    <MDBRow>
                        <MDBCol size="5" style={{ fontSize: 14 }}>
                            {GlobalFunctions.formatDateTime(note.createdAt)}
                        </MDBCol>

                        <MDBCol size="5" style={{ fontSize: 14 }}>
                            {note.createdBy
                                ? note.createdBy.firstname + " " + note.createdBy.lastname
                                : ""}
                            {renderAssignedTo()}
                        </MDBCol>

                        <MDBCol size="2">
                            <MDBIcon
                                style={{
                                    color: "#7B626E",
                                    float: "right",
                                    padding: "4px 8px 4px 4px",
                                }}
                                onClick={this.updateTask.bind(this, note)}
                                data-tip={"Complete"}
                                icon="check"
                            />
                        </MDBCol>
                    </MDBRow>

                    {renderAccountPatient(note)}
                </MDBCardHeader>

                <MDBCardBody>{note.text}</MDBCardBody>
                <ReactTooltip />
            </MDBCard>
        );
    }

    renderTaskList() {
        let tasks = this.state.tasks;
        return (
            <div>
                {tasks.map((task, idx) => {
                    return this.generateTask(task, idx, false);
                })}
            </div>
        );
    }

    renderReasonsService(statusName, start, end, isPoints) {
        if (statusName === "In Process") {
            statusName = "InProcess";
        }
        let orders = this.state.orders.filter((x) => x.status === statusName && x.orderType === "Service").sort((a, b) => {
            if (a.description.replace("Service - ", "") > b.description.replace("Service - ", ""))
                return 1;
            else if (a.description.replace("Service - ", "") < b.description.replace("Service - ", ""))
                return -1;
            else
                return 1;
        });
        return (
            <div className={"listOfReasonsService"}>
                {orders.map((order) => {
                    if (order.status === statusName) {
                        return this.renderReasonLink(order, start, end, isPoints, true);
                    }
                    return null;
                })}
            </div>
        );
    }

    renderReasons(statusName, start, end, isPoints) {
        if (statusName === "In Process") {
            statusName = "InProcess";
        }
        let orders = this.state.orders.filter((x) => x.status === statusName).sort((a, b) => {
            if (a.description.replace("Service - ", "") > b.description.replace("Service - ", ""))
                return 1;
            else if (a.description.replace("Service - ", "") < b.description.replace("Service - ", ""))
                return -1;
            else
                return 1;
        });
        return (
            <div className={"listOfReasonsService"}>
                {orders.map((order) => {
                    if (order.status === statusName) {
                        return this.renderReasonLink(order, start, end, isPoints, false);
                    }
                    return null;
                })}
            </div>
        );
    }

    renderAllReasons(statusName, start, end, isPoints) {
        if (statusName === "In Process") {
            statusName = "InProcess";
        }
        let orders = this.state.orders.filter((x) => x.status === statusName).sort((a, b) => {
            if (a.description.replace("Service - ", "") > b.description.replace("Service - ", ""))
                return 1;
            else if (a.description.replace("Service - ", "") < b.description.replace("Service - ", ""))
                return -1;
            else
                return 1;
        });
        return (
            <div className={"listOfAllReasons"}>
                {orders.map((order) => {
                    if (order.status === statusName) {
                        return this.renderAllReasonLink(order, start, end, isPoints);
                    }
                    return null;
                })}
            </div>
        )
    }

    renderAllReasonLink(order, start, end, isPoints) {
        const { reasons } = this.context;
        if (order.description !== "Blank Reason") {
            if (reasons && reasons.length > 0) {
                var reasonId = reasons.filter(x => x.description === order.description);
                if (reasonId.length === 1) {
                    var targetId = reasonId[0].id;
                    //console.log(targetId + " added for: " + order.status + ", " + order.description)
                }
            }
            return (
                <Route
                    key={order.id}
                    render={({ history }) => (
                        <div
                            className={
                                "listOfReasonsService reasonHostLink listOfReasons"
                            }
                            onClick={(e) => {
                                e.stopPropagation();
                                var pathname = "/order";
                                if (order.status === "InProcess") {
                                    if (order.description === "Follow Up Task")
                                        pathname = "/followup";
                                    else if (order.description === "Verification")
                                        pathname = "/verification";
                                    // else if (order.description.indexOf("Service -") < 0)
                                    //     pathname = "/inProcess/" + targetId < 900 ? targetId : order.id;
                                }
                                else if (order.status === "Setup" && order.orderType === "New" && order.description === "Blank Reason") {
                                    pathname = "/billing";
                                }
                                history.push({
                                    pathname: [pathname],
                                    state: {
                                        header: order.description,
                                        shouldOverride: true,
                                        filters: {
                                            statuses: [
                                                order.status === "InProcess"
                                                    ? "In Process"
                                                    : order.status === "ReadyToDeliver"
                                                        ? "Ready to Deliver"
                                                        : order.status,
                                            ],
                                            reasonIds: targetId < 900 ? [targetId] : null,
                                            startDate: null,
                                            endDate: end.toLocaleDateString(),
                                            shouldOverride: true,
                                        },
                                    },
                                });
                            }}
                        >
                            <span className={"dbStatusCardsText"}>
                                <b>
                                    {order.orderType + " - " + order.description.replace("Service - ", "")}: &emsp;
                                    {isPoints ? order.totalPoints : order.ordercount}
                                </b>
                            </span>
                        </div>
                    )}
                />
            );
        } else {
            if (order.status === "Setup") {
                return (<Route
                    key={order.id}
                    render={({ history }) => (
                        <div
                            className={
                                "listOfReasonsService reasonHostLink listOfReasons"
                            }
                            onClick={(e) => {
                                e.stopPropagation();
                                history.push({
                                    pathname: "/billing",
                                });
                            }}
                        >
                            <span className={"dbStatusCardsText"}>
                                <b>
                                    {order.orderType + " - " + order.description}: &emsp;
                                    {isPoints ? order.totalPoints : order.ordercount}
                                </b>
                            </span>
                        </div>
                    )}
                />);
            }
            else {
                return (
                    <div key={order.id} className={"reasonHostLink listOfReasons"}>
                        <span className={"dbStatusCardsText"}>
                            <b>
                                {order.orderType + " - " + order.description}:&ensp;
                                {isPoints ? order.totalPoints : order.ordercount}
                            </b>
                        </span>
                    </div>
                );
            }
        }
    }

    renderReasonLink(order, start, end, isPoints, isService) {
        const { reasons } = this.context;
        if ((order.description.toLowerCase().includes("service") !== isService || order.orderType === "Service") && this.state.activeBasicView === "1") {
            return;
        }
        if (order.description !== "Blank Reason") {
            if (reasons && reasons.length > 0) {
                //console.log(reasons)
                var reasonId = reasons.filter(x => x.description === order.description);
                if (reasonId.length === 1) {
                    var targetId = reasonId[0].id;
                    //console.log(targetId + " added for: " + order.status + ", " + order.description)
                }
            }
            let st = {
                header: order.description,
                shouldOverride: true,
                filters: {
                    statuses: [
                        order.status === "InProcess"
                            ? "In Process"
                            : order.status === "ReadyToDeliver"
                                ? "Ready to Deliver"
                                : order.status,
                    ],
                    reasonIds: targetId < 900 ? [targetId] : null,
                    startDate: null,
                    endDate: end.toLocaleDateString(),
                    shouldOverride: true,
                },
            };
            if (order.description.indexOf("Service - IR- ") === 0) {
                st.header = order.description.substring(14);
            }
            return (
                <Route
                    key={order.id}
                    render={({ history }) => (
                        <div
                            className={
                                isService
                                    ? "reasonHostLink listOfReasonsService"
                                    : "reasonHostLink listOfReasons"
                            }
                            onClick={(e) => {
                                e.stopPropagation();
                                var pathname = "/order";
                                if (order.status === "InProcess") {
                                    if (order.description === "Follow Up Task")
                                        pathname = "/followup";
                                    else if (order.description === "Verification")
                                        pathname = "/verification";
                                    else if (order.description.indexOf("Service -") < 0 || order.description.toLowerCase().includes("cst") || order.description.toLowerCase().includes("approval"))
                                        pathname = "/inProcess/" + (targetId < 900 ? targetId : order.id);
                                }
                                else if (order.description.indexOf("Service - IR- ") === 0)
                                    pathname = "/internalRequest";
                                history.push({
                                    pathname: [pathname],
                                    state: st,
                                });
                            }}
                        >
                            <span className={"dbStatusCardsText"}>
                                <b>
                                    {order.description.replace("Service - ", "")}: &emsp;
                                    {isPoints ? order.totalPoints : order.ordercount}
                                </b>
                            </span>
                        </div>
                    )}
                />
            );
        }
        else {
            if (order.status === "Setup") {
                return (<Route
                    key={order.id}
                    render={({ history }) => (
                        <div
                            className={
                                "listOfReasonsService reasonHostLink listOfReasons"
                            }
                            onClick={(e) => {
                                e.stopPropagation();
                                history.push({
                                    pathname: "/billing",
                                });
                            }}
                        >
                            <span className={"dbStatusCardsText"}>
                                <b>
                                    {order.description}: &emsp;
                                    {isPoints ? order.totalPoints : order.ordercount}
                                </b>
                            </span>
                        </div>
                    )}
                />);
            }
            else {
                return (
                    <div key={order.id} className={"reasonHostLink listOfReasons"}>
                        <span className={"dbStatusCardsText"}>
                            <b>
                                {order.description}:&ensp;
                                {isPoints ? order.totalPoints : order.ordercount}
                            </b>
                        </span>
                    </div>
                );
            }
        }
    }

    setFlip(status, idx) {
        let statuses = this.state.statuses;
        statuses[idx].flipped = !statuses[idx].flipped;
        this.setState({
            statuses: statuses,
        });
    }

    renderCard(status, idx) {
        let st = this.state;
        let end = new Date(),
            start = new Date();
        let statusName = status.displayName;
        if (statusName === "In Process") {
            statusName = "InProcess";
        }

        start.setDate(end.getDate() - 14);
        let flipped = status.flipped;

        let orders = [],
            count = 0.0,
            points = 0.0;
        switch (st.activeBasicView) {
            case "2":
                orders = this.state.orders.filter(x => x.orderType === "Service" && (x.status === status.name || x.status === status.displayName));
                orders.forEach(r => {
                    count += r.ordercount;
                    points += r.totalPoints || 0;
                })
                break;
            case "3":
                orders = this.state.orders.filter(x => x.status === status.name || x.status === status.displayName);
                orders.forEach(r => {
                    count += r.ordercount;
                    points += r.totalPoints || 0;
                })
                break;
            default:
            case "1":
                orders = this.state.orders.filter(x => x.orderType !== "Service" && (x.status === status.name || x.status === status.displayName));
                orders.forEach(r => {
                    count += r.ordercount;
                    points += r.totalPoints || 0;
                })
                break;
        }

        return (
            <div>
                <MDBRotatingCard
                    className={"cardDashDiv"}
                    flipped={flipped}
                    onClick={() => {
                        this.setFlip(status, idx);
                    }}
                >
                    <MDBCard className={flipped ? "back cardDash" : "front cardDash"}>
                        <MDBCardHeader
                            className={"cardHeaderDash"}
                            style={{ paddingBottom: 0 }}
                        >
                            <MDBRow>
                                <MDBCol md={"1"}></MDBCol>
                                <MDBCol>
                                    <MDBRow style={{ justifyContent: "center" }}>
                                        {flipped ? (
                                            <h2>
                                                {points.toFixed(2)}
                                            </h2>
                                        ) : (
                                            <h2>
                                                {count}
                                            </h2>
                                        )}
                                    </MDBRow>
                                    <MDBRow style={{ justifyContent: "center" }}>
                                        <p style={{ textAlign: "center", margin: 0 }}>
                                            {flipped
                                                ? status.displayName + " Points"
                                                : status.displayName}
                                        </p>
                                    </MDBRow>{" "}
                                </MDBCol>
                                <MDBCol md={"1"}>
                                    <div
                                        style={{
                                            float: "right",
                                            position: "relative",
                                            left: "15px",
                                        }}
                                    >
                                        <MDBIcon icon="exchange-alt" />
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardHeader>
                        <Route
                            render={({ history }) => (
                                <MDBCardBody
                                    cascade
                                    className={
                                        st.activeBasicView === "1"
                                            ? "cardBodyDash"
                                            : "cardBodyDash hidden"}
                                    onClick={() => {
                                        history.push({
                                            pathname: "/order",
                                            state: {
                                                shouldOverride: true,
                                                filters: {
                                                    statuses: [status.displayName],
                                                    startDate: start,
                                                    endDate: end,
                                                },
                                            },
                                        });
                                    }}
                                >
                                    {this.renderReasons(status.name === "InProcess" ? status.name : status.displayName, start, end, flipped)}

                                </MDBCardBody>
                            )}
                        />
                        <div
                            className={
                                st.activeBasicView === "1"
                                    ? "dbStatusCardsSales"
                                    : "dbStatusCardsSales hidden"
                            }
                        >
                            <b>
                                Sales : {flipped ? points.toFixed(2) : count}
                            </b>
                        </div>
                        <Route
                            render={({ history }) => (
                                <MDBCardFooter
                                    cascade
                                    className={
                                        st.activeBasicView === "2"
                                            ? "text-center cardBodyDash"
                                            : "text-center cardBodyDash hidden"
                                    }
                                    onClick={() => {
                                        history.push({
                                            pathname: "/order",
                                            state: {
                                                shouldOverride: true,
                                                filters: {
                                                    statuses: [status.displayName],
                                                    startDate: start,
                                                    endDate: end,
                                                },
                                            },
                                        });
                                    }}
                                >
                                    {this.renderReasonsService(
                                        status.displayName,
                                        start,
                                        end,
                                        flipped
                                    )}
                                </MDBCardFooter>
                            )}
                        />
                        <div
                            className={
                                st.activeBasicView === "2"
                                    ? "dbStatusCardsSalesService"
                                    : "dbStatusCardsSalesService hidden"
                            }
                        >
                            <b>
                                Service : {flipped ? points.toFixed(2) : count}
                            </b>
                        </div>
                        <Route
                            render={({ history }) => (
                                <MDBCardFooter
                                    cascade
                                    className={
                                        st.activeBasicView === "3"
                                            ? "text-center cardBodyDash"
                                            : "text-center cardBodyDash hidden"
                                    }
                                    onClick={() => {
                                        history.push({
                                            pathname: "/order",
                                            state: {
                                                shouldOverride: true,
                                                filters: {
                                                    statuses: [status.displayName],
                                                    startDate: start,
                                                    endDate: end,
                                                },
                                            },
                                        });
                                    }}
                                >

                                    {this.renderAllReasons(status.displayName, start, end, flipped)}
                                </MDBCardFooter>
                            )}
                        />
                        <div
                            className={
                                st.activeBasicView === "3"
                                    ? "dbStatusCardsSalesService"
                                    : "dbStatusCardsSalesService hidden"
                            }
                        >
                            <b>
                                All : {flipped ? points.toFixed(2) : count}
                            </b>
                        </div>
                    </MDBCard>
                </MDBRotatingCard>
            </div>
        );
    }

    renderTaskLists() {
        let st = this.state;
        let props = {
            tasks: this.state.tasks,
            loaded: this.state.tasksLoaded,
        };
        return (
            <MDBRow>
                <MDBCol size="12" style={{ padding: '10px 30px 0px' }}>
                    <h5 className={"dashboardText"} style={{ padding: '0px 8px 0px' }}>
                        Tasks
                    </h5>
                    <hr className={"dashboardLine"} style={{ marginTop: '0' }} />

                    <MDBNav tabs className="nav-justified navFull" color="primary">
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={st.activeItemJustified === "1"}
                                onClick={this.toggleJustified("1")}
                                role="tab"
                            >
                                All Tasks
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={st.activeItemJustified === "4"}
                                onClick={this.toggleJustified("4")}
                                role="tab"
                            >
                                Reverse Quality
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={st.activeItemJustified === "5"}
                                onClick={this.toggleJustified("5")}
                                role="tab"
                            >
                                Sales Notifications
                            </MDBNavLink>
                        </MDBNavItem>
                    </MDBNav>

                    <MDBTabContent
                        className="card"
                        style={{ paddingTop: "1rem" }}
                        activeItem={st.activeItemJustified}
                    >
                        <MDBTabPane tabId="1" role="tabpanel">
                            {st.activeItemJustified === "1" ? (
                                <TaskTable {...props} />
                            ) : (
                                <div />
                            )}
                        </MDBTabPane>
                        <MDBTabPane tabId="4" role="tabpanel">
                            {st.activeItemJustified === "4" ? <RQTable /> : <div />}
                        </MDBTabPane>
                        <MDBTabPane tabId="5" role="tabpanel">
                            {st.activeItemJustified === "5" ? (
                                <PCRNotificationsTable />
                            ) : (
                                <div />
                            )}
                        </MDBTabPane>
                    </MDBTabContent>
                </MDBCol>

            </MDBRow>
        );
    }

    renderBasicView() {
        let st = this.state;
        return (
            <div>
                <MDBContainer fluid>
                    <MDBRow className={"cardDashRow"}>
                        <MDBNav tabs className="nav-justified navBasic" color="primary">
                            <MDBNavItem>
                                <MDBNavLink
                                    link
                                    to="#"
                                    role="tab"
                                    active={st.activeBasicView === "1"}
                                    onClick={this.toggleBasic("1")}
                                >
                                    Sales
                                </MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                                <MDBNavLink
                                    link
                                    to="#"
                                    role="tab"
                                    active={st.activeBasicView === "2"}
                                    onClick={this.toggleBasic("2")}
                                >
                                    Service
                                </MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                                <MDBNavLink
                                    link
                                    to="#"
                                    role="tab"
                                    active={st.activeBasicView === "3"}
                                    onClick={this.toggleBasic("3")}
                                >
                                    All
                                </MDBNavLink>
                            </MDBNavItem>
                        </MDBNav>

                        {this.state.statuses.map((status, idx) => {
                            return <MDBCol>{this.renderCard(status, idx)}</MDBCol>;
                        })}
                    </MDBRow>
                </MDBContainer>
                {this.renderTaskLists()}
            </div>
        );
    }

    renderAdminView() {
        return (
            <div>
                <MDBRow
                    style={{ justifyContent: "center", padding: "14px 0px 8px" }}
                >
                    <button
                        type="button"
                        data-tip={"Home"}
                        className="btn dashButton btn-sm"
                        onClick={() => this.setState({ currentView: "standardView" })}
                    >
                        Home
                    </button>
                    <button
                        type="button"
                        data-tip={"Sales Pipeline Chart"}
                        className="btn  btn-sm dashButton"
                        onClick={() => this.setState({ currentView: "pipelineGraph" })}
                    >
                        Pipeline Chart
                    </button>
                    <button
                        type="button"
                        data-tip={"Delivery Averages"}
                        className="btn dashButton btn-sm"
                        onClick={() => this.setState({ currentView: "deliveryAverages" })}
                    >
                        Delivery Averages
                    </button>
                    <button
                        type="button"
                        data-tip={"Month at a Glance"}
                        className="btn dashButton btn-sm"
                        onClick={() => this.setState({ currentView: "monthGlance" })}
                    >
                        Month at a Glance
                    </button>
                </MDBRow>
            </div>
        );
    }

    renderStandardView() {
        if (this.state.currentView === "standardView") {
            return this.renderBasicView();
        }
    }

    newSearch(region) {
        this.setState({
            isLoaded: false,
        });
        let today = new Date();
        let filters = {
            end: new Date().toLocaleDateString(),
            start: new Date(today.setDate((today.getDate()) - 14)).toLocaleDateString(),
            locType: "Sales",
            status: [0, 1, 2, 3],
        };

        if (region) {
            filters.region = region;
        }

        return OrderService.getPipelineView(!region || region === 0 ? null : region)
            .then((res) => {
                this.formatPipelineResults(res);
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isLoaded: true });
            });
    }

    formatPipelineResults(res, cat) {
        let bigAry = [];

        let topPayors = ["Medicare", "Medicaid", "Cash", "Private"];
        let cats = [
            "Standard PWC",
            "Standard MWC",
            "POV",
            "Complex MWC",
            "Complex PWC",
            "Pediatric - Complex MWC",
        ];
        if (cat) {
            cat.map((c) => {
                return cats.push(c.name);
            });
        }

        res = res.filter(x => x.totalPoints > 0);

        res.forEach((order) => {
            let payor = order.payorSource;
            let category = order.category;

            if (
                order.category === "Complex - Group 2" ||
                order.category === "Complex Base Only"
            ) {
                category = "Complex PWC";
            }

            if (topPayors.indexOf(payor) === -1) {
                payor = "Other";
            }

            if (!order.salesLocation) {
                return;
            }

            if (order.reason === "Contract Services") {
                return;
            }

            let v = bigAry.find((vals) => {
                return vals["Sales Location"] === order.salesLocation;
            });

        if (v) {
                v.TotalPoints += order.totalPoints || 0;
                v[order.status] += order.totalPoints || 0;
                v[order.status] = parseFloat(v[order.status].toFixed(2));
                if (order.status === "Setup") {
                    v[payor] += order.totalPoints || 0;
                    v[payor] = parseFloat(v[payor].toFixed(2));
                    v[category] += 1;
                    v[category] = parseFloat(v[category].toFixed(2));
                }
                searchForRep(order, v[order.status + "Data"]);
            } else {
                pushInitialObject(order, payor, category);
            }
        });

        function searchForRep(order, aryToSearch) {
            let rv = aryToSearch && aryToSearch.length > 0 ? aryToSearch.find((repVal) => {
                return repVal.salesRep === order.salesRep;
            }) : null;

            if (rv) {
                rv.points += order.totalPoints || 0;
                rv.points = Number(rv.points.toFixed(2));
            } else {
                if (!aryToSearch)
                    aryToSearch = [];
                aryToSearch.push({
                    salesRep: order.salesRep,
                    points: order.totalPoints || 0,
                });
            }
        }

        function pushInitialObject(order, payor, category) {
            let outsideData = {
                "Sales Location": order.salesLocation,
                TotalPoints: 0,
                New: 0,
                NewData: [],
                "In Process": 0,
                "In ProcessData": [],
                "Ready to Deliver": 0,
                "Ready to DeliverData": [],
                Setup: 0,
                SetupData: [],
                Other: 0,
                OtherData: [],
                payorSource: 0,
                payorSourceData: [],
            };
            topPayors.forEach((ele) => {
                outsideData[ele] = 0;
            });
            cats.forEach((ele) => {
                outsideData[ele] = 0;
                if (ele === "Complex" || ele === "Standard")
                    outsideData[ele + "Data"] = [];
            });

            outsideData.TotalPoints += order.totalPoints || 0;
            outsideData[order.status] += order.totalPoints || 0;
            outsideData[order.status] = parseFloat(
                outsideData[order.status].toFixed(2)
            );
            if (order.status === "Setup") {
                outsideData[payor] += order.totalPoints || 0;
                outsideData[payor] = parseFloat(outsideData[payor].toFixed(2));
                outsideData[category] += 1;
                outsideData[category] = parseFloat(outsideData[category].toFixed(2));
            }

            outsideData[order.status + "Data"].push({
                salesRep: order.salesRep,
                points: order.totalPoints,
            });

            bigAry.push(outsideData);
        }

        bigAry = bigAry.filter((a) => {
            return a.TotalPoints > 0;
        });

        let totals = { newTotal: 0, processTotal: 0, rtdTotal: 0, setupTotal: 0 };

        bigAry.forEach((v) => {
            totals.newTotal += v.New;
            totals.processTotal += v.InProcess || v["In Process"];
            totals.rtdTotal += v["Ready to Deliver"];
            totals.setupTotal += v.Setup;
        });

        for (let p in totals) {
            totals[p] = totals[p].toFixed(2);
        }

        bigAry.sort((a, b) => {
            return a.TotalPoints > b.TotalPoints ? -1 : 1;
        });

        this.setState({
            pipelineResults: bigAry,
            stateTotals: totals,
        });
    }

    sortByRegion(region) {
        this.newSearch(region);
    }

    renderSalesPipeline() {
        if (this.state.currentView === "salesPipeline") {
            return <SalesPipelineView history={this.props.history} />;
        }
    }

    renderMonthGlance() {
        if (this.state.currentView === "monthGlance") {
            return <MonthGlanceView />;
        }
    }

    renderPipelineGraph() {
        if (this.state.currentView === "pipelineGraph") {
            console.log(this.state.pipelineResults)
            console.log(this.state.stateTotals)
            return (
                <div
                    style={{
                        backgroundColor: "#FFF",
                        padding: 25,
                        margin: 25,
                        paddingBottom: 50,
                        marginTop: 20,
                    }}
                >
                    <ColumnPipeline
                        props={{
                            sortByRegion: this.sortByRegion.bind(this),
                            pro: this.state.pipelineResults,
                            totals: this.state.stateTotals,
                        }}
                    />
                </div>
            );
        }
    }

    renderDeliveryAverages() {
        const { allRegions } = this.context;

        let ary = [];

        allRegions.forEach((region) => {
            ary.push({
                label: region.name,
                value: region.id,
            });
        });

        if (this.state.currentView === "deliveryAverages") {
            return (
                <div style={{ minHeight: 500 }}>
                    <DeliveryAverageDays allRegions={ary} />
                </div>
            );
        }
    }

    render() {
        return (
            <div style={{ overflowY: "hidden", overflowX: "hidden" }}>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={5000}
                />
                {this.renderAdminView()}
                {this.renderStandardView()}
                {this.renderSalesPipeline()}
                {this.renderMonthGlance()}
                {this.renderPipelineGraph()}
                {this.renderDeliveryAverages()}
            </div>
        );
    }
}

export default Kiosk;
