import api from "../Security/api";

class StateSalesTaxService {
	async getAllStates() {
		return new Promise((res, reject) => {
			api
				.get("/stateSalesTax")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async updateState(state) {
		return new Promise((res, reject) => {
			api
				.put("/stateSalesTax", state)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createState(account) {
		return new Promise((res, reject) => {
			api
				.post("/stateSalesTax", account)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async deleteState(id) {
		return new Promise((res, reject) => {
			api
				.delete("/stateSalesTax/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}
}

const stateSalesTaxService = new StateSalesTaxService();

export default stateSalesTaxService;
