import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {isGroupTableRow} from "@devexpress/dx-grid-core";
import {
    Grid,
    Table,
    VirtualTable,
    TableHeaderRow,
    PagingPanel,
    TableGroupRow,
    GroupingPanel,
    DragDropProvider,
    Toolbar,
    TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";
import {
    GroupingState,
    SortingState,
    IntegratedSorting,
    IntegratedGrouping,
    PagingState,
    IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
    Plugin,
    Template,
    TemplateConnector,
    TemplatePlaceholder,
} from "@devexpress/dx-react-core";
import {
    Button,
    Container,
    Col,
    Row,
    Spinner,
    toast,
    ToastContainer,
    MDBIcon,
    Modal,
    MDBModalHeader,
    MDBRow,
    MDBCol,
    MDBModalFooter,
    MDBModalBody,
    MDBCard, MDBCardBody, Input,
} from "mdbreact";
import OrderService from "../Security/OrderService/orderService";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import "./orderList.css";
import ReactTooltip from "react-tooltip";
import NoteService from "../Security/NoteService/noteService";
import GlobalFunctions from "../Filters/GlobalFunctions";

const ItemCounter = () => (
    <Plugin name="ItemCounter">
        <Template
            name="tableCell"
            predicate={({tableRow}) => isGroupTableRow(tableRow)}
        >
            {(params) => (
                <TemplateConnector>
                    {({getCollapsedRows}) => {
                        const updatedParams = {
                            ...params,
                            tableRow: {
                                ...params.tableRow,
                                row: {
                                    ...params.tableRow.row,
                                    collapsedRows: getCollapsedRows(params.tableRow.row) || [],
                                },
                            },
                        };
                        return <TemplatePlaceholder params={updatedParams}/>;
                    }}
                </TemplateConnector>
            )}
        </Template>
    </Plugin>
);

const Root = (props) => <Grid.Root {...props} style={{height: "100%"}}/>;

function getVal(row) {
    if (row.collapsedRows.length > 0) {
        return "Count: " + row.collapsedRows.length;
    } else {
        return "";
    }
}

const Content = ({row, column}) => (
    <span>
		<span>
			{column.title} : {row.value}
		</span>
		<span style={{fontWeight: "bold"}}>&nbsp; {getVal(row)}</span>
	</span>
);

export default class serviceDocumentationView extends React.Component {
    constructor(props) {
        super(props);

        let groupColumns = [];

        if (window.localStorage.getItem("serviceDocFollowUpGrouping")) {
            groupColumns = JSON.parse(window.localStorage.getItem("serviceDocFollowUpGrouping"));
        }

        const data = {
            columns: [
                {
                    title: "Id",
                    name: "id",
                },
                {
                    title: "Patient Name",
                    name: "patientName",
                },
                {
                    title: "Patient Phone",
                    name: "patientPhone",
                },
                {
                    title: "Last Attempt",
                    name: "daysSinceLastAttempt",
                },
                {
                    title: "Days In Queue",
                    name: "daysInQueue",
                },
                {
                    title: "Attempts Made",
                    name: "attemptsMade"
                },
                {
                    title: "Contact",
                    name: "contact",
                },
            ],
            rows: [],
            integratedSortingColumnExtensions: [],
        };

        let defaultColumnWidths = [
            {columnName: "contact", width: 80},
            {columnName: "id", width: 80},
            {columnName: "patientName", width: 150},
            {columnName: "patientPhone", width: 150},
            {columnName: "daysSinceLastAttempt", width: 150},
            {columnName: "daysInQueue", width: 120},
            {columnName: "attemptsMade", width: 120}
        ];

        let queueId = 57;
        if (props.location && props.location.pathname && props.location.pathname.indexOf("pendingTicket") >= 0)
            queueId = 52;

        this.state = {
            queueId: queueId,
            order: {},
            editRow: {},
            data: data,
            isLoaded: false,
            grouping: [],
            groupingColumns: groupColumns,
            defaultColumnWidths: defaultColumnWidths,
            groupSummaryItems: [],
            notesRetrieved: false,
            orderNotes: [],
            nNote: "",
            contactRadio: ""
        };

        this.changeGrouping = (grouping) => {
            let ary = [];

            grouping.forEach((group) => {
                ary.push({columnName: group.columnName});
            });
            window.localStorage.setItem('serviceDocFollowUpGrouping', JSON.stringify(ary));
            this.setState({
                groupingColumns: ary,
            });
        };

        this.getResults(queueId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location && this.props.location && prevProps.location.pathname !== this.props.location.pathname) {
            let loc = this.props.location.pathname;
            if (loc.indexOf("pendingTicket") >= 0 && this.state.queueId !== 52){
                this.setState({
                    queueId: 52,
                })
                this.getResults(52);
            }
            else if (loc.indexOf("serviceDoc") >= 0 && this.state.queueId !== 57){
                this.setState({
                    queueId: 57,
                })
                this.getResults(57);
            }
        }
    }

    // this triggered even when going back to the dashboard, so instead of a listener, 
    //we cahnged to checking prevState against current state only and it works better - CD
    //componentDidMount() {
    //    this.props.history.listen((loc, action) => {
    //        console.log(loc)
    //        if (loc && loc.pathname && loc.pathname.indexOf("pendingTicket") >= 0 && this.state.queueId != 52) {
    //            this.setState({
    //                queueId: 52,
    //            })
    //            this.getResults(52);
    //        }
    //        else if (loc && loc.pathname && loc.pathname.indexOf("serviceDoc") >= 0 && this.state.queueId != 57){
    //            this.setState({
    //                queueId: 57,
    //            })
    //            this.getResults(57);
    //        }
    //    })
    //}

    static contextTypes = {
        currentUser: PropTypes.object,
        companyName: PropTypes.string,
    };

    downloadFunction() {
        this.clientCSV(this.state.data, "Orders.csv");
    }

    toggleEditModal(order) {
        this.setState({
            toggleModal: !this.state.toggleModal,
            notesRetrieved: false,
            orderNotes: [],
            nNote: "",
            contactRadio: "",
            order: order || {},
        });
    }

    //This will convert all the data in the grid to a csv file
    clientCSV(stateData, filename) {
        let result,
            ctr,
            keys = [],
            headers = [],
            columnDelimiter = ",",
            lineDelimiter = "\n",
            data;

        data = stateData.rows || null;
        if (data == null || !data.length) {
            return null;
        }

        stateData.columns.forEach((col) => {
            keys.push(col.name);
            headers.push(col.title);
        });

        let replace = ["patientName", "patientPhone"];

        result = "";
        result += headers.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;

                if (replace.indexOf(key) > -1 && item[key] != null) {
                    result += item[key].replace(/,/g, " ");
                } else {
                    result += item[key];
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        this.downloadBlob(result, filename);
    }

    //this actually prompts the download
    downloadBlob(csvData, filename) {
        let blob = new Blob([csvData], {
            type: "application/csv;charset=utf-8;",
        });

        if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, filename);
        } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a"),
                csvUrl = URL.createObjectURL(blob);

            link.href = csvUrl;
            link.style = "visibility:hidden";
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
    sortNotes(allNotes) {

        let sorted = allNotes.sort((a,b) => {
            let da = new Date(a.createdAt).getTime(),
                db = new Date(b.createdAt).getTime();

            return da > db ? -1: 1
        });

        return sorted;
    }

    getOrderNotes(id) {
        NoteService.getNotes(id)
            .then((n) => {
                let notes = this.sortNotes(n)
                this.setState({
                    notesRetrieved: true,
                    orderNotes: notes,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getResults(queueId) {
        return OrderService.getServiceDocFollowUpNative(queueId)
            .then((res) => {
                console.log(res)
                this.buildNewResults(res);
            })
            .catch((err) => {
                console.log(err);
                this.setState({isLoaded: true});
            });
    }

    buildNewResults(res) {
        let ary = [],
            dt = this.state.data;

        res.forEach((val) => {
            ary.push({
                id: val.id,
                patientName: val.patientName,
                daysSinceLastAttempt: val.daysSinceLastAttempt + " Days Ago",
                patientPhone: val.patientPhone,
                daysInQueue: val.daysInQueue,
                attemptsMade: val.attemptsMade,
                contact: this.renderContactButton(val),
            });
        });

        dt.rows = ary;

        toast.success("Found " + res.length + " Results");

        this.setState({
            data: dt,
            isLoaded: true,
        });
    }

    renderContactButton(order) {
        return <Button
            floating
            size="sm"
            color={"blue"}
            data-tip={"Sync to Brightree"}
            onClick={() => {
                this.getOrderNotes(order.id)
                this.toggleEditModal(order);
            }}
        >
            <MDBIcon icon="file-contract" style={{fontSize: "2em"}}/>
        </Button>
    }

    renderNotesList() {
        let n = this.state.orderNotes;
        return (
            <div style={{overflowY: "scroll", overflowX: "hidden"}} className={"serviceDocNoteDiv"}>
                {n.map((note) => {
                    return (
                        <MDBCard style={{backgroundColor: "#FFF7D1", margin: 6}} key={note.id}>
                            <MDBRow>
                                <MDBCol size="6">
                                    {GlobalFunctions.formatDateTime(note.createdAt)}
                                </MDBCol>

                                <MDBCol size="5">
                                    {note.createdBy ? note.createdBy.firstname + " " + note.createdBy.lastname : ""}
                                </MDBCol>
                            </MDBRow>
                            <MDBCardBody>
                                <p style={{whiteSpace: "pre-line"}}>{note.text}</p>
                            </MDBCardBody>
                        </MDBCard>
                    );
                })}
            </div>
        );
    }

    handleNoteChange = (e) => {
        this.setState({
            nNote: e.target.value,
        });
    };

    updateOrder(order){
        const {currentUser} = this.context;
        let saveOrder = {
            orderId: order.id,
            noteText: this.state.nNote,
            contactType: this.state.contactRadio,
            userId: currentUser.id,
            reasonId: this.state.queueId,
        }

        NoteService.addSdfuNote(saveOrder)
            .then((res) => {
                this.getResults(this.state.queueId);
                this.toggleEditModal();
                toast.success("Contact Attempt Noted");
            })
            .catch((err) => {
                console.log(err);
                toast.error("Error: Contact attempt not saved");
            });


    }
    renderModal() {
        let order = this.state.order;
        return (
            <Modal
                className={"serviceDocModal"}
                isOpen={this.state.toggleModal}
                toggle={() => {
                    return;
                }}
            >
                <MDBModalHeader
                    className={"mHeaderTitle"}
                    style={{
                        backgroundColor: "#5581C1",
                        padding: "4px 10px",
                        color: "#FFF",
                    }}
                >
                    <MDBRow>
                        <MDBCol size="6">{order.patientName}</MDBCol>
                        <MDBCol size="6" style={{textAlign: "right", paddingRight: 10}}>
                            <span>Order Id: {order.id}</span>
                        </MDBCol>

                    </MDBRow>
                </MDBModalHeader>
                <MDBModalBody>
                    <MDBRow>
                        <MDBCol md={"8"}>
                            {this.renderNotesList()}
                        </MDBCol>
                        <MDBCol>
                            <MDBRow>
                                <MDBCol>
                                    <Input
                                        type="textarea"
                                        label="Add Note"
                                        onChange={this.handleNoteChange.bind(this)}
                                        outline
                                        style={{height: "400px", width: "100%"}}
                                    />
                                </MDBCol>
                            </MDBRow>

                            <MDBRow style={{justifyContent: "center"}}>
                                <Input
                                    onClick={() => this.setState({contactRadio: "Phone"})}
                                    checked={this.state.contactRadio === "Phone"}
                                    label="Phone"
                                    type="radio"
                                    id="phoneRadio"
                                />
                                <Input
                                    onClick={() => this.setState({contactRadio: "Email"})}
                                    checked={this.state.contactRadio === "Email"}
                                    label="Email"
                                    type="radio"
                                    id="emailRadio"
                                />
                                <Input
                                    onClick={() => this.setState({contactRadio: "Fax"})}
                                    checked={this.state.contactRadio === "Fax"}
                                    label="Fax"
                                    type="radio"
                                    id="faxRadio"
                                />

                            </MDBRow>

                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>

                <MDBModalFooter style={{padding: "5px"}}>
                    <MDBCol size="2" style={{float: "right", justifyContent: "right"}}>
                        <Button
                            floating
                            rounded
                            size="sm"
                            data-tip={"Mark as Contacted and Save Note"}
                            disabled={this.state.nNote === "" || this.state.contactRadio === ""}
                            color={"success"}
                            onClick={() => {
                                this.updateOrder(order);

                            }}
                        >
                            <MDBIcon fas icon="save" style={{fontSize: "2em"}}/>
                        </Button>
                        <Button
                            floating
                            size="sm"
                            color={"red"}
                            data-tip={"Cancel"}
                            onClick={() => this.toggleEditModal()}
                        >
                            <MDBIcon icon="times" style={{fontSize: "2em"}}/>
                        </Button>
                    </MDBCol>
                    <ReactTooltip/>
                </MDBModalFooter>

            </Modal>
        )
    }


    renderTable() {
        const Cell = ({row, column, ...props}) => {
            if (column.name === "contact") {
                return (
                    <Table.Cell
                        {...props}
                    />
                );
            } else {
                return (
                    <Table.Cell
                        {...props}
                        onClick={(e) => {
                            if (e.ctrlKey === true) {
                                this.setState({
                                    targetLink: row.id,
                                });

                                setTimeout(() => {
                                    document.getElementById("orderListId").click();
                                }, 5);
                            } else {
                                window.open("/order/" + row.id, "_blank");
                                //this.props.history.push({
                                //    pathname: "/order/" + row.id,
                                //    state: {goto: "serviceDoc"},
                                //});
                            }
                        }}
                        style={{
                            cursor: "pointer",
                        }}
                    />
                );
            }
        };

        if (this.state.isLoaded === true) {
            return (
                <Grid
                    style={{maxWidth: "1800px !important"}}
                    rows={this.state.data.rows}
                    rootComponent={Root}
                    columns={this.state.data.columns}
                >
                    <DragDropProvider/>
                    <SortingState
                        defaultSorting={[{ columnName: "attemptsMade", direction: "asc"}]}
                    />
                    <IntegratedSorting
                        columnExtensions={this.state.data.integratedSortingColumnExtensions}
                    />
                    <GroupingState
                        grouping={this.state.groupingColumns}
                        onGroupingChange={this.changeGrouping}
                    />
                    <IntegratedGrouping/>
                    <PagingState defaultCurrentPage={0} pageSize={25}/>
                    <IntegratedPaging/>
                    <VirtualTable cellComponent={Cell} height={"auto"}/>
                    <TableColumnResizing
                        defaultColumnWidths={this.state.defaultColumnWidths}
                    />
                    <TableHeaderRow showSortingControls/>
                    <PagingPanel/>
                    <TableGroupRow contentComponent={Content} showColumnsWhenGrouped/>
                    <ItemCounter/>
                    <Toolbar/>
                    <GroupingPanel/>
                </Grid>
            );
        } else {
            return <div/>;
        }
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }

    renderTableOrSpinner() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <div className={"purchasingGrid ordersListGrid"}
                 style={{height: 0.9 * window.innerHeight, backgroundColor: "white"}}>
                {this.renderTable()}
            </div>
        );
    }

    render() {
        return (
            <div>
                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={true}
                    autoClose={3000}
                    position={"top-right"}
                    style={{marginTop: "75px"}}
                />
                {this.renderModal()}

                <div style={{maxWidth: "98%", marginLeft: "1rem"}}>
                    <Row>
                        <Col size={"12"}>{this.renderTableOrSpinner()}</Col>
                    </Row>

                    <Link
                        id="orderListId"
                        to={{
                            pathname: `/order/${this.state.targetLink}`,
                            state: {goto: "serviceDoc"},
                        }}
                        target="_blank"
                        rel="opener"
                        style={{display: "none"}}
                        activeclassname="active"
                    />

                    <div className={"fixed-bottom downloadCsvDiv"}>
                        <Button
                            className={"downloadCsvButton"}
                            floating
                            size="sm"
                            color={"primary"}
                            data-tip={"Download CSV"}
                            onClick={this.clientCSV.bind(
                                this,
                                this.state.data,
                                "ServiceDocFollowUp.csv"
                            )}
                        >
                            <MDBIcon icon="download" style={{fontSize: "2em"}}/>
                        </Button>

                        <ReactTooltip/>
                    </div>
                </div>
            </div>
        );
    }
}
