import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { isGroupTableRow } from "@devexpress/dx-grid-core";
import {
	Grid,
	Table,
	VirtualTable,
	TableHeaderRow,
	PagingPanel,
	TableGroupRow,
	GroupingPanel,
	DragDropProvider,
	Toolbar,
	TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";
import {
	GroupingState,
	SortingState,
	IntegratedSorting,
	IntegratedGrouping,
	PagingState,
	IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
	Plugin,
	Template,
	TemplateConnector,
	TemplatePlaceholder,
} from "@devexpress/dx-react-core";
import {
	Button,
	Container,
	Col,
	Row,
	Spinner,
	toast,
	ToastContainer,
	MDBIcon,
} from "mdbreact";
import OrderService from "../Security/OrderService/orderService";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import "./orderList.css";
import ReactTooltip from "react-tooltip";

const ItemCounter = () => (
	<Plugin name="ItemCounter">
		<Template
			name="tableCell"
			predicate={({ tableRow }) => isGroupTableRow(tableRow)}
		>
			{(params) => (
				<TemplateConnector>
					{({ getCollapsedRows }) => {
						const updatedParams = {
							...params,
							tableRow: {
								...params.tableRow,
								row: {
									...params.tableRow.row,
									collapsedRows: getCollapsedRows(params.tableRow.row) || [],
								},
							},
						};
						return <TemplatePlaceholder params={updatedParams} />;
					}}
				</TemplateConnector>
			)}
		</Template>
	</Plugin>
);

const Root = (props) => <Grid.Root {...props} style={{ height: "100%" }} />;

const compareDate = (a, b) => {
	//f1 and s1 should be a year eg 2018...BC
	//f2 and s2 should be a month number eg 11,9,4...BC

	let f1 = parseInt(a.substring(0, 4)),
		f2 = parseInt(a.substring(5)),
		s1 = parseInt(b.substring(0, 4)),
		s2 = parseInt(b.substring(5));

	if (f1 > s1) {
		return 1;
	} else if (f1 < s1) {
		return -1;
	}

	//if we reach here, f1 and s1 are the same;

	if (f2 > s2) {
		return 1;
	} else if (f2 < s2) {
		return -1;
	}

	//if here, it is the same value but that shouldn't be possible;
	return 0;
};

function getVal(row) {
	if (row.collapsedRows.length > 0) {
		return "Count: " + row.collapsedRows.length;
	} else {
		return "";
	}
}

const Content = ({ row, column }) => (
	<span>
		<span>
			{column.title} : {row.value}
		</span>
		<span style={{ fontWeight: "bold" }}>&nbsp; {getVal(row)}</span>
	</span>
);

export default class billingView extends React.Component {
	constructor(props) {
		super(props);

		let groupColumns = [
			{ columnName: "setupMonth" },
			{ columnName: "category" },
		];

		if (window.localStorage.getItem("billingGrouping")) {
			groupColumns = JSON.parse(window.localStorage.getItem("billingGrouping"));
		}

		const data = {
			columns: [
				{
					title: "BT Sync",
					name: "btSync",
				},
				{
					title: "Id",
					name: "id",
				},
				{
					title: "Patient Name",
					name: "patientName",
				},
				{
					title: "Setup Date",
					name: "setupDate",
				},
				{
					title: "Setup Month",
					name: "setupMonth",
				},
				//{
				//	title: "Phone",
				//	name: "phone",
				//},
				//{
				//	title: "Address",
				//	name: "address",
				//},
				{
					title: "Category",
					name: "category",
				},
				{
					title: "MSRP",
					name: "msrp",
				},
				{
					title: "Cost",
					name: "cost",
				},
				{
					title: "Payor Source",
					name: "payorSource",
				},
				{
					title: "Insurance",
					name: "insurance",
				},
				{
					title: "Product",
					name: "product",
				},
				{
					title: "Service Location",
					name: "serviceLocation",
				},
			],
			rows: [],
			integratedSortingColumnExtensions: [
				{ columnName: "setupMonth", compare: compareDate },
			],
		};

		let defaultColumnWidths = [
			{ columnName: "btSync", width: 80 },
			{ columnName: "id", width: 80 },
			{ columnName: "patientName", width: 200 },
			{ columnName: "phone", width: 150 },
			{ columnName: "address", width: 350 },
			{ columnName: "category", width: 200 },
			{ columnName: "msrp", width: 120 },
			{ columnName: "cost", width: 120 },
			{ columnName: "setupMonth", width: 150 },
			{ columnName: "setupDate", width: 150 },
			{ columnName: "payorSource", width: 200 },
			{ columnName: "insurance", width: 200 },
			{ columnName: "product", width: 200 },
			{ columnName: "serviceLocation", width: 200 },
		];

		this.state = {
			data: data,
			isLoaded: false,
			grouping: [],
			groupingColumns: groupColumns,
			defaultColumnWidths: defaultColumnWidths,
			groupSummaryItems: [],
		};

		this.changeGrouping = (grouping) => {
			let ary = [];

			grouping.forEach((group) => {
				ary.push({ columnName: group.columnName });
			});
			window.localStorage.setItem('billingGrouping', JSON.stringify(ary));
			this.setState({
				groupingColumns: ary,
			});
		};

		this.getResults();
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		companyName: PropTypes.string,
	};

	//componentDidMount() {
	//	let temp = JSON.parse(sessionStorage.getItem('billingGrouping'));
	//	if (temp && temp.length > 0) {
	//		this.setState({
	//			groupingColumns: temp,
 //           })
 //       }
 //   }

	downloadFunction() {
		this.clientCSV(this.state.data, "Orders.csv");
	}

	//This will convert all the data in the grid to a csv file
	clientCSV(stateData, filename) {
		let result,
			ctr,
			keys = [],
			headers = [],
			columnDelimiter = ",",
			lineDelimiter = "\n",
			data;

		data = stateData.rows || null;
		if (data == null || !data.length) {
			return null;
		}

		stateData.columns.forEach((col) => {
			keys.push(col.name);
			headers.push(col.title);
		});

		let replace = ["category", "address", "patientName", "phone"];

		result = "";
		result += headers.join(columnDelimiter);
		result += lineDelimiter;

		data.forEach(function (item) {
			ctr = 0;
			keys.forEach(function (key) {
				if (ctr > 0) result += columnDelimiter;

				if (replace.indexOf(key) > -1 && item[key] != null) {
					result += item[key].replace(/,/g, " ");
				} else {
					result += item[key];
				}

				ctr++;
			});
			result += lineDelimiter;
		});

		this.downloadBlob(result, filename);
	}

	//this actually prompts the download
	downloadBlob(csvData, filename) {
		let blob = new Blob([csvData], {
			type: "application/csv;charset=utf-8;",
		});

		if (window.navigator.msSaveBlob) {
			// FOR IE BROWSER
			navigator.msSaveBlob(blob, filename);
		} else {
			// FOR OTHER BROWSERS
			let link = document.createElement("a"),
				csvUrl = URL.createObjectURL(blob);

			link.href = csvUrl;
			link.style = "visibility:hidden";
			link.download = filename;

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	getResults() {
		return OrderService.getBillingViewNative()
			.then((res) => {
				this.buildNewResults(res);
			})
			.catch((err) => {
				this.setState({ isLoaded: true });
			});
	}

	buildNewResults(res) {
		const { companyName } = this.context;
		let ary = [],
			dt = this.state.data;

		res.forEach((val) => {
			ary.push({
				id: val.id,
				patientName: val.patientName || "",
				phone: val.phone || "",
				address: val.address || "",
				category: val.category || "",
				msrp: val.msrp || "0",
				cost: val.cost || "0",
				setupDate: val.setupDate || "",
				setupMonth: val.setupMonth || "",
				payorSource: val.payorSource || "",
				insurance: val.insurance || "",
				product: val.product || 'No Products Listed',
				serviceLocation: val.serviceLocation || "",
				btSync: val.btId === null && companyName !== "corkmedical" ? this.renderSyncButton(val.id) : '',
			});
		});

		dt.rows = ary;

		toast.success("Found " + res.length + " Results");

		if (companyName === 'corkmedical') {
			this.setState({
				groupingColumns:
					[
						{ columnName: "payorSource" },
						{ columnName: "setupMonth" },
						{ columnName: "category" },
					]
			})
		}

		this.setState({
			data: dt,
			isLoaded: true,
		});
	}

	renderSyncButton(id){
		return <Button
			floating
			size="sm"
			color={"blue"}
			data-tip={"Sync to Brightree"}
			onClick={() => {
				this.syncToBrighttree(id);
			}}
		>
			<MDBIcon icon="sync-alt" style={{ fontSize: "2em" }} />
		</Button>
	}

	syncToBrighttree(id){
		OrderService.syncOrderWithBT(id)
			.then((res) => {
				if(res){
					toast.success("Order: " + id + " is being created. Please allow up to 1 minute for the sync to complete.")
					this.getResults();
				}
				else{
					toast.warn('An error occurred syncing the order with bt');
				}
			})
			.catch((err) => {
				this.setState({ isLoaded: true });
			});
	}

	renderTable() {
		const Cell = ({ row, column, ...props }) => {
			if(column.name === "btSync"){
				return (
					<Table.Cell
						{...props}
					/>
				);
			}
			else{
				return (
					<Table.Cell
						{...props}
						onClick={(e) => {
							if (e.ctrlKey === true) {
								this.setState({
									targetLink: row.id,
								});

								setTimeout(() => {
									document.getElementById("orderListId").click();
								}, 5);
							} else {
								this.props.history.push({
									pathname: "/order/" + row.id,
									state: { goto: "billing" },
								});
							}
						}}
						style={{
							cursor: "pointer",
						}}
					/>
				);
			}
		};

		if (this.state.isLoaded === true) {
			return (
				<Grid
					style={{ maxWidth: "1800px !important" }}
					rows={this.state.data.rows}
					rootComponent={Root}
					columns={this.state.data.columns}
				>
					<DragDropProvider />
					<SortingState
						defaultSorting={[{ columnName: "setupMonth", direction: "desc" }]}
					/>
					<IntegratedSorting
						columnExtensions={this.state.data.integratedSortingColumnExtensions}
					/>
					<GroupingState
						grouping={this.state.groupingColumns}
						onGroupingChange={this.changeGrouping}
					/>
					<IntegratedGrouping />
					<PagingState defaultCurrentPage={0} pageSize={25} />
					<IntegratedPaging />
					<VirtualTable cellComponent={Cell} height={"auto"} />
					<TableColumnResizing
						defaultColumnWidths={this.state.defaultColumnWidths}
					/>
					<TableHeaderRow showSortingControls />
					<PagingPanel />
					<TableGroupRow contentComponent={Content} showColumnsWhenGrouped />
					<ItemCounter />
					<Toolbar />
					<GroupingPanel />
				</Grid>
			);
		} else {
			return <div />;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	renderTableOrSpinner() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<div className={"purchasingGrid ordersListGrid"}
				 style={{height: 0.9 * window.innerHeight, backgroundColor: "white"}}>
				{this.renderTable()}
			</div>
		);
	}

	render() {
		return (
			<div>
				<ToastContainer
					hideProgressBar={true}
					newestOnTop={true}
					autoClose={3000}
					position={"top-right"}
					style={{ marginTop: "75px" }}
				/>

				<div style={{ maxWidth: "98%", marginLeft: "1rem" }}>
					<Row>
						<Col size={"12"}>{this.renderTableOrSpinner()}</Col>
					</Row>

					<Link
						id="orderListId"
						to={{
							pathname: `/order/${this.state.targetLink}`,
							state: { goto: "billing" },
						}}
						target="_blank"
						rel="opener"
						style={{ display: "none" }}
						activeclassname="active"
					/>

					<div className={"fixed-bottom downloadCsvDiv"}>
						<Button
							className={"downloadCsvButton"}
							floating
							size="sm"
							color={"primary"}
							data-tip={"Download CSV"}
							onClick={this.clientCSV.bind(
								this,
								this.state.data,
								"Billing.csv"
							)}
						>
							<MDBIcon icon="download" style={{ fontSize: "2em" }} />
						</Button>

						<ReactTooltip />
					</div>
				</div>
			</div>
		);
	}
}
