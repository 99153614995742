import React from "react";
import {
	Button,
	Container,
	DataTable,
	MDBIcon,
	Spinner,
	toast,
} from "mdbreact";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import ReportsService from "../Security/ReportsService/reportsService";

export default class complaintReport extends React.Component {
	constructor(props) {
		super(props);

		let filters = this.determineFilters(props.filters);
		let topFilters = props.topFilters;

		//table headers & columns
		const complaintsData = {
			columns: [
				{
					label: "Feedback ID",
					field: "id",
					sort: "asc",
					width: 200,
				},
				{
					label: "Order ID",
					field: "orderId",
					sort: "asc",
					width: 200,
				},
				{
					label: "Patient Name",
					field: "patientName",
					sort: "asc",
					width: 200,
				},
				{
					label: "Status",
					field: "status",
					sort: "asc",
					width: 200,
				},
				{
					label: "Category",
					field: "category",
					sort: "asc",
					width: 200,
				},
				{
					label: "Reason",
					field: "reason",
					sort: "asc",
					width: 200,
				},
				{
					label: "Opened On",
					field: "openedOn",
					sort: "asc",
					width: 200,
				},
				{
					label: "Opened By",
					field: "openedBy",
					sort: "asc",
					width: 200,
				},
				{
					label: "Closed On",
					field: "closedOn",
					sort: "asc",
					width: 200,
				},
				{
					label: "Closed By",
					field: "closedBy",
					sort: "asc",
					width: 200,
				},
				{
					label: "Sales Location",
					field: "salesLocation",
					sort: "asc",
					width: 200,
				},
				{
					label: "Service Location",
					field: "serviceLocation",
					sort: "asc",
					width: 200,
				},
			],
			rows: [],
		};

		this.state = {
			allComplaints: [],
			complaintsData: complaintsData,
			startDate: new Date(filters.startDate),
			endDate: new Date(filters.endDate),
			pointsOnlyProducts: false,
			csvFunction: props.csvFunction,
			isLoaded: true,
			users: [],
			topFilters: topFilters,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		// Get the most up-to-date filters passed down from parent...JK
		if (
			JSON.stringify(this.props.topFilters) !==
			JSON.stringify(this.state.topFilters)
		) {
			this.setState({
				topFilters: this.props.topFilters,
			});
		}
		// Checks if the generate reports button has been clicked...JK
		if (prevProps.generateClicked !== this.props.generateClicked) {
			this.getComplaintReport();
		}
	}

	determineFilters(userFilters) {
		let d = new Date(),
			filters = {};

		if (userFilters) {
			if (userFilters.startDate) {
				filters.startDate = new Date(userFilters.startDate);
			} else {
				filters.startDate = new Date(d.getFullYear(), d.getMonth(), 1);
			}

			if (userFilters.endDate) {
				filters.endDate = new Date(userFilters.endDate);
			} else {
				filters.endDate = d;
			}
		} else {
			filters.startDate = new Date(d.getFullYear(), d.getMonth(), 1);
			filters.endDate = d;
		}

		return filters;
	}

	getComplaintReport() {
		let tp = this.state.topFilters;

		let filters = {
			startDate: tp.startDate,
			endDate: tp.endDate,
		};

		try {
			filters = {
				startDate: new Date(tp.startDate).toLocaleDateString(),
				endDate: new Date(tp.endDate).toLocaleDateString(),
			};
		} catch {
			//Only need one toast since all reports get generated at the same time...BC
			toast.error("Please check your dates.");
			return;
		}

		// Have to check len since showDatePicker defaults to false at startup...JK
		if (!tp.showDatePicker && tp.quickFilter.length > 0) {
			filters.startDate = tp.quickFilter[0].toLocaleDateString();
			filters.endDate = tp.quickFilter[1].toLocaleDateString();
		} else {
			// Check to make sure they have selected a date and the dates aren't set to the default if not using the quickfilter...JK
			if (
				filters.startDate === "12/31/1969" ||
				filters.endDate === "12/31/1969"
			) {
				toast.error("Please check your dates");
				return;
			}
		}

		this.setState({ isLoaded: false });
		
		ReportsService.getComplaintReport(filters)
			.then((res) => {
				this.setState({ allComplaints: res });

				this.formatRows();
			})
			.catch((err) => {
				//handle error..BC
			});
	}

	formatRows() {
		let complaints = this.state.allComplaints,
			dt = this.state.complaintsData,
			ary = [];

		complaints.forEach((c) => {
			ary.push({
				id: c.id,
				orderId: c.orderId || " ",
				patientName: c.patientName,
				status: c.issueStatus,
				category: c.issueCategory,
				reason: c.issueReason,
				salesLocation: c.salesLocation,
				serviceLocation: c.serviceLocation,
				openedOn: c.openedOn || " ",
				openedBy: c.openedBy || " ",
				closedOn: c.closedOn || " ",
				closedBy: c.closedBy || " ",
			});
		});
		dt.rows = ary;

		this.setState({
			complaintsData: dt,
			isLoaded: true,
		});
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	//get the data in the table
	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<div>
				<DataTable
					entriesOptions={[25, 50, 100]}
					pagesAmount={10}
					data={this.state.complaintsData}
					responsive
					fixed
					striped
					bordered
				></DataTable>

				<Button
					style={{ float: "right" }}
					color={"blue"}
					floating
					size="sm"
					data-tip={"Download CSV"}
					onClick={this.state.csvFunction.bind(
						this,
						this.state.complaintsData,
						"feedbackExport.csv"
					)}
				>
					<MDBIcon icon="download" style={{ fontSize: "2em" }} />
				</Button>
				<ReactTooltip />
			</div>
		);
	}
}
