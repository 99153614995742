import React from "react";
import "./jkTooltip.css";
import {MDBCol, MDBRow} from "mdbreact";

export default class UpdateNotes extends React.Component {

    renderVersion472() {
        return (
            <div>
                Version 4.7.2
                <ul>
                    <li>Updates</li>
                    <ul>
                        <li>Validation rules and UI changes have been applied to Expenses, which will now be loaded into another system, <b><i>except for In-Service appointments</i></b> that will remain logged in SalesPilot.</li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion471() {
        return (
            <div>
                Version 4.7.1
                <ul>
                    <li>Updates</li>
                    <ul>
                        <li>Images attached to expenses will now be resized before submission.</li>
                        <li>Added a fix for liaison schedules when a user doesn't log out of SalesPilot.</li>
                        <li>Added a fix for merging attachents that will not allow images to be selected, only PDF's.</li>
                        <li>Added a fix to disable the save button on the Order page, when adding a note.</li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion470() {
        return (
            <div>
                Version 4.7.0
                <ul>
                    <li>Other updates</li>
                    <ul>
                        <li>Service Approvals now has a dynamic queue to work from.</li>
                        <li>Patient collections now has the ability to note how much has been collected from patients by order.</li>
                        <li>A report can be generated to view patient payments that have been collected.</li>
                        <li>The Order Type field has been added to all In Process dynamic queues.</li>
                        <li>Service orders that are approved can also be sent to the Pre-Auth Follow Up queue for simultaneous workflow.</li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion460() {
        return (
            <div>
                Version 4.6.0
                <ul>
                    <li>Verification Updates</li>
                    <ul>
                        <li>All automatic verification will now run through pVerify, instead of availity.</li>
                        <li>The pVerify layout and design have been updated to be easier to read!</li>
                    </ul>
                    <li>Other Updates</li>
                    <ul>
                        <li>Locations are now alphabetized.</li>
                        <li>The sales pipeline chart has been fixed.</li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion450() {
        return (
            <div>
                Version 4.5.0
                <ul>
                    <li>Service Feature Updates</li>
                    <ul>
                        <li>Quotes are available for 'Service - CST - Need Part Number', 'Service - Approval', and 'Service - CST - Diagnose Issue'</li>
                        <li>Custom queue pages available for In-Process CST queues</li>
                    </ul>
                    <li>Other updates</li>
                    <ul>
                        <li>Added Item Id search box on the Quote Builder.</li>
                        <li>Account names are now sorted on the order page.</li>
                        <li>Deleted activities can be restored on the activity edit page.</li>
                        <li>Updated the navigation for Internal Request queues from the Dashboard.</li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion440() {
        return (
            <div>
                Version 4.4.0
                <ul>
                    <li>New Customer Experience Features</li>
                    <ul>
                        <li>Orders with complaints will now alert users, but of course, legel complaints will take priority still.</li>
                        <li>Patient Feedback can now be assigned to users on the customer support team.</li>
                        <li>Patient Compliments will now track the exact Rehab Medical employee with a new Complimented User input.</li>
                        <li>Pateint feedback will now have an included followup date for open feedback/complaints.</li>
                        <li>Patient feedback that requires an Action Plan will now have an indicator. Action plans will be added as a feedback note.</li>
                        <li>Several fields were added the the patient feedback listing page, including the time feedback was created, days open, and filters are being added to make searching easier.</li>
                    </ul>
                    <li>Other updates</li>
                    <ul>
                        <li>Products will be required when creating service orders.</li>
                        <li>Patients and orders will now include an indicator displaying the last time Rehab Medical had chart notes uploaded for that patient.</li>
                        <li>There is now a method to create an RX form when creating a PA Summary. Part of the Quote Builder and PA Summary builder on service orders.</li>
                    </ul>
                    <li>Cork Medical updates</li>
                    <ul>
                        <li>Customizable pricing is now allowed on an Account basis, letting reps set the initial price of products for Dealers and LTC facilities.</li>
                        <li>Changing the pricing on Dealer and LTC orders will update the point values automatically.</li>
                    </ul>
                    <li>Archive is now obsolete for Rehab Medical. Cork will be next.</li>
                </ul>
            </div>
        )
    }

    renderVersion4310() {
        return (
            <div>
                Version 4.3.10
                <ul>
                    <li>New Features</li>
                    <ul>
                        <li>Added a quote builder on the order details page to generate a quote for specific items.</li>
                        <li>Added a Pre-Auth summary document builder (for service orders) after creating a quote.</li>
                        <li>Either the Quote builder or PA Summary builder can be skipped if only one document is required.</li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion4117() {
        return (
            <div>
                Version 4.1.17
                <ul>
                    <li>Verification updates</li>
                    <ul>
                        <li>
                            Layout and display have changed.
                        </li>
                    </ul>
                    <li>Dashboard updates</li>
                    <ul>
                        <li>
                            We moved the service reasons to the bottom of each board (New, in Process, RTD, Setup).
                        </li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion410() {
        return (
            <div>
                Version 4.1.0
                <ul>
                    <li>Verification updates</li>
                    <ul>
                        <li>
                            When an order is saved in In-Process/Verificaiton, it will now trigger a call to collect insurance information from Availity and pVerify.
                        </li>
                        <li>
                            The Verify Insurance button has been replaced with a button to view these details on the order page.
                            Please note: the button will not trigger the call to the services like it used to, it will only show what we have in the system currently.
                        </li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion400() {
        return (
            <div>
                Version 4.0.0
                <ul>
                    <li>Speed improvements</li>
                    <ul>
                        <li>
                            We have completely reverted the API to a REST service, just like it used to be. Enjoy.
                        </li>
                    </ul>
                    <li>Bug fixes</li>
                    <ul>
                        <li>
                            Timestamps have been corrected across the app, especially where UTC is involved.
                        </li>
                        <li>
                            UTC timestamp has been removed from attachments, it was causing an issue where files were not found because of the time difference, especially at the end of the month.
                        </li>
                    </ul>
                    <li>Order Page</li>
                    <ul>
                        <li>
                            Added a button to view field notes after an order is in Setup.
                        </li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion340() {
        return (
            <div>
                Version 3.4.0
                <ul>
                    <li>Order Listing</li>
                    <ul>
                        <li>
                            Added Payor and Insurance filters
                        </li>
                    </ul>
                    <li>Speed improvements</li>
                    <ul>
                        <li>
                            Reports, Order listing, Patient info, and several other pages have new and faster endpoints, look for the order info page coming soon!
                        </li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion304() {
        return (
            <div>
                Version 3.04
                <ul>
                    <li>Welcome to the new SalesPilot!</li>
                    <ul>
                        <li>
                            Logging in now uses your Active Directory credentials, the same login you use for your computer.
                            Complete with 2-Factor Auth!
                        </li>
                    </ul>
                    <li>Pipeline Chart</li>
                    <ul>
                        <li>
                            Added view by payor.
                        </li>
                    </ul>
                    <li>Faxes</li>
                    <ul>
                        <li>
                            View, download, rotate pages or upload your faxes directly to an order.
                        </li>
                    </ul>
                    <li>Brightree Integration (Order page and Delivery page)</li>
                    <ul>
                        <li>
                            We can now add the actual Brightree item id's to a product, and push patients and orders directly to Brightree. This is available when scheduling an order on the deliveries page or on the order details page, where you can click the checkbox next to a product and assign the id's.
                        </li>
                    </ul>
                    <li>Inventory Tracking Report</li>
                    <ul>
                        <li>
                            View current inventory.
                        </li>
                    </ul>
                    <li>PO Delivery Update</li>
                    <ul>
                        <li>
                            Added the vendor field.
                        </li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion256(){
        return (
            <div>
                Version 2.56
                <ul>
                    <li>Dashboard</li>
                    <ul>
                        <li>
                            Added new button for 'Month at a Glance' to mimic the functionality from the IOS app.
                        </li>
                    </ul>
                    <li>Order View</li>
                    <ul>
                        <li>
                            When an order is moved to setup and the setup date is entered from the web, the time is set to 12:00 pm
                            to avoid issues with orders being on different dates in reports due to timezones.
                        </li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion255(){
        return (
            <div>
                Version 2.55
                <ul>
                    <li>Recurring Orders</li>
                    <ul>
                        <li>
                            On the order page, a new order type is available: Re-Supply.  Your manager can provide more information
                            if this order type will change anything within your role.
                        </li>
                        <li>
                            When that order type is selected, another section is added to the left in the order, allowing you to set the
                            frequency that the order will be created.  This is not necessary to save a Re-Supply order.
                        </li>
                        <li>
                            A corresponding page will show all of the orders that are set to be re-created and the date that will happen.
                            This tracks orders that are using the frequency, not just orders that are of the Re-Supply type.
                        </li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion254(){
        return (
            <div>
                Version 2.54
                <ul>
                    <li>Account Details View</li>
                    <ul>
                        <li>
                            The notes on the account view page will now also display the notes for the activities associated with the account.
                        </li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion253(){
        return (
            <div>
                Version 2.53
                <ul>
                    <li>Expenses Page</li>
                    <ul>
                        <li>
                            Fixed issue preventing users from being able to download the CSV.
                        </li>
                    </ul>
                    <li>Purchase Tracking</li>
                    <ul>
                        <li>
                            Added field to the order for 'Expected Ship Date' that will be populated during the purchasing process.
                        </li>
                        <li>
                            That field will also show up on the main orders listing page, and the deliveries page, allowing for the ability to
                            reschedule and know what date it needs to be after.
                        </li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion252(){
        return (
            <div>
                Version 2.52
                <ul>
                    <li>Follow-Up View</li>
                    <ul>
                        <li>
                            Added button to allow users to search for orders by insurance policy number.
                        </li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion251(){
        return (
            <div>
                Version 2.51
                <ul>
                    <li>Patient Order View</li>
                    <ul>
                        <li>Added ability to create a home assessment on the web which will then populate on the tablet.</li>
                    </ul>

                    <li>Purchasing</li>
                    <ul>
                        <li>Updated permissions to add and edit products.</li>
                        <li>Added ability to track a PO and update it with appropriate tracking information.</li>
                        <li>If the updated tracking is past the scheduled delivery date, that order will move into
                            a new status under the Ready to Deliver bucket.</li>
                    </ul>

                    <li>Routing</li>
                    <ul>
                        <li>A couple of the listing screens now properly route to and from the order page.</li>
                    </ul>

                    <li>Home Assessment Compare</li>
                    <ul>
                        <li>An Admin screen has been created to determine differences between the home assessment created on
                            the web and one created on the field.</li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion250() {
        return (
            <div>
                Version 2.50
                <ul>
                    <li>Liaison Meetings View</li>
                    <ul>
                        <li>
                            Fixed issue preventing meetings from being able to be moved if order status was "Ready to
                            Deliver"
                        </li>
                    </ul>
                </ul>
            </div>
        )
    }


    renderVersion249() {
        return (
            <div>
                Version 2.49
                <ul>
                    <li>General</li>
                    <ul>
                        <li>
                            Changed multiple pages to use a smaller user object to save time on initial load and limit
                            server usage.
                        </li>
                    </ul>

                    <li>Insurance Details</li>
                    <ul>
                        <li>Created page that allows a manager to input all the details about an insurance used in the
                            verification, billing, etc. processes.
                        </li>
                        <li>Added icon next to insurance on the order page that when clicked, pops up a modal with the
                            insurance details currently in the system.
                        </li>
                    </ul>

                </ul>
            </div>
        )
    }

    renderVersion248() {
        return (
            <div>
                Version 2.48
                <ul>
                    <li>Orders List</li>
                    <ul>
                        <li>Fixed issues with time portion of 'Last Status Change' not being formatted for current
                            timezone.
                        </li>
                    </ul>

                    <li>Verification & Follow-Up View</li>
                    <ul>
                        <li>Fixed issues with time portion of 'Moved Into Queue' not being formatted for current
                            timezone.
                        </li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion247() {
        return (
            <div>
                Version 2.47
                <ul>
                    <li>
                        Task List
                        <ul>
                            <li>
                                Fixed an issue where tasks were not displaying the correct information.
                            </li>
                            <li>
                                Fixed tasks to link to the corresponding order.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Verification View
                        <ul>
                            <li>
                                Fixed issue with incorrect date for column "Moved Into Queue"
                            </li>
                        </ul>
                    </li>
                </ul>
                <hr/>
            </div>
        )
    }

    renderVersion246() {
        return (
            <div>
                Version 2.46
                <ul>

                    <li>Main</li>
                    <ul>
                        <li>Added blue background to all pages.</li>
                    </ul>

                    <li>Users</li>
                    <ul>
                        <li>Required at least one location to be selected for new user accounts.</li>
                    </ul>

                    <li>Region</li>
                    <ul>
                        <li>Added ability to set a Sales Director and Operations Director for regions.</li>
                    </ul>

                    <li>Locations</li>
                    <ul>
                        <li>Added ability to set a Area Manager and Sales Manager for locations.</li>
                    </ul>

                    <li>Dashboard</li>
                    <ul>
                        <li>Reformatted dashboard for a better user experience.</li>
                        <li>Added Region drop-down for pipeline chart and delivery averages chart.</li>
                    </ul>

                    <li>Sales Report</li>
                    <ul>
                        <li>
                            Added Service Location to the table and CSV export.
                        </li>
                    </ul>

                    <li>Order View</li>
                    <ul>
                        <li>Added visual indicator to DOB field if the patient is Pediatric. (under 18)</li>
                        <li>Added popup if the patient has an open legal complaint on file.</li>
                    </ul>

                    <li>Patient View</li>
                    <ul>
                        <li>Added visual indicator to DOB field if the patient is Pediatric. (under 18)</li>
                        <li>Added checks to help prevent orders from being created without a Sales or Service
                            Location.
                        </li>
                    </ul>

                    <li>Listing Screens</li>
                    <ul>
                        <li>Added Pediatric column to the following listing screens with a value of "Yes" or "No":</li>
                        <li>In Rehab Only, grid is first grouped by pediatric field for all except the order listing
                            grid
                        </li>
                        <ul>
                            <li>Order Listing</li>
                            <li>Deliveries</li>
                            <li>Verification</li>
                            <li>Follow Up</li>
                        </ul>
                    </ul>

                </ul>
                <hr/>
            </div>
        )
    }

    renderVersion245() {
        return (
            <div>
                Version 2.45
                <ul>
                    <li>
                        PCR Links
                        <ul>
                            <li>
                                Fixed an issue where sorting on location was not sorting the existing PCR links by that
                                location.
                            </li>
                        </ul>
                    </li>
                    <li>
                        PCR Notifications View
                        <ul>
                            <li>
                                Reformatted view to separate notifications by Sales Rep that is also in a collapsible
                                box and includes the total number
                                of notifications for that rep (for that day).
                            </li>
                            <li>
                                Added Sales Rep name to each notification card as well.
                            </li>
                            <li>
                                Added loading spinner until user context is loaded, which is necessary for getting the
                                rep information before
                                rendering the cards.
                            </li>
                        </ul>
                    </li>
                </ul>
                <hr/>
            </div>
        )
    }

    renderVersion244() {
        return (
            <div>
                Version 2.44
                <ul>
                    <li>
                        Verification View

                        <ul>
                            <li>
                                Fixed issue with CSV download columns not lining up properly
                            </li>

                            <li>
                                Added a column for "Currently Being Worked By"
                            </li>

                            <li>
                                Updated Priority column name on screen and in download
                            </li>

                            <li>
                                Changed default sorting to be Insurance -> Priority -> Moved Into Queue
                            </li>

                            <li>
                                Added feature where orders clicked from this view will be assigned to the user that
                                clicks it.
                                If another user is already working that order, a message will be displayed alerting
                                them.
                            </li>

                        </ul>

                    </li>

                    <li>
                        Follow-Up View
                        <ul>
                            <li>
                                Added column "Moved Into Queue"
                            </li>

                            <li>
                                Added column "Currently Being Worked By"
                            </li>

                            <li>
                                Added feature where orders clicked from this view will be assigned to the user that
                                clicks it.
                                If another user is already working that order, a message will be displayed alerting
                                them.
                            </li>
                        </ul>
                    </li>

                    <li>
                        Orders Listing Screen
                        <ul>
                            <li>
                                Changed "Last Status Change" column to include time of day
                            </li>
                        </ul>
                    </li>

                    <li>
                        Operations Dropdown
                        <ul>
                            <li>
                                Moved Product Categories and Product Sizes out of the admin menu and into this menu;
                                It can only be accessed by Executive Management roles and above
                            </li>
                        </ul>
                    </li>

                    <li>
                        PCR Links
                        <ul>
                            <li>Added Sales Managers to the PCR links and fixed issues with the page not showing current
                                links.
                            </li>
                        </ul>
                    </li>
                </ul>
                <MDBRow>
                    <MDBCol>

                    </MDBCol>
                </MDBRow>
                <hr/>
            </div>
        )


    }

    render() {

        return (
            <div>
                {this.renderVersion472()}
                {this.renderVersion471()}
                {this.renderVersion470()}
                {this.renderVersion460()}
                {this.renderVersion450()}
                {this.renderVersion440()}
                {this.renderVersion4310()}
                {this.renderVersion4117()}
                {this.renderVersion410()}
                {this.renderVersion400()}
                {this.renderVersion340()}
                {this.renderVersion304()}
                {this.renderVersion256()}
                {this.renderVersion255()}
                {this.renderVersion254()}
                {this.renderVersion253()}
                {this.renderVersion252()}
                {this.renderVersion251()}
                {this.renderVersion250()}
                {this.renderVersion249()}
                {this.renderVersion248()}
                {this.renderVersion247()}
                {this.renderVersion246()}
                {this.renderVersion245()}
                {this.renderVersion244()}
            </div>
        );
    }
}
