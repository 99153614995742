import api from "../api";

class ExpenseService {
	GetFormattedDate(date) {
		if (date == null || date === "") {
			return "";
		}

		let month = date.getMonth() + 1,
			day = date.getDate(),
			year = date.getFullYear();

		return month + "/" + day + "/" + year;
	}

	async getAllExpenses(filters) {
		return new Promise((res, reject) => {
			let f = filters || {};

			let params = {
				page: f.page || 0,
				pageSize: f.pageSize || 10,
				start: f.start || "",
				end: f.end || "",
				ids: f.ids || [],
				userId: f.userId,
			};
			api
				.get("/expenses", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	//exec spExpenseExport '1/1/2019', '3/7/2019','2,3,5'
	// calls proc spExpenseReport; not sure what previous comment is indicating
	async getExpensesForCSV(filters) {
		return new Promise((res, reject) => {
			api
				.get("/expenseListingCsv", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getExpense(id) {
		return new Promise((res, reject) => {
			api
				.get("/expenses/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async updateExpense(expense) {
		return new Promise((res, reject) => {
			api
				.put("/expenses", expense)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createExpense(expense) {
		return api.post("/expenses", expense);
	}
}

const expenseService = new ExpenseService();

export default expenseService;
