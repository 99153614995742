import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import ReactDOMServer from 'react-dom/server';
import {
    Button,
    CardHeader,
    Card,
    CardBody,
    Col,
    Container,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Fa,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    Nav,
    NavLink,
    NavItem,
    Row,
    Spinner,
    TabContent,
    TabPane,
    ToastContainer,
    toast,
    MDBModal,
    MDBContainer,
    MDBSelect,
    MDBCol,
    MDBRow,
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBIcon,
    MDBBtn,
    MDBInput,
    MDBModalHeader
} from "mdbreact";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import classnames from "classnames";
import Select from "react-select";
import moment from "moment";
import OrderService from "../Security/OrderService/orderService";
import UserService from "../Security/UserService/userService";
import AccountService from "../Security/AccountService/accountService";
import TrainingLinkService from "../Settings/trainingLinkService";
import "./patientView.css";
import {
    DragDropProvider,
    Grid,
    GroupingPanel,
    Table,
    TableColumnResizing,
    TableGroupRow,
    TableHeaderRow,
    Toolbar,
    TableColumnVisibility,
    PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import {
    GroupingState,
    IntegratedGrouping,
    DataTypeProvider,
    PagingState,
    IntegratedPaging,
} from "@devexpress/dx-react-grid";
import ComplaintService from "../Security/ComplaintService/ComplaintService";
import PatientOrders from "../Security/PatientService/patientOrdersService";
import PatientNoteService from "../Security/PatientNoteService/PatientNoteService";
import ReactTooltip from "react-tooltip";
import googleService from "../Security/GoogleService/GoogleService";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ServiceOrderReasonService from "../Security/ServiceOrderReasonService/ServiceOrderReasonService";
import PatientTrainingLinkService from "../Settings/patientTrainingLinkService";
import patientAppService from "../Security/PatientService/PatientAppService";
import patientPhoneService from "../Security/PatientService/PatientPhoneService";
import GlobalFunctions from "../Filters/GlobalFunctions";

//const apiKey = 'eDF3LRILoAxo10s3TpIsSQcv034AyeVRrBYr8P4G8iM~'; // thinkgeo
const apiKey = "AIzaSyAM9_trzKLE1Y2J7eWPSjl3i5jrtgBHe9s"; // google

const DateTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={DateFormatter} {...props} />
);

const DateFormatter = ({ value }) => {
    if (value != null) {
        return new Date(value).toDateString();
    } else {
        return "No Date";
    }
};

export default class PatientView extends React.Component {
    constructor(props) {
        super(props);

        let geoCodeClient = {};

        if (window.tg) {
            try {
                geoCodeClient = new window.tg.GeocodingClient(apiKey);
            } catch (e) {
                console.log(e);
            }
        }

        const groupColumns = [{ columnName: "status" }];

        const tOptions = [
            {
                text:
                    "Setup Issue – patient was not setup correctly on initial delivery",
                value:
                    "Setup Issue – patient was not setup correctly on initial delivery",
            },
            {
                text: "Misdiagnosed – last part recommendation was incorrect",
                value: "Misdiagnosed – last part recommendation was incorrect",
            },
            {
                text:
                    "Manufacturer Error – vendor sent wrong parts or suggested wrong parts",
                value:
                    "Manufacturer Error – vendor sent wrong parts or suggested wrong parts",
            },
            {
                text: "Equipment Fit – ATP recommendations were not correct initially",
                value: "Equipment Fit – ATP recommendations were not correct initially",
            },
            {
                text:
                    "Equipment Abuse – patient is using equipment incorrectly or abusing equipment",
                value:
                    "Equipment Abuse – patient is using equipment incorrectly or abusing equipment",
            },
            {
                text:
                    "Quality Issue – equipment is a lemon or has repeat issues on same parts",
                value:
                    "Quality Issue – equipment is a lemon or has repeat issues on same parts",
            },
        ];

        const data = {
            columns: [
                {
                    title: "Type",
                    name: "orderType",
                },
                {
                    title: "Order Id",
                    name: "orderId",
                },
                {
                    title: "Status",
                    name: "status",
                },
                {
                    title: "Reason",
                    name: "reason",
                },
                {
                    title: "Equipment",
                    name: "equipment",
                },
                {
                    title: "Change Date",
                    name: "lastUpdatedOn",
                },
                {
                    title: "Last Note",
                    name: "lastNoteDate",
                },
                {
                    title: "Created",
                    name: "when",
                },
                {
                    title: "Setup Date",
                    name: "setupDate",
                },
            ],
            rows: [],
            dateColumns: ["when", "lastUpdatedOn", "lastNoteDate", "setupDate"],
            defaultColumnWidths: [
                { columnName: "orderType", width: 80 },
                { columnName: "orderId", width: 80 },
                { columnName: "status", width: 80 },
                { columnName: "reason", width: 80 },
                { columnName: "equipment", width: 200 },
                { columnName: "lastUpdatedOn", width: 150 },
                { columnName: "lastNoteDate", width: 150 },
                { columnName: "when", width: 150 },
                { columnName: "setupDate", width: 150 },
            ],
        };

        const phoneData = {
            columns: [
                {
                    title: "Patient ID",
                    name: "patientId",
                },
                {
                    title: "ID",
                    name: "id",
                },
                {
                    title: "Contact Name",
                    name: "contactName",
                },
                {
                    title: "Phone Number",
                    name: "phoneNumber",
                },
                {
                    title: "Phone Type",
                    name: "phoneType",
                },
                {
                    title: "Delete",
                    name: "delete",
                },
            ],
            defaultHiddenColumnNames: ["patientId"],
            rows: [],
        };
        //get all results to save
        let results = props.location.results;

        let id = props.match.params.id;
        // if (props.location.state != null && props.location.state.patient != null) {
        // 	patient = props.location.state.patient;
        // 	// this.getAccountOwner(patient.account.id);
        // 	isLoaded = true;
        // } else {
        // this.retrievePatient(id);
        // }

        this.state = {
            id: id,
            notes: [],
            myVideos: [],
            allVideos: [],
            vidsList: [],
            chosenVideo: {},
            videosLoaded: false,
            tasks: [],
            accountOwner: {},
            patient: {},
            ePatient: {},
            isLoaded: false,
            patientOrders: [],
            orderIdList: [],
            selectedPreviousOrder: {},
            serviceOrderReasons: tOptions,
            orderModalOpen: false,
            createComplaintModal: false,
            createPatientNoteModal: false,
            gridData: data,
            groupColumns: groupColumns,
            patientProducts: [],
            patientProductsLoaded: false,
            productsAddedList: [],
            nOrder: {
                type: "Order",
                quality: false,
                hasInfectiousDisease: false,
                contacts: [],
                priority: 0,
                startedAt: new Date(),
                lastStatusChange: new Date(),
                account: {},
                approvals: [{}],
                insuranceType: null,
                secondaryInsuranceType: null,
                products: [],
                orderType: "New",
                status: 0,
            },
            nComplaint: {
                issueCategory: {},
                issueReason: {},
            },
            complaints: [],
            complaintNotes: {},
            currentComplaint: {},
            complaintNoteModal: false,
            complaintNoteText: "",
            closeComplaintModal: false,
            selectedOrderTypeRadio: 0,
            serviceLocation: null,
            salesLocation: null,
            orderTypes: [
                { id: 0, name: "New" },
                { id: 1, name: "Pickup" },
                { id: 2, name: "Service" },
                { id: 3, name: "Exchange" },
                { id: 4, name: "Internal Request" },
                { id: 5, name: "Re-Supply" },
            ],
            statuses: [
                "New",
                "In Progress",
                "Ready to Deliver",
                "Setup",
                "Cancelled",
            ],
            noteText: "",
            results: results,
            geoClient: geoCodeClient,
            searchResults: [],
            currOpenNote: {},
            ptnNoteModal: false,

            appApproval: null,
            isChangingPassword: false,
            newPassword: null,

            //preferredContactMethod: null,
            preferredContactMethods: [
                { value: "SMS", label: "SMS" },
                { value: "Call", label: "Call" },
                { value: "Email", label: "Email" },
            ],
            phoneTypes: [
                { value: "Cell", label: "Cell" },
                { value: "Home", label: "Home" },
                { value: "EmergencyContact", label: "Emergency Contact" },
                { value: "Other", label: "Other" },
            ],
            newPhoneNumber: {
                patientId: null,
                contactName: null,
                phoneType: null,
                phoneNumber: null,
            },
            patientPhoneNumbers: [], // list of new or modified
            //allPhoneNumbers: [], // all numbers, new/mod get added as placeholders
            deletedNumbers: [],
            addPhoneModalOpen: false,
            phoneData: phoneData,

            allUsers: [],
            allLocations: [],
            allSalesReps: [],
            insuranceTypes: [],
            insuranceSubTypes: [],
            complaintCategories: [],
            complaintReasons: [],
            isPediatric: false,

            patientEditTemplate: {
                id: id,
                type: "patient",
                item: {}
            },
            disableCreate: false,
            popUpComplaints: false,
            isCompliment: false,
            options: [],
            filteredOpts: [],
            complimentedUser: null,
            csUsers: [],
            filteredCSUsers: [],
            searchQ: "",
            searchText: "",
        };

        this.state.TabsOneActiveItem = "3";

        this.retrievePatient(id);
        //this.getPatientOrders(id);
        // this.getPatientComplaints(id);
        this.getPatientNotes(id);
        this.getPatientVideos(id);
        //this.getAppApproval();
        //this.getPatientPhones(id);
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        companyName: PropTypes.string,
        salesReps: PropTypes.array,
        insuranceTypes: PropTypes.array,
        insuranceSubTypes: PropTypes.array,
        internalLocations: PropTypes.array,
        complaintCategories: PropTypes.array,
        complaintReasons: PropTypes.array,
        productTypes: PropTypes.array,
        productSubTypes: PropTypes.array,
        productOverrides: PropTypes.array,
        stateSalesTax: PropTypes.array,
        allUsers: PropTypes.array,
    };

    componentDidMount() {
        this.getLocations();
        this.getReps();
        this.getInsuranceTypes();
        this.getInsuranceSubTypes();
        this.getComplaintCategories();
        this.getComplaintReasons();
        this.getPatientProducts();
        this.getAllUsers();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { allUsers } = this.context;
        if (
            allUsers.length !== this.state.allUsers.length
        ) {
            this.getAllUsers();
        }
    }

    getLocations() {
        const { internalLocations } = this.context;
        this.setState({
            allLocations: internalLocations
        });
    }

    getReps() {
        const { salesReps } = this.context;
        this.setState({
            allSalesReps: salesReps,
        });
    }

    getStateList() {
        const { stateSalesTax } = this.context;
        let lst = [];
        stateSalesTax.forEach(x => {
            lst.push({
                label: x.name + " - " + x.abbr,
                value: x.abbr,
            })
        })
        return lst;
    }

    getInsuranceTypes() {
        const { insuranceTypes } = this.context;
        this.setState({
            insuranceTypes: insuranceTypes,
        });
    }

    getInsuranceSubTypes() {
        const { insuranceSubTypes } = this.context;
        this.setState({
            insuranceSubTypes: insuranceSubTypes,
        });
    }

    getComplaintCategories() {
        const { complaintCategories } = this.context;
        this.setState({
            complaintCategories: complaintCategories,
        });

    }

    getComplaintReasons() {
        const { complaintReasons } = this.context;
        this.setState({
            complaintReasons: complaintReasons,
        });
    }

    toggle = (tabset, tab) => {
        if (this.state.TabsOneActiveItem !== tab) {
            this.setState({
                TabsOneActiveItem: tab,
            });
        }
    };

    toggleOrderModal() {
        let p = this.state.patient;

        if (!p.address || !p.city || !p.state || !p.zipCode) {
            toast.error(
                "Patient must have address information set before adding an Order"
            );
        } else if (!p.insuranceType) {
            toast.error(
                "Patient must have a Payor Source set before adding an Order"
            );
        } else {
            let currentState = this.state.orderModalOpen;
            this.setState({ orderModalOpen: !currentState });
        }
    }

    toggleNewPhoneModal() {
        let isOpen = this.state.addPhoneModalOpen;

        this.setState({ addPhoneModalOpen: !isOpen });
    }

    addressTextChanged(e) {
        let val = e.target.value;
        const nPatient = this.state.ePatient,
            tplt = this.state.patientEditTemplate;
        nPatient["address"] = val;
        tplt.item["address"] = val;
        this.setState({ ePatient: nPatient, patientEditTemplate: tplt });
        let geocoderResultNode = document.getElementById("geocoderResult");
        if (val) {
            //this.geocoder(val);
            this.geoGoogle(val);
        } else {
            this.setState({ searchResults: [] });
            if (geocoderResultNode) {
                geocoderResultNode.innerHTML = "";
            }
        }
    }

    validateComplaint() {
        let comp = this.state.nComplaint;
        if (comp.issueCategory.name == null) {
            toast.warn("You must add a feedback category");
            return false;
        }

        if (comp.issueReason.name == null) {
            toast.warn("You must add a feedback reason");
            return false;
        }

        if (this.state.isCompliment === true && comp.complimentedUserId == null) {
            toast.warn("You must add complimented employee");
            return false
        }

        if (comp.orderId == null) {
            toast.warn("You must select an order");
            return false;
        }

        if (comp.complaintDescription == null || comp.complaintDescription === "") {
            toast.warn("You must add a feedback description");
            return false;
        }

        return true;
    }

    addPatientComplaint() {
        const { currentUser } = this.context;

        let shouldContinue = this.validateComplaint();

        if (!shouldContinue) {
            return;
        }

        let p = this.state.patient,
            comp = this.state.nComplaint;

        // comp.name = p.firstName + ' ' + p.lastName;
        comp.patientId = p.id;
        comp.issueLoggedBy = { firstname: currentUser.firstname, lastname: currentUser.lastname, id: currentUser.id };
        comp.issueLoggedById = currentUser.id;
        comp.issueLoggedOn = new Date().toISOString();
        comp.issueStatus = 0;
        comp.issueCategoryId = comp.issueCategory.id;
        comp.issueReasonId = comp.issueReason.id;

        return ComplaintService.createPatientIssue(comp)
            .then((res) => {
                toast.success("Feedback added successfully");

                let c = this.state.complaints;
                c.push(res);

                this.setState({
                    createComplaintModal: false,
                    complaints: c,
                    nComplaint: {
                        issueCategory: {},
                        issueReason: {},
                    },
                });
            })
            .catch((e) => {
                console.log(e)
            });
    }

    calculateAutofocus = (a) => {
        if (this.state["formActivePanel" + a + "Changed"]) {
            return true;
        }
    };

    getAccountOwner(accountId) {
        return AccountService.getAccount(accountId)
            .then((account) => {
                return UserService.getUser(account.owner.id)
                    .then((user) => {
                        this.setState({
                            accountOwner: user,
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    sortIssues(issues) {
        let sorted = issues.sort((a, b) => {
            let da = new Date(a.issueLoggedOn).getTime(),
                db = new Date(b.issueLoggedOn).getTime();

            return da > db ? -1 : 1
        });
        return sorted;
    }

    retrievePatient(id) {
        let statuses = ["New", "In Progress", "Ready To Deliver", "Setup", "Cancelled"];
        return OrderService.getPatientById(id)
            .then((data) => {
                data.patientIssue = this.sortIssues(data.patientIssue);
                let nOrder = this.state.nOrder;
                nOrder.account = data.account;
                let gridData = this.state.gridData,
                    o = [],
                    orders = [],
                    complaintNotes = this.state.complaintNotes;

                //data.orderActivity.forEach(d => {
                //    //if (d.status < 4) {
                //    o.push({
                //        status: statuses[d.status],
                //        orderType: d.orderType,
                //        orderId: d.id,
                //        reason: d.reason,
                //        equipment: d.equipment,
                //        lastUpdatedOn: d.changeDate,
                //        lastNoteDate: d.lastNoteDate,
                //        when: d.createDate,
                //        setupDate: d.setupDate,
                //        id: d.id,
                //        full: d,
                //    });
                //    let l = d.id
                //        .toString()
                //        .concat(d.orderType ? " - " + d.orderType : " - Blank Type")
                //        .concat(d.status ? " - " + d.status : " - Blank Status");
                //    orders.push({ label: l, value: d.id });
                //    //}
                //});
                //gridData.rows = o;

                //this.createOrderList(data.orderActivity);

                data.patientIssue.forEach(p => {
                    complaintNotes["notes" + p.id] = p.issueNotes;
                })

                this.determineLegalComplaints(data.patientIssue);

                this.setState({
                    patient: data,
                    accountOwner: data.account.owner,
                    ePatient: JSON.parse(JSON.stringify(data)),
                    isLoaded: true,
                    //gridData: gridData,
                    //patientOrders: orders,
                    complaints: data.patientIssue,
                    complaintNotes: complaintNotes,
                });
            });
    }

    //getPatientOrders(id) {
    //    let statuses = ["New", "In Progress", "Ready To Deliver", "Setup", "Cancelled"];
    //    OrderService.getPatientOrdersById(id)
    //        .then(res => {
    //            console.log(res)
    //            let gridData = this.state.gridData,
    //                o = [],
    //                orders = [];

    //            res.forEach(d => {
    //                //if (d.status < 4) {
    //                    o.push({
    //                        status: statuses[d.status],
    //                        orderType: d.orderType,
    //                        orderId: d.id,
    //                        reason: d.reason,
    //                        equipment: d.equipment,
    //                        lastUpdatedOn: d.changeDate,
    //                        lastNoteDate: d.lastNoteDate,
    //                        when: d.createDate,
    //                        setupDate: d.setupDate,
    //                        id: d.id,
    //                        full: d,
    //                    });
    //                    let l = d.id
    //                        .toString()
    //                        .concat(d.orderType ? " - " + d.orderType : " - Blank Type")
    //                        .concat(d.status ? " - " + d.status : " - Blank Status");
    //                    orders.push({ label: l, value: d.id });
    //                //}
    //            });
    //            gridData.rows = o;

    //            this.createOrderList(res);

    //            this.setState({
    //                gridData: gridData,
    //                patientOrders: orders,
    //            });
    //        })
    //        .catch(err => {
    //            console.log(err)
    //        })
    //}

    getAppApproval() {
        let id = this.state.id;

        patientAppService
            .getPatientApprovals(id)
            .then((res) => {
                this.setState({ appApproval: res });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getAllUsers() {
        const { allUsers } = this.context;
        let o = [];
        let cso = [];

        allUsers.map((u) => {
            return o.push({
                name: u.firstname + " " + u.lastname,
                id: u.id,
            })
        })

        let filterCSUsers = allUsers.filter((u) => ["CARE_COORDINATOR", "CUSTOMER_EXPERIENCE", "EXECUTIVE_MANAGEMENT"].includes(u.role));

        filterCSUsers.map((u) => {
            return cso.push({
                name: u.firstname + " " + u.lastname,
                id: u.id,
            })
        })
        this.setState({
            allUsers: allUsers,
            options: o,
            filteredOpts: o,
            csUsers: cso,
            filteredCSUsers: cso
        })
    }

    getPatientPhones(id) {
        patientPhoneService.getPatientPhones(id).then((res) => {
            let rows = [],
                data = this.state.phoneData;
            res.forEach((val, idx) => {
                var row = {
                    id: val.id,
                    patientId: val.patientId,
                    contactName: val.contactName,
                    phoneType: val.phoneType,
                    phoneNumber: val.phoneNumber,
                };
                row.delete = this.renderDeletePhoneButton(row);
                rows.push(row);
            });
            data.rows = rows;
            this.setState({ phoneData: data });
        });
    }

    createOrderList(orders) {
        let l = [];

        orders.forEach((o) => {
            l.push({
                text:
                    o.id +
                    " -- " +
                    o.orderType +
                    " -- " +
                    (o.setupDate
                        ? "Setup: " + new Date(o.setupDate).toLocaleDateString()
                        : ""),
                value: o,
            });
        });

        this.setState({
            orderIdList: l,
        });
    }

    determineLegalComplaints(complaints) {
        let legalComp = complaints.filter(({ issueReason, issueStatus }) => {
            return issueStatus === 0 && issueReason && issueReason.name === "Legal"
        }
        );

        let nonLegalComp = complaints.filter(({ issueReason, issueStatus }) => {
            return issueStatus === 0 && issueReason && issueReason.name !== "Legal"
        }
        );

        if (nonLegalComp.length > 0 && legalComp.length === 0) {
            this.setState({
                popUpComplaintModal: true,
                popupComplaints: nonLegalComp,
            })
        } else if (legalComp.length > 0) {
            this.setState({
                popUpComplaintModal: true,
                popupComplaints: legalComp,
            })
        } else {
            return false
        }

        // if (complaints.find(({issueReason}) => issueReason.name =="Legal")){
        // 	let z = complaints.filter(({issueReason}) => issueReason.name =="Legal");
        // 	if (z.find(({issueClosedOn}) => issueClosedOn === null)){
        // 		this.setState({
        // 			popUpComplaintModal: true,
        // 		})
        // 	}}
    }

    getPatientNotes(id) {
        return PatientNoteService.getNotesByPatient(id)
            .then((res) => {
                this.setState({
                    notes: res,
                });
            })
            .catch((e) => {
                toast.error(e);
            });
    }

    getAllVideos() {
        return TrainingLinkService.getAllTrainingLinks().then((r) => {
            let obj = [];

            r.forEach((v) => {
                obj.push({ text: v.name + ":   " + v.description, value: v });
            });

            this.setState({
                allVideos: r,
                vidsList: obj,
                videosLoaded: true,
            });
        });
    }

    getPatientVideos(id) {
        return PatientTrainingLinkService.getVideosByPatient(id).then((r) => {
            this.setState({
                myVideos: r,
            });
        });
    }

    createComplaintNote() {
        const { currentUser } = this.context;

        let issueId = this.state.currentComplaint.id,
            text = this.state.complaintNoteText,
            obj = {};

        if (text === "") {
            toast.warn("You must enter note text");
            return;
        }

        obj.patientIssueId = issueId;
        obj.noteText = text;
        obj.noteAddedOn = new Date().toISOString();
        obj.noteAddedBy = currentUser.username;

        ComplaintService.createPatientIssueNote(obj)
            .then((res) => {
                toast.success("Note created successfully");
                let notes = this.state.complaintNotes;

                notes["notes" + issueId].push(res);

                this.setState({
                    complaintNoteModal: false,
                    complaintNotes: notes,
                    complaintNoteText: "",
                });

                //if (close) {
                //    this.closeComplaint();
                //}
            })
            .catch((e) => {
                toast.error(e);
            });
    }

    createPatientNote() {
        const { currentUser } = this.context;

        let notes = this.state.notes,
            note = {
                patientId: parseInt(this.state.id),
                note: this.state.noteText,
                noteAddedBy: currentUser.username,
                noteAddedOn: new Date(),
            };

        PatientNoteService.createPatientNote(note)
            .then((res) => {
                notes.reverse();
                notes.push(res);
                notes.reverse();
                this.setState({
                    notes: notes,
                    createPatientNoteModal: false,
                });
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    }

    closeComplaint() {
        const { currentUser } = this.context;

        let c = this.state.currentComplaint,
            idx = this.state.complaints.findIndex((v) => {
                return v.id === c.id;
            });

        c.issueClosedBy = currentUser;
        c.issueClosedById = currentUser.id;
        c.issueClosedOn = new Date().toISOString();
        c.issueStatus = 1;
        console.log(c)
        return ComplaintService.updatePatientIssue(c)
            .then((res) => {
                console.log(res)
                toast.success("Customer feedback closed successfully");

                let com = this.state.complaints;
                res.issueClosedBy = currentUser;
                com.splice(idx, 1, res);

                this.setState({
                    closeComplaintModal: false,
                    complaints: com,
                });
            })
            .catch((e) => {
                console.log(e)
                toast.error(e);
            });
    }

    getOrderType = (orderType) => {
        const nOrder = this.state.nOrder;
        nOrder.orderType = orderType.name;

        this.setState({
            nOrder: nOrder,
            selectedOrderTypeRadio: orderType.id,
        });
    };

    handleChange(property, event) {
        //update the property that the user has edited in the order...BC
        let nPatient = this.state.ePatient;
        if (property === "height" || property === "weight" || property === "btPId") {
            nPatient[property] = event.target.value ? parseInt(event.target.value) : null;
        }
        else if (property === "state" || property === "ciState") {
            nPatient[property] = event.value;
        }
        else {
            nPatient[property] = event.target.value;
        }
        this.setState({ ePatient: nPatient });
    }

    geocoder(val) {
        let geocodingClient = this.state.geoClient;
        let opts = {
            MaxResults: 5,
            LocationType: "Street,Address",
            Countries: "us",
            AutoComplete: true,
            VerboseResults: true,
        };
        const callback = (status, res) => {
            if (status !== 200) {
                alert(res.error);
            } else {
                let locations = res.data.locations;
                //this.renderSearchResult(locations);
                this.setState({ searchResults: locations });
            }
        };

        if (geocodingClient && geocodingClient.searchByPoint) {
            // Call the searchByPoint API to search the points by the input address.
            geocodingClient.searchByPoint(val, callback, opts);
        }
    }

    geoGoogle(val) {
        if (val) {
            googleService.searchAddress(val).then((res) => {
                this.setState({ searchResults: res.results });
            });
        }
    }

    renderSearchResult() {
        let results = this.state.searchResults;
        if (results.length > 0) {
            return (
                <div className="resultWrap">
                    <div style={{ width: "100%" }}>
                        <button
                            id="popup-closer"
                            className="ol-popup-closer"
                            onClick={this.clearSearch.bind(this)}
                        />
                    </div>
                    <br />
                    <ul id="geocoderResult">
                        {results.map((item) => {
                            return (
                                <li key={results.indexOf(item)}>
                                    <button
                                        style={{
                                            border: "0",
                                            backgroundColor: "transparent",
                                            color: "#3887BE",
                                        }}
                                        onClick={this.setAddress.bind(this, item)}
                                    >
                                        {" "}
                                        {item.address}{" "}
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                    <div className="loading hidden">
                        <img
                            src="https://samples.thinkgeo.com/cloud/example/image/Spinner-1s-50px.gif"
                            alt="loading"
                        />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    renderGoogleSearchResult() {
        let results = this.state.searchResults;
        if (results.length > 0) {
            return (
                <div className="resultWrap">
                    <div style={{ width: "100%" }}>
                        <button
                            id="popup-closer"
                            className="ol-popup-closer"
                            onClick={this.clearSearch.bind(this)}
                        />
                    </div>
                    <br />
                    <ul id="geocoderResult">
                        {results.map((item, index) => {
                            if (
                                index < 4 &&
                                item.formatted_address &&
                                item.formatted_address.length > 0
                            ) {
                                return (
                                    <li key={results.indexOf(item)}>
                                        <button
                                            style={{
                                                border: "0",
                                                backgroundColor: "transparent",
                                                color: "#3887BE",
                                            }}
                                            onClick={this.setGoogleAddress.bind(this, item)}
                                        >
                                            {" "}
                                            {item.formatted_address}{" "}
                                        </button>
                                    </li>
                                );
                            }
                            return null;
                        })}
                    </ul>
                    <div className="loading hidden">
                        <img
                            src="https://samples.thinkgeo.com/cloud/example/image/Spinner-1s-50px.gif"
                            alt="loading"
                        />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    clearSearch() {
        this.setState({ searchResults: [] });
    }

    setAddress = (location) => {
        const nPatient = this.state.ePatient;
        let addr = location.address.split(",");

        let street = addr[0],
            city = addr[1],
            county = addr[2],
            state = addr[3],
            zip = addr[4];

        nPatient.address = street ? street.trim() : "";
        nPatient.city =
            city && city.toLowerCase().indexOf("county") < 0 ? city.trim() : "";
        nPatient.state =
            state && city.toLowerCase().indexOf("county") < 0
                ? state.trim()
                : county
                    ? county.trim()
                    : "";
        nPatient.zipCode =
            zip && parseInt(zip) ? zip.trim() : parseInt(state) ? state : "";
        nPatient.latitude = location.locationPoint.x;
        nPatient.longitude = location.locationPoint.y;
        this.setState({ ePatient: nPatient, searchResults: [] });
    };

    setGoogleAddress = (location) => {
        const nPatient = this.state.ePatient;

        let addr = location.address_components;
        let numComp = "",
            streetComp = "",
            city = "",
            state = "",
            zip = "";
        addr.forEach((item) => {
            if (item.types.includes("street_number")) {
                numComp = item.long_name;
            } else if (item.types.includes("route")) {
                streetComp = item.long_name;
            } else if (
                item.types.includes("neighborhood") ||
                item.types.includes("locality")
            ) {
                city = item.long_name;
            } else if (item.types.includes("administrative_area_level_1")) {
                state = item.long_name;
            } else if (item.types.includes("postal_code")) {
                zip = item.long_name;
            }
        });

        nPatient.address = numComp + " " + streetComp;
        nPatient.city = city;
        nPatient.state = state;
        nPatient.zipCode = zip;
        nPatient.latitude = location.geometry.location.lat || null;
        nPatient.longitude = location.geometry.location.lng || null;
        this.setState({ ePatient: nPatient, searchResults: [] });
    };

    handleDatePickerChange = (property, value) => {
        //update the date property on the object that the user has edited in the order...BC
        const nPatient = this.state.ePatient;
        value = moment(value).format("MM/DD/YYYY");
        nPatient[property] = value;
        this.setState({ ePatient: nPatient });
    };

    handleCheckboxChange = (property, event) => {
        //update the property that the user has edited in the order...BC
        const nPatient = this.state.ePatient;
        nPatient[property] = event.target.checked;
        this.setState({ ePatient: nPatient });
    };

    handleComplaintCheckboxChange = (property, event) => {
        const complaint = this.state.nComplaint;
        complaint[property] = event.target.checked;
        this.setState({
            nComplaint: complaint
        })
    }

    handleNoteChange(ev) {
        this.setState({ noteText: ev.target.value });
    }

    completedChange = (event) => {
        this.setState({ completed: event.target.checked });
    };

    changeInsuranceType(type, secondary) {
        let ePatient = this.state.ePatient,
            tplt = this.state.patientEditTemplate;
        if (secondary === true) {
            ePatient.secondaryInsuranceType = type;
            ePatient.secondaryInsuranceTypeId = type.id;
            ePatient.secondaryInsuranceSubType = null;
            ePatient.secondaryInsuranceSubTypeId = null;
            tplt.item.secondaryInsuranceTypeId = type.id;
            tplt.item.secondaryInsuranceSubTypeId = null;
        } else {
            ePatient.insuranceType = type;
            ePatient.insuranceTypeId = type.id;
            ePatient.insuranceSubType = null;
            ePatient.insuranceSubTypeId = null;
            tplt.item.insuranceTypeId = type.id;
            tplt.item.insuranceSubTypeId = null;
        }
        this.setState({ ePatient: ePatient, patientEditTemplate: tplt });
    }

    changeInsuranceSubType(type, secondary) {
        if (type != null) {
            delete type.deleted;
            delete type.deletedAt;
            delete type.deletedById;
        }
        let ePatient = this.state.ePatient,
            tplt = this.state.patientEditTemplate;
        if (secondary === true) {
            ePatient.secondaryInsuranceSubType = type;
            ePatient.secondaryInsuranceSubTypeId = type.id;
            tplt.item.secondaryInsuranceSubTypeId = type.id;
        } else {
            ePatient.insuranceSubType = type;
            ePatient.insuranceSubTypeId = type.id;
            tplt.item.insuranceSubTypeId = type.id;
        }
        this.setState({ ePatient: ePatient });
    }

    changeContactRelationship(rel) {
        let ePatient = this.state.ePatient;

        ePatient.emergencyRelationship = rel;

        this.setState({ ePatient: ePatient });
    }

    changeContactMethod(rel) {
        let ePatient = this.state.ePatient;

        ePatient.preferredContactMethod = rel;

        this.setState({ ePatient: ePatient });
    }

    savePatient() {
        let t = this,
            st = this.state,
            ph = st.patientPhoneNumbers;

        if (st.ePatient.mbi && st.ePatient.mbi.length !== 11) {
            toast.error("Please enter a valid MBI.");
            return;
        }

        const regex = /((|\()\d{3}(|\))-\d{3}-\d{4})/g;

        if (st.patient.dateOfBirth !== st.ePatient.dateOfBirth) {
            st.ePatient.dateOfBirth = moment(st.ePatient.dateOfBirth).format(
                "MM/DD/YYYY"
            );
        }

        if (st.ePatient.phone && !regex.test(st.ePatient.phone)) {
            toast.warning(
                "Invalid phone number.  Try re-entering phone number and saving again."
            );
        }

        let phs = ph.filter((x) => x.id === null);
        if (phs && phs.length > 0) {
            patientPhoneService
                .addPatientPhones(st.ePatient.id, phs)
                .then((res) => {
                    this.getPatientPhones(this.state.id);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        return OrderService.updatePatient(t.state.ePatient)
            .then((data) => {
                this.setState({
                    patient: data,
                    ePatient: JSON.parse(JSON.stringify(data)),
                });

                toast.success("Saved Successfully!");
            })
            .catch((err) => {
                toast.error("Oh no! An error occurred while saving.");
            });
    }

    createOrder(history) {
        if (this.state.disableCreate) {
            return
        }
        this.setState({
            disableCreate: true
        })
        const { currentUser } = this.context;
        let st = this.state;
        let order = st.nOrder,
            patient = st.ePatient,
            owner = st.accountOwner,
            productsAdded = st.productsAddedList;

        let activity = {
            type: "Order",
            accountId: patient.accountId,
            //ownerId: patient.account.ownerId,
            startedAt: new Date().toISOString(),
            endedAt: new Date().toISOString(),
            quality: false,
            modified: new Date().toISOString(),
            priority: 0,
            isExpense: false,
        };
        console.log(order)
        console.log(productsAdded)
        //default sales and service location to owner of account...BC
        if (owner.userprofileLocations && owner.userprofileLocations.length > 0) {
            //order.serviceLocation = {
            //             id: owner.userprofileLocations[0].locations.id,
            //             name: owner.owneruserprofileLocations[0].locations.name,
            //};
            //         order.salesLocation = {
            //             id: owner.userprofileLocations[0].locations.id,
            //             name: owner.owneruserprofileLocations[0].locations.name,
            //};
            order.serviceLocationId = owner.userprofileLocations[0].locations.id;
            order.salesLocationId = owner.userprofileLocations[0].locations.id;
        }

        //if the type is new, this works just fine...BC
        if (order.orderType === "New") {
            activity.ownerId = owner.id;
        } else {
            //otherwise, the owner is the current logged in user...BC
            activity.ownerId = currentUser.id;
        }

        let er = [];
        //in this case, they must set the service location themselves from the box...BC
        if (this.state.serviceLocation != null) {
            //order.serviceLocation = this.state.serviceLocation;
            order.serviceLocationId = this.state.serviceLocation.id;
        }
        else {
            er.push("You must select a service location.");
        }
        if (this.state.salesLocation != null) {
            //order.salesLocation = this.state.salesLocation;
            order.salesLocationId = this.state.salesLocation.id;
        }
        else {
            er.push("You must select a sales location.");
        }
        if (order.orderType === "Service" && (!productsAdded || productsAdded.length === 0)) {
            er.push("At least one product is required for a Service order. Please select a product and try again.");
        }
        if (er.length > 0) {
            er.forEach(e => toast.error(e));
            this.setState({
                disableCreate: false
            });
            return;
        }

        //region set order properties
        //order.insuranceType = patient.insuranceType;
        //      order.insuranceSubType = patient.insuranceSubType;
        order.insuranceTypeId = patient.insuranceTypeId;
        order.insuranceSubTypeId = patient.insuranceSubTypeId;
        order.insurancePolicy = patient.insurancePolicy;
        order.insuranceGroup = patient.insuranceGroup;

        //order.secondaryInsuranceType = patient.secondaryInsuranceType;
        //order.secondaryInsuranceSubType = patient.secondaryInsuranceSubType;
        order.secondaryInsuranceTypeId = patient.secondaryInsuranceTypeId;
        order.secondaryInsuranceSubTypeId = patient.secondaryInsuranceSubTypeId;
        order.secondaryInsurancePolicy = patient.secondaryInsurancePolicy;
        order.secondaryInsuranceGroup = patient.secondaryInsuranceGroup;

        order.lastUpdatedOn = new Date();
        //order.lastUpdatedBy = { id: currentUser.id, name: currentUser.username };
        order.lastUpdatedById = currentUser.id;
        //order.patient = patient;
        order.patientId = patient.id;
        order.patientName = patient.firstName + " " + patient.lastName;
        order.lastStatusChange = new Date().toISOString();

        order.phone = patient.phone;
        order.address = patient.address;
        order.city = patient.city;
        order.state = patient.state;
        order.zipCode = patient.zipCode;
        order.dateOfBirth = patient.dateOfBirth;
        //endregion

        if (
            (order.orderType === "Pickup" ||
                order.orderType === "Exchange" ||
                order.orderType === "Service") &&
            productsAdded.length > 0
        ) {
            //if (order.orderType === "Service") {
            //    order.status = 1;
            //    //order.orderStatusReasonId = 61;
            //}
            order.orderActivityProducts = [];
            for (let product of productsAdded) {
                order.orderActivityProducts.push({
                    products: {
                        abnRequired: false,
                        amount: 0,
                        approved: false,
                        hcpcs: product.hcpcs || "",
                        points: 0,
                        productSubType: {
                            id: product.productSubType.id,
                            name: product.productSubType.name,
                            hcpcs: product.hcpcs || "",
                            productType: {
                                id: product.productType.id,
                                name: product.productType.name,
                            },
                        },
                        productSubTypeId: product.productSubType.id,
                        productTypeId: product.productType.id,
                        productType: {
                            id: product.productType.id,
                            name: product.productType.name,
                        },
                        qty: 1,
                        serial: product.serial || "",
                    }
                });
            }
        }

        console.log(order)
        console.log(activity)
        let po = this.state.selectedPreviousOrder;
        activity.orderActivity = order;
        return OrderService.createOrder(activity)
            .then((res) => {
                this.setState({
                    disableCreate: false
                })
                this.toggleOrderModal();

                if (po.previousOrderId) {
                    po.currentOrderId = res.id;
                    this.createServiceOrderReasonRecord(po);
                }

                history.push({
                    pathname: "/order/" + res.id,
                    state: { order: res },
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    createPatientPhone() {
        const regex = /((|\()\d{3}(|\))-\d{3}-\d{4})/g;
        let phones = this.state.phoneData,
            pt = this.state.ePatient,
            rows = [],
            allPhones = this.state.patientPhoneNumbers,
            newPhone = this.state.newPhoneNumber,
            valid = true;

        if (!newPhone.contactName) {
            toast.error("You must add a contact name.");
            valid = false;
        }

        if (!newPhone.phoneType) {
            toast.error("You must select a phone type.");
            valid = false;
        }

        if (!newPhone.phoneNumber) {
            toast.error("You must add a phone number.");
            valid = false;
        } else if (!regex.test(newPhone.phoneNumber)) {
            toast.error("You must add a valid phone number.");
            valid = false;
        } else if (valid) {
            phones.rows.forEach((val, idx) => {
                rows.push(val);
            });
            allPhones.push({
                contactName: newPhone.contactName,
                phoneNumber: newPhone.phoneNumber,
                phoneType: newPhone.phoneType,
                patientId: pt.id,
                id: null,
            });
            let nPhone = {
                contactName: newPhone.contactName,
                phoneNumber: newPhone.phoneNumber,
                phoneType: newPhone.phoneType,
                patientId: pt.id,
                id: null,
                delete: null,
            };
            nPhone.delete = this.renderDeletePhoneButton(nPhone);
            rows.push(nPhone);
            phones.rows = rows;
            var p = this.state.newPhoneNumber;
            p.phoneNumber = null;
            p.contactName = null;
            p.phoneType = null;
            this.setState({
                newPhoneNumber: p,
                phoneData: phones,
                patientPhoneNumbers: allPhones,
                addPhoneModalOpen: false,
            });
        }
    }

    createServiceOrderReasonRecord(issue) {
        ServiceOrderReasonService.createServiceOrderReason(issue)
            .then((res) => {
                this.setState({
                    selectedPreviousOrder: {},
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    syncPatientToBT() {
        PatientOrders.syncPatientToBT(this.state.id)
            .then(res => {
                if (res.syncPatient) {
                    toast.success("Patient has been created in BT.");
                    window.location.reload();
                }
                else {
                    toast.warn("There was an issue creating the patient.");
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    renderLoadingSpinner() {
        return (
            <Container>
                <div style={{ textAlign: "center", verticalAlign: "center" }}>
                    <Spinner multicolor />
                </div>
            </Container>
        );
    }

    renderOrderListReturn() {
        return (
            <Route
                render={({ history }) => (
                    <Button
                        color={"yellow"}
                        floating
                        size="sm"
                        data-tip={"Back to patient list"}
                        data-place="bottom"
                        onClick={() => {
                            history.push({
                                pathname: "/patientList/",
                                results: this.state.results,
                            });
                        }}
                    >
                        <Fa size="2x" icon={"backward"} />
                    </Button>
                )}
            />
        );
    }

    renderChangePasswordButton() {
        let approval = this.state.appApproval;
        if (approval && approval.length > 0) {
            return (
                <Button
                    color={"blue"}
                    floating
                    size="sm"
                    data-tip={"Change App Password"}
                    data-place={"Bottom"}
                    onClick={() => {
                        this.setState({ isChangingPassword: true });
                    }}
                >
                    <Fa size="2x" icon={"edit"}>
                        {" "}
                    </Fa>
                </Button>
            );
        } else {
            return "";
        }
    }

    renderTopCard() {
        const { companyName } = this.context;
        let st = this.state,
            ePatient = st.ePatient,
            btPId = ePatient.btPId,
            nameColumnSize = "4";

        if (ePatient && btPId && companyName !== "corkmedical") {
            nameColumnSize = "2";
        }

        function renderBrightreePatientID() {
            if (ePatient && btPId && companyName !== "corkmedical") {
                return (
                    <Col size="2">
                        <h5 style={{ marginTop: 12, fontWeight: 600, color: "#FFFFFF" }}>
                            {"Brightree Patient ID: " + st.patient.btPId}
                        </h5>
                    </Col>
                );
            }
        }


        return (
            <Card>
                <CardHeader color={"blue darken-3"}>
                    <Row>
                        <Col size="1">{this.renderOrderListReturn()}</Col>
                        <Col size={nameColumnSize} >
                            <h4 style={{ marginTop: 10, fontWeight: 600, color: "#FFFFFF" }}>
                                {this.state.patient.firstName +
                                    " " +
                                    this.state.patient.lastName + " " + (this.state.patient.suffix || "")}
                            </h4>
                        </Col>
                        {renderBrightreePatientID()}
                        <Col size="1" style={{ textAlign: "right" }}>
                            {this.renderChangePasswordButton()}

                            {/* <Button
								color={"blue"}
								floating
								size="sm"
								data-tip={"Change App Password"}
								data-place={"bottom"}
								onClick={() => {
									this.setState({ isChangingPassword: true });
								}}
							>
								<Fa size="2x" icon={"edit"}>
									{" "}
								</Fa>
							</Button> */}

                        </Col>
                        <Col size="1" style={{ textAlign: "right" }}>
                            {!btPId && companyName !== "corkmedical" ?
                                <Button
                                    color={"blue"}
                                    floating
                                    size="sm"
                                    data-tip={"Sync Patient to BT"}
                                    data-place={"bottom"}
                                    onClick={() => this.syncPatientToBT()}
                                >
                                    <Fa size="2x" icon={"sync-alt"}>
                                        {" "}
                                    </Fa>
                                </Button>
                                : ''
                            }
                        </Col>
                        <Col size="1" style={{ textAlign: "right" }}>
                            <Button
                                color={"secondary"}
                                floating
                                size="sm"
                                data-place={"bottom"}
                                data-tip={"Add New Order"}
                                onClick={this.toggleOrderModal.bind(this)}
                            >
                                <Fa size="2x" icon={"plus"}>
                                    {" "}
                                </Fa>
                            </Button>
                        </Col>
                        <Col size="1" style={{ textAlign: "right" }}>
                            <Button
                                color={"yellow"}
                                floating
                                size="sm"
                                data-place={"bottom"}
                                data-tip={"Add a Patient Note"}
                                onClick={() => this.setState({ createPatientNoteModal: true })}
                            >
                                <Fa size="2x" icon={"notes-medical"}>
                                    {" "}
                                </Fa>
                            </Button>
                        </Col>
                        <Col size="1" style={{ textAlign: "right" }}>
                            <Button
                                color={"red"}
                                floating
                                size="sm"
                                data-place={"bottom"}
                                data-tip={"Add Patient Feedback"}
                                onClick={() => this.setState({ createComplaintModal: true })}
                            >
                                <Fa size="2x" icon={"comments"}>
                                    {" "}
                                </Fa>
                            </Button>
                        </Col>
                        <Col size="1" style={{ textAlign: "right" }}>
                            <Button
                                floating
                                size="sm"
                                color={"success"}
                                data-place={"bottom"}
                                data-tip={"Update Patient"}
                                onClick={this.savePatient.bind(this)}
                            >
                                <Fa far size="2x" icon={"save"}>
                                    {" "}
                                </Fa>
                            </Button>
                        </Col>
                        <Col size="1" style={{ textAlign: "right" }}>
                            <Button
                                floating
                                size="sm"
                                color={"indigo"}
                                data-tip={"Download Patient Feedback"}
                                data-place={"bottom"}
                                onClick={this.clientCSV.bind(this)}
                            >
                                <MDBIcon icon="download" style={{ fontSize: "2em" }} />
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
            </Card>
        );
    }

    renderOrdersCard() {
        return (
            <Container className="mt-5 orderList" style={{ backgroundColor: "#FFF" }}>
                {this.renderTable()}
            </Container>
        );
    }

    renderComplaintNotes(id) {
        let notes = this.state.complaintNotes["notes" + id];

        if (notes) {
            notes = notes.sort((a, b) => {
                return a.noteAddedOn < b.noteAddedOn ? -1 : 1;
            });

            return notes.map((n, idx) => {
                return (
                    <tr key={idx}>
                        <td>{n.noteAddedOn ? new Date(n.noteAddedOn).toLocaleDateString() : ""}</td>

                        <td>{n.noteAddedBy}</td>

                        <td>{n.noteText}</td>
                    </tr>
                );
            }).reverse();
        }
    }

    canEdit(role) {
        switch (role) {
            case "ADMIN":
            case "SYSTEM_ADMIN":
            case "CUSTOMER_EXPERIENCE":
            case "CARE_COORDINATOR":
            case "EXECUTIVE_MANAGEMENT":
            case "OFFICE_MANAGER":
                return true;
            default:
                return false;
        }
    }

    getNextFollowUpDate() {
        let t = new moment();
        let y = t.weekday();
        if (y < 4) {
            t.add(2, 'days')
        } else {
            t.add(4, 'days');
        }

        return t;
    }

    handleSearch(e) {
        let st = this.state,
            search = st.searchQ,
            csUsers = st.csUsers;

        this.setState({
            searchQ: e.target.value
        })

        let filter = csUsers.filter((f) => f.name.toLowerCase().includes(search.toLowerCase()))

        if (e.target.value !== "") {
            this.setState({
                filteredCSUsers: filter,
            })
        } else {
            this.setState({
                filteredCSUsers: csUsers,
            })
        }
    }

    handleSearchChange(e) {
        let st = this.state,
            opts = st.options,
            search = st.searchText;

        this.setState({
            searchText: e.target.value
        })

        let filter = opts.filter((f) => f.name.toLowerCase().includes(search.toLowerCase()))

        if (e.target.value !== "") {
            this.setState({
                filteredOpts: filter,
            })
        } else {
            this.setState({
                filteredOpts: opts,
            })
        }
    }

    renderPatientComplaintsCard() {
        const { complaintCategories, currentUser, complaintReasons } = this.context;

        let com = this.state.complaints,
            obj = {},
            op = this.state.options,
            csUsers = this.state.csUsers,
            canEdit = this.canEdit(currentUser.role);

        let updateComplaint = (val, item, prop) => {
            item[prop] = val;
            if (prop === "issueCategory") {
                item.issueCategoryId = val.id;
                this.setState({ complaints: com });
            }
            if (prop === "issueReason") {
                item.issueReasonId = val.id;
                this.setState({ complaints: com });
            }
            if (prop === "assignedToId") {
                item.assignedToId = val.id;
                this.setState({
                    complaints: com,
                })
            }
            if (prop === "complimentedUserId") {
                item.complimentedUserId = val.id;
                this.setState({ complaints: com })
            }

            if (prop === "nextFollowupDate") {
                val.set("hour", 12);
                val.set("minute", 0);
                item.nextFollowupDate = GlobalFunctions.getUTCMoment(val);
            }

            return ComplaintService.updatePatientIssue(item)
                .then((res) => {
                    let complaints = com.slice(),
                        comIndex = complaints.findIndex((c) => c.id === item.id),
                        notes = this.state.complaintNotes;

                    complaints[comIndex] = res;
                    let info = val.name ? val.name : GlobalFunctions.formatDate(val);
                    obj.noteText = "Feedback " + prop + " Changed to " + info;
                    obj.patientIssueId = res.id;
                    // obj.noteAddedOn = new Date();
                    obj.noteAddedBy = currentUser.username;

                    this.setState({
                        complaints: complaints,
                    })
                    ComplaintService.createPatientIssueNote(obj)
                        .then((res) => {
                            if (notes["notes" + item.id] === null || notes["notes" + item.id] === undefined) {
                                notes["notes" + item.id] = [];
                            }

                            notes["notes" + item.id].push(res);

                            this.setState({
                                complaints: complaints,
                                complaintNotes: notes,
                            });

                            if (prop === "issueCategory") {
                                let d = document
                                    .getElementsByClassName("issueReasonDropdown")
                                    .item(0);
                                let a = d.getElementsByTagName("a").item(0);
                                a.click();
                            }
                            toast.success("Complaint Updated");
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((e) => {
                    toast.error(e);
                });
        }

        return (
            <Container>
                {com.map((c, idx) => {
                    return (
                        <Card key={idx} style={{ marginBottom: "2rem" }}>
                            <CardHeader style={{ backgroundColor: "#5881C1", color: "white" }}>
                                <Row style={{ paddingBottom: '20px' }}>
                                    <Col size="2">

                                        Feedback ID: {c.id}

                                    </Col>
                                    <Col size="2">

                                        Order ID: {c.orderId}
                                    </Col>
                                </Row>
                                <Row>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <Col size="2" style={{ cursor: "pointer" }}>
                                            <DatePicker
                                                id={"followUpDatePicker"}
                                                style={{ backgroundColor: "white", color: "black" }}
                                                format="MM/DD/YYYY"
                                                size="small"
                                                inputVariant="outlined"
                                                label={"Follow Up Date"}
                                                value={c.nextFollowupDate || null}
                                                onChange={(date) => {
                                                    updateComplaint(
                                                        date,
                                                        c,
                                                        "nextFollowupDate"
                                                    )
                                                    this.setState({
                                                        currentComplaint: c,
                                                        complaintNoteModal: true,
                                                    })
                                                }}
                                            />
                                        </Col>
                                    </MuiPickersUtilsProvider>
                                    <Col size="3" style={{ textAlign: "center" }}>
                                        <Dropdown className={"idDropdown idDropdownSales"}>
                                            <DropdownToggle
                                                disabled={!canEdit}
                                                className={"idDropdownToggle"}
                                                nav
                                            >
                                                <span>
                                                    {" "}
                                                    {c.issueCategory ? c.issueCategory.name : ""} &nbsp;
                                                    <Fa icon={"angle-down"} />
                                                </span>
                                            </DropdownToggle>
                                            <DropdownMenu basic style={{ width: "100%" }}>
                                                <DropdownItem disabled>
                                                    Select Feedback Type
                                                </DropdownItem>
                                                <DropdownItem divider />
                                                {complaintCategories.map((type, index) => {
                                                    return (
                                                        <DropdownItem
                                                            key={index}
                                                            onClick={updateComplaint.bind(
                                                                this,
                                                                type,
                                                                c,
                                                                "issueCategory"
                                                            )}
                                                        >
                                                            {type.name}
                                                        </DropdownItem>
                                                    );
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Col>
                                    <Col size="4" style={{ textAlign: "center" }}>
                                        <Dropdown
                                            className={
                                                "idDropdown idDropdownSales issueReasonDropdown"
                                            }
                                        >
                                            <DropdownToggle
                                                disabled={!canEdit}
                                                className={"idDropdownToggle"}
                                                nav
                                            >
                                                <span>
                                                    {" "}
                                                    {c.issueReason
                                                        ? c.issueReason.name
                                                        : "Select Reason"}{" "}
                                                    &nbsp;
                                                    <Fa icon={"angle-down"} />
                                                </span>
                                            </DropdownToggle>
                                            <DropdownMenu basic style={{ width: "100%" }}>
                                                <DropdownItem disabled>
                                                    Select Feedback Reason
                                                </DropdownItem>
                                                <DropdownItem divider />
                                                {complaintReasons
                                                    .filter((res) => {
                                                        return res.issueCategory.id === c.issueCategory.id;
                                                    })
                                                    .map((type, index) => {
                                                        return (
                                                            <DropdownItem
                                                                key={index}
                                                                onClick={updateComplaint.bind(
                                                                    this,
                                                                    type,
                                                                    c,
                                                                    "issueReason"
                                                                )}
                                                            >
                                                                {type.name}
                                                            </DropdownItem>
                                                        );
                                                    })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Col>
                                    <Col size="3" style={{ textAlign: "center" }}>
                                        <Dropdown
                                            className={
                                                "idDropdown idDropdownSales"
                                            }
                                        >
                                            <DropdownToggle
                                                disabled={!canEdit}
                                                className={"idDropdownToggle"}
                                                nav
                                            >
                                                <span>
                                                    {" "}
                                                    {c.assignedToId
                                                        ? csUsers.filter((f) => f.id === c.assignedToId).map((m) => m.name)
                                                        : "Assign To"}{" "}
                                                    &nbsp;
                                                    <Fa icon={"angle-down"} />
                                                </span>
                                            </DropdownToggle>
                                            <DropdownMenu basic style={{ width: "100%" }}>
                                                <Input className="form-control" placeholder="Search..." onChange={this.handleSearch.bind(this)} value={this.state.searchQ} />
                                                <DropdownItem disabled>
                                                    Select User
                                                </DropdownItem>
                                                <DropdownItem divider />
                                                {this.state.filteredCSUsers

                                                    .map((u, index) => {
                                                        return (
                                                            <DropdownItem
                                                                key={index}
                                                                onClick={updateComplaint.bind(
                                                                    this,
                                                                    u,
                                                                    c,
                                                                    "assignedToId"
                                                                )}
                                                            >
                                                                {u.name}
                                                            </DropdownItem>
                                                        );
                                                    })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Col>
                                </Row>
                                {c.issueCategory.name === "Compliment" ?
                                    (<Row center>
                                        <Col size="3" style={{ textAlign: "center" }}>
                                            <Dropdown
                                                className={
                                                    "idDropdown idDropdownSales"
                                                }
                                            >
                                                <DropdownToggle
                                                    disabled={!canEdit}
                                                    className={"idDropdownToggle"}
                                                    nav
                                                >
                                                    <span>
                                                        {" "}
                                                        {c.complimentedUserId
                                                            ? op.filter((f) => f.id === c.complimentedUserId).map((m) => m.name)
                                                            : "Assign Compliment"}{" "}
                                                        &nbsp;
                                                        <Fa icon={"angle-down"} />
                                                    </span>
                                                </DropdownToggle>
                                                <DropdownMenu id="userDropdownMenu" basic style={{ width: "100%" }}>
                                                    <Input className="form-control" placeholder="Search..." onChange={this.handleSearchChange.bind(this)} value={this.state.searchText} />
                                                    <DropdownItem disabled>
                                                        Select User
                                                    </DropdownItem>
                                                    <DropdownItem divider />
                                                    {this.state.filteredOpts.map((u, index) => {
                                                        return (
                                                            <DropdownItem
                                                                key={index}
                                                                onClick={updateComplaint.bind(
                                                                    this,
                                                                    u,
                                                                    c,
                                                                    "complimentedUserId"
                                                                )}
                                                            >
                                                                {u.name}
                                                            </DropdownItem>
                                                        )
                                                    }
                                                    )}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                        <Col size="5"></Col>
                                    </Row>
                                    ) :
                                    null
                                }

                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col size="3">
                                        Created On: {new Date(c.issueLoggedOn).toLocaleDateString()}
                                    </Col>

                                    <Col size="4">Created By: {c.issueLoggedBy ? c.issueLoggedBy.firstname + " " + c.issueLoggedBy.lastname : currentUser.firstname + " " + currentUser.lastname}</Col>

                                    <Col size="2">
                                        Status: {c.issueStatus === 0 ? "Open" : "Closed"}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col size="12">
                                        <hr />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col size="12">
                                        Feedback Description: {c.complaintDescription}
                                    </Col>
                                </Row>

                                <br />

                                <Row>
                                    <Col sixe="12">
                                        <table
                                            className={"complaintNotesTable"}
                                            style={{ width: "100%" }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: "12%" }}>Date</th>

                                                    <th style={{ width: "12%" }}>Added By</th>

                                                    <th>Text</th>
                                                </tr>
                                                {this.renderComplaintNotes(c.id)}
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>

                                <br />

                                {c.issueStatus === 1 ? (
                                    <Row>
                                        <Col size="4">
                                            Closed On:{" "}
                                            {c.issueClosedOn
                                                ? new Date(c.issueClosedOn).toLocaleDateString()
                                                : "Not Closed"}
                                        </Col>

                                        <Col size="4">
                                            Closed By:{" "}
                                            {c.issueClosedBy ? c.issueClosedBy.firstname + " " + c.issueClosedBy.lastname : "Not Closed"}
                                        </Col>
                                    </Row>
                                ) : (
                                    <Row >
                                        <Col size="7" />


                                        <Col size="5" style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                            <Button
                                                className={"footerButtonCol"}
                                                size="md"
                                                data-tip={"Set Follow Up Date & Note"}
                                                onClick={() => {
                                                    updateComplaint(this.getNextFollowUpDate(),
                                                        c,
                                                        "nextFollowupDate"
                                                    )
                                                    this.setState({
                                                        currentComplaint: c,
                                                        complaintNoteModal: true,
                                                    })
                                                }
                                                }
                                            >
                                                {" Set Follow Up Date "}
                                            </Button>
                                            <Button
                                                color={"secondary"}
                                                floating
                                                size="sm"
                                                data-tip={"Add Feedback Note"}
                                                onClick={() => {
                                                    this.setState({
                                                        currentComplaint: c,
                                                        complaintNoteModal: true,
                                                    });
                                                }}
                                            >
                                                <Fa size="2x" icon={"plus"}>
                                                    {" "}
                                                </Fa>
                                            </Button>

                                            <Button
                                                color={"red"}
                                                floating
                                                size="sm"
                                                data-tip={"Close Feedback"}
                                                onClick={() =>
                                                    this.setState({
                                                        closeComplaintModal: true,
                                                        currentComplaint: c,
                                                    })
                                                }
                                            >
                                                <Fa size="2x" icon={"check-square"}>
                                                    {" "}
                                                </Fa>
                                            </Button>
                                        </Col>
                                        <ReactTooltip />
                                    </Row>
                                )}
                            </CardBody>
                        </Card>
                    );
                })}
            </Container>
        );
    }

    changePassword() {
        var pWord = this.state.newPassword,
            approval = this.state.appApproval;
        if (pWord) {
            patientAppService
                .changePassword(approval.id, pWord)
                .then((res) => {
                    this.setState({
                        isChangingPassword: false,
                        newPassword: null,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            alert("You must add the new password.");
            return;
        }
    }

    //call to download data
    clientCSV() {
        let d = this.state.complaints,
            dn = this.state.complaintNotes;

        let outsideHeaders =
            ["id", "orderId", "patientId", "issueStatus", "complaintDescription", "issueCategory", "issueReason",
                "issueLoggedBy", "issueLoggedOn", "issueClosedBy", "issueClosedOn"];

        let legibleOutsideHeaders = ["Feedback ID", "Order ID", "Patient ID", "Issue Status", "Complaint Description", "Issue Category", "Issue Reason",
            "Issue Logged By", "Issue Logged On", "Issue Closed By", "Issue Closed On"];

        let insideHeaders = ["id", "noteAddedBy", "noteAddedOn", "noteText"];

        let legibleInsideHeaders = ["Note ID", "Note Added By", "Note Added On", "Note Text"];

        let result = "",
            columnDelimiter = ",",
            lineDelimiter = "\n";


        d.forEach(function (item) {

            result += legibleOutsideHeaders.join(columnDelimiter);
            result += lineDelimiter;

            outsideHeaders.forEach((property) => {

                switch (property) {
                    case "complaintDescription":
                        let n = item[property].replace(/\n/g, " ");
                        n = n.replace(/,/g, " ");
                        result += n;
                        result += columnDelimiter;
                        break;
                    case "issueLoggedBy":
                    case "issueClosedBy":
                    case "issueCategory":
                    case "issueReason":
                        let v = item[property] ? item[property].name : " "
                        result += v;
                        result += columnDelimiter;
                        break;
                    case "issueLoggedOn":
                        let y = item[property] ? new Date(item[property]).toLocaleDateString() : ""
                        result += y;
                        result += columnDelimiter;
                        break;
                    case "issueClosedOn":
                        let x = item[property] ? new Date(item[property]).toLocaleDateString() : ""
                        result += x;
                        result += columnDelimiter;

                        //double line delimiter for ease of reading
                        result += lineDelimiter;
                        result += lineDelimiter;
                        break;
                    case "issueStatus":
                        let z = item[property] === 0 ? "Open" : "Closed";
                        result += z;
                        result += columnDelimiter;
                        break;
                    default:
                        result += item[property];
                        result += columnDelimiter;
                        break;
                }

            })

            let nProp = "notes" + item.id;
            let nAry = dn[nProp];

            if (nAry) {
                result += legibleInsideHeaders.join(columnDelimiter);
                result += lineDelimiter;

                nAry.forEach((note) => {

                    insideHeaders.forEach(iProp => {
                        switch (iProp) {
                            case "id":
                            case "noteAddedBy":
                                result += note[iProp];
                                result += columnDelimiter;
                                break;
                            case "noteAddedOn":
                                let u = note[iProp] ? new Date(note[iProp]).toLocaleDateString() : ""
                                result += u;
                                result += columnDelimiter;
                                break;
                            case "noteText":
                                let p = note[iProp].replace(/\n/g, " ");
                                p = p.replace(/,/g, " ");
                                result += p;
                                break;
                            default:
                                break;
                        }
                    })
                    //add a new line after each note
                    result += lineDelimiter;
                });
            }
            //add a new line after all the notes are done
            result += lineDelimiter;
            result += lineDelimiter;

        });

        this.downloadBlob(result, "patientFeedback.csv");
    }

    //csv downlaod data here
    downloadBlob(csvData, filename) {
        let blob = new Blob([csvData], {
            type: "application/csv;charset=utf-8;",
        });

        if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, filename);
        } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a"),
                csvUrl = URL.createObjectURL(blob);

            link.href = csvUrl;
            link.style = "visibility:hidden";
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    addVideo() {
        let video = this.state.chosenVideo,
            p = this.state.patient;

        if (!video.id) {
            return;
        }

        let vo = {
            name: video.name,
            patientId: p.id,
            trainingLinkId: video.id,
            sms: false,
        };

        PatientTrainingLinkService.addPatientTrainingLink(vo).then((r) => {
            let v = this.state.myVideos;
            v.push(r);

            this.setState({
                myVideos: v,
                chosenVideo: {},
            });
        });
    }

    sendVideo(v, idx) {
        const regex = /((|\()\d{3}(|\))-\d{3}-\d"4")/g;

        if (!regex.test(this.state.ePatient.phone)) {
            return toast.danger(
                "Invalid phone number.  Please re-enter phone number and try again"
            );
        }

        v.sms = true;

        let myVideos = this.state.myVideos.slice();

        PatientTrainingLinkService.updatePatientTrainingLink(v)
            .then((r) => {
                myVideos.splice(idx, 1, r);

                this.setState({
                    myVideos: myVideos,
                });
            })
            .catch((e) => {
                toast.warning("There was an error.  Try again later.");
            });
    }

    removeVideo(v, idx) {
        let myVideos = this.state.myVideos.slice();

        PatientTrainingLinkService.deletePatientTrainingLink(v.id)
            .then((r) => {
                myVideos.splice(idx, 1);

                this.setState({
                    myVideos: myVideos,
                });
            })
            .catch((e) => {
                toast.warning("There was an error.  Try again later.");
            });
    }

    renderVideoTiles() {
        let v = this.state.myVideos,
            av = this.state.allVideos;

        return v.map((vid, idx) => {
            let sourceV = av.find((q) => {
                return q.id === vid.trainingLinkId;
            });

            if (!sourceV) {
                sourceV = {};
            }

            return (
                <MDBCol size="4">
                    <MDBCard key={idx} style={{ margin: 5 }}>
                        <MDBCardHeader color={"indigo"}>
                            <MDBRow>
                                <MDBCol size="12">
                                    {vid.name}
                                    <span style={{ float: "right" }}>
                                        <MDBBtn
                                            size="sm"
                                            color={"danger"}
                                            data-tip={"Remove Video"}
                                            onClick={() => this.removeVideo(vid, idx)}
                                            floating
                                            style={{ margin: 0 }}
                                        >
                                            <MDBIcon icon="times" />
                                        </MDBBtn>
                                    </span>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol size="12">{sourceV.description}</MDBCol>
                                <MDBCol size="12" style={{ marginTop: 10 }}>
                                    <a href="/#" style={{ color: "blue" }}>{sourceV.url}</a>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            );
        });
    }

    renderPatientVideosCard() {
        if (this.state.videosLoaded === false) {
            this.getAllVideos();
            this.getPatientVideos(this.state.id);
            return;
        }

        return (
            <Container>
                <CardBody>
                    <Row>
                        <MDBCol size="10">
                            <MDBSelect
                                outline
                                color="default"
                                style={{ maxHeight: "100px", marginTop: "1px" }}
                                options={this.state.vidsList}
                                label={"Choose a Video"}
                                getValue={this.handleVideoChange.bind(this)}
                            />
                        </MDBCol>

                        <MDBCol size="2">
                            <MDBBtn
                                size="sm"
                                data-tip={"Add Video to Patient's List"}
                                onClick={() => this.addVideo()}
                                floating
                                color={"success"}
                            >
                                <MDBIcon icon={"plus"} />
                            </MDBBtn>
                        </MDBCol>
                    </Row>
                    <Row>
                        {this.renderVideoTiles()}
                        <ReactTooltip />
                    </Row>
                </CardBody>
            </Container>
        );
    }

    renderPatientNotesCard() {
        let com = this.state.notes;
        return (
            <Container>
                <Card style={{ marginBottom: "2rem" }}>
                    <CardBody>
                        <Row>
                            <Col sixe="12">
                                <table
                                    className={"complaintNotesTable"}
                                    style={{ width: "100%", tableLayout: "fixed" }}
                                >
                                    <thead>
                                        <tr>
                                            <th style={{ width: "12%" }}>Date</th>

                                            <th style={{ width: "12%" }}>Added By</th>

                                            <th style={{ width: "76%" }}>Text</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {com.map((c, idx) => {
                                            return (
                                                <tr key={idx} style={{ maxWidth: "100%" }}>
                                                    <td style={{ width: "12%" }}>
                                                        {new Date(c.noteAddedOn).toLocaleDateString()}
                                                    </td>

                                                    <td style={{ width: "12%" }}>{c.noteAddedBy}</td>
                                                    <td
                                                        style={{
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        <div
                                                            style={{ cursor: "pointer" }}
                                                            onClick={this.patientNoteClicked.bind(this, c)}
                                                            data-tip={this.renderTip(c.note)}
                                                        >
                                                            {c.note}
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        );
    }

    renderTip(note) {
        return note.length > 150
            ? note.substr(0, 150).concat("... (Click to view more)")
            : note;
    }

    patientNoteClicked = (note) => {
        this.setState({
            currOpenNote: note,
        });
        this.togglePatientNoteModal();
    };

    togglePatientNoteModal = () => {
        this.setState({
            ptnNoteModal: !this.state.ptnNoteModal,
        });
    };

    renderPtnNoteModal() {
        let n = this.state.currOpenNote;
        return (
            <Modal
                isOpen={this.state.ptnNoteModal}
                toggle={this.togglePatientNoteModal}
            >
                <ModalBody>
                    <div>
                        <p>
                            <b>Date: </b>
                            {new Date(n.noteAddedOn).toLocaleDateString()}
                        </p>
                        <p>
                            <b>Added By: </b>
                            {n.noteAddedBy}
                        </p>
                        <p style={{ whiteSpace: "pre-line" }}>
                            <b>Note: </b>
                            {n.note || ""}
                        </p>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    renderDeletePhoneButton(phone) {
        return (
            <Button
                color={"red"}
                floating
                size="sm"
                data-tip={"Delete Phone Number"}
                onClick={() => {
                    if (
                        window.confirm(
                            "This will delete the phone number for this patient. Are you sure you wish to delete this entry?"
                        )
                    ) {
                        if (phone.id) {
                            patientPhoneService
                                .deletePatientPhone(phone.id)
                                .then((res) => {
                                    this.getPatientPhones(this.state.id);
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                            // this.getPatientPhones(this.state.id);
                        } else {
                            let data = this.state.phoneData,
                                res = [];

                            res = data.rows.filter((x) => {
                                return (
                                    x.contactName !== phone.contactName &&
                                    x.phoneNumber !== phone.phoneNumber &&
                                    x.id === null
                                );
                            });

                            data.rows = res;
                            this.setState({ phoneData: data });
                        }
                    }
                }}
            >
                <Fa size="1x" icon={"minus"}>
                    {" "}
                </Fa>
            </Button>
        );
    }

    renderTable() {
        const TableRow = ({ row, ...restProps }) => (
            <Table.Row
                {...restProps}
                // eslint-disable-next-line no-alert
                onClick={() => {
                    this.props.history.push({
                        pathname: "/order/" + row.id,
                        //state: {order: row.full},
                    });
                }}
                style={{
                    cursor: "pointer",
                }}
            />
        );

        if (this.state.isLoaded === true) {
            return (
                <Grid
                    style={{ maxWidth: "1800px !important" }}
                    rows={this.state.gridData.rows}
                    columns={this.state.gridData.columns}
                >
                    <DateTypeProvider for={this.state.gridData.dateColumns} />
                    <GroupingState
                        grouping={this.state.groupColumns}
                        defaultExpandedGroups={[
                            "Cancelled",
                            "New",
                            "InProcess",
                            "ReadyToDeliver",
                            "Setup",
                        ]}
                        onGroupingChange={this.changeGrouping}
                    />
                    <IntegratedGrouping />
                    <PagingState defaultCurrentPage={0} pageSize={15} />
                    <IntegratedPaging />
                    <DragDropProvider />
                    <Table rowComponent={TableRow} />
                    <TableColumnResizing
                        defaultColumnWidths={this.state.gridData.defaultColumnWidths}
                    />
                    <TableHeaderRow />
                    <PagingPanel />
                    <TableGroupRow />
                    <Toolbar />
                    <GroupingPanel />
                </Grid>
            );
        } else {
            return <div />;
        }
    }

    renderPhoneTable() {
        return (
            <Grid
                style={{ maxWidth: "1800px !important" }}
                rows={this.state.phoneData.rows}
                columns={this.state.phoneData.columns}
            >
                <Table />
                <TableHeaderRow />
                <TableColumnVisibility
                    defaultHiddenColumnNames={
                        this.state.phoneData.defaultHiddenColumnNames
                    }
                />
            </Grid>
        );
    }

    renderGender() {
        let item = this.state.ePatient,
            types = [
                "Male",
                "Female",
                "Other"
            ];

        return (
            <MDBCol className="smallMargin">
                <TextField
                    select
                    size="small"
                    fullWidth
                    label="Gender"
                    value={item.gender || ""}
                    onChange={this.handleChange.bind(this, "gender")}
                    variant="outlined"
                >
                    {types.map((option, idx) => {
                        return (
                            <MenuItem key={idx} value={types[idx]}>
                                {option}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </MDBCol>
        );
    }

    renderBasicInfo() {
        let item = this.state.ePatient,
            dob = item.dateOfBirth;

        if (dob) {
            dob = moment
                .utc(new Date(item.dateOfBirth), "MM-DD-YYYY")
                .format("MMMM DD, YYYY");
        }

        return (
            <div
                style={{
                    border: "1px solid #7986cb",
                    margin: 8,
                    padding: 0,
                    paddingLeft: 15,
                    paddingRight: 15,
                    marginTop: 20
                }}
            >
                <label className="patientInfoLabel">Basic Info</label>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <MDBRow>
                        <MDBCol style={{ paddingTop: 0 }}>
                            <MDBInput
                                label="First Name"
                                containerClass="smallMargin"
                                style={{ paddingTop: 8 }}
                                valueDefault={item.firstName || ""}
                                outline
                                onChange={this.handleChange.bind(this, "firstName")}
                            />
                        </MDBCol>
                        <MDBCol style={{ paddingTop: 0 }}>
                            <MDBInput
                                label="Last Name"
                                containerClass="smallMargin"
                                style={{ paddingTop: 8 }}
                                valueDefault={item.lastName || ""}
                                outline
                                onChange={this.handleChange.bind(this, "lastName")}
                            />
                        </MDBCol>
                        <MDBCol
                            size="2"
                            className="smallMargin uiDateOutline"
                            style={{ paddingTop: 0 }}
                        >
                            <DatePicker
                                format="MM/DD/YYYY"
                                size="small"
                                inputVariant="outlined"
                                label={"Date of Birth"}
                                emptyLabel={"Date of Birth"}
                                value={item.dateOfBirth || null}
                                onChange={(date) => this.handleDatePickerChange("dateOfBirth", date)}
                            />
                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                        <MDBCol>
                            <MDBInput
                                type="number"
                                label="Height (Inches)"
                                containerClass="smallMargin"
                                style={{ paddingTop: 8 }}
                                valueDefault={item.height || ""}
                                outline
                                onChange={this.handleChange.bind(this, "height")}
                            />
                        </MDBCol>
                        <MDBCol>
                            <MDBInput
                                type="number"
                                label="Weight (Pounds)"
                                containerClass="smallMargin"
                                style={{ paddingTop: 8 }}
                                valueDefault={item.weight || ""}
                                outline
                                onChange={this.handleChange.bind(this, "weight")}
                            />
                        </MDBCol>
                        {this.renderGender()}
                    </MDBRow>

                    <MDBRow>
                        <MDBCol>
                            <div className="geocoder">
                                <MDBInput
                                    id="txtAddress"
                                    label="Address"
                                    containerClass="smallMargin"
                                    style={{ paddingTop: 8 }}
                                    value={item.address || ""}
                                    //valueDefault={item.address || ""}
                                    outline
                                    onChange={this.addressTextChanged.bind(this)}
                                />
                                {this.renderGoogleSearchResult()}
                            </div>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                        <MDBCol>
                            <MDBInput
                                id="txtCity"
                                label="City"
                                containerClass="smallMargin"
                                style={{ paddingTop: 8 }}
                                value={item.city || ""}
                                outline
                                onChange={this.handleChange.bind(this, "city")}
                            />
                        </MDBCol>
                        <MDBCol>
                            {/*<MDBInput*/}
                            {/*    id="txtState"*/}
                            {/*    label="State"*/}
                            {/*    containerClass="smallMargin"*/}
                            {/*    style={{paddingTop: 8}}*/}
                            {/*    value={item.state || ""}*/}
                            {/*    outline*/}
                            {/*    onChange={this.handleChange.bind(this, "state")}*/}
                            {/*/>*/}
                            <Select
                                placeholder={item.state || "Select State..."}
                                options={this.getStateList()}
                                onChange={this.handleChange.bind(this, "state")}
                            />
                        </MDBCol>
                        <MDBCol>
                            <MDBInput
                                id="txtZip"
                                label="ZIP Code"
                                containerClass="smallMargin"
                                style={{ paddingTop: 8 }}
                                value={item.zipCode || ""}
                                outline
                                onChange={this.handleChange.bind(this, "zipCode")}
                            />
                        </MDBCol>
                    </MDBRow>
                </MuiPickersUtilsProvider>
            </div>
        );
    }

    renderDateOfBirth(item) {
        if (this.isPediatric(item.dateOfBirth))
            return (
                <DatePicker className={"pediatricDate"}
                    format="MM/DD/YYYY"
                    size={"small"}
                    inputVariant="outlined"
                    label={"DOB - Pediatric"}
                    emptyLabel={"Date of Birth"}
                    value={item.dateOfBirth || null}
                    onChange={(date) => this.handleDatePickerChange("dateOfBirth", date)}
                />)
        else
            return (
                <DatePicker
                    format="MM/DD/YYYY"
                    size={"small"}
                    inputVariant="outlined"
                    label={"Date of Birth"}
                    emptyLabel={"Date of Birth"}
                    value={item.dateOfBirth || null}
                    onChange={(date) => this.handleDatePickerChange("dateOfBirth", date)}
                />)
    }

    isPediatric(dob) {
        let a = moment(new Date());
        let b = moment(dob);

        let y = a.diff(b, 'years');

        if (y < 18) {
            return true;
        }
        return false
    }

    renderPreferredContact() {
        let item = this.state.ePatient,
            types = [
                "SMS",
                "Call",
                "Email"
            ];

        return (
            <MDBCol className="smallMargin">
                <TextField
                    select
                    size="small"
                    fullWidth
                    label="Preferred Contact Method"
                    value={item.preferredContactMethod || ""}
                    onChange={this.handleChange.bind(this, "preferredContactMethod")}
                    variant="outlined"
                >
                    {types.map((option, idx) => {
                        return (
                            <MenuItem key={idx} value={types[idx]}>
                                {option}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </MDBCol>
        );
    }

    renderEmergencyRelationship() {
        let item = this.state.ePatient,
            types = [
                "Spouse/Partner",
                "Sibling",
                "Child",
                "Parent",
                "Aunt/Uncle",
                "Other"
            ];

        return (
            <MDBCol className="smallMargin">
                <TextField
                    select
                    size="small"
                    fullWidth
                    label="Emergency Contact Relationship"
                    value={item.emergencyRelationship || ""}
                    onChange={this.handleChange.bind(this, "emergencyRelationship")}
                    variant="outlined"
                >
                    {types.map((option, idx) => {
                        return (
                            <MenuItem key={idx} value={types[idx]}>
                                {option}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </MDBCol>
        );
    }

    renderContactInfo() {
        let item = this.state.ePatient;

        return (
            <div
                style={{
                    border: "1px solid #7986cb",
                    margin: 8,
                    padding: 0,
                    paddingLeft: 15,
                    paddingRight: 15,
                    marginTop: 20
                }}
            >
                <label className="viewContactInfoLabel">Contact Info</label>
                <MDBRow>
                    <MDBCol style={{ paddingTop: 0 }}>
                        <MDBInput
                            label="Phone Number"
                            containerClass="smallMargin"
                            style={{ paddingTop: 8 }}
                            valueDefault={item.phone || ""}
                            outline
                            onChange={this.handleChange.bind(this, "phone")}
                        />
                    </MDBCol>
                    <MDBCol style={{ paddingTop: 0 }}>
                        <MDBInput
                            label="Alternative Phone"
                            containerClass="smallMargin"
                            style={{ paddingTop: 8 }}
                            valueDefault={item.alternativePhone || ""}
                            outline
                            onChange={this.handleChange.bind(this, "alternativePhone")}
                        />
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol>
                        <MDBInput
                            label="Email Address"
                            containerClass="smallMargin"
                            style={{ paddingTop: 8 }}
                            valueDefault={item.email || ""}
                            outline
                            onChange={this.handleChange.bind(this, "email")}
                        />
                    </MDBCol>
                    {this.renderPreferredContact()}
                </MDBRow>

                <MDBRow>
                    <MDBCol>
                        <MDBInput
                            label="Emergency Contact"
                            containerClass="smallMargin"
                            style={{ paddingTop: 8 }}
                            valueDefault={item.emergencyContact || ""}
                            outline
                            onChange={this.handleChange.bind(this, "emergencyContact")}
                        />
                    </MDBCol>
                    <MDBCol>
                        <MDBInput
                            label="Emergency Contact Phone Number"
                            containerClass="smallMargin"
                            style={{ paddingTop: 8 }}
                            valueDefault={item.emergencyContactPhone || ""}
                            outline
                            onChange={this.handleChange.bind(this, "emergencyContactPhone")}
                        />
                    </MDBCol>
                    {this.renderEmergencyRelationship()}
                </MDBRow>
            </div>
        );
    }

    renderDiseaseInfo() {
        let item = this.state.ePatient;

        return (
            <div
                style={{
                    border: "1px solid #7986cb",
                    margin: 8,
                    padding: 0,
                    paddingLeft: 15,
                    paddingRight: 15,
                    marginTop: 20
                }}
            >
                <label className="viewDiseaseInfoLabel">Disease Info</label>
                <MDBRow>
                    <MDBCol size="2.9" style={{ paddingTop: 5, paddingRight: 20 }}>
                        <MDBInput
                            label="Infectious Disease"
                            containerClass="smallMargin"
                            value={item.hasInfectiousDisease || false}
                            checked={item.hasInfectiousDisease || false}
                            data-tip="Priority"
                            type="checkbox"
                            onChange={this.handleCheckboxChange.bind(this, "hasInfectiousDisease")}
                            filled
                            id="diseaseCheckbox"
                        />
                    </MDBCol>
                    <MDBCol style={{ paddingTop: 0 }}>
                        <MDBInput
                            label="Disease Notes"
                            containerClass="smallMargin"
                            style={{ paddingBottom: 8 }}
                            valueDefault={item.diseaseNotes || ""}
                            outline
                            onChange={this.handleChange.bind(this, "diseaseNotes")}
                        />
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }

    renderPhoneNumberSection() {
        return (
            <>
                <div
                    style={{
                        border: "1px solid #7986cb",
                        margin: 8,
                        padding: 0,
                        paddingLeft: 15,
                        paddingRight: 15,
                        marginTop: 20
                    }}
                >
                    <label className="viewPhoneNumbersLabel">Phone Numbers</label>
                    <Button
                        color="green"
                        floating
                        size="sm"
                        data-tip="Add New Phone Number"
                        onClick={this.toggleNewPhoneModal.bind(this)}
                        className="addPhoneNumber"
                    >
                        <Fa size="1x" icon="plus">
                            {" "}
                        </Fa>
                    </Button>
                    <Row style={{ display: "flex", justifyContent: "center" }}>
                        <Col size="12">{this.renderPhoneTable()}</Col>
                    </Row>

                    <Row>
                        <Col size="12">
                            <hr />
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

    renderClinicalInformation() {
        let st = this.state,
            patient = st.ePatient;

        return (
            <>
                <div
                    style={{
                        border: "1px solid #7986cb",
                        margin: 8,
                        padding: 0,
                        paddingLeft: 15,
                        paddingRight: 15,
                        marginTop: 20
                    }}

                >
                    <label className="viewClinicalInfoLabel" >
                        Clinical Information
                    </label>

                    <ReactTooltip />
                    <Row>
                        <Col size={"12"} className={"insuranceSelects"} style={{ paddingTop: 5, paddingRight: 20 }}>
                            <MDBInput
                                label={"Doctor Name"}
                                style={{ paddingTop: 8 }}
                                outline
                                valueDefault={patient.ciName || ""}
                                onChange={this.handleChange.bind(this, "ciName")}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col size={"12"} className={"insuranceSelects"}>
                            <MDBInput
                                label={"Address"}
                                style={{ paddingTop: 8 }}
                                outline
                                valueDefault={patient.ciAddress || ""}
                                onChange={
                                    this.handleChange.bind(this, "ciAddress")
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col size={"6"} className={"insuranceSelects"}>
                            <MDBInput
                                label={"City"}
                                outline
                                style={{ paddingTop: 8 }}
                                valueDefault={patient.ciCity || ""}
                                onChange={
                                    this.handleChange.bind(this, "ciCity")
                                }
                            />
                        </Col>
                        <Col size={"6"} className={"insuranceSelects"}>
                            {/*<MDBInput*/}
                            {/*    label={ "State" }*/}
                            {/*    outline*/}
                            {/*    style={ { paddingTop: 8 } }*/}
                            {/*    valueDefault={ patient.ciState || "" }*/}
                            {/*    onChange={*/}
                            {/*        this.handleChange.bind(this, "ciState")*/}
                            {/*    }*/}
                            {/*/>*/}
                            <Select
                                placeholder={patient.ciState || "Select State..."}
                                options={this.getStateList()}
                                onChange={this.handleChange.bind(this, "ciState")}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col size={"6"} className={"insuranceSelects"}>
                            <MDBInput
                                label={"Zip Code"}
                                outline
                                style={{ paddingTop: 8 }}
                                valueDefault={patient.ciZip || ""}
                                onChange={
                                    this.handleChange.bind(this, "ciZip")
                                }
                            />
                        </Col>
                        <Col size={"6"} className={"insuranceSelects"}>
                            <MDBInput
                                label={"Phone Number"}
                                outline
                                style={{ paddingTop: 8 }}
                                valueDefault={patient.ciPhone || ""}
                                onChange={
                                    this.handleChange.bind(this, "ciPhone")
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col size={"6"} className={"insuranceSelects"}>
                            <MDBInput
                                label={"Fax"}
                                outline
                                style={{ paddingTop: 8 }}
                                valueDefault={patient.ciFax || ""}
                                onChange={
                                    this.handleChange.bind(this, "ciFax")
                                }
                            />
                        </Col>
                        <Col size={"6"} className={"insuranceSelects"}>
                            <MDBInput
                                label={"NPI"}
                                outline
                                style={{ paddingTop: 8 }}
                                valueDefault={patient.ciNpi || ""}
                                onChange={
                                    this.handleChange.bind(this, "ciNpi")
                                }
                            />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }

    renderBTKey() {
        const { companyName } = this.context;
        let item = this.state.ePatient;
        //let canEdit = this.canEdit(currentUser.role);
        //if (!item.btPId) {
        //	canEdit = true;
        //      }

        if (companyName === "corkmedical") {
            return
        }

        return (
            <div
                style={{
                    border: "1px solid #7986cb",
                    margin: 8,
                    padding: 0,
                    paddingLeft: 15,
                    paddingRight: 15,
                    marginTop: 20
                }}
            >
                <div className="viewBTKeyLabel">
                    <label>BT Key</label>
                    <Fa size="1x"
                        icon="question-circle"
                        style={{ marginLeft: '10px' }}
                        data-html={true}
                        data-tip={ReactDOMServer.renderToString(<div><img alt="Brightree URL screenshot" src={require('../../images/patientkeylocation.png')} /><p>How to Find the patient key:<br />1. Locate and open the patient detail in Brightree.<br />2. Locate the PatientKey value in the URL (see above) and copy the number value (00000 - in the image)<br />3. After ensuring that it is the correct patient, paste that number in this field and click save.<br /><b><u>NOTE: If another number is entered here, orders created in BT will be tied to another patient.</u></b></p></div>)}>
                        {" "}
                    </Fa>
                </div>
                <MDBRow>


                    {/*<MDBCol style={{ paddingTop: 0 }}>*/}
                    {/*	<MDBInput*/}
                    {/*		disabled={!canEdit}*/}
                    {/*		label="BT Key"*/}
                    {/*		containerClass="smallMargin"*/}
                    {/*		style={{ paddingTop: 8 }}*/}
                    {/*		valueDefault={item.btPId || ""}*/}
                    {/*		outline*/}
                    {/*		onChange={this.handleChange.bind(this, "btPId")}*/}
                    {/*	/>*/}
                    {/*</MDBCol>*/}
                    <MDBCol style={{ paddingTop: 0 }}>
                        <MDBInput
                            label="BT Key"
                            containerClass="smallMargin"
                            style={{ paddingTop: 8 }}
                            valueDefault={item.btPId || ""}
                            outline
                            onChange={this.handleChange.bind(this, "btPId")}
                        />
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }

    renderPatientInformation() {
        return (
            <span>
                <span>
                    {this.renderBasicInfo()}
                </span>
                <span>
                    {this.renderContactInfo()}
                </span>
                <span>
                    {this.renderClinicalInformation()}
                </span>
                <span>
                    {this.renderDiseaseInfo()}
                </span>
                <span>
                    {this.renderBTKey()}
                </span>
                <span>
                    {this.renderPhoneNumberSection()}
                </span>
            </span>
        )
    }

    renderPrimarySection() {
        let item = this.state.ePatient;
        const { insuranceTypes, insuranceSubTypes } = this.context;

        return (
            <Col size="6" style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
                <Row>
                    <Dropdown className={"idDropdown idDropdownClinic"}>
                        <DropdownToggle className={"idDropdownToggle"} nav>
                            Payor Source
                            <span style={{ float: "right" }}>
                                {item.insuranceType ? item.insuranceType.name : ""}
                                &nbsp;
                                <Fa icon={"angle-down"} />
                            </span>
                        </DropdownToggle>
                        <DropdownMenu basic>
                            <DropdownItem disabled>Select Payor Source</DropdownItem>
                            <DropdownItem divider />
                            {insuranceTypes.map((type, index) => {
                                return (
                                    <DropdownItem
                                        key={index}
                                        onClick={this.changeInsuranceType.bind(this, type, false)}
                                    >
                                        {type.name}
                                    </DropdownItem>
                                );
                            })}
                        </DropdownMenu>
                    </Dropdown>

                    <Dropdown className={"idDropdown idDropdownClinic"}>
                        <DropdownToggle className={"idDropdownToggle"} nav>
                            Insurance
                            <span style={{ float: "right" }}>
                                {item.insuranceSubType ? item.insuranceSubType.name : ""}
                                &nbsp;
                                <Fa icon={"angle-down"} />
                            </span>
                        </DropdownToggle>
                        <DropdownMenu basic>
                            <DropdownItem disabled>Select Insurance</DropdownItem>
                            <DropdownItem divider />
                            {insuranceSubTypes.map((type, index) => {
                                let ePatient = this.state.ePatient;
                                if (
                                    ePatient.insuranceType &&
                                    type.insuranceType.id === ePatient.insuranceType.id
                                ) {
                                    return (
                                        <DropdownItem
                                            key={index}
                                            onClick={this.changeInsuranceSubType.bind(
                                                this,
                                                type,
                                                false
                                            )}
                                        >
                                            {type.name}{" "}
                                        </DropdownItem>
                                    );
                                }
                                return null;
                            })}
                        </DropdownMenu>
                    </Dropdown>
                </Row>

                <Row>
                    <Col size="6">
                        <Input
                            label={"Primary Policy Number"}
                            value={item.insurancePolicy || ""}
                            onChange={this.handleChange.bind(this, "insurancePolicy")}
                        />
                    </Col>

                    <Col size="6">
                        <Input
                            label={"Primary Group Number"}
                            value={item.insuranceGroup || ""}
                            onChange={this.handleChange.bind(this, "insuranceGroup")}
                        />
                    </Col>
                </Row>
            </Col>
        );
    }

    renderSecondarySection() {
        let item = this.state.ePatient;
        const { insuranceTypes, insuranceSubTypes } = this.context;

        return (
            <Col size="6" style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
                <Row>
                    <Dropdown className={"idDropdown idDropdownClinic"}>
                        <DropdownToggle className={"idDropdownToggle"} nav>
                            Secondary Payor Source
                            <span style={{ float: "right" }}>
                                {item.secondaryInsuranceType
                                    ? item.secondaryInsuranceType.name
                                    : ""}
                                &nbsp;
                                <Fa icon={"angle-down"} />
                            </span>
                        </DropdownToggle>
                        <DropdownMenu basic>
                            <DropdownItem disabled>Secondary Payor Source</DropdownItem>
                            <DropdownItem divider />
                            {insuranceTypes.map((type, index) => {
                                return (
                                    <DropdownItem
                                        key={index}
                                        onClick={this.changeInsuranceType.bind(this, type, true)}
                                    >
                                        {type.name}{" "}
                                    </DropdownItem>
                                );
                            })}
                        </DropdownMenu>
                    </Dropdown>

                    <Dropdown className={"idDropdown idDropdownClinic"}>
                        <DropdownToggle className={"idDropdownToggle"} nav>
                            Secondary Insurance
                            <span style={{ float: "right" }}>
                                {item.secondaryInsuranceSubType
                                    ? item.secondaryInsuranceSubType.name
                                    : ""}
                                &nbsp;
                                <Fa icon={"angle-down"} />
                            </span>
                        </DropdownToggle>
                        <DropdownMenu basic>
                            <DropdownItem disabled>Secondary Insurance</DropdownItem>
                            <DropdownItem divider />
                            {insuranceSubTypes.map((type, index) => {
                                let ePatient = this.state.ePatient;
                                if (
                                    ePatient.secondaryInsuranceType &&
                                    type.insuranceType.id === ePatient.secondaryInsuranceType.id
                                ) {
                                    return (
                                        <DropdownItem
                                            key={index}
                                            onClick={this.changeInsuranceSubType.bind(
                                                this,
                                                type,
                                                true
                                            )}
                                        >
                                            {type.name}{" "}
                                        </DropdownItem>
                                    );
                                }
                                return null;
                            })}
                        </DropdownMenu>
                    </Dropdown>
                </Row>

                <Row>
                    <Col size="6">
                        <Input
                            label={"Secondary Policy Number"}
                            value={item.secondaryInsurancePolicy || ""}
                            onChange={this.handleChange.bind(
                                this,
                                "secondaryInsurancePolicy"
                            )}
                        />
                    </Col>

                    <Col size="6">
                        <Input
                            label={"Secondary Group Number"}
                            value={item.secondaryInsuranceGroup || ""}
                            onChange={this.handleChange.bind(this, "secondaryInsuranceGroup")}
                        />
                    </Col>
                </Row>
            </Col>
        );
    }

    renderInsuranceCard() {
        let item = this.state.ePatient;
        return (
            <div>
                <Row>
                    <Col size="6">
                        <h5 style={{ fontWeight: 600 }}>Primary</h5>
                    </Col>

                    <Col size="6">
                        <h5 style={{ fontWeight: 600 }}>Secondary</h5>
                    </Col>

                    <Col size="12">
                        <hr />
                    </Col>

                    {this.renderPrimarySection()}

                    {this.renderSecondarySection()}

                    <Col size="12">
                        <Input
                            label={"MBI"}
                            value={item.mbi || ""}
                            onChange={this.handleChange.bind(
                                this,
                                "mbi"
                            )}
                        />
                    </Col>
                </Row>

                <Row></Row>
            </div>
        );
    }

    renderTabNav() {
        return (
            <Nav tabs className="nav-justified" color={"blue"} style={{ margin: 0 }}>
                <NavItem>
                    <NavLink
                        link
                        to="#"
                        className={classnames({
                            active: this.state.TabsOneActiveItem === "1",
                        })}
                        onClick={() => {
                            this.toggle("1", "1");
                        }}
                    >
                        Patient Information
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        link
                        to="#"
                        className={classnames({
                            active: this.state.TabsOneActiveItem === "2",
                        })}
                        onClick={() => {
                            this.toggle("1", "2");
                        }}
                    >
                        Insurance
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        link
                        to="#"
                        className={classnames({
                            active: this.state.TabsOneActiveItem === "3",
                        })}
                        onClick={() => {
                            this.toggle("1", "3");
                        }}
                    >
                        Orders
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        link
                        to="#"
                        className={classnames({
                            active: this.state.TabsOneActiveItem === "4",
                        })}
                        onClick={() => {
                            this.toggle("1", "4");
                        }}
                    >
                        Patient Notes
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        link
                        to="#"
                        className={classnames({
                            active: this.state.TabsOneActiveItem === "5",
                        })}
                        onClick={() => {
                            this.toggle("1", "5");
                        }}
                    >
                        Patient Feedback
                    </NavLink>
                </NavItem>

                {/*<NavItem>*/}
                {/*    <NavLink*/}
                {/*        link*/}
                {/*        to="#"*/}
                {/*        className={classnames({*/}
                {/*            active: this.state.TabsOneActiveItem === "6"*/}
                {/*        })}*/}
                {/*        onClick={() => {*/}
                {/*            this.toggle("1", "6");*/}
                {/*        }}>*/}
                {/*        Patient Training Videos*/}
                {/*    </NavLink>*/}
                {/*</NavItem>*/}
            </Nav>
        );
    }

    changeServiceLocation(loc) {
        let sl = this.state.serviceLocation;

        if (loc == null) {
            sl = null;
        } else {
            sl = {
                id: loc.id,
                name: loc.name,
            };
        }

        this.setState({ serviceLocation: sl });
    }

    changeLocation(val, type) {
        let o = null;

        if (val) {
            o = {
                name: val.name,
                id: val.id,
            };
        }

        this.setState({ [type]: o });
    }

    renderSalesLocationDropdown() {
        const { internalLocations, salesReps } = this.context;

        let ary = internalLocations,
            index = null;

        let rep = salesReps.find((r) => {
            return r.id === this.state.accountOwner.id;
        });

        if (rep && rep.userprofileLocations && rep.userprofileLocations.length > 0) {
            ary = rep.userprofileLocations.map(x => x.locations);
        }

        if (this.state.salesLocation) {
            index = ary.find((loc) => {
                return loc.id === this.state.salesLocation.id
            })
        }
        if (index && !this.state.salesLocation) {
            this.changeLocation(index, "salesLocation")
        }

        return (
            <Col size="6">
                <div className={"uiOutlines"}>
                    <TextField
                        id="Sales-Location"
                        select
                        size="small"
                        fullWidth
                        label="Sales Location"
                        value={index ? index : ""}
                        onChange={(e) =>
                            this.changeLocation(e.target.value, "salesLocation")
                        }
                        variant="outlined"
                    >
                        {ary.map((option, idx) => {
                            return (
                                <MenuItem key={idx} value={ary[idx]}>
                                    {option.name}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </div>
            </Col>
        );
    }

    renderServiceLocationDropdown() {
        const { internalLocations, salesReps } = this.context;

        let ary = internalLocations,
            curr = this.state.serviceLocation,
            index = null;

        let rep = salesReps.find((r) => {
            return r.id === this.state.accountOwner.id;
        });

        if (curr) {
            index = ary.find((il) => {
                return il.id === curr.id;
            });
        } else {
            if (rep && rep.userprofileLocations && rep.userprofileLocations.length > 0) {
                index = ary.find((il) => {
                    return il.id === rep.userprofileLocations[0].locationsId;
                });
            }
        }
        if (index && !this.state.serviceLocation) {
            this.changeLocation(index, "serviceLocation")
        }

        return (
            <Col size="6">
                <div className={"uiOutlines"}>
                    <TextField
                        id="Service-Location"
                        select
                        size="small"
                        fullWidth
                        label="Service Location"
                        value={index ? index : ""}
                        onChange={(e) =>
                            this.changeLocation(e.target.value, "serviceLocation")
                        }
                        variant="outlined"
                    >
                        {ary.map((option, idx) => {
                            return (
                                <MenuItem key={idx} value={ary[idx]}>
                                    {option.name}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </div>
            </Col>
        );
    }

    renderPatientProducts() {
        let st = this.state;
        let radio = st.selectedOrderTypeRadio,
            products = st.patientProducts;

        if (radio === 1 || radio === 2 || radio === 3) {
            if (!st.patientProductsLoaded) {
                return (
                    <Col>
                        <div style={{ margin: "auto", display: "table" }}>
                            <p>
                                {st.patient.firstName + " " + st.patient.lastName}'s Products
                            </p>
                            <div
                                style={{ margin: "auto", display: "table" }}
                                className="spinner-border text-primary"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </Col>
                );
            }
            return (
                <Col style={{ marginTop: "5%" }}>
                    <label>Patient's Products</label>
                    <MDBSelect
                        outline
                        multiple
                        color="default"
                        style={{ maxHeight: "100px", marginTop: "1px" }}
                        options={products}
                        label
                        getValue={(e) => this.handleProductChange(e)}
                    />
                </Col>
            );
        }
    }

    renderServiceOrderReasonSection() {
        let st = this.state;
        let radio = st.selectedOrderTypeRadio,
            orders = st.orderIdList,
            reasons = st.serviceOrderReasons;

        if (radio === 2 && orders.length > 0) {
            return (
                <div
                    style={{
                        border: "1px solid #1976D2",
                        padding: 5,
                        margin: 5,
                        marginTop: 15,
                    }}
                >
                    <Col>
                        Select Previous Order to Tie Service To
                        <MDBSelect
                            outline
                            color="default"
                            style={{ maxHeight: "100px", marginTop: "1px" }}
                            options={orders}
                            label
                            getValue={this.populateServiceOrderReasonObject.bind(this)}
                        />
                        Select Reason For Service
                        <MDBSelect
                            outline
                            color="default"
                            style={{ maxHeight: "100px", marginTop: "1px" }}
                            options={reasons}
                            label
                            getValue={this.selectServiceOrderReason.bind(this)}
                        />
                    </Col>
                </div>
            );
        }
    }

    getPatientProducts() {
        let arr = [],
            gridData = this.state.gridData,
            o = [],
            orders = [];
        return PatientOrders.getPatientOrders(this.state.id)
            .then((res) => {
                console.log(res)
                if (res.length > 0) {
                    res.map(resp => {
                        let prods = "";
                        if (resp.orderType === "New" && resp.orderActivityProducts && resp.orderActivityProducts.length > 0) {
                            resp.orderActivityProducts.map((pr) => {
                                if (pr.products) {
                                    let s = pr.products.serial ? " - " + pr.products.serial : "";
                                    if (prods.length > 0)
                                        prods += " | ";
                                    prods += (pr.products.productSubType ? pr.products.productSubType.name : "");
                                    return arr.push({
                                        text: (pr.products.productType ? pr.products.productType.name : "") + " - " + (pr.products.productSubType ? pr.products.productSubType.name : "") + s,
                                        value: pr.products,
                                    })
                                }
                                return null;
                            });
                        }
                        //if (resp.status !== 4) {
                            o.push({
                                status: this.state.statuses[resp.status],
                                orderType: resp.orderType,
                                orderId: resp.id,
                                reason: resp.orderStatusReason ? resp.orderStatusReason.description : '',
                                equipment: prods,
                                lastUpdatedOn: resp.lastUpdatedOn,
                                lastNoteDate: resp.activity ? resp.activity.lastNoteDate : null,
                                when: resp.activity.startedAt,
                                setupDate: resp.setupDate,
                                id: resp.id,
                                full: resp,
                            });
                            //console.log(o)
                        //}
                        let l = resp.id
                            .toString()
                            .concat(resp.orderType ? " - " + resp.orderType : " - Blank Type")
                            .concat(resp.status ? " - " + resp.status : " - Blank Status");
                        orders.push({ label: l, value: resp.id });

                        return null;
                    });
                    gridData.rows = o;
                    this.setState({
                        patientProducts: arr,
                        patientProductsLoaded: true,
                        gridData: gridData,
                        patientOrders: orders,
                    });

                    this.createOrderList(res);
                } else {
                    arr.push({
                        text: "No products found",
                        value: "product",
                        disabled: true,
                    });
                    this.setState({
                        patientProducts: arr,
                        patientProductsLoaded: true,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    handleProductChange(product) {
        console.log(product)
        this.setState({
            productsAddedList: product,
        });
    }

    populateServiceOrderReasonObject(oAry) {
        if (oAry.length === 0) {
            return;
        }

        let order = oAry[0];

        let o = {
            previousOrderId: order.id,
            name: order.patientName,
            technician: order.technician ? order.technician.name : "No Technician",
            salesRep: order.owner ? order.owner.name : "No Sales Rep",
            atp: order.atp ? order.atp.name : "No ATP",
            serviceReason: "",
        };

        this.setState({
            selectedPreviousOrder: o,
        });
    }

    selectServiceOrderReason(r) {
        let o = this.state.selectedPreviousOrder;

        if (r.length === 0) {
            return;
        }

        o.serviceReason = r[0];

        this.setState({
            selectedPreviousOrder: o,
        });
    }

    getCategoryList() {
        const { productTypes } = this.context;
        let a = [];
        a.push({
            label: "Category...",
            value: "",
        });
        productTypes.map((productType) => {
            return a.push({
                label: productType.name,
                value: productType,
            });
        });
        return a;
    }

    getProductList() {
        // let subTypes = this.state.productSubTypes;
        const { productSubTypes } = this.context;
        let a = [];
        let cc = this.state.chosenCategory;
        if (cc && Object.keys(cc).length !== 0) {
            productSubTypes.map((product) => {
                if (product.productType.name === cc.name) {
                    let h = product.hcpcs !== null ? " | " + product.hcpcs : "";
                    let l = product.name + h;
                    return a.push({
                        label: l,
                        value: product,
                    });
                }
                return null;
            });
        } else {
            productSubTypes.map((product) => {
                let h = product.hcpcs !== null ? " | " + product.hcpcs : "";
                let l = product.productType.name + " | " + product.name + h;
                return a.push({
                    label: l,
                    value: product,
                });
            });
        }
        return a;
    }

    handleProductSelectChange = (e) => {
        console.log(e)
        delete e.value.deleted;
        delete e.value.deletedById;
        delete e.value.deletedAt;
        this.addNewProduct(e.value);
    };

    addNewProduct = (productAdded) => {
        const { currentUser, productTypes, productOverrides } = this.context;
        let o = this.state.nOrder,

            np = {
                abnRequired: false,
                approved: false,
                hcpcs: productAdded.hcpcs || "",
                productSubTypeId: productAdded.id,
                productSubType: productAdded,
                productTypeId: productAdded.productType.id,
                productType: {
                    id: productAdded.productType.id,
                    name: productAdded.productType.name,
                },
                points: 0.0,
                qty: 1,
                serial: "",
                amount: 0,
                updatedOn: new Date().toISOString(),
                updatedById: currentUser.id,
                updatedBy: { id: currentUser.id, name: currentUser.username }
            };

        let found = false,
            insuranceID = this.state.patient.insuranceType.id,
            insuranceMatrix = [],
            correctProduct;

        let productAddedID = productAdded.productType.id;

        for (let p of productTypes) {
            if (p.id === productAddedID) {
                correctProduct = p;
                break;
            }
        }


        if (productAdded && productAdded.overridePoints && productAdded.overridePoints.length > 0) {
            let f = productOverrides.filter((pro) => {
                return (
                    pro.insuranceTypeId === insuranceID &&
                    pro.productSubTypeId === productAdded.id
                );
            });
            if (f.length > 0) {
                np.points = f[0].points;
                found = true;
            }
        }
        if (correctProduct && found === false && correctProduct.insuranceMatrixEntry && correctProduct.insuranceMatrixEntry.length > 0) {
            insuranceMatrix = correctProduct.insuranceMatrixEntry;
            for (let i of insuranceMatrix) {
                if (i.insuranceType.id === insuranceID) {
                    np.points = i.points;
                    break;
                }
            }
        }

        o.orderActivityProducts = [{ products: np }];

        this.setState({
            nOrder: o,
            productsAddedList: np,
        });

    };

    renderProductDropdown() {
        return (
            <Col size="6">
                <div className={"uiOutlines"}>
                    <Select
                        placeholder={"Product..."}
                        options={this.getProductList()}
                        onChange={this.handleProductSelectChange.bind(this)}
                    />
                </div>
            </Col>
        );
    }

    handleCategoryChange = (e) => {
        this.setState({
            chosenCategory: e.value,
        });
    };

    renderCategoryDropdown() {
        return (
            <Col size="6">
                <div className={"uiOutlines"}>
                    <Select
                        placeholder={"Category..."}
                        options={this.getCategoryList()}
                        onChange={this.handleCategoryChange.bind(this)}
                    />
                </div>
            </Col>
        );
    }

    handleVideoChange(video) {
        this.setState({ chosenVideo: video[0] });
    }

    renderCreateOrderRoute() {
        return (
            <Route
                render={({ history }) => (
                    <div>
                        <Button
                            disabled={this.state.disableCreate}
                            color={"success"}
                            rounded
                            size="sm"
                            data-tip={"Create Order"}
                            onClick={() => this.createOrder(history)}
                        >
                            <Fa size="2x" icon={"check"}>
                                {" "}
                            </Fa>
                        </Button>
                    </div>
                )}
            />
        );
    }

    renderpopUpComplaintModal() {
        let bgHeaderColor = null;
        let eO = this.state.ePatient;

        let comp = eO.patientIssue.map(i => i)

        let legalComp = comp.filter(({ issueReason, issueStatus }) => {
            return issueStatus === 0 && issueReason && issueReason.name === "Legal"
        }
        );

        if (legalComp.length > 0) {
            bgHeaderColor = "#F93154"
        } else {
            bgHeaderColor = "#fbc02d"
        }
        return (
            <Modal isOpen={this.state.popUpComplaintModal}>
                <MDBModalHeader
                    style={{ backgroundColor: bgHeaderColor, color: "white" }}
                >
                    {legalComp.length > 0 ? "Legal Complaint" : "Open Complaint"}
                </MDBModalHeader>
                <ModalBody style={{ backgroundColor: "white" }}>
                    {this.renderPopUpComplaintBody(legalComp)}
                </ModalBody>
                <ModalFooter style={{ backgroundColor: "white" }}>
                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"success"}
                        data-tip={"Acknowledge"}
                        onClick={() => this.setState({
                            popUpComplaintModal: false
                        }
                        )}
                    >
                        <MDBIcon icon="check" style={{ fontSize: "2em" }} />
                    </Button>

                </ModalFooter>
                <ReactTooltip />
            </Modal>
        );
    }

    renderPopUpComplaintBody(legalComplaint) {
        let c = [],
            complaints = this.state.popupComplaints || [];

        complaints.forEach((cc) => {
            c.push(cc.complaintDescription)
        })

        return (
            <div>
                <p>{legalComplaint.length > 0 ? "Please send patient to compliance. This patient has a legal complaint on file: " : "This patient has an open complaint on file:"}</p>
                {c.map(complaint => (
                    <div>
                        <ul><li>{complaint}</li></ul>
                    </div>
                ))}
            </div>
        );
    }

    removeProduct(idx) {
        let no = this.state.nOrder,
            op = no.orderActivityProducts;

        op.splice(idx, 1);

        this.setState({
            nOrder: no
        })
    }

    renderOrderProductsList() {
        let op = this.state.nOrder.orderActivityProducts ? this.state.nOrder.orderActivityProducts.map(x => x.products) : [];

        if (op.length === 0) {
            return;
        }

        return (
            <div style={{ paddingTop: 6 }}>
                <span style={{ fontWeight: "bold" }}> Products </span>

                <MDBRow>
                    {op.map((p, idx) => {
                        return (
                            <MDBCol size={12} key={idx}>
                                <span>{p.productType.name}</span> &nbsp; &nbsp;
                                <span>{p.productSubType.name}</span>
                                <MDBIcon style={{ color: 'red', float: 'right' }}
                                    icon={"trash"}
                                    onClick={() => this.removeProduct(idx)}
                                    data-tip={"Remove"} />
                            </MDBCol>
                        )
                    })}
                </MDBRow>
                <ReactTooltip />
            </div>
        )
    }

    renderNewOrderStuff() {
        if (this.state.selectedOrderTypeRadio === 0) {
            return (
                <MDBRow>
                    {this.renderCategoryDropdown()}
                    {this.renderProductDropdown()}
                </MDBRow>
            )
        }
    }

    renderOrderProducts() {
        if (this.state.selectedOrderTypeRadio === 0) {
            return this.renderOrderProductsList();
        }
    }

    renderNewOrderModal() {
        return (
            <Modal
                size="small"
                isOpen={this.state.orderModalOpen}
                toggle={() => {
                    return;
                }}
            >
                <ModalBody style={{ backgroundColor: "#FFFFFF", marginTop: 25 }}>
                    <CardHeader
                        style={{ borderRadius: "8px" }}
                        className="form-header text-center font-weight-bold blue-gradient"
                    >
                        <h3>Create New Order</h3>
                    </CardHeader>

                    <label>Order Type</label>
                    <Row>
                        {this.state.orderTypes.map((orderType, index) => {
                            return (
                                <Col md="6" key={orderType.id}>
                                    <Input
                                        type="radio"
                                        gap
                                        label={orderType.name}
                                        checked={this.state.selectedOrderTypeRadio === index}
                                        onClick={() => this.getOrderType(orderType)}
                                        id={"radio" + orderType.id}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                    <Col size="12">
                        <hr />
                    </Col>
                    <Row>
                        {this.renderSalesLocationDropdown()}
                        {this.renderServiceLocationDropdown()}
                    </Row>
                    {this.renderNewOrderStuff()}
                    {this.renderOrderProducts()}
                    {this.renderServiceOrderReasonSection()}
                    {this.renderPatientProducts()}
                </ModalBody>
                <ModalFooter color={"indigo"}>
                    {this.renderCreateOrderRoute()}

                    <Button
                        color={"red"}
                        rounded
                        size="sm"
                        data-tip={"Cancel"}
                        onClick={() => this.toggleOrderModal()}
                    >
                        <Fa size="2x" icon={"times"}>
                            {" "}
                        </Fa>
                    </Button>
                    <ReactTooltip />
                </ModalFooter>
            </Modal>
        );
    }

    renderNewComplaintModal() {
        const { complaintCategories, complaintReasons, allUsers } = this.context;
        let st = this.state;

        function getComplaintCategories(categories) {
            let c = [];
            categories.forEach((cat) => {
                c.push({ label: cat.name, value: cat });
            });
            return c;
        }

        function getComplaintReasons(reasons) {
            let r = [],
                cat = st.nComplaint.issueCategory;

            //only push reasons if a category has been selected...BC
            reasons.forEach((res) => {
                if (cat && cat.id) {
                    if (res.issueCategory && res.issueCategory.id === cat.id) {
                        r.push({
                            label: res.name,
                            value: res,
                        });
                    }
                }
            });

            return r;
        }

        function getAllUsers(users) {
            let u = [];
            users.forEach((user) => {
                u.push({
                    label: user.firstname + " " + user.lastname,
                    value: user.id
                })
            });
            return u;
        }

        return (
            <Modal
                centered
                size="large"
                isOpen={this.state.createComplaintModal}
                toggle={() => {
                    return;
                }}
            >
                <ModalBody style={{ backgroundColor: "#FFFFFF" }}>
                    <CardHeader
                        style={{ borderRadius: "8px" }}
                        className="form-header text-center font-weight-bold blue-gradient"
                    >
                        <h3>Add Patient Feedback</h3>
                    </CardHeader>

                    <Row>
                        <Col><label>Feedback Type</label></Col>
                        <Col><label>Feedback Reason</label></Col>
                    </Row>
                    <Row>
                        <Col>
                            <Select
                                placeholder={this.state.nComplaint.issueCategory.name}
                                options={getComplaintCategories(complaintCategories)}
                                onChange={(val) => {
                                    let o = this.state.nComplaint;
                                    o.issueCategory = val.value;
                                    this.setState({ nComplaint: o });
                                    if (val.value.id === 3) {
                                        this.setState({
                                            isCompliment: true,
                                        })
                                    } else {
                                        this.setState({
                                            isCompliment: false,
                                        })
                                    }
                                }}
                            />
                        </Col>
                        <Col>
                            <Select
                                placeholder={this.state.nComplaint.issueReason.name}
                                options={getComplaintReasons(complaintReasons)}
                                onChange={(val) => {
                                    let o = this.state.nComplaint;
                                    o.issueReason = val.value;

                                    this.setState({ nComplaint: o });
                                }}
                            />
                        </Col>
                    </Row>

                    {this.state.isCompliment === true ? (
                        <>
                            <Row>
                                <Col><label style={{ paddingTop: '8px' }}>Assign To</label></Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Select
                                        isSearchable
                                        // isClearable
                                        placeholder={this.state.complimentedUser ? this.state.complimentedUser : allUsers.label}
                                        options={getAllUsers(allUsers)}
                                        onChange={(val) => {
                                            let o = this.state.nComplaint;
                                            o.complimentedUserId = val.value;
                                            this.setState({
                                                nComplaint: o,
                                                complimentedUser: val.label,
                                            })
                                        }}
                                    />
                                </Col>
                            </Row></>)
                        :
                        null
                    }

                    <label style={{ paddingTop: '8px' }}>Order</label>
                    <Select
                        search
                        placeholder={this.state.nComplaint.orderId}
                        options={this.state.patientOrders}
                        onChange={(val) => {
                            let o = this.state.nComplaint;
                            o.orderId = val.value;
                            this.setState({ nComplaint: o });
                        }}
                    />
                    <br />
                    <Row style={{ paddingBottom: '10px' }}>
                        <MDBInput
                            value={this.state.nComplaint.actionPlanRequired || false}
                            checked={this.state.nComplaint.actionPlanRequired || false}
                            onChange={this.handleComplaintCheckboxChange.bind(this, "actionPlanRequired")}
                            label="Action Plan Required"
                            type="checkbox"
                            id="actionPlanRequiredCheckbox"
                            containerClass="mr-5"
                            filled
                        />{" "}
                    </Row>
                    <textarea
                        placeholder={"Feedback Description"}
                        onChange={(e) => {
                            let nC = this.state.nComplaint;
                            nC.complaintDescription = e.target.value;
                            this.setState({
                                nComplaint: nC,
                            });
                        }}
                        style={{ width: "100%", height: "200px" }}
                    ></textarea>
                </ModalBody>
                <ModalFooter color={"indigo"}>
                    <Button
                        color={"success"}
                        floating
                        size="sm"
                        data-tip={"Add Patient Feedback"}
                        onClick={() => this.addPatientComplaint()}
                    >
                        <Fa size="2x" icon={"check"}>
                            {" "}
                        </Fa>
                    </Button>

                    <Button
                        color={"red"}
                        floating
                        size="sm"
                        data-tip={"Cancel"}
                        onClick={() => this.setState({ createComplaintModal: false })}
                    >
                        <Fa size="2x" icon={"times"}>
                            {" "}
                        </Fa>
                    </Button>

                    <ReactTooltip />
                </ModalFooter>
            </Modal>
        );
    }

    renderNewPatientNoteModal() {
        return (
            <Modal
                centered
                size="large"
                isOpen={this.state.createPatientNoteModal}
                toggle={() => {
                    return;
                }}
            >
                <ModalBody style={{ backgroundColor: "#FFFFFF" }}>
                    <CardHeader
                        style={{ borderRadius: "8px" }}
                        className="form-header text-center font-weight-bold blue-gradient"
                    >
                        <h3>Add Patient Note</h3>
                    </CardHeader>

                    <form className=" mx-3 grey-text">
                        <textarea
                            placeholder={"Add note text here"}
                            onChange={this.handleNoteChange.bind(this)}
                            style={{ width: "100%", height: "200px" }}
                        ></textarea>
                        <br />
                    </form>

                    <Col size="12">
                        <hr />
                    </Col>
                </ModalBody>
                <ModalFooter color={"indigo"}>
                    <Button
                        color={"success"}
                        floating
                        size="sm"
                        data-tip={"Add Patient Note"}
                        onClick={() => this.createPatientNote()}
                    >
                        <Fa size="2x" icon={"check"}>
                            {" "}
                        </Fa>
                    </Button>

                    <Button
                        color={"red"}
                        floating
                        size="sm"
                        data-tip={"Cancel"}
                        onClick={() => this.setState({ createPatientNoteModal: false })}
                    >
                        <Fa size="2x" icon={"times"}>
                            {" "}
                        </Fa>
                    </Button>

                    <ReactTooltip />
                </ModalFooter>
            </Modal>
        );
    }

    renderNewPhoneModal() {
        let phone = this.state.newPhoneNumber;

        return (
            <Modal
                centered
                isOpen={this.state.addPhoneModalOpen}
                toggle={() => {
                    this.toggleNewPhoneModal();
                }}
            >
                <ModalBody>
                    <CardHeader
                        style={{ borderRadius: "8px" }}
                        className="form-header text-center font-weight-bold blue-gradient"
                    >
                        <h3>Add Patient Phone</h3>
                    </CardHeader>

                    <Row>
                        <Col size="12">
                            <Input
                                label="Contact Name"
                                value={phone.contactName}
                                onChange={(e) => {
                                    let n = this.state.newPhoneNumber;
                                    n.contactName = e.target.value;
                                    this.setState({ newPhoneNumber: n });
                                }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col size="12">
                            <Input
                                label="Phone Number (dashes only)"
                                value={phone.phoneNumber}
                                onChange={(e) => {
                                    let n = this.state.newPhoneNumber;
                                    n.phoneNumber = e.target.value;
                                    this.setState({ newPhoneNumber: n });
                                }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col size="12">
                            <label>Phone Type</label>
                            <Select
                                placeholder={"Phone Type"}
                                options={this.state.phoneTypes}
                                onChange={(val) => {
                                    let o = this.state.newPhoneNumber;
                                    o.phoneType = val.value;

                                    this.setState({ newPhoneNumber: o });
                                }}
                            />
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button
                        color={"success"}
                        floating
                        size="sm"
                        data-tip={"Add Patient Phone"}
                        onClick={() => this.createPatientPhone()}
                    >
                        <Fa size="2x" icon={"check"}>
                            {" "}
                        </Fa>
                    </Button>

                    <Button
                        color={"red"}
                        floating
                        size="sm"
                        data-tip={"Cancel"}
                        onClick={() => this.setState({ addPhoneModalOpen: false })}
                    >
                        <Fa size="2x" icon={"times"}>
                            {" "}
                        </Fa>
                    </Button>

                    <ReactTooltip />
                </ModalFooter>
            </Modal>
        );
    }

    renderNewComplaintNoteModal() {
        return (
            <Modal
                centered
                size="large"
                isOpen={this.state.complaintNoteModal}
                toggle={() => {
                    return;
                }}
            >
                <ModalBody style={{ backgroundColor: "#FFFFFF" }}>
                    <CardHeader
                        style={{ borderRadius: "8px" }}
                        className="form-header text-center font-weight-bold blue-gradient"
                    >
                        <h3>Add New Feedback Note</h3>
                    </CardHeader>

                    <Input
                        label={"Note Text"}
                        value={this.state.complaintNoteText}
                        onChange={(e) =>
                            this.setState({ complaintNoteText: e.target.value })
                        }
                    />
                </ModalBody>
                <ModalFooter color={"indigo"}>
                    <Button
                        color={"success"}
                        floating
                        size="sm"
                        data-tip={"Add Feedback Note"}
                        onClick={() => this.createComplaintNote()}
                    >
                        <Fa size="2x" icon={"check"}>
                            {" "}
                        </Fa>
                    </Button>

                    <Button
                        color={"red"}
                        floating
                        size="sm"
                        data-tip={"Cancel"}
                        onClick={() =>
                            this.setState({
                                currentComplaint: {},
                                complaintNoteModal: false,
                            })
                        }
                    >
                        <Fa size="2x" icon={"times"}>
                            {" "}
                        </Fa>
                    </Button>
                    <ReactTooltip />
                </ModalFooter>
            </Modal>
        );
    }

    renderCloseComplaintModal() {
        return (
            <Modal
                centered
                size="large"
                isOpen={this.state.closeComplaintModal}
                toggle={() => {
                    this.setState({ closeComplaintModal: false });
                }}
            >
                <CardHeader
                    style={{ borderRadius: "8px" }}
                    className="form-header text-center font-weight-bold blue-gradient"
                >
                    <h3>Final Feedback Note</h3>
                </CardHeader>

                <ModalBody style={{ backgroundColor: "#FFFFFF" }}>
                    <textarea
                        placeholder="Final feedback note text"
                        onChange={(e) =>
                            this.setState({ complaintNoteText: e.target.value })
                        }
                        style={{ width: "100%", height: "200px" }}
                    >
                    </textarea>
                </ModalBody>
                <ModalFooter color={"indigo"}>
                    <Button
                        color={"success"}
                        floating
                        size="sm"
                        data-tip={"Create Final Note"}
                        onClick={() => {
                            this.createComplaintNote();
                            this.closeComplaint();
                        }}
                    >
                        <Fa size="2x" icon={"check"}>
                            {" "}
                        </Fa>
                    </Button>

                    <Button
                        color={"red"}
                        floating
                        size="sm"
                        data-tip={"Cancel"}
                        onClick={() =>
                            this.setState({
                                currentComplaint: {},
                                closeComplaintModal: false,
                            })
                        }
                    >
                        <Fa size="2x" icon={"times"}>
                            {" "}
                        </Fa>
                    </Button>
                    <ReactTooltip />
                </ModalFooter>
            </Modal>
        );
    }

    renderChangePasswordModal() {
        var pt = this.state.appApproval;

        return (
            <MDBModal
                centered
                isOpen={this.state.isChangingPassword}
                toggle={() => this.setState({ isChangingPassword: false })}
            >
                <MDBContainer>
                    <CardHeader
                        style={{ marginBottom: 2 }}
                        color={"indigo"}
                        className="form-header text-center"
                    >
                        Change Patient Password
                    </CardHeader>
                    <MDBRow>
                        <MDBCol size="12">
                            <div>
                                <Input
                                    onChange={(evt) => {
                                        this.setState({ newPassword: evt.target.value });
                                    }}
                                    filled
                                    label="New Password"
                                />
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol size="6"></MDBCol>
                        <MDBCol size="3">
                            <div style={{ paddingTop: 25 }}>
                                <MDBBtn
                                    size="sm"
                                    color={"red"}
                                    onClick={() =>
                                        this.setState({
                                            isChangingPassword: false,
                                            newPassword: null,
                                        })
                                    }
                                >
                                    cancel
                                </MDBBtn>
                            </div>
                        </MDBCol>
                        <MDBCol size="3">
                            <div style={{ paddingTop: 25 }}>
                                <MDBBtn
                                    size="sm"
                                    color={"success"}
                                    onClick={() => this.changePassword(pt.id)}
                                >
                                    Save
                                </MDBBtn>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBModal>
        );
    }

    render() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <div>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={5000}
                />
                <Container>
                    <Row style={{ paddingBottom: "1em" }}>
                        <Col size="12">{this.renderTopCard()}</Col>
                    </Row>
                    <Row style={{ paddingBottom: "1em" }}>
                        <Col size="12">
                            <Row>

                                <Col size="12">
                                    {this.renderTabNav()}
                                    <TabContent
                                        className="card"
                                        activeItem={this.state.TabsOneActiveItem}
                                    >
                                        <TabPane tabId="1" role="tabpanel">
                                            {this.renderPatientInformation()}
                                        </TabPane>
                                        <TabPane tabId="2" role="tabpanel">
                                            {this.renderInsuranceCard()}
                                        </TabPane>
                                        <TabPane tabId="3" role="tabpanel">
                                            {this.renderOrdersCard()}
                                        </TabPane>
                                        <TabPane tabId="4" role="tabpanel">
                                            {this.renderPatientNotesCard()}
                                        </TabPane>
                                        <TabPane tabId="5" role="tabpanel">
                                            {this.renderPatientComplaintsCard()}
                                        </TabPane>
                                        {/*<TabPane tabId="6" role="tabpanel">*/}
                                        {/*    {this.renderPatientVideosCard()}*/}
                                        {/*</TabPane>*/}
                                    </TabContent>

                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Container>
                {this.renderNewOrderModal()}
                {this.renderNewComplaintModal()}
                {this.renderNewComplaintNoteModal()}
                {this.renderCloseComplaintModal()}
                {this.renderNewPatientNoteModal()}
                {this.renderPtnNoteModal()}
                {this.renderChangePasswordModal()}
                {this.renderNewPhoneModal()}
                {this.renderpopUpComplaintModal()}
                <ReactTooltip />
            </div>
        );
    }
}
