import React from "react";
import PropTypes from "prop-types";
import { ResponsiveBar } from '@nivo/bar'
import { MDBCol, MDBRow } from "mdbreact";
import RegionService from "../../Seating/Settings/regionServices";
import Select from "react-select";

export default class ColumnPipeline extends React.Component {
    constructor(props) {
        super(props);

        this.sortByRegion = props.props.sortByRegion

        this.state = {
            pipeline: [
                { value: 0, label: 'Points', keys: ["New", "In Process", "Ready to Deliver", "Setup"], sorted: true },
                { value: 1, label: 'Payor', keys: ["Medicare", "Cash", "Medicaid", "Private", "Other",], sorted: false },
                { value: 2, label: 'Category', keys: ["Standard PWC", "Standard MWC", "POV", "Complex MWC", "Complex PWC", "Pediatric - Complex MWC"], sorted: false }
            ],
            selectedPipeline: { value: 0, label: 'Points', keys: ["New", "In Process", "Ready to Deliver", "Setup"] },
            data: props.props.pro,
            filteredData: props.props.pro,
            currentType: "",
            totals: props.props.totals,
            regions: [],
            selectedRegion: null,
        };
    }

    static contextTypes = {
        productTypes: PropTypes.array,
    };

    componentDidMount() {
        this.retrieveRegions()
        this.getCategories()
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.props.pro !== this.state.data) {
            this.setState({
                data: nextProps.props.pro,
                filteredData: nextProps.props.pro,
                totals: nextProps.props.totals,
                currentType: "",
            })
        }
        return true;
    }

    getCategories() {
        const { productTypes } = this.context;
        let pipeline = this.state.pipeline;
        let cats = ["Complex", "Standard"];
        productTypes.map(t => {
            return cats.push(t.name);
        });
        pipeline[2].keys = cats;
        this.setState({
            pipeline: pipeline,
        })
    }

    clickFunction = (data) => {
        this.reformatData(data.id);
    }

    retrieveRegions() {
        return RegionService.getAllRegions()
            .then((res) => {
                let arr = [];

                res.forEach((region) => {
                    arr.push({
                        label: region.name,
                        value: region.id,
                    });
                });

                arr.push({
                    label: "All Regions",
                    value: 0,
                })
                this.setState({
                    regions: arr,
                });
            })
            .catch((err) => {
            });

    }

    sortKeys(val) {
        let data = this.state.data,
            totals = [],
            allTotals = this.state.totals,
            keys = this.state.pipeline[val].keys,
            sum = 0;

        keys.map(function (ele) {
            sum = data.reduce(function (prev, current) {
                return prev + current[ele]
            }, 0);

            if (sum > 0) {
                return totals.push({ name: ele, total: sum })
            }
            return null;
        })
        let newKeys = totals.sort((a, b) => b.total - a.total).map(function (item) {
            allTotals[item.name] = item.total.toFixed(2).toString();
            return item['name'];
        });

        let pipeline = this.state.pipeline;
        pipeline[val].sorted = true;
        this.setState({
            pipeline: pipeline,
        })
        return newKeys;
    }

    handleSelectChange = (e) => {
        this.setState({
            selectedRegion: e.value,
        },
            () => {
                this.regionChanged(e.value);
            });
    };

    handlePipeLineChange = (e) => {
        if (this.state.currentType !== "") {
            this.reformatData(this.state.currentType)
        }
        let pipeline = e;
        if (!pipeline.sorted) {
            pipeline.keys = this.sortKeys(pipeline.value);
        }

        this.setState({
            selectedPipeline: pipeline,
        });
    };

    renderRegionSelect() {
        return (
            <Select
                placeholder="Region"
                options={this.state.regions.length !== 0 ? this.state.regions : [{ label: "Loading Regions" }]}
                onChange={this.handleSelectChange.bind(this)}
            />

        )
    }

    renderPipelineSelect() {
        return (
            <Select
                isDisabled={!this.state.data.length > 0}
                defaultValue={this.state.pipeline[0]}
                placeholder="Pipeline"
                options={this.state.pipeline}
                onChange={this.handlePipeLineChange.bind(this)}
            />
        )
    }

    regionChanged(region) {
        this.sortByRegion(region)
    }

    reformatData(type) {
        let od = this.state.data,
            nd = [];

        if (type === this.state.currentType) {

            this.setState({
                currentType: "",
                filteredData: od
            });
            return;
        }

        let propA,
            propB;

        switch (type) {
            case "New":
                propA = "New";
                propB = "NewData";
                break;
            case "In Process":
                propA = "In Process";
                propB = "In ProcessData";
                break;
            case "InProcess":
                propA = "InProcess";
                propB = "InProcessData";
                break;
            case "Ready to Deliver":
                propA = "Ready to Deliver";
                propB = "Ready to DeliverData"
                break;
            case "Setup":
            default:
                propA = "Setup";
                propB = "SetupData"
                break;
        }

        od.forEach(r => {
            let obj = {
                'Sales Location': r["Sales Location"],
                [propA]: r[propA],
                [propB]: r[propB],
                TotalPoints: r[propA],
            }

            nd.push(obj);
        })

        nd.sort((a, b) => {
            return a.TotalPoints > b.TotalPoints ? -1 : 1;
        });

        if (nd.length === 0) {
            return;
        }

        this.setState({
            filteredData: nd,
            currentType: type,
        })

    }

    customTooltip = (d) => {
        if (this.state.selectedPipeline.value !== 0) {
            return (
                <div
                    style={{
                        padding: 20,
                        color: d.color,

                    }}
                >
                    <MDBRow style={{ justifyContent: 'space-around', textAlign: 'center' }} className={"pipeLineHover"}>
                        <MDBCol size={12} style={{ color: d.color }}>
                            {d.id + " : " + d.value}
                        </MDBCol>
                        {d.id === "Complex PWC" ?
                            <MDBCol size={12} style={{ color: d.color, fontStyle: "italic", fontSize: "12px", maxWidth: "200px !important" }}>
                                {"Includes 'Complex – Group 2' "} <br />
                                {"& 'Complex Base Only'"}
                            </MDBCol>

                            : ""}


                    </MDBRow>
                    {/*<strong>*/}
                    {/*    {d.id}: {d.value}*/}
                    {/*</strong>*/}
                </div>
            )
        }
        else {

            let data = d.data[d.id + "Data"];

            let fd = data.filter(p => {
                return p.points > 0
            });

            return (
                <div style={{ height: 275, width: 350 }}>
                    <MDBRow style={{ justifyContent: 'space-around', textAlign: 'center' }}>
                        <MDBCol size={12} style={{ color: "#FFF" }}>
                            {d.indexValue + " -- " + d.id}
                        </MDBCol>
                    </MDBRow>
                    <ResponsiveBar
                        data={fd}
                        keys={['points']}
                        indexBy={"salesRep"}
                        margin={{ top: 20, right: 0, bottom: 100, left: 60 }}
                        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        colors={{ scheme: 'category10' }}
                        colorBy={'index'}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: -36,
                            legend: 'Sales Rep',
                            legendPosition: 'middle',
                            legendOffset: 60
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Points',
                            legendPosition: 'middle',
                            legendOffset: -40
                        }}
                        theme={{
                            textColor: "#FFF",
                        }}
                    />
                </div>
            )
        }
    }

    renderTopRow() {
        let colors = [
            "#1F77B4", "#FF9333", "#4CAE4C", "#DC4849", "#A47EC7",
            "#9E7067", "#E78CCB", "#939393", "#C6C744", "#3AC8D6"
        ]
        let t = this.state.totals;
        if (this.state.selectedPipeline.value === 0) {
            return (

                <MDBRow style={{ textAlign: "center", marginBottom: "10px" }}>
                    <MDBCol style={{
                        color: "#418CBF",
                        fontWeight: 'bold',
                        border: "3px solid #418CBF",
                        borderRadius: 3,
                        margin: 5
                    }}>
                        New: {t.newTotal}
                    </MDBCol>
                    <MDBCol style={{
                        color: "#FF9333",
                        fontWeight: 'bold',
                        border: "3px solid #FF9333",
                        borderRadius: 3,
                        margin: 5
                    }}>
                        In Process: {t.processTotal}
                    </MDBCol>
                    <MDBCol style={{
                        color: "#4CAF4C",
                        fontWeight: 'bold',
                        border: "3px solid #4CAF4C",
                        borderRadius: 3,
                        margin: 5
                    }}>
                        Ready to Deliver: {t.rtdTotal}
                    </MDBCol>
                    <MDBCol style={{
                        color: "#DC4849",
                        fontWeight: 'bold',
                        border: "3px solid #DC4849",
                        borderRadius: 3,
                        margin: 5
                    }}>
                        Setup: {t.setupTotal}
                    </MDBCol>
                </MDBRow>
            )
        } else if (this.state.selectedPipeline.value === 1) {
            return (
                <MDBRow style={{ textAlign: "center", marginBottom: "10px" }}>
                    {this.state.selectedPipeline.keys.map((payor, index) => {
                        let i = index % colors.length;
                        return (
                            <MDBCol style={{
                                color: colors[i],
                                fontWeight: 'bold',
                                border: "3px solid " + colors[i],
                                borderRadius: 3,
                                margin: 5
                            }}>
                                {payor}: {t[payor]}
                            </MDBCol>
                        )
                    })}

                </MDBRow>
            )
        }
        else if (this.state.selectedPipeline.value === 2) {
            return (
                <MDBRow style={{ textAlign: "center", marginBottom: "10px" }}>
                    {this.state.selectedPipeline.keys.map((payor, index) => {
                        let i = index % colors.length;
                        return (
                            <MDBCol style={{
                                minWidth: "15%",
                                color: colors[i],
                                fontWeight: 'bold',
                                border: "3px solid " + colors[i],
                                borderRadius: 3,
                                margin: 5
                            }}>
                                {payor}: {t[payor]}
                            </MDBCol>
                        )
                    })}

                </MDBRow>
            )
        }
    }

    render() {
        let pipeline = this.state.selectedPipeline.value;
        return (
            <div>
                <MDBRow>
                    <MDBCol size={4}>
                        {this.renderRegionSelect()}
                    </MDBCol>
                    <MDBCol size={4}>
                        {this.renderPipelineSelect()}
                    </MDBCol>
                </MDBRow>
                {this.renderTopRow()}
                <div style={{ height: window.innerHeight * .9 }}>
                    <ResponsiveBar
                        data={this.state.filteredData}
                        tooltip={(e) => this.customTooltip(e)}
                        enableLabel={true}
                        // keys={["Medicare", "Other", "Medicaid", "New", "InProcess", "Ready to Deliver", "Setup"]}
                        keys={this.state.selectedPipeline.keys}
                        indexBy="Sales Location"
                        margin={{ top: 0, right: 130, bottom: 150, left: 60 }}
                        padding={0.15}
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        colors={{ scheme: 'category10' }}
                        // markers={[
                        //     // {
                        //     //     axis: 'y',
                        //     //     value: pipeline === 0 ? this.state.median : 0,
                        //     //     lineStyle: { stroke: 'rgba(0, 0, 0, .35)', strokeWidth: 2 },
                        //     //     legend: pipeline === 0 ? (this.state.currentType || "New") + ' Median Points: ' + this.state.median : "",
                        //     //     legendOrientation: 'vertical',
                        //     // },
                        // ]}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 4,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: -40,
                            legend: "Sales Location",
                            legendPosition: 'middle',
                            legendOffset: 80
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: pipeline === 2 ? "Count" : 'Points',
                            legendPosition: 'middle',
                            legendOffset: -40
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        legends={[
                            {
                                onClick: pipeline === 0 ? (data) => this.clickFunction(data) : "",
                                dataFrom: 'keys',
                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        theme={{
                            tooltip: {
                                top: pipeline === 0 ? 300 : 0,
                                anchor: "right",
                                container: {
                                    background: pipeline === 0 ? "#555" : "#000",
                                },
                            },
                        }}
                    />

                </div>
                <MDBRow>
                    <MDBCol>
                        {pipeline === 0 ?
                            "Data displayed is for the past 60 days. Setup points are for current month only." :
                            "Data displayed is for orders in setup with a setup date in the current calender month."
                        }

                    </MDBCol>
                </MDBRow>
            </div>
        );
    }
}