import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	Spinner,
	CardHeader,
	MDBIcon,
	MDBTable,
	MDBTableBody,
	MDBTableHead,
	MDBCol,
	MDBRow,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Input,
	toast,
	ToastContainer,
} from "mdbreact";
import LocationService from "../Security/Locations/locationsService";
import GroupService from "../Security/UserService/groupService";
import googleService from "../Security/GoogleService/GoogleService";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import "./locations.css";
import PropTypes from "prop-types";
import RegionService from "./regionServices";

export default class locations extends React.Component {
    constructor(props) {
        super(props);

        const data = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                },
                {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                },
                {
                    label: "Type",
                    field: "type",
                    sort: "asc",
                },
                {
                    label: "Region",
                    field: "region",
                    sort: "asc",
                },
                {
                    label: "Address",
                    field: "address",
                    sort: "asc",
                },
                {
                    label: "City",
                    field: "city",
                    sort: "asc",
                },
                {
                    label: "State",
                    field: "state",
                    sort: "asc",
                },
                {
                    label: "Zip Code",
                    field: "zipcode",
                    sort: "asc",
                },
                {
                    label: "Area Manager",
                    field: "areaManager",
                    sort: "asc",
                },
                {
                    label: "Sales Manager",
                    field: "salesManager",
                    sort: "asc",
                },
                {
                    label: "Phone",
                    field: "phone",
                    sort: "asc",
                },
                {
                    label: "Edit",
                    field: "edit",
                    sort: "asc",
                },
                {
                    label: "View Users",
                    field: "users",
                    sort: "asc",
                },
                {
                    label: "Delete",
                    field: "delete",
                    sort: "asc",
                },
            ],
            rows: [],
        };

		const locationTypes = [
			{
				label: "SeatingClinic",
				value: 1,
			},
			{
				label: "Internal",
				value: 0,
			},
		];

		this.state = {
			data: data,
			isLoaded: false,
			editModal: false,
			usersModal: false,
			name: "",
			currIndex: -1,
			errorMessage: "",
			currLocation: {},
			currUsers: [],
			regions: [],
			regionSelected: [],
			locationTypes: locationTypes,
			typeSelected: {},
			nLocation: {},
			nUsers: [],
			typeSelectedAdd: [],
			modalType: "",
			allUsers: [],
			changedUsers: [],
			searchResults: [],
			regionSelectedAdd: [],
			selectedSalesDirector: null,
			selectedOperationsDirector: null,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		allUsers: PropTypes.array,
	};

	componentDidMount() {
		// this.retrieveLocations();
		this.retrieveRegions();
		this.getAllUsers();
	}

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { allUsers} = this.context;
        if (allUsers.length !== this.state.allSystemUsers.length) {
            this.getAllUsers();
        }
    }

    getAllUsers() {
        const { allUsers} = this.context;
        let users = [];
        //filter(region => region.value === regionId);
        let allSalesManagers = allUsers.filter(user => user.role === "SALES_MANAGER"),
            allAreaManagers = allUsers.filter(user => user.role === "AREA_MANAGER");

        let arrSales = [];
        let arrArea = [];

        allAreaManagers.forEach((user) => {
            arrArea.push({
                label: user.firstname + " " + user.lastname,
                value: user.id,
            });
        });

        allSalesManagers.forEach((user) => {
            arrSales.push({
                label: user.firstname + " " + user.lastname,
                value: user.id,
            });
        });


        allUsers.map((user) => {
            return users.push({
                label: user.firstname + " " + user.lastname,
                value: user.id,
                active: false,
            });
		});
		users.sort((a, b) => {
				if (a.label < b.label) return -1;
				if (a.label > b.label) return 1;
				return 0;
			});
        this.setState({
            allSystemUsers: users,
            nUsers: users,
            salesManagers: arrSales,
            areaManagers: arrArea,
        });
    }

    retrieveLocations() {
        let t = this;
        LocationService.findAllLocations()
            .then((res) => {
                let arr = [],
                    d = this.state.data;
                res.forEach((loc, index) => {
                    arr.push({
                        id: loc.id,
                        name: loc.name,
                        type: loc.type === 0 ? "Internal" : "Seating Clinic",
                        region: this.getRegionName(loc.regionId),
                        address: loc.address || "No address",
                        city: loc.city || "No city",
                        state: loc.state || "No state",
                        zip: loc.zipCode || "No zip code",
                        areaManager: this.getManager(loc.areaManager) || "No area manager",
                        salesManager: this.getManager(loc.salesManager) || "No sales manager",
                        phone: loc.phone || "no phone",
                        edit: t.renderEditButton(loc, index),
                        users: t.renderUsers(loc, index),
                        remove: t.renderRemoveButton(loc),
                    });
                });

                d.rows = arr;

                t.setState({
                    data: d,
                    isLoaded: true,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getManager(manager) {
        if (!manager) {
            return ("No Manager")
        }
        return manager.name

    }

    getRegionName(regionId) {
        let r = this.state.regions,
            regionName = "No region";
        let reg = r.filter(region => region.value === regionId);
        if (reg.length > 0) {
            regionName = reg[0].label;
        }
        return (regionName);
    }

    retrieveRegions() {
        return RegionService.getAllRegions()
            .then((res) => {
                let arr = [];

                res.forEach((region, index) => {
                    arr.push({
                        label: region.name,
                        value: region.id,
                    });
                });

                this.setState({
                    regions: arr,
                });
                this.retrieveLocations();
            })
            .catch((err) => {
                this.retrieveLocations();
                //handle error...BC
            });

    }

    renderUsers(loc, index) {
        return (
            <MDBIcon icon="user"
                     onClick={() => this.handleUsersClick(loc, index)}
            />
        );
    }

	renderEditButton(loc, index) {
		return (
			<MDBIcon icon="edit" style={{color: "#7ac5ff"}} onClick={() => this.handleEditClick(loc, index)} />
		);
	}

    clearSearch() {
        this.setState({searchResults: []});
    }

	renderRemoveButton(loc) {
		return (
			<MDBIcon icon="trash" style={{color: "#db0404"}} onClick={() => this.handleRemoveClick(loc)} />
		);
	}

    handleRemoveClick(loc) {
        LocationService.deleteLocation(loc)
            .then((res) => {
                this.retrieveLocations();
                toast.success("Location Removed");
            })
            .catch((err) => {
                toast.warn("Error occurred while removing Location");
            });
    }

    addressTextChanged(e) {
        let val = e.target.value;
        const currLocation = this.state.currLocation;
        currLocation["address"] = val;

        let geocoderResultNode = document.getElementById("geocoderResult");
        if (val) {
            this.geoGoogle(val);
        } else {
            this.setState({searchResults: []});
            if (geocoderResultNode) {
                geocoderResultNode.innerHTML = "";
            }
        }
    }

    geoGoogle(val) {
        if (val) {
            googleService.searchAddress(val).then((res) => {
                this.setState({searchResults: res.results});
            });
        }
    }

	renderGoogleSearchResult() {
		let results = this.state.searchResults;
		if (results.length > 0) {
			return (
				<div className="resultWrap">
					<div style={{ width: "100%" }}>
						<button
							id="popup-closer"
							className="ol-popup-closer"
							onClick={this.clearSearch.bind(this)}
						/>
					</div>
					<br />
					<ul id="geocoderResult">
						{results.map((item, index) => {
							if (
								index < 4 &&
								item.formatted_address &&
								item.formatted_address.length > 0
							) {
								return (
									<li key={results.indexOf(item)}>
										<button
											style={{
												border: "0",
												backgroundColor: "transparent",
												color: "#3887BE",
											}}
											onClick={this.setGoogleAddress.bind(this, item)}
										>
											{" "}
											{item.formatted_address}{" "}
										</button>
									</li>
								);
							}
							return null;
						})}
					</ul>
					<div className="loading hidden">
						<img
							src="https://samples.thinkgeo.com/cloud/example/image/Spinner-1s-50px.gif"
							alt="loading"
						/>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

    setGoogleAddress = (location) => {
        const loc = this.state.currLocation;

        let addr = location.address_components;

        let numComp = "",
            streetComp = "";
        addr.forEach((item) => {
            if (item.types.includes("street_number")) {
                numComp = item.long_name;
            } else if (item.types.includes("route")) {
                streetComp = item.long_name;
            } else if (
                item.types.includes("neighborhood") ||
                item.types.includes("locality")
            ) {
                loc.city = item.long_name;
            } else if (item.types.includes("administrative_area_level_1")) {
                loc.state = item.short_name;
            } else if (item.types.includes("postal_code")) {
                loc.zipCode = item.long_name;
            }
        });

        loc.address = numComp + " " + streetComp;
        loc.latitude = location.geometry.location.lat || "";
        loc.longitude = location.geometry.location.lng || "";

        this.setState({currLocation: loc, searchResults: []});
    };

	handleUsersClick(location, index) {
		const { allUsers } = this.context;
		let users = [];
		allUsers.map(u => {
			if (u.userprofileLocations && u.userprofileLocations.length > 0 && u.userprofileLocations.filter(x => location.id === x.locationsId).length > 0) {
				return users.push({
					label: u.firstname + " " + u.lastname,
					value: u.id,
					active: false,
                })
			}
			return null;
		})

		this.setState({
			currUsers: users,
			currLocation: location,
		})
		this.usersToggle();
    }

    handleLocationSave = () => {
        let st = this.state;
        return GroupService.addUsersToLocations(st.changedUsers, st.currLocation.id)
            .then((res) => {
                this.usersToggle();
                toast.success("Location users changed");
            })
            .catch((err) => {
                console.log(err);
            });
    };

	handleEditClick(location, index) {
		let l = JSON.parse(JSON.stringify(location));
		this.setState({
			currLocation: l,
			modalType: "edit",
			editModal: !this.state.editModal,
			currIndex: index,
			typeSelected: {
				label: l.type !== null ? (l.type === 0 ? "Internal" : "SeatingClinic") : null,
				value: l.type !== null ? l.type.toString() : null,
			},
		});
	}

    editToggle = () => {
        this.setState({
            editModal: !this.state.editModal,
            errorMessage: "",
            nLocation: {},
            typeSelectedAdd: null,
        });
    };

    usersToggle = () => {
        this.setState({
            usersModal: !this.state.usersModal,
            changedUsers: [],
        });
    };

	addUser = (user, index) => {
		let users = this.state.nUsers,
            locUsers = this.state.currUsers;
        if (locUsers.findIndex((u) => u.value === user.value) === -1) {
			users[index].active = !user.active;
            this.setState({
                nUsers: users,
            });
        }
    };

    removeUser = (user, index) => {
        let locUsers = JSON.parse(JSON.stringify(this.state.currUsers));
        locUsers[index].active = !user.active;
        this.setState({
            currUsers: locUsers,
        });
    };

    moveUsersOut = () => {
        let locUsers = this.state.currUsers,
            changedUsers = this.state.changedUsers;
        locUsers
            .filter((user) => user.active)
            .map((user) => {
                user.active = false;
                locUsers.splice(
                    locUsers.findIndex((u) => u.value === user.value),
                    1
                );
                let i = changedUsers.indexOf(user.value);
                if (i === -1) {
                    return changedUsers.push(user.value);
                } else {
                    return changedUsers.splice(i, 1);
                }
			});
        this.setState({
            currUsers: locUsers,
            changedUsers: changedUsers,
        });
    };

    moveUsersIn = () => {
        let u = this.state.nUsers;
        let locUsers = this.state.currUsers;
        let changedUsers = this.state.changedUsers;

		u.filter((user) => user.active === true).map((user) => {
            let i = locUsers.findIndex((u) => u.value === user.value);
            if (i === -1) {
                user.active = false;
                locUsers.push(user);
			}

            let ii = changedUsers.indexOf(user.value);
            if (ii === -1) {
                changedUsers.push(user.value);
            } else {
                // If users is already present then that means they're trying
                // to revert their changes so remove from change array...JK
                changedUsers.splice(ii, 1);
            }
            return null;
		});

        this.setState({
            currUsers: locUsers,
            nUsers: u,
            changedUsers: changedUsers,
        });
    };

    renderUsersModal() {
        let users = this.state.currUsers,
            allUsers = this.state.nUsers;

		return (
			<Modal
				size={"lg"}
				className={"modalM"}
				isOpen={this.state.usersModal}
				toggle={this.usersToggle}
			>
				<ModalHeader
					style={{ backgroundColor: "#5881C1", color: "white" }}
					toggle={this.usersToggle}
				>
					{this.state.currLocation.name}
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					<MDBRow>
						<MDBCol className={"userMCols"}>
							<ul className={"userList"}>
								{allUsers.map((user, index) => {
									return (
										<li
											key={user.value}
											className={user.active ? "visitedItem" : "unvisitedItem"}
											onClick={this.addUser.bind(this, user, index)}
										>
											{user.label}
										</li>
									);
								})}
							</ul>
						</MDBCol>
						<MDBCol className={"userMCols"}>
							<div className={"userBtnDiv"}>
								<Button
									floating
									size="sm"
									data-tip={"Move into Location"}
									onClick={this.moveUsersIn}
								>
									<MDBIcon icon="angle-right" />
								</Button>
							</div>
							<div className={"userBtnDiv"}>
								<Button
									floating
									size="sm"
									data-tip={"Move out Location"}
									onClick={this.moveUsersOut}
								>
									<MDBIcon icon="angle-left" />
								</Button>
							</div>
						</MDBCol>
						<MDBCol className={"userMCols"}>
							<ul className={"userList"}>
								{users
									.map((user, index) => {
										return (
											<li
												key={user.value}
												className={
													user.active ? "visitedItem" : "unvisitedItem"
												}
												onClick={this.removeUser.bind(this, user, index)}
											>
												{user.label}
											</li>
										);
									})}
							</ul>
						</MDBCol>
					</MDBRow>
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "left" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Save"}
						onClick={this.handleLocationSave}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>
					<Button
						style={{ float: "left" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Cancel"}
						onClick={this.usersToggle}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

    renderEditModal() {
        let l = this.state.currLocation;
        let nLoc = this.state.nLocation;
        let t = this.state.modalType;

        let v = nLoc;

		if (t === "edit") {
			v = l;
		}
		
		let name = v.name,
			regionName = this.getRegionName(v.regionId),
			salesDirectorName = v.salesDirector ? v.salesDirector.firstname + " " + v.salesDirector.lastname : "",
			operationsDirectorName = v.operationsDirector ? v.operationsDirector.firstname + " " + v.operationsDirector.lastname : "",
			address = v.address,
			lat = v.latitude,
			long = v.longitude,
			city = v.city,
			state = v.state,
			zip = v.zipCode,
			phone = v.phone,
			typeName = v && v.type > -1 ? this.state.locationTypes.filter(x => x.value === v.type)[0].label : "Location Type...";

		return (
			<Modal isOpen={this.state.editModal} toggle={this.editToggle}>
				<ModalHeader
					style={{ backgroundColor: "#5881C1", color: "white" }}
					toggle={this.editToggle}
				>
					{t === "edit" ? "Edit Location" : "Add Location"}
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					<div>
						<p style={{ color: "red" }}>{this.state.errorMessage}</p>
						<Input
							outline
							label="Name"
							value={name || undefined}
							onChange={this.handleEditChange.bind(this, "name")}
						/>
						<Input
							outline
							label="Address"
							value={address || undefined}
							onChange={this.addressTextChanged.bind(this)}
						/>
						<Select
							placeholder={typeName || "Location Type..."}
							label="Location Type"
							options={this.state.locationTypes}
							onChange={this.handleSelectChange.bind(this)}
						/><br/>
						<Select
							placeholder={regionName || "Sales Director..."}
							label="Sales Director"
							options={this.state.regions}
							onChange={this.handleRegionChange.bind(this)}
						/><br/>
						<Select
							placeholder={salesDirectorName || "Sales Director..."}
							label="Sales Director"
							options={this.state.allUsers}
							onChange={this.handleSalesDirectorChange.bind(this)}
						/><br/>
						<Select
							placeholder={operationsDirectorName || "Operations Director..."}
							label="Operations Director"
							options={this.state.allUsers}
							onChange={this.handleOperationsDirectorChange.bind(this)}
						/>
						{this.renderGoogleSearchResult()}
						<Input type={"hidden"} value={lat || undefined} />
						<Input type={"hidden"} value={long || undefined} />
						<Input
							outline
							label="City"
							value={city || undefined}
							onChange={this.handleEditChange.bind(this, "city")}
						/>
						<Input
							outline
							label="State"
							value={state || undefined}
							onChange={this.handleEditChange.bind(this, "state")}
						/>
						<Input
							outline
							label="Zip Code"
							value={zip || undefined}
							onChange={this.handleEditChange.bind(this, "zipCode")}
						/>
						<Input
							outline
							label="Phone"
							value={phone || undefined}
							onChange={this.handleEditChange.bind(this, "phone")}
						/>
					</div>
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "left" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Save"}
						onClick={this.handleEditSave}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>
					<Button
						style={{ float: "left" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Cancel"}
						onClick={this.handleEditClose}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

    handleEditSave = () => {
        if (this.validateEdit()) {
            if (this.state.modalType === "edit") {
                return LocationService.updateLocation(this.state.currLocation)
                    .then((res) => {
                        this.setState({
                            editModal: !this.state.editModal,
                            errorMessage: "",
                            modalType: "",
                        });
                        toast.success("Location Edited!");
                        this.retrieveLocations();
                    })
                    .catch((err) => {
                        toast.warn("An error occurred while saving.");
                    });

            } else {
                return LocationService.createLocation(this.state.nLocation)
                    .then((loc) => {
                        this.setState({
                            errorMessage: "",
                            nLocation: {},
                            typeSelectedAdd: null,
                            editModal: !this.state.editModal,
                            modalType: "",
                        });
                        toast.success(loc.name + " Location has been Added!");
                        this.retrieveLocations();
                    })
                    .catch((err) => {
                        toast.warn("An error occurred adding a new Location");
                    });
            }
        }
    };

    validateEdit() {
        let l = {};
        if (this.state.modalType === "edit") {
            l = this.state.currLocation;
        } else {
            l = this.state.nLocation;
        }
        if (!l.latitude && !l.longitude) {
            toast.warn("The address is not valid for this location.");
        }
        if (!l.name) {
            this.setError("Please add a Location Name");
            return false;
        } else if (l.type === undefined || l.type === null) {
            this.setError("Please select a Type");
            return false;
        }
        return true;
    }

    setError(e) {
        this.setState({
            errorMessage: e,
        });
    }

    handleEditClose = () => {
        this.setState({
            currIndex: -1,
            errorMessage: "",
            editModal: !this.state.editModal,
            currLocation: {},
        });
    };

    handleEditChange(property, e) {
        if (this.state.modalType === "edit") {
            let l = this.state.currLocation;
            l[property] = e.target.value;
            this.setState({
                currLocation: l,
            });
        } else {
            let l = this.state.nLocation;
            l[property] = e.target.value;
            this.setState({
                nLocation: l,
            });
        }
    }

    handleSalesManagerChange = (e) => {
        let v = e.target ? e.target.value : {};

        let l = this.state.nLocation,
            prop = "nLocation";

        if (this.state.modalType === "edit") {
            l = this.state.currLocation;
            prop = "currLocation";
        }

        l.salesManager = {
            name: v.label,
            id: v.value
        };

        this.setState({
            [prop]: l
        });
    };

    handleAreaManagerChange = (e) => {
		let v = e.target ? e.target.value : {};

		let l = this.state.nLocation,
			prop = "nLocation";

		if (this.state.modalType === "edit") {
			l = this.state.currLocation;
			prop = "currLocation";
		}

		l.areaManager = {
			name: v.label,
			id: v.value
		};

		this.setState({
			[prop]: l
		});
	};

	handleRegionChange = (e) => {
		if(this.state.modalType === "edit") {
			let l = this.state.currLocation;
			l.regionId = e.value;
			this.setState({
				currLocation: l,
				regionSelected: e,
			});
		} else {
			let l = this.state.nLocation;
			l.regionId = e.value;
			this.setState({
				currLocation: l,
				regionSelectedAdd: e,
			});
		}
	}

	handleSalesDirectorChange = (e) => {
		if(this.state.modalType === "edit") {
			let l = this.state.currLocation;
			l.salesDirectorId = e.value;
			this.setState({
				currLocation: l,
				salesDirectorSelected: e,
			});
		} else {
			let l = this.state.nLocation;
			l.salesDirectorId = e.value;
			this.setState({
				currLocation: l,
				operationsDirectorSelectedAdd: e,
			});
		}
	}

	handleOperationsDirectorChange = (e) => {
		if(this.state.modalType === "edit") {
			let l = this.state.currLocation;
			l.operationsDirectorId = e.value;
			this.setState({
				currLocation: l,
				regionSelected: e,
			});
		} else {
			let l = this.state.nLocation;
			l.operationsDirectorId = e.value;
			this.setState({
				currLocation: l,
				regionSelectedAdd: e,
			});
		}
	}

	handleSelectChange = (e) => {
		if (this.state.modalType === "edit") {
			let l = this.state.currLocation;
			l.type = e.value;
			this.setState({
				currLocation: l,
				typeSelected: e,
			});
		} else {
			let l = this.state.nLocation;
			l.type = e.value;
			this.setState({
				nLocation: l,
				typeSelectedAdd: e,
			});
		}
	};

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<div className="locations_table">
					<MDBTable small striped style={{ textAlign: "center" }}>
						<MDBTableHead columns={this.state.data.columns} />
						<MDBTableBody rows={this.state.data.rows} />
					</MDBTable>
				</div>
			);
		} else {
			return <div> </div>;
		}
	}

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }

    addNewClick = () => {
        this.setState({
            modalType: "add",
        });
        this.editToggle();
    };

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<Container className={"mainContainer mt-5"}>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Card>
					<CardHeader
						style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize: 30 }}>
						Locations
						<Button
							style={{ float: "right" }}
							floating
							size="sm"
							color={"secondary"}
							data-tip={"Add New Location"}
							onClick={this.addNewClick}
						>
							<MDBIcon icon="building" style={{ fontSize: "2em" }} />
						</Button>
					</CardHeader>
					<CardBody>{this.renderTable()}</CardBody>
				</Card>
				<ReactTooltip />
				{this.renderEditModal()}
				{this.renderUsersModal()}
			</Container>
		);
	}
}
