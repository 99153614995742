import api from "../Security/api";

class RegionService {

	async getAllRegions() {
		return new Promise((res, reject) => {
			api
				.get("/regions")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async updateRegion(region) {
		return new Promise((res, reject) => {
			api
				.put("/regions", region)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createRegion(region) {
		return new Promise((res, reject) => {
			api
				.post("/regions", region)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async deleteRegion(id) {
		return new Promise((res, reject) => {
			api
				.delete("/regions/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}
}

const regionService = new RegionService();

export default regionService;
