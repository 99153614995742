import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import "./orderList.css";

import {isGroupTableRow} from "@devexpress/dx-grid-core";
import {
    Grid,
    Table,
    VirtualTable,
    TableHeaderRow,
    PagingPanel,
    TableGroupRow,
    GroupingPanel,
    DragDropProvider,
    Toolbar,
    TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";

import {
    GroupingState,
    IntegratedGrouping,
    PagingState,
    IntegratedPaging, SortingState, IntegratedSorting,
} from "@devexpress/dx-react-grid";
import {
    Plugin,
    Template,
    TemplateConnector,
    TemplatePlaceholder,
} from "@devexpress/dx-react-core";
import Select from "react-select";
import {
    Button,
    Container,
    Card,
    Col,
    Row,
    Spinner,
    toast,
    ToastContainer,
    MDBIcon,
    Modal,
    MDBModalHeader,
    ModalBody,
    ModalFooter,
    MDBModalBody,
    MDBModal,
    MDBInput,
    MDBBtn,
    MDBCol,
    MDBRow,
    MDBCard, MDBSpinner,
} from "mdbreact";
import OrderService from "../Security/OrderService/orderService";
import ActivityService from "../Security/ActivityService/activityService";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import "./orderList.css";
import ReactTooltip from "react-tooltip";
import RegionService from "../Settings/regionServices";
import moment from "moment";

const ItemCounter = () => (
    <Plugin name="ItemCounter">
        <Template
            name="tableCell"
            predicate={({tableRow}) => isGroupTableRow(tableRow)}
        >
            {(params) => (
                <TemplateConnector>
                    {({getCollapsedRows}) => {
                        const updatedParams = {
                            ...params,
                            tableRow: {
                                ...params.tableRow,
                                row: {
                                    ...params.tableRow.row,
                                    collapsedRows: getCollapsedRows(params.tableRow.row) || [],
                                },
                            },
                        };
                        return <TemplatePlaceholder params={updatedParams}/>;
                    }}
                </TemplateConnector>
            )}
        </Template>
    </Plugin>
);

const Root = (props) => <Grid.Root {...props} style={{height: "100%"}}/>;

function getVal(row) {
    if (row.collapsedRows.length > 0) {
        let r = row.collapsedRows,
            t = 0;

        r.forEach((rd) => {
            t += parseFloat(rd.points);
        });

        return "Count: " + row.collapsedRows.length + "    Points: " + t.toFixed(1);
    } else {
        return "";
    }
}

const Content = ({row, column}) => (
    <span>
		<span>
			{column.title} : {row.value}
		</span>
		<span style={{fontWeight: "bold"}}>&nbsp; {getVal(row)}</span>
	</span>
);

const compareDate = (a, b) => {
    const priorityA = new Date(a);
    const priorityB = new Date(b);
    if (priorityA === priorityB) {
        return 0;
    }
    return priorityA < priorityB ? -1 : 1;
};

export default class FollowUpView extends React.Component {
    constructor(props) {
        super(props);

        let groupColumns = [
            { columnName: "followUpDate" },
            { columnName: "payorSource" },
            { columnName: "insurance" }
        ];

        const data = {
            columns: [
                {
                    title: "Id",
                    name: "id",
                },
                {
                    title: "Pediatric",
                    name: "pediatric",
                },
                {
                    title: "Moved Into Queue",
                    name: "timestamp",
                },
                {
                    title: "Being Worked On By",
                    name: "currentlyBeingWorkedBy",
                },
                {
                    title: "Patient Name",
                    name: "patientName",
                },
                {
                    title: "Follow-Up Date",
                    name: "followUpDate",
                },
                {
                    title: "Follow-Up In X Days",
                    name: "followUpDays",
                },
                {
                    title: "Phone",
                    name: "phone",
                },
                {
                    title: "Sales Location",
                    name: "salesLocation",
                },
                {
                    title: "Payor Source",
                    name: "payorSource",
                },
                {
                    title: "Insurance",
                    name: "insurance",
                },
                {
                    title: "Points",
                    name: "points",
                },
                {
                    title: "Category",
                    name: "category",
                },
                {
                    title: "Unassign",
                    name: "unassign",
                },


            ],
            rows: [],
            integratedSortingColumnExtensions: [
                {columnName: "timestamp", compare: compareDate},
            ],
        };

        let defaultColumnWidths = [
            {columnName: "id", width: 70},
            {columnName: "pediatric", width: 80},
            {columnName: "timestamp", width: 160},
            {columnName: "currentlyBeingWorkedBy", width: 160},
            {columnName: "patientName", width: 150},
            {columnName: "phone", width: 120},
            {columnName: "salesLocation", width: 120},
            {columnName: "category", width: 120},
            {columnName: "followUpDate", width: 120},
            {columnName: "followUpDays", width: 140},
            {columnName: "payorSource", width: 100},
            {columnName: "insurance", width: 140},
            {columnName: "points", width: 70},
            {columnName: "unassign", width: 80},
        ];

        this.state = {
            data: data,
            isLoaded: false,
            grouping: [],
            groupingColumns: groupColumns,
            defaultColumnWidths: defaultColumnWidths,
            groupSummaryItems: [],
            regions: [],
            selectedRegion: null,
            allResults: [],
            ctrl: false,
            workedOnModal: false,
            currentOrder: [],
            workedByMe: [],
            policyModalOpen: false,
            policyResults: [],
            policyText: "",
            searchPressed: false,
        };

        this.changeGrouping = (grouping) => {
            let ary = [];

            grouping.forEach((group) => {
                ary.push({columnName: group.columnName});
            });

            this.setState({
                groupingColumns: ary,
            });
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        companyName: PropTypes.string,
        allUsers: PropTypes.array,
    };

    downloadFunction() {
        this.clientCSV(this.state.data, "Orders.csv");
    }

    componentDidMount() {
        //const {companyName} = this.context;

        //let gc = this.state.groupingColumns;

        //if (companyName === 'rehabmedical' || companyName === 'uat') {
        //    gc.unshift({columnName: "pediatric"})
        //    this.setState({groupingColumns: gc})
        //}

        this.retrieveRegions();
    }

    //This will convert all the data in the grid to a csv file
    clientCSV(stateData, filename) {
        let result,
            ctr,
            keys = [],
            headers = [],
            columnDelimiter = ",",
            lineDelimiter = "\n",
            data;

        data = stateData.rows || null;
        if (data == null || !data.length) {
            return null;
        }

        stateData.columns.forEach((col) => {
            keys.push(col.name);
            headers.push(col.title);
        });

        let replace = ["category", "patientName", "phone", "timestamp"];

        result = "";
        result += headers.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;

                if (replace.indexOf(key) > -1 && item[key] != null) {
                    result += item[key].replace(/,/g, " ");
                } else {
                    result += item[key];
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        this.downloadBlob(result, filename);
    }

    //this actually prompts the download
    downloadBlob(csvData, filename) {
        let blob = new Blob([csvData], {
            type: "application/csv;charset=utf-8;",
        });

        if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, filename);
        } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a"),
                csvUrl = URL.createObjectURL(blob);

            link.href = csvUrl;
            link.style = "visibility:hidden";
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    getResults() {
        return OrderService.getFollowUpViewNative(this.state.selectedRegion || "")
            .then((res) => {
                this.buildNewResults(res);
            })
            .catch((err) => {
                console.log(err);
                this.setState({isLoaded: true});
            });
    }

    retrieveRegions() {
        return RegionService.getAllRegions()
            .then((res) => {
                let arr = [];

                res.forEach((region, index) => {
                    arr.push({
                        label: region.name,
                        value: region.id,
                    });
                });

                arr.push({
                    label: "All Regions",
                    value: -1,
                })
                this.setState({
                    regions: arr,
                });
                this.getResults()
            })
            .catch((err) => {
            });

    }

    handleSelectChange = (e) => {
        this.setState({
            workedByMe: [],
            selectedRegion: e && e.value > 0 ? e.value : null,
            },
        () => {
            this.getResults();
        });
    };

    compareDateOfBirth(dob) {
        let a = moment(new Date());
        let b = moment(dob);

        let y = a.diff(b, 'years');

        if (y < 18) {
            return "Yes";
        }
        return "No"
    }

    buildNewResults(res) {

        function calculateDays(day) {
            let dt = 1000 * 60 * 60 * 24;

            const date1 = new Date(day);

            if (day2.toLocaleDateString() === date1.toLocaleDateString()) {
                return 0;
            }

            const diffTime = Math.abs(day2 - date1);

            return Math.ceil(diffTime / (dt));

        }

        let ary = [],
            dt = this.state.data,
            day2 = new Date();

        res.forEach((val) => {


            ary.push({
                id: val.id,
                pediatric: this.compareDateOfBirth(val.dateOfBirth),
                timestamp: val.timestamp,
                currentlyBeingWorkedBy: val.currentlyWorkedBy,
                patientName: val.patientName,
                phone: val.phone,
                salesLocation: val.salesLocation,
                category: val.category,
                followUpDate: val.followUpDate || new Date().toLocaleString(),
                followUpDays: calculateDays(val.followUpDate || new Date().toLocaleString()) + " Days",
                payorSource: val.payorSource,
                insurance: val.insurance,
                points: val.points,
                unassign: this.createBtn(val.currentlyWorkedBy, val.id),
            });
        });

        dt.rows = ary;

        toast.success("Found " + res.length + " Results");

        this.setState({
            data: dt,
            isLoaded: true,
        });
    }

    renderBeingWorkedOnModal() {
        const { allUsers } = this.context;
        let workingOnId = this.state.workingOnId,
            workingName = workingOnId ? allUsers.filter(x => x.id === workingOnId).map(x => (x.firstname + " " + x.lastname) || x.username) : "";

        return (
            <Modal isOpen={this.state.workedOnModal}>
                <MDBModalHeader
                    style={{backgroundColor: "#F93154", color: "white"}}
                >
                    Already Being Worked On
                </MDBModalHeader>
                <ModalBody style={{backgroundColor: "white"}}>
                    {/*{this.renderBeingWorkedOnBody()}*/}
                    <div>
                        Order is being worked by {workingName}
                    </div>
                </ModalBody>
                <ModalFooter style={{backgroundColor: "white"}}>

                    <Button
                        style={{float: "right"}}
                        floating
                        size="sm"
                        color={"red"}
                        data-tip={"Go Back"}
                        onClick={() => {
                            this.getResults()
                            this.setState({
                                    workedOnModal: false
                                }
                            )
                        }}
                    >
                        <MDBIcon icon="times" style={{fontSize: "2em"}}/>
                    </Button>

                    <Button
                        style={{float: "right"}}
                        floating
                        size="sm"
                        color={"success"}
                        data-tip={"Acknowledge"}
                        onClick={() => {
                            this.setState({
                                    workedOnModal: false
                                }
                            )
                            this.openOrder(this.state.currentOrderId)
                        }
                        }
                    >
                        <MDBIcon icon="check" style={{fontSize: "2em"}}/>
                    </Button>


                </ModalFooter>
                <ReactTooltip/>
            </Modal>
        );
    }

    renderBeingWorkedOnBody() {
        let workedOnBy = ""
        if (this.state.currentOrder && this.state.currentOrder.id && this.state.currentOrder.currentlyBeingWorkedById && this.state.currentOrder.currentlyBeingWorkedBy) {
            console.log(this.state.currentOrder)
            workedOnBy = this.state.currentOrder.currentlyBeingWorkedBy.username
        }
        return (
            <div>
                This order is already being worked on by {workedOnBy}
            </div>
        );
    }

    createBtn(workedBy, id) {
        const {currentUser} = this.context;
        if (!workedBy) {
            return
        }

        if (workedBy === currentUser.firstname + " " + currentUser.lastname) {
            let ary = this.state.workedByMe;
            ary.push(id);
            this.setState({
                workedByMe: ary,
            })
            return (
                <
                    MDBIcon icon="backspace"
                            size={"2x"}
                            id="btn"
                            style={{color: "#6b93c4"}}
                            onClick={() => this.clearIsWorkingOn(id)}
                />
            );
        }
    }

    openOrder(id) {
        if (id) {
            OrderService.setBeingWorkedBy(id);
        }

        if (this.state.ctrl === true) {
            this.setState({
                targetLink: id,
            });

            setTimeout(() => {
                document.getElementById("orderListId").click();
            }, 5);
        } else {
            this.props.history.push({
                pathname: "/order/" + id,
                state: {
                    //order: this.state.currentOrder,
                    goto: "followUp",
                },
            });
        }
    }

    clearIsWorkingOnAll() {
        let ary = this.state.workedByMe
        ary.forEach((id, index) => {
            this.clearIsWorkingOn(id)
        });
        this.setState({
            workedByMe: [],
        })
    }

    togglePolicyModal() {
        let isOpen = this.state.policyModalOpen;

        this.setState({
            policyModalOpen: !isOpen,
            policyResults: [],
            policyText: "",
        })
    }

    searchByPolicyNumber() {
        let number = this.state.policyText;

        this.setState({
            searchPressed: true
        })

        ActivityService.getByPolicyNumber(number).then(r => {
            this.setState({
                policyResults: r,
                searchPressed: false,
            })
        }).catch(e => {
            this.setState({
                searchPressed: false,
            })
        })
    }

    clearIsWorkingOn(id) {
        OrderService.clearBeingWorkedBy(id);
    }

    checkIsWorkingOn(id) {
        const { currentUser } = this.context;
        this.setState({
            currentOrderId: id,
        })
        //return OrderService.getOrderActivity(id)
        return OrderService.getCurrentlyWorkingByOrder(id)
            .then((res) => {
                if (res && res !== currentUser.id) {
                    this.setState({
                        workedOnModal: true,
                        workingOnId: res,
                    })
                    return true
                }
                else {
                    this.openOrder(id)
                }
                //this.setState({
                //    currentOrder: res,
                //})
                //console.log(res)
                ////if (res) {
                //if (res && res.id && res.currentlyBeingWorkedById && res.currentlyBeingWorkedById !== currentUser.id) { // && res.currentlyBeingWorkedBy) {
                //    this.setState({
                //        workedOnModal: true,
                //    })
                //    return true
                //} else {
                //    this.openOrder()
                //}

            })
            .catch((err) => {
                return false
            });
    }


    openOrderFromPolicy(orderId) {


        this.setState({
            targetLink: orderId,
        });

        setTimeout(() => {
            document.getElementById("orderListId").click();
        }, 5);

        return;
        //this.props.history.push({
        //    pathname: "/order/" + orderId,
        //    state: {
        //        order: this.state.currentOrder,
        //        goto: "followUp",
        //    },
        //});
    }

    renderPolicyResults() {
        let r = this.state.policyResults;

        return (
            <div>
                {r.map(v => {
                    return (
                        <MDBCard className={"policyNumberRow"}
                                 onClick={() => this.openOrderFromPolicy(v.id)}>
                            <MDBRow>
                                <MDBCol>
                                    {v.patientName}
                                </MDBCol>
                                <MDBCol>
                                    {v.status}&nbsp; {v.orderStatusReason ? v.orderStatusReason.name : ""}
                                </MDBCol>

                                <MDBCol>
                                    {v.id}
                                </MDBCol>
                            </MDBRow>

                        </MDBCard>
                    )
                })}
            </div>
        )
    }


    renderPolicyModal() {
        return (
            <MDBModal isOpen={this.state.policyModalOpen} toggle={() => this.togglePolicyModal()}>
                <MDBModalHeader toggle={() => this.togglePolicyModal()}>
                    Search By Policy Number
                </MDBModalHeader>
                <MDBModalBody>
                    <MDBRow>
                        <MDBCol size={8}>
                            <MDBInput
                                outline
                                containerClass={"smallMargin"}
                                style={{paddingTop: 8}}
                                label={"Policy Number"}
                                value={this.state.policyText}
                                onChange={(e) => {
                                    this.setState({policyText: e.target.value})
                                }}/>
                        </MDBCol>
                        <MDBCol>
                            {this.state.searchPressed ?
                                <MDBSpinner small/> :

                                <MDBBtn
                                    color={'indigo'}
                                    size={"sm"}
                                    onClick={() => this.searchByPolicyNumber()}>
                                    <MDBIcon icon={"search"}/>
                                </MDBBtn>
                            }
                        </MDBCol>

                    </MDBRow>
                    <div style={{maxHeight: 300, overflowY: 'scroll', overflowX: 'hidden'}}>
                        {this.renderPolicyResults()}
                    </div>
                </MDBModalBody>
            </MDBModal>
        )
    }

    renderTable() {
        const Cell = ({row, column, ...props}) => {
            return (
                <Table.Cell
                    {...props}
                    onClick={(e) => {
                        if (e.target.id === "btn") {
                            return
                        }
                        if (e.ctrlKey === true) {
                            this.setState({
                                ctrl: true,
                            });
                        } else {
                            this.setState({
                                ctrl: false,
                            });
                        }
                        this.checkIsWorkingOn(row.id);
                    }}
                    style={{
                        cursor: "pointer",
                    }}
                />
            );
        };

        if (this.state.isLoaded === true) {
            return (
                <Grid
                    style={{maxWidth: "1800px !important"}}
                    rows={this.state.data.rows}
                    rootComponent={Root}
                    columns={this.state.data.columns}
                >
                    <DragDropProvider/>
                    <SortingState
                        defaultSorting={[{columnName: "timestamp", direction: "asc"}]}
                    />
                    <IntegratedSorting
                        columnExtensions={this.state.data.integratedSortingColumnExtensions}
                    />
                    <GroupingState
                        grouping={this.state.groupingColumns}
                        onGroupingChange={this.changeGrouping}
                    />
                    <IntegratedGrouping/>
                    <PagingState defaultCurrentPage={0} pageSize={25}/>
                    <IntegratedPaging/>
                    <VirtualTable cellComponent={Cell} height={"auto"}/>
                    <TableColumnResizing
                        defaultColumnWidths={this.state.defaultColumnWidths}
                    />
                    <TableHeaderRow showSortingControls/>
                    <PagingPanel/>
                    <TableGroupRow contentComponent={Content} showColumnsWhenGrouped/>
                    <ItemCounter/>
                    <Toolbar/>
                    <GroupingPanel/>
                </Grid>
            );
        } else {
            return <div/>;
        }
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }

    renderTableOrSpinner() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <div className={"purchasingGrid ordersListGrid"}
                 style={{height: 0.9 * window.innerHeight, backgroundColor: "white"}}>
                {this.renderTable()}
            </div>
        );
    }

    renderRegionSelect() {

        return (
            <Card style={{
                overflow: "visible",
                height: "50px",
                width: "98%",
                marginLeft: "1%",
                backgroundColor: "#5881C1",
                marginTop: "5px"
            }}>
                <Row>
                    <Col md={2}
                        style={{ paddingTop: "5px", marginLeft: "10px" }}
                    >
                        <Select
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            placeholder="Region"
                            options={this.state.regions || [{ label: "Loading Regions" }]}
                            onChange={(e) => this.handleSelectChange(e)}
                        />
                    </Col>
                    <Col md={6}></Col>
                    <Col md={2}>
                        <button
                            type="button"
                            class={"btn btn-primary clear-btn"}
                            data-tip={"This will allow you to search by insurance Policy Number"}
                            onClick={() => {
                                this.togglePolicyModal()
                            }}
                        >Find By Policy #
                        </button>
                    </Col>
                    <Col md={1}>
                        <button
                            disabled={this.state.workedByMe.length === 0}
                            type="button"
                            class={"btn btn-primary clear-btn"}
                            onClick={() => {
                                this.clearIsWorkingOnAll()
                            }}
                        >Clear My Orders
                        </button>
                    </Col>
                </Row>
            </Card>
        )
    }

    render() {
        return (
            <div>
                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={true}
                    autoClose={3000}
                    position={"top-right"}
                    style={{marginTop: "75px"}}
                />
                {this.renderPolicyModal()}

                <div style={{maxWidth: "98%", marginLeft: "1rem"}}>
                    <Row>
                        {this.renderRegionSelect()}
                    </Row>
                    <Row>
                        <Col>{this.renderTableOrSpinner()}</Col>
                    </Row>

                    <Link
                        id="orderListId"
                        to={{
                            pathname: `/order/${this.state.targetLink}`,
                            state: {goto: "followup"},
                        }}
                        target="_blank"
                        rel="opener"
                        style={{display: "none"}}
                        activeclassname="active"
                    />

                    <div className={"fixed-bottom downloadCsvDiv"}>
                        <Button
                            className={"downloadCsvButton"}
                            floating
                            size="sm"
                            color={"primary"}
                            data-tip={"Download CSV"}
                            onClick={this.clientCSV.bind(
                                this,
                                this.state.data,
                                "Follow-Up.csv"
                            )}
                        >
                            <MDBIcon icon="download" style={{fontSize: "2em"}}/>
                        </Button>
                        {this.renderBeingWorkedOnModal()}
                        <ReactTooltip/>
                    </div>
                </div>
            </div>
        );
    }
}
