import React from "react";
import {
	CardHeader,
	Col,
	Fa,
	Input,
	MDBContainer,
	MDBRow,
	Modal,
	ModalBody,
	ModalFooter,
	Row,
} from "mdbreact";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import NoteService from "../../Security/NoteService/noteService";
import globalFunctions from "../../Filters/GlobalFunctions";

export default class CreateNoteModal extends React.Component {
	constructor(props) {
		super(props);

		this.toggleNoteModal = props.toggleNoteModal;
		this.addNoteUpdateOrderCloseModal = props.addNoteUpdateOrderCloseModal;
		this.toggleProcessing = props.toggleProcessing;

		this.state = {
			saving: false,
			eOrder: props.eOrder,
			usedLiaisonKeys: [],
			resultingLiaisonKeys: [],
			existingMeeting: {},
			allNotes: props.allNotes,
			noteModalOpen: false,
			taskCompleted: true,
			noteAssignedTo: props.eOrder.activity.owner || null,
			noteAssignedToId: props.eOrder.activity.ownerId || null,
			usersList: [],
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		allUsers: PropTypes.array,
	};

	componentDidMount(){
		this.getUserList();
	}

	componentDidUpdate() {
		const { allUsers } = this.context;
		if (allUsers.length !== this.state.usersList.length) {
			this.getUserList();
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.noteModalOpen !== prevState.noteModalOpen) {
			return {
				noteModalOpen: nextProps.noteModalOpen,
				eOrder: nextProps.eOrder,
			};
		} else return null;
	}

	getUserList() {
		const { allUsers } = this.context;
		let a = [];

		allUsers.map(x => {
			return a.push({
				label: x.firstname + " " + x.lastname,
				value: x.id,
            })
        })
		this.setState({ usersList: a });
	}

	taskCompletedChange = (value) => {
		this.setState({ taskCompleted: value });
	};

	handleNoteUserSelected = (e) => {
		let user = { name: e.label, id: e.value };
		this.setState({
			noteAssignedTo: user,
		});
	};

	addNewNote() {
		if(this.state.saving){
			return
		}
		this.setState({
			saving:true
		})
		this.toggleProcessing();
		this.toggleNoteModal();
		//get the current user context that can be passed into the note DTO...BC
		const { currentUser } = this.context;

		let cb = currentUser; //{ id: currentUser.id, username: currentUser.username };

		let note = {
			task: !this.state.taskCompleted,
			completed: this.state.taskCompleted,
			//assignedTo: this.state.noteAssignedTo,
			assignedToId: this.state.noteAssignedTo ? this.state.noteAssignedTo.id : null,
			createdAt: globalFunctions.getUTCMoment(),
			createdBy: cb,
			createdById: cb.id,
			account: this.state.eOrder.activity.account,
			accountId: this.state.eOrder.activity.accountId,
			patientName: this.state.eOrder.patientName,
			type: "ActivityNote",
			activityId: this.state.eOrder.id,
			dueDate: null,
			isAutoVerification: this.props.isAutoVerification,
		};

		note.text = document.getElementById("newActivityNoteText").value;

		return NoteService.createActivityNote(this.state.eOrder.id, note)
			.then((res) => {

				if(res){
					//need to set the last note date because otherwise the save overrides it...BC
					//let ord = this.state.eOrder;
					//ord.activity.lastNoteDate = new Date();

					if(res.createdAt.indexOf("Z") > 0){
						res.createdAt = res.createdAt.split("Z")[0];
					}

					this.addNoteUpdateOrderCloseModal(res, this.state.eOrder);
					this.setState({saving:false})
				}
			})
			.catch((err) => {
				console.log(err);
				//Maybe send an error to the user?...BC
			});
	}

	renderNoteModal() {
		return (
			<MDBContainer>
				<MDBRow>
					<Modal
						centered
						isOpen={this.state.noteModalOpen}
						toggle={() => this.toggleNoteModal()}
					>
						<ModalBody style={{ backgroundColor: "#FFFFFF" }} className="mx-3">
							<CardHeader color={"indigo"} className="form-header text-center">
								Add New Note
							</CardHeader>
							<form className=" mx-3 grey-text">
								<textarea
									placeholder={"Add note text here."}
									autoFocus
									id={"newActivityNoteText"}
									style={{ width: "100%", height: "200px" }}
								>{this.props.text || ""}</textarea>
								<Row>
									<Col size={"6"}>
										<Input
											type="radio"
											gap
											label={"Note"}
											checked={this.state.taskCompleted}
											onClick={this.taskCompletedChange.bind(this, true)}
											id={"noteRadio"}
										/>
									</Col>

									<Col size={"6"}>
										<Input
											type="radio"
											gap
											label={"Task"}
											checked={!this.state.taskCompleted}
											onClick={this.taskCompletedChange.bind(this, false)}
											id={"taskRadio"}
										/>
									</Col>
								</Row>

								<br />
								<label> Assigned to (optional) </label>
								<Select
									placeholder={
										this.state.noteAssignedTo
											? this.state.noteAssignedTo.name
											: "Select User..."
									}
									options={this.state.usersList}
									onChange={this.handleNoteUserSelected.bind(this)}
								/>
								<br />

								<ModalFooter>
									<span
										className={"ptBtn saveBtn"}
										data-tip={"Add Note"}
										onClick={this.addNewNote.bind(this)}
									>
										<Fa size={"2x"} icon={"check"}>
											{" "}
										</Fa>
									</span>
									<span
										className={"ptBtn cancelBtn"}
										data-tip={"Cancel"}
										onClick={() => this.toggleNoteModal()}
									>
										<Fa size={"2x"} icon={"times"}>
											{" "}
										</Fa>
									</span>
								</ModalFooter>
								<ReactTooltip />
							</form>
						</ModalBody>
					</Modal>
				</MDBRow>
			</MDBContainer>
		);
	}

	render() {
		return <div>{this.renderNoteModal()}</div>;
	}
}
