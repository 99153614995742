import api from "../Security/api";

class EquipmentTrainingLinkService {
	async getAllTrainingLinks() {
		return new Promise((res, reject) => {
			let params = {
				page: 0,
				size: 100,
				paged: false,
			};
			api
				.get("/equipmentTrainingLink", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getVideosByProduct(id) {
		return new Promise((res, reject) => {
			let url = "/equipmentTrainingLinkByCategory/" + id;

			return api
				.get(url)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getVideosByCategory(id) {
		return new Promise((res, reject) => {
			let url = "/equipmentTrainingLinkByCategory/" + id;

			return api
				.get(url)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async updateTrainingLink(link) {
		return new Promise((res, reject) => {
			api
				.put("/equipmentTrainingLink/" + link.id, link)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createTrainingLink(link) {
		return new Promise((res, reject) => {
			api
				.post("/equipmentTrainingLink", link)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async deleteTrainingLink(id) {
		return new Promise((res, reject) => {
			api
				.delete("/equipmentTrainingLink/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	// calls proc spQueueTrainingVideos
	async fireEquipmentVideoProcess(filters) {
		return new Promise((res, reject) => {
			api
				.get("/equipmentTrainingLinkNative", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}
}

const equipmentTrainingLink = new EquipmentTrainingLinkService();

export default equipmentTrainingLink;
