import api from "../api";

class PurchaseQueueService {

	// calls TWO procs
	// calls proc spGetPurchaseQueue
	// calls proc spGetPurchaseQueueApprovals
	async getPurchaseQueueListing(status) {
		return new Promise((res, reject) => {
			api
				.get("/purchaseQueueListing", { status: status })
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	// calls proc spGetInventoryRequestQueue
	async getInventoryRequestListing() {
		return new Promise((res, reject) => {
			api
				.get("/inventoryQueue")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createPurchaseQueues(purchseQueues) {
		return new Promise((res, reject) => {
			api
				.post("/purchaseQueues", purchseQueues)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}
	async createPurchaseTracking(params) {
		return new Promise((res, reject) => {
			api
				.post("/purchaseTracking", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async findPurchaseTrackingById(orderId) {
		return new Promise((res, reject) => {
			api
				.get("/purchaseTrackingPOID/" + orderId)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async updatePurchaseTracking(params) {
		return new Promise((res, reject) => {
			api
				.post("/purchaseTrackingProcUpdate", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async findPurchaseQueuesByOrderId(orderId) {
		return new Promise((res, reject) => {
			api
				.get("/purchaseQueuesByOrder/" + orderId)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async findPurchaseQueuesById(pqId) {
		return new Promise((res, reject) => {
			api
				.get("/purchaseQueue/" + pqId)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async findPurchaseQueuesByIds(ids) {
		return new Promise((res, reject) => {
			api
				.get("/purchaseQueuesByIds", ids)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	// async createPurchaseQueue(vendor) {
	//     return new Promise((res,reject) => {
	//         api.post('/vendor', vendor).then(data => {
	//             res(data);
	//         }).catch(err => {
	//             reject();
	//         })
	//     })
	// }

	async approveAll(pqs) {
		return new Promise((res, reject) => {
			api
				.post("/approvePurchaseQueues", pqs)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					console.log(err);
					reject();
				});
		});
	}

	async approveInventoryRequests(pqs) {
		return new Promise((res, reject) => {
			api
				.post("/approveInventoryRequest", pqs)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					console.log(err);
					reject();
				});
		});
	}

	async editPurchaseQueue(pq) {
		return new Promise((res, reject) => {
			api
				.put("/purchaseQueue", pq)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async deletePurchaseQueue(id) {
		return new Promise((res, reject) => {
			api
				.delete("/purchaseQueue/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async orderExists(id) {
		return new Promise((res, reject) => {
			api.get("/orders/exists/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
    }
}

const purchaseQueueService = new PurchaseQueueService();

export default purchaseQueueService;
