import api from "../api";

class UserService {
	async getAllUsers() {
		return new Promise((res, reject) => {
			api
				.get("/allUsersAsync")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getAllTherapists() {
		return new Promise((res, reject) => {
			api
				.get("/findTherapists")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getAllSalesReps() {
		return new Promise((res, reject) => {
			api
				.get("/findSalesReps")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	getAllTechs() {
		return new Promise((res, reject) => {
			api
				.get("/canScheduleToDeliver")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async updateUser(user) {
		return new Promise((res, reject) => {
			api
				.put("/users", user)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async clearTokens(user){
		return new Promise((res, reject) => {
			api.delete("/usersTokens/" + user.id).then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async changePassword(params) {
		return new Promise((res, reject) => {
			api
				.put("/changePassword", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async updateUserAndSendEmail(user) {
		return new Promise((res, reject) => {
			api
				.put("/usersUpdateAndEmail/" + user.id, user)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getUser(id) {
		return new Promise((res, reject) => {
			api
				.get("/users/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getPCRLinks() {
		return new Promise((res, reject) => {
			api
				.get("/users/salesPCRLinks")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createPCRLink(link){
		return new Promise((res, reject) => {
			api
				.post("/users/salesPCRLinks", link)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async updatePCRLink(link){
		return new Promise((res, reject) => {
			api
				.put("/users/salesPCRLinks", link)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getUserGroups() {
		return new Promise((res, reject) => {
			api
				.get("/users/groups")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createUser(user) {
		return new Promise((res, reject) => {
			api
				.post("/users", user)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async findAllUsers() {
		let params = {
			page: 0,
			size: 1000,
			tpUsers: false,
		};
		return new Promise((res, reject) => {
			api
				.get("/users", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	// calls proc spGetUsers
	async findAllActiveUsers() {
		let params={includeAll: true}

		return new Promise((res, reject) => {
			api
				.get("/spActiveUsersList", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async findThirdPartyUsers() {
		return new Promise((res, reject) => {
			api
				.get("/users/findThirdPartyUsers")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createThirdPartyUser(user) {
		return new Promise((res, reject) => {
			api
				.post("/thirdPartyUsers", user)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getThirdPartyUser(id) {
		return new Promise((res, reject) => {
			api
				.get("/thirdPartyUsers/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async checkThirdPartyUser(contactId) {
		return new Promise((res, reject) => {
			api
				.get("/checkThirdPartyUsers/" + contactId)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getContactsByAccount(id) {
		return new Promise((res, reject) => {
			let params = {
					page: 0,
					size: 25,
				},
				url = "/sources/" + id + "/contacts";

			return api
				.get(url, params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async moveAccounts(accounts, newOwner) {
		return new Promise((res, reject) => {
			let params = {
				accounts: accounts || [],
				newOwnerId: newOwner.id || null,
			};

			return api
				.post("/moveAccounts", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async findAccountsForUsers(id) {
		console.log(id);
		return new Promise((res, reject) => {
			return api
				.get("/users/" + id + "/accounts")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async findUsersByLocation(id) {
		return new Promise((res, reject) => {
			return api
				.get("/findUsersByLocation/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

    async findTechsByLocation(id) {
        return new Promise((res,reject) => {
            return api.get('/findTechsByLocation/' + id).then(data => {
                res(data);
            }).catch( err => {
                reject();
            })
        })
    }

    async addUserReportFilters(filters){
        return new Promise((res,rej) => {
            return api.post('/users/userReportFilters', filters).then(data => {
                res(data);
            }).catch(err => {
                rej();
            })
        })
    }

	async removeUserReportFilter(id){
        return new Promise((res,rej) => {
            return api.delete('/users/userReportFilters/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            })
        })
	}

	async getMyFaxes(id) {
		return new Promise((res, rej) => {
			return api.get('/users/myFaxes/' + id).then(data => {
				res(data);
			}).catch(err => {
				rej();
			})
		})
	}

	async removeFax(id) {
		return new Promise((res, rej) => {
			return api.delete('/fax/' + id).then(data => {
				res();
			}).catch(err => {
				rej();
			})
		})
	}

	async getFaxImages(docId) {
		return new Promise((res, reject) => {
			return api
				.get("/fax/images/" + docId)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async getMyPcrReps(id) {
		return new Promise((res, reject) => {
			return api
				.get("/users/myReps/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async updatePreferences(prefs) {
		return new Promise((res, reject) => {
			return api
				.put("/users/updatePreferences", prefs)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async createPreferences(id, prefs) {
		return new Promise((res, reject) => {
			return api
				.post("/users/createPreferences/" + id, prefs)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async downloadFax(docId) {
		return new Promise((res, reject) => {
			return api
				.getFile("/fax/" + docId)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async mergeAndUploadFax(doc) {
		return new Promise((res, reject) => {
			return api
				.post("/fax/merge", doc)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

}

const userService = new UserService();

export default userService;
