import api from "./api";

class AuthService {
	login(credentials, otherCompany) {
		let company = credentials.company || "dev";
		return new Promise((res, reject) => {
			let baseURL = api.buildURL(company);
			//    let baseURL = api.buildURL('dev');
			fetch(baseURL + "/token", {
				method: "post",
				headers: {
					"Content-Type": "application/json;charset=utf-8",
				},
				body: JSON.stringify({
					username: credentials.username,
					password: credentials.password,
				}),
			})
				.then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response.json();
					} else {
						reject("Unable to login.  Check your credentials.");
					}
				})
				.then((response) => {
					if (!otherCompany) {
						response.company = company;
						window.localStorage.setItem(
							"credentials",
							JSON.stringify(response)
						);
					}
					res(response);
				})
				.catch((err) => {
					reject("Unable to login " + err);
				});
		});
	}

	validateCode(params, company, url) {
		let config = {
				method: "post",
				headers: { "Content-Type": "application/json;charset=utf-8" },
			},
			c = company || "dev";
		config.body = JSON.stringify(params);
		return new Promise((res, reject) => {
			let baseURL = api.buildURL(c);
			baseURL = baseURL + url + api.buildQueryParams(params);
			fetch(baseURL, config)
				.then((response) => {
					if (response.status >= 200 && response.status < 300) {
						return response.json();
					} else {
						reject();
					}
				})
				.then((response) => {
					res(response);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	logout(cb) {}

	async getMyUserDetails() {
		return new Promise((res, reject) => {
			api
				.get("/users/whoami")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async getPatientByIdFromOtherCompany(patientId, params, credentials) {
		return new Promise((res, reject) => {
			return api
				.getFromOtherCompany("/patients/" + patientId, params, credentials)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	setToken(obj) {
		return api.setToken(obj);
	}

}

const authService = new AuthService();

export default authService;
