import React from "react";
import { MDBCol, MDBRow } from "mdbreact";
import moment from "moment";

export default class approvalTab extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			order: props.order,
			productTypes: props.productTypes,
			productSubTypes: props.productSubTypes,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.order !== prevState.order) {
			return { order: nextProps.order };
		} else return null;
	}

	renderBasicInfo() {
		let o = this.state.order,
			p = o.patient;

		return (
			<div style={{ fontSize: ".9em" }}>
				<MDBRow style={{ justifyContent: "space-between" }}>
					<MDBCol size="3">{p.firstName + " " + p.lastName}</MDBCol>
					<MDBCol size="3">{p.dateOfBirth}</MDBCol>

					<MDBCol size="6">
						{p.address + " " + p.city + ", " + p.state + " " + p.zipCode}
					</MDBCol>
				</MDBRow>

				<MDBRow style={{ justifyContent: "space-between" }}>
					<MDBCol size="3">{p.phone}</MDBCol>
					<MDBCol size="3">{p.email}</MDBCol>
					<MDBCol size="6" />
				</MDBRow>
			</div>
		);
	}

	renderPrimaryInsurance() {
		let order = this.state.order;

		return (
			<MDBRow style={{ fontSize: ".9em" }}>
				<MDBCol size={"12"}>
					{order.insuranceType
						? order.insuranceType.name
						: "No Primary Payor Source"}
					{" / "}
					{order.insuranceSubType
						? order.insuranceSubType.name
						: "No Primary Insurance"}
				</MDBCol>

				<MDBCol size={"5"}>
					{"Policy # " + (order.insurancePolicy || "")}
				</MDBCol>

				<MDBCol size={"5"}>{"Group # " + (order.insuranceGroup || "")}</MDBCol>
			</MDBRow>
		);
	}

	renderSecondaryInsurance() {
		let order = this.state.order;

		return (
			<MDBRow style={{ fontSize: ".9em" }}>
				<MDBCol size={"12"}>
					{order.secondaryInsuranceType
						? order.secondaryInsuranceType.name
						: "No Secondary Payor Source"}
					{" / "}
					{order.secondaryInsuranceSubType
						? order.secondaryInsuranceSubType.name
						: "No Secondary Insurance"}
				</MDBCol>

				<MDBCol size={"5"}>
					{"Secondary Policy # " + (order.secondaryInsurancePolicy || "")}
				</MDBCol>

				<MDBCol size={"5"}>
					{"Secondary Group # " + (order.secondaryInsuranceGroup || "")}
				</MDBCol>
			</MDBRow>
		);
	}

	renderApprovalData() {
		let order = this.state.order,
			appr = order.orderActivityApprovals && order.orderActivityApprovals.length > 0 ? order.orderActivityApprovals[0].approvals : {};
			
		if (!appr) {
			return <div />;
		}

		return (
			<div style={{ fontSize: ".9em" }}>
				<MDBRow style={{ justifyContent: "space-between" }}>
					<MDBCol size="3">
						{appr.sameOrSimilar || "No Same or Similar Specified"}
					</MDBCol>
					<MDBCol size="3">{"Auth # " + (appr.authNumber || "")}</MDBCol>
					<MDBCol size="3">
						{appr.inFacility
							? "Patient is in Facility"
							: "Patient is NOT in Facility"}
					</MDBCol>
					<MDBCol size="3">
						{"Deliver By: " +
							(moment(appr.deliverByDate).format("MM/DD/YYYY") ||
								"Unspecified")}{" "}
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol size="3">Deductible: ${appr.deductible || 0}</MDBCol>
					<MDBCol size="3">Co Insurance: ${appr.coInsurance || 0}</MDBCol>
					<MDBCol size="3">
						Due at Delivery: ${appr.paymentOnDelivery || 0}
					</MDBCol>
					<MDBCol size="3">
						Total Responsibility: ${appr.patientResponsibility || 0}
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol size={"4"}>
						{appr.rental
							? "Patient is Renting Equipment"
							: "Patient is Purchasing Equipment"}
					</MDBCol>
					<MDBCol size={"4"}>
						{appr.financialHardship ? "Patient Has Financial hardship" : ""}
					</MDBCol>
				</MDBRow>
			</div>
		);
	}

	renderProductsTable() {
		let p = this.props.products,
			productTypes = this.state.productTypes,
			productSubTypes = this.state.productSubTypes;

		return (
			<div>
				<MDBRow style={{ paddingBottom: 4, textDecoration: "underline" }}>
					<MDBCol size="3">Category</MDBCol>

					<MDBCol size="6">Product</MDBCol>

					<MDBCol size="3">Serial</MDBCol>
				</MDBRow>

				{p.map((product) => {
					if (product.approved) {
						var pt = productTypes && product.productTypeId ? productTypes.filter(x => x.id === product.productTypeId)[0] : null;
						var pst = productSubTypes && product.productSubTypeId ? productSubTypes.filter(x => x.id === product.productSubTypeId)[0] : null;
						return (
							<MDBRow key={product.id} style={{ fontSize: ".8em" }}>
								<MDBCol size="3">
									{pt ? pt.name : ""}
								</MDBCol>
								<MDBCol size="6">
									{pst ? pst.name : ""}
								</MDBCol>
								<MDBCol size="3">{product.serial || ""}</MDBCol>
							</MDBRow>
						);
					}
					return null;
				})}
			</div>
		);
	}

	renderFieldNotes() {
		let order = this.state.order,
			appr = order.orderActivityApprovals[0];

		if (appr && appr.approvals && appr.approvals.fieldNotes) {
			return (
				<div>
					<br />
					<MDBRow>
						<MDBCol size={"12"}>
							<strong style={{ fontSize: "1em" }}>Field Notes</strong>
						</MDBCol>
						<MDBCol>
							<p>{appr.approvals.fieldNotes}</p>
						</MDBCol>
					</MDBRow>
				</div>
			);
		}
	}

	render() {
		let order = this.state.order;

		return (
			<div>
				<MDBRow>
					<MDBCol md="12" style={{ textAlign: "center" }}>
						<h5>
							<strong>
								Approved on {moment(order.approvalDate).format("MMM Do, YYYY")}
							</strong>
						</h5>
					</MDBCol>
				</MDBRow>

				<MDBRow>
					<MDBCol md="12">
						<strong style={{ fontSize: "1em" }}>Patient Information</strong>
					</MDBCol>
				</MDBRow>
				{this.renderBasicInfo()}
				<br />

				<MDBRow>
					<MDBCol md="12">
						<strong style={{ fontSize: "1em" }}>Insurance Information</strong>
					</MDBCol>
				</MDBRow>

				<MDBRow style={{ justifyContent: "space-between" }}>
					<MDBCol size="6">{this.renderPrimaryInsurance()}</MDBCol>

					<MDBCol size="6">{this.renderSecondaryInsurance()}</MDBCol>
				</MDBRow>

				<br />
				<MDBRow>
					<MDBCol size={"12"}>
						<strong style={{ fontSize: "1em" }}>Approval Information</strong>
					</MDBCol>
				</MDBRow>
				{this.renderApprovalData()}

				{this.renderFieldNotes()}

				<br />
				<MDBRow>
					<MDBCol size={"12"}>
						<strong style={{ fontSize: "1em" }}>Product Details</strong>
					</MDBCol>
				</MDBRow>
				{this.renderProductsTable()}
			</div>
		);
	}
}
